import api from '~/app/common/api'
import alert$ from '~/app/store/alert'
import { limitPerPage } from './constants'
import { UserLogsReducers } from './types'

export function fetchUserAuditLogs(store: any, self: object & UserLogsReducers) {
  return async ({ userId, page }: { userId: number; page: number }): Promise<void> => {
    store.dispatch(self.setUserLogsLoading())

    try {
      const offset = page * limitPerPage
      const response = await api.fetchUserAuditLogs(userId, limitPerPage, offset)

      await store.dispatch(self.setUserLogsLoaded({ data: response, page }))
    } catch (error) {
      store.dispatch(self.setUserLogsError())
      alert$.call.setNotification(String(error))
    }
  }
}

export function clearUserAuditLogs(store: any, self: object & UserLogsReducers) {
  return () => {
    store.dispatch(self.setUserLogsInitialState())
  }
}
