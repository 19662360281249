import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import moment from 'moment'
import { ProductType } from '~/app/common/types'
import { selectUserRoles } from '~/app/store/app/selectors'
import user$ from '~/app/store/user'
import Button from '~/app/components/Button/Button'
import sortByCreated from '~/app/utils/sortByCreated'
import ActivityTag from '~/app/components/ActivityTag'
import MagicWand from '~/app/components/Icons/MagicWand'
import UserProfileImage from '~/app/assets/img/user-profile-image.png'
import {
  HeaderSection,
  ProfileImage,
  Title,
  UserInfo,
  TagsLine,
  ImageWrapper,
  MagicWandSection,
  LinkButton,
  MagicLinkSection,
  MagicLinkNotification,
} from './styled'
import Subscription from '../../Users/UsersList/Item/Subscription'
import FeatureFlagTooltip from './FeatureFlagTooltip'
import ManualOrderLightBox from './ManualOrderLightBox'

const isSuperAdmin = (userRoles) => (userRoles || []).find((role) => role.name === 'superadmin')

function UserHeader(props) {
  const templates = props.subscriptions

  if (!props.user) {
    return null
  }

  return (
    <HeaderSection>
      <ProfileImage src={UserProfileImage} />
      <Title>
        {props.user.email} <FeatureFlagTooltip />
      </Title>

      <UserInfo>
        <li>ID#{props.user.id}</li>
        <li>Registered {moment(props.user.createdAt).format('DD MMM YYYY')}</li>
        <TagsLine>
          {templates.length > 0 ? (
            sortByCreated(templates.slice()).map((template) => (
              <ImageWrapper key={template.id}>
                <Subscription subscription={template} />{' '}
              </ImageWrapper>
            ))
          ) : (
            <ActivityTag status="incomplete" />
          )}
        </TagsLine>
      </UserInfo>

      {isSuperAdmin(props.userRoles) && (
        <MagicLinkSection>
          <MagicWandSection>
            <LinkButton onClick={props.generateMagicLink}>
              Generate Magic Link <MagicWand />
            </LinkButton>
          </MagicWandSection>

          {props.magicLink && (
            <>
              <MagicLinkNotification>Link has been generated!</MagicLinkNotification>
              <MagicLinkNotification danger>
                <strong>WARNING:</strong> Make sure to log out of the user’s account and double
                check that you are in the correct account prior to performing actions on behalf of
                any user
              </MagicLinkNotification>
              <Button primary small onClick={props.handleMagicLinkClick}>
                Go to User Account
              </Button>
            </>
          )}
        </MagicLinkSection>
      )}
      <MagicLinkSection>
        <LinkButton onClick={props.handleManualOrderModal}>Create Manual Order</LinkButton>
      </MagicLinkSection>
      {props.isManualModalOpen && (
        <ManualOrderLightBox
          isOpen={props.isManualModalOpen}
          closeHandler={props.handleManualOrderModal}
          handleTabChange={props.handleTabChange}
          activeTab={props.activeTab}
          pastOrders={props.pastOrders}
        />
      )}
    </HeaderSection>
  )
}

UserHeader.propTypes = {
  generateMagicLink: PropTypes.func.isRequired,
  handleMagicLinkClick: PropTypes.func.isRequired,
  handleManualOrderModal: PropTypes.func.isRequired,
  isManualModalOpen: PropTypes.bool,
  handleTabChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  pastOrders: PropTypes.array,
  magicLink: PropTypes.string,
  subscriptions: PropTypes.array,
  user: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  }),
  userRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
}

const mapStateToProps = (state) => ({
  magicLink: user$.getMagicLink(state),
  userRoles: selectUserRoles(state),
  pastOrders: user$.getPastOrders(state),
})

export default compose(
  connect(mapStateToProps),
  lifecycle({
    componentWillUnmount() {
      const { magicLink } = this.props

      if (magicLink) {
        user$.call.clearMagicLink()
      }
    },
  }),
  withState('isManualModalOpen', 'setManualModalOpen', false),
  withState('activeTab', 'setActiveTab', ProductType.blend),
  withHandlers({
    generateMagicLink:
      ({ user }) =>
      () => {
        user$.call.fetchMagicLink(user.email)
      },
    handleMagicLinkClick:
      ({ magicLink }) =>
      () => {
        window.open(magicLink, '_blank')
      },
    handleManualOrderModal:
      ({ isManualModalOpen, setManualModalOpen }) =>
      () => {
        setManualModalOpen(() => (isManualModalOpen ? false : true))
      },
    handleTabChange:
      ({ setActiveTab }) =>
      (tab) => {
        setActiveTab(() => tab)
      },
  }),
)(UserHeader)
