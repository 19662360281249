interface Props {
  onClick?: () => void
  className?: string
  fillColor?: string
}

export default function MerchIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 38.28 38.28"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Merch Icon</title>
      <desc>Little Spoon merch shop bag icon</desc>
      <g>
        <g>
          <rect fill="none" strokeWidth="0px" width="38.28" height="38.28" />
          <path
            fill={props.fillColor}
            strokeWidth="0px"
            d="m5.16,31.41c-.07.38.13.76.48.93l7.79,3.7s.02,0,.02,0c.11.05.23.08.34.08,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.04,0,.07,0,.11,0,.5-.07,12.43-1.64,18.74-4.76.3-.15.49-.46.48-.8l-.71-19.7s-.01-.04-.02-.07c0-.07-.03-.14-.05-.21-.02-.04-.03-.09-.05-.13-.04-.06-.09-.12-.14-.17-.02-.02-.04-.06-.06-.08,0,0-.02,0-.03-.01-.06-.05-.14-.07-.21-.1-.04-.01-.07-.04-.11-.05,0,0,0,0-.01,0-.04,0-3.34-.67-5.73-1.54-.14-1.87-.94-3.52-2.2-4.48-.97-.74-2.12-.98-3.23-.69-.71.19-1.34.6-1.87,1.14-.35-.58-.78-1.08-1.28-1.47-.99-.76-2.15-1.01-3.29-.7-2.14.57-3.64,2.95-3.64,5.78v2.47c-1.58.23-3.13.41-4.55.51-.01,0-.02,0-.03,0-.03,0-.06.02-.1.03-.09.02-.18.05-.27.11-.02,0-.04,0-.05.02-.02.01-.03.04-.05.06-.07.06-.13.13-.17.21-.02.03-.04.06-.06.1-.04.1-.06.21-.07.32,0,.02-.01.03,0,.05,0,.18.87,14.64.01,19.45ZM27.69,10.83l-1.2.26-.45.1v-.86c.55.18,1.11.35,1.66.51Zm-20.77,2.35l5.73,2.16c.14,2.84.76,12.48.39,18.6l-6.09-2.89c.62-4.84.15-13.79-.03-17.88Zm7.45,2.25l16.36-3.49.66,18.12c-5.08,2.33-13.67,3.76-16.65,4.21.4-6.09-.2-15.57-.37-18.83Zm2.6-2.31l-3.43.73-3.85-1.46c1.78-.23,3.6-.53,5.35-.84.66-.12,1.3-.24,1.92-.36v.28s0,1.65,0,1.65Zm7.34-3.45v1.51s0,.38,0,.38l-5.62,1.2v-1.35s0-.55,0-.55c2.68-.54,4.79-1.03,5.56-1.22.02,0,.04.02.06.03Zm-6.74-3.53c-.39.87-.6,1.89-.6,2.97v.33c-1.5.29-3.13.59-4.76.85v-2.21c0-1.99,1.02-3.76,2.36-4.12.78-.21,1.41.11,1.8.41.53.41.94,1.04,1.21,1.78Zm6.61,1.76s-.03,0-.05,0c-.04.01-1.87.45-4.5,1v-.82c0-.61-.08-1.2-.21-1.76.41-.67.96-1.16,1.58-1.33.75-.2,1.36.1,1.74.39.7.54,1.21,1.45,1.43,2.52Z"
          />
        </g>
      </g>
    </svg>
  )
}
