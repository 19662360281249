interface Props {
  className?: string
}

export default function BigPlanIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="63" viewBox="0 0 100 63" {...props}>
      <title>Big plan</title>
      <g fill="none" fillRule="evenodd" transform="translate(-12 -31)">
        <circle cx="62" cy="62" r="62" opacity="0.1" />
        <g transform="translate(13 33)">
          <ellipse cx="34.722" cy="30" fill="#DCE8F4" rx="30.278" ry="30" />
          <ellipse cx="30.278" cy="30" stroke="#2A313F" strokeWidth="1.5" rx="30.278" ry="30" />
          <path
            stroke="#2A313F"
            strokeWidth="1.5"
            d="M.993 21.64c12.82 4.59 22.5 6.885 29.037 6.885 6.536 0 16.215-2.295 29.036-6.886"
          />
          <path
            fill="#FFF"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M30.03 28.525v30.983"
          />
          <path
            fill="#49C5B1"
            stroke="#2A313F"
            strokeWidth="1.5"
            d="M23.094 14.262c-.646 2.788-2.398 3.717-5.257 2.788-4.289-1.394-4.537.198-4.413 2.61.124 2.412 3.126 6.201 6.398 4.307 3.272-1.895 4.964.513 7.445-4.596 1.655-3.406.264-5.109-4.173-5.109z"
          />
          <path
            fill="#49C5B1"
            d="M49.11 14.65c-3.334 2.329-5.576 2.088-6.726-.721-1.725-4.214-3.54-2.804-5.923-.337-2.382 2.467-4.06 8.198.29 8.489 4.349.291 3.124 3.79 10.189.38 4.71-2.274 5.433-4.877 2.17-7.811z"
          />
          <ellipse cx="11.664" cy="31.967" fill="#F9E07F" rx="1.737" ry="1.967" />
          <ellipse cx="19.11" cy="32.951" fill="#F9E07F" rx="1.737" ry="1.967" />
          <ellipse cx="15.139" cy="35.902" fill="#F9E07F" rx="1.737" ry="1.967" />
          <ellipse cx="17.621" cy="42.295" fill="#F9E07F" rx="1.737" ry="1.967" />
          <ellipse cx="21.095" cy="39.344" fill="#F9E07F" rx="1.737" ry="1.967" />
          <ellipse cx="22.088" cy="46.23" fill="#F9E07F" rx="1.737" ry="1.967" />
          <ellipse cx="11.664" cy="41.311" fill="#F9E07F" rx="1.737" ry="1.967" />
          <path
            stroke="#2A313F"
            strokeWidth="1.3"
            d="M36.978 32.459c.96 0 1.738.778 1.738 1.737v13.247a1.737 1.737 0 01-3.475 0V34.196c0-.96.778-1.737 1.737-1.737zM23.081 44.262c.959 0 1.737.881 1.737 1.968 0 1.086-.778 1.967-1.737 1.967-.96 0-1.738-.881-1.738-1.967 0-1.087.778-1.968 1.738-1.968zm-4.468-3.934c.96 0 1.738.88 1.738 1.967 0 1.087-.778 1.967-1.738 1.967s-1.737-.88-1.737-1.967c0-1.086.778-1.967 1.737-1.967zm-5.956-.984c.96 0 1.737.881 1.737 1.967 0 1.087-.777 1.968-1.737 1.968-.96 0-1.737-.881-1.737-1.968 0-1.086.778-1.967 1.737-1.967zm9.43-1.967c.96 0 1.738.88 1.738 1.967 0 1.087-.778 1.967-1.737 1.967-.96 0-1.737-.88-1.737-1.967 0-1.086.777-1.967 1.737-1.967zm-5.955-3.443c.959 0 1.737.881 1.737 1.968 0 1.086-.778 1.967-1.737 1.967-.96 0-1.738-.88-1.738-1.967 0-1.087.778-1.968 1.738-1.968zm3.97-2.95c.96 0 1.738.88 1.738 1.967 0 1.086-.778 1.967-1.738 1.967s-1.737-.88-1.737-1.967c0-1.087.778-1.967 1.737-1.967zM12.657 30c.96 0 1.737.88 1.737 1.967 0 1.087-.777 1.967-1.737 1.967-.96 0-1.737-.88-1.737-1.967 0-1.086.778-1.967 1.737-1.967z"
          />
          <path
            stroke="#2A313F"
            strokeWidth="1.5"
            d="M47.124 14.65c-3.334 2.329-5.576 2.088-6.726-.721-1.724-4.214-3.54-2.804-5.922-.337-2.383 2.467-4.061 8.198.289 8.489 4.35.291 3.125 3.79 10.19.38 4.709-2.274 5.432-4.877 2.17-7.811z"
          />
          <path
            fill="#49C5B1"
            d="M28.051 3.61c.777 3.975-.432 5.77-3.627 5.384-4.792-.58-4.233 1.654-2.908 4.885 1.325 3.23 6.086 7.449 8.264 3.882 2.177-3.567 4.996-.82 4.811-8.502-.123-5.12-2.303-7.004-6.54-5.649z"
          />
          <path
            stroke="#2A313F"
            strokeWidth="1.5"
            d="M28.436 4.053c.776 3.975-.432 5.77-3.627 5.383-4.792-.58-4.233 1.654-2.908 4.885 1.324 3.231 6.085 7.45 8.263 3.882 2.177-3.566 4.996-.82 4.811-8.502-.123-5.12-2.303-7.003-6.54-5.648z"
          />
          <rect width="3.474" height="16.721" x="34.249" y="33.443" fill="#FFB3AB" rx="1.737" />
          <path
            fill="#FF979D"
            d="M47.505 30.328a1.43 1.43 0 01.115 2.09l-9.517 9.893a1.65 1.65 0 01-2.244.123 1.43 1.43 0 01-.115-2.089l9.518-9.893a1.648 1.648 0 012.243-.124z"
          />
          <path
            fill="#FF979D"
            d="M38.957 33.603a1.658 1.658 0 012.216.476l7.79 11.23a1.455 1.455 0 01-.446 2.077 1.658 1.658 0 01-2.216-.477l-7.79-11.23a1.455 1.455 0 01.446-2.076z"
          />
          <path
            stroke="#2A313F"
            strokeWidth="1.7"
            d="M48.994 29.345a1.43 1.43 0 01.115 2.089l-9.517 9.893c-.6.624-1.58.678-2.244.124a1.43 1.43 0 01-.115-2.09l9.518-9.893a1.648 1.648 0 012.243-.123z"
          />
          <path
            stroke="#302D1F"
            strokeWidth="1.7"
            d="M39.95 32.62a1.658 1.658 0 012.216.475l7.79 11.231a1.455 1.455 0 01-.446 2.076 1.658 1.658 0 01-2.216-.476l-7.79-11.231a1.455 1.455 0 01.446-2.076z"
          />
        </g>
        <g transform="translate(49 32)">
          <ellipse cx="32" cy="30.5" fill="#FACE4E" rx="31" ry="30.5" />
          <ellipse
            cx="31"
            cy="30.5"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="31"
            ry="30.5"
          />
          <g transform="rotate(28 -60.976 58.853)">
            <rect width="3.5" height="7.379" fill="#49C5B1" rx="1.75" />
            <rect
              width="3.5"
              height="7.379"
              x="1"
              stroke="#2A313F"
              strokeLinejoin="round"
              strokeWidth="1.7"
              rx="1.75"
            />
          </g>
          <g transform="rotate(-33 82.678 -40.75)">
            <rect width="3.5" height="7.379" fill="#CBDA4C" rx="1.75" />
            <rect
              width="3.5"
              height="7.379"
              x="1"
              fill="#49C5B1"
              stroke="#2A313F"
              strokeLinejoin="round"
              strokeWidth="1.7"
              rx="1.75"
            />
          </g>
          <g transform="rotate(-120 31.336 4.522)">
            <rect width="5.5" height="11.315" y="0.984" fill="#49C5B1" rx="2.75" />
            <rect
              width="5.5"
              height="11.315"
              x="1.058"
              y="0.406"
              stroke="#2A313F"
              strokeLinejoin="round"
              strokeWidth="1.5"
              rx="2.75"
            />
          </g>
          <path
            fill="#F4AAAE"
            d="M32.5 18.315c8.008 0 14.5 6.387 14.5 14.266a14.02 14.02 0 01-1.296 5.903H19.296A14.02 14.02 0 0118 32.581c0-7.88 6.492-14.266 14.5-14.266z"
          />
          <path
            stroke="#2A313F"
            strokeWidth="1.5"
            d="M30.5 18.315c8.008 0 14.5 6.387 14.5 14.266a14.02 14.02 0 01-1.296 5.903H17.296A14.02 14.02 0 0116 32.581c0-7.88 6.492-14.266 14.5-14.266z"
          />
        </g>
      </g>
    </svg>
  )
}
