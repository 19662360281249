import moment from 'moment-timezone'

const printTime = (value, unit) => {
  if (value === 0) return ''
  return `${value} ${unit}${value > 1 ? 's' : ''} `
}

export const getAgeAtSignup = (birthdate, signupDate) => {
  if (signupDate === undefined) {
    return null
  }

  const years = Math.floor(moment(signupDate).diff(birthdate, 'years', true))
  const months = Math.floor(
    moment(signupDate).diff(moment(birthdate).add(years, 'years'), 'months', true),
  )
  const days = Math.floor(
    moment(signupDate).diff(
      moment(birthdate).add(months, 'months').add(years, 'years'),
      'days',
      true,
    ),
  )
  return `${printTime(years, 'year')}${printTime(months, 'month')}${printTime(days, 'day')}`
}
export default getAgeAtSignup
