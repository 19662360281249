import { isNumber, required, maxLength } from '~/app/common/validators'
import Currency from '~/app/components/InputTypes/Currency'
import InputRadios from '~/app/components/InputTypes/RadioButtons'
import InputTextArea from '~/app/components/InputTypes/Textarea'
import Switch from '~/app/components/InputTypes/Switch'
import Select from '~/app/components/InputTypes/Select'
const formDefinition = [
  {
    label: 'Amount',
    name: 'amount',
    type: Currency,
    validators: [isNumber, required],
  },
  {
    label: 'Note',
    name: 'note',
    type: InputTextArea,
    validators: [maxLength(255), required],
  },
  {
    label: 'Add or Remove Credits',
    name: 'addOrRemove',
    options: [
      {
        id: 1,
        name: 'Add',
        value: true,
      },
      {
        id: 2,
        name: 'Remove',
        value: false,
      },
    ],
    type: InputRadios,
    validators: [required],
  },
  {
    label: 'Notify User?',
    name: 'notify',
    textIfFalse: 'No',
    textIfTrue: 'Yes',
    type: Switch,
    value: false,
  },
  {
    hasEmptyOption: true,
    label: 'Reason for Credit *',
    name: 'mainCreditsReason',
    type: Select,
    validators: [required],
  },
  {
    hasEmptyOption: true,
    label: 'Sub Reason *',
    name: 'subCreditsReason',
    type: Select,
    validators: [required],
  },
]
export default formDefinition
