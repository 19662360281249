import Plan from '~/app/components/Plan'
import { Item } from './styled'
import type { ProductType } from '~/app/common/types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'
import moment from 'moment-timezone'
import type { ApiUpcomingOrder } from '~/app/store/user/deliverySchedule/types'
import { firstOrderStatus } from '~/app/common/constants'

interface Props {
  kid: {
    name: string
    subscriptions: Array<{
      id: number
      planId: number
      mealsPerDay: null | number
      mealsPerDelivery: null | number
      groupName: string
      type: ProductType
      active: boolean
      deletedAt: null | string
      deactivatedAt: null | string
      parentSubscription: null | number
      createdAt: string
    }>
  }
  plans: {
    id: number
    mealsPerDelivery: number
    mealsPerDay: number
    groupName: string
  }[]
  user: {
    id: number
  }
}

export default function SubscriptionsList({ kid, plans, user }: Props) {
  const findPlanById = (planId: number) => (plans || []).find((p) => p.id === planId)
  const findParentSubscriptionById = (id: number) => kid.subscriptions.find((p) => p.id === id)
  const getFirstOrderStatus = useGetFirstOrderStatus()

  return (
    <>
      {kid.subscriptions.map((subscription) => {
        const showPlanActionsButton = subscription.parentSubscription
          ? findParentSubscriptionById(subscription.parentSubscription)?.active
          : true
        return (
          <Item key={subscription.id}>
            <Plan
              kidName={kid.name}
              type={subscription.type}
              isActive={subscription.active}
              mealsPerDay={findPlanById(subscription.planId)?.mealsPerDay}
              mealsPerDelivery={findPlanById(subscription.planId)?.mealsPerDelivery}
              templateId={subscription.id}
              userId={user.id}
              // @ts-expect-error TODO: Fix error
              deactivatedAt={subscription.deactivatedAt}
              // @ts-expect-error TODO: Fix error
              parentSubscription={subscription.parentSubscription}
              // @ts-expect-error TODO: Fix error
              deletedAt={subscription.deletedAt}
              createdAt={subscription.createdAt}
              // @ts-expect-error TODO: Fix error
              showPlanActionsButton={showPlanActionsButton}
              firstOrderStatus={getFirstOrderStatus(subscription.id)}
            />
          </Item>
        )
      })}
    </>
  )
}

/**
 * Returns a function that checks for the first order of a subscription(template) to see if it's pending, charged, or canceled
 */
function useGetFirstOrderStatus(): (templateId: number) => string {
  const upcomingOrders: ApiUpcomingOrder[] = useMappedState(user$.getUpcomingOrders)
  const getFirstOrderStatus = (templateId: number) => {
    const now = moment.utc()
    let orderStatusText = ''
    const upcomingOrdersForTemplate = upcomingOrders.find(
      (subscription) => subscription.templateId === templateId,
    )?.orders
    const firstOrder = upcomingOrdersForTemplate?.find((order) => order.isFirst)
    const firstEditableMondayAfter = firstOrder && moment.utc(firstOrder.editableTo).add(2, 'days')
    const firstEditableHourAfter = firstOrder && moment.utc(firstOrder.editableTo).add(1, 'hour')

    if (
      firstOrder?.chargeId &&
      firstOrder?.canceledAt &&
      firstEditableMondayAfter &&
      now < firstEditableMondayAfter
    ) {
      orderStatusText = firstOrderStatus.canceled
    } else if (firstOrder?.chargeId && firstEditableHourAfter && now < firstEditableHourAfter) {
      orderStatusText = firstOrderStatus.charged
    } else if (firstOrder && !firstOrder.chargeId) {
      orderStatusText = firstOrderStatus.pending
    }

    return orderStatusText
  }

  return getFirstOrderStatus
}
