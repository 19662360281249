import styled from 'styled-components'
import { TooltipProps } from '..'
import placeTooltip from './placeTooltip'
import placeTooltipArrow from './placeTooltipArrow'

export const Wrap = styled.div`
  position: relative;
  text-align: center;
`

export const TooltipBox = styled.div.attrs(({ options = {} }: TooltipProps) => ({
  active: options.active,
  noWrap: options.noWrap,
  position: options.position,
  width: options.width,
}))`
  position: absolute;
  ${(props) => placeTooltip(props.position)}
  width: ${(props) => (props.noWrap ? 'max-content' : props.width)};
  padding: 12px 20px;
  border-radius: 4px;
  background: ${(props) => props.theme.color.darkGray};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.14);
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.color.white};
  text-transform: uppercase;
  transform: translate(-50%, 0);
  z-index: 1;
  display: none;
  margin-top: 10px;
  pointer-events: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    ${(props) => placeTooltipArrow(props.position)}
    width: 15px;
    height: 15px;
    background: ${(props) => props.theme.color.darkGray};
    transform: translate(-50%, 0) scaleX(0.66) rotate(45deg);
    border-radius: 2px;
  }

  ${
    /* @ts-ignore */
    Wrap
  }:hover & {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`
