import PlusIcon from '~/app/assets/svg/PlusIcon'
import { StyledButton } from './styled'

type Props = {
  text: string
  handleClick: () => void
}

export default function AddElementRow({ text, handleClick }: Props) {
  return (
    <StyledButton onClick={handleClick}>
      <PlusIcon color="#d8dce3" />
      {text}
    </StyledButton>
  )
}
