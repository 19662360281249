import { compose, pure, withHandlers } from 'recompose'
import moment from 'moment-timezone'
import { omit } from 'ramda'
import { CustomizedDatePicker, PostponeDatePickerWrapper } from './styled'

const Monday = 1

function getNextMonday() {
  return moment(new Date()).isoWeekday(7).hour(0).minute(0).second(0)
}

// set nextDelivery cutoff to today if Monday or following Monday
function getNextDeliveryCutoff() {
  const now = new Date()
  return moment(now).day() === Monday ? moment(now) : getNextMonday()
}

// only display Wednesdays that fall after the next upcoming delivery
function availableToDeliver(date: moment.Moment): boolean {
  const isWednesday = date.day() === 3
  return isWednesday && date.isAfter(getNextDeliveryCutoff())
}

interface Props {
  handleChange: () => void
  onChange: () => void
  label: string
  placeholder?: string
  value?: string | null | moment.Moment
  defaultValue?: string | null | moment.Moment
  postpone?: boolean
}

function DateInput({
  value,
  label,
  placeholder,
  handleChange,
  defaultValue,
  postpone = false,
  ...restProps
}: Props) {
  // check to see if returned Date component should be default or postpone format
  if (postpone) {
    return (
      <PostponeDatePickerWrapper>
        <CustomizedDatePicker
          // @ts-ignore
          selected={value ? moment(value) : null}
          // @ts-ignore
          label={label}
          onChange={handleChange}
          // @ts-ignore
          filterDate={availableToDeliver}
          {...omit(['onChange'], restProps)}
          // @ts-ignore
          inline
        />
      </PostponeDatePickerWrapper>
    )
  }

  return (
    <CustomizedDatePicker
      // @ts-ignore
      selected={value || defaultValue ? moment(value || defaultValue) : null}
      // @ts-ignore
      label={label}
      placeholder={placeholder}
      onChange={handleChange}
      {...omit(['onChange'], restProps)}
    />
  )
}

DateInput.defaultProps = {
  inputType: 'text',
  label: '',
  value: null,
}

export default compose(
  pure,
  withHandlers({
    // @ts-ignore
    handleChange:
      ({ onChange }) =>
      (value: moment.Moment) => {
        onChange(value ? value.toISOString() : null)
      },
  }),
  // @ts-ignore
)(DateInput)
