import { connect } from 'react-redux'
import { compose, withStateHandlers, withHandlers, lifecycle } from 'recompose'
import { map, filter, identity, pipe } from 'ramda'
import withModal from '~/app/hocs/withModal/index'
import user$ from '~/app/store/user'
import faultyOrderReasons$ from '~/app/store/faultyOrderReasons'
import {
  selectGiftProducts,
  selectGiftProductsLoaded,
  selectGiftProductsLoading,
} from '~/app/store/products/selectors'
import { fetchGiftProducts } from '~/app/store/products/actions'
import FaultyOrderLightBox from './FaultyOrderLightBox'
import type { Order, OrderDeliveryIssues } from './types'

export interface Props {
  setFaultyOrder: (order: Order) => { faultyOrder: Order }
}

export default compose(
  connect(
    (state) => ({
      faultyOrderReasons: faultyOrderReasons$.getReasons(state),
      giftProducts: selectGiftProducts(state),
      giftProductsLoaded: selectGiftProductsLoaded(state),
      giftProductsLoading: selectGiftProductsLoading(state),
    }),
    {
      fetchGiftProducts,
    },
  ),
  lifecycle({
    componentDidMount() {
      faultyOrderReasons$.call.fetchFaultyOrderReasons()

      if (!this.props.giftProductsLoaded) {
        this.props.fetchGiftProducts(100, 0)
      }
    },
  }),
  withStateHandlers(
    {
      faultyOrder: null,
    },
    {
      setFaultyOrder: () => (order) => ({
        faultyOrder: order,
      }),
      unsetFaultyOrder: () => () => ({
        faultyOrder: null,
      }),
    },
  ),
  withHandlers({
    handleFaultyOrderConfirm: (props) => (data) => {
      const { id: orderId } = props.faultyOrder
      const parseIds = pipe(
        filter(identity),
        map((id) => Number(id)),
      )
      const formattedData: Omit<OrderDeliveryIssues, 'reporter'> = {
        issueIds: parseIds(data.issueIds) as number[],
        note: data.note,
        photosProvided: Boolean(data.photosProvided),
        resent: Boolean(data.resent),
        products: data.products,
        intercomLink: data.intercomLink,
        photos: data.photos,
      }

      if (data.extraProductIds && data.resent) {
        formattedData.extraProductIds = [Number(data.extraProductIds)]
      }

      user$.call.setFaultyOrder({
        orderId,
        userId: props.userId,
        resend: formattedData.resent,
        ...formattedData,
      })
      props.unsetFaultyOrder()
    },
  }),
  withModal(
    (props) => props.faultyOrder && props.faultyOrderReasons,
    (props) => (
      <FaultyOrderLightBox
        isOpen
        onCancel={props.unsetFaultyOrder}
        onConfirm={props.handleFaultyOrderConfirm}
        faultyOrder={props.faultyOrder}
        faultyOrderReasons={props.faultyOrderReasons}
        giftProducts={props.giftProducts}
      />
    ),
  ),
)
