import styled from 'styled-components'

export default styled.span`
  background-color: ${(props: any) => props.color};
  ${(props: any) =>
    props.status === 'partially paused'
      ? `background-image: linear-gradient(to right, ${props.theme.color.yellow}, ${props.theme.color.lightGray})`
      : ''};
  border-radius: 100px;
  color: ${(props) => props.theme.color.white};
  padding: 6px 12px;
  text-transform: capitalize;
`
