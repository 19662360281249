/**
 * sets fetched shipping box data into redux object
 *
 * @param data - fetches box data
 */
export const setShippingBoxes = (state: any) => (data: any) => ({
  ...state,
  data,
})

/**
 * sets loading status of fetched shipping box data into redux object
 *
 * @param status - status of fetch call
 */
export const setStatus = (state: any) => (status: 'pending' | 'loading' | 'loaded' | 'error') => ({
  ...state,
  status,
})
