import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

/**
 * Fetches user feature flags and passes it to the reducer
 *
 * @param store - Root store
 * @param self$ - userFeatureFlag store
 */
export function fetchUserFeatureFlags(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    loader$.call.show()
    await self$.call.setUserFeatureFlagsLoading()
    try {
      const response = await Api.fetchUserFeatureFlags(userId)
      self$.call.setUserFeatureFlagsLoaded(response)
    } catch (error: any) {
      alert$.call.setNotification(error.message)
      self$.call.setUserFeatureFlagsError()
    }
    loader$.call.hide()
  }
}
