import PropTypes from 'prop-types'
import { compose, pure, withHandlers } from 'recompose'
import Checkmark from '~/app/assets/svg/Checkmark'
import { Box } from '~/app/components/InputTypes/Checkbox/styled'
import { Wrap, Option, Label } from './styled'

const RadioButtons = ({ value, options, handleClick }) => (
  <Wrap>
    {options.map((option) => (
      <Option key={option.id} onClick={handleClick(option)}>
        <Box checked={value === option.value}>
          <Checkmark color="#fff" />
        </Box>
        <Label>{option.name}</Label>
      </Option>
    ))}
  </Wrap>
)

RadioButtons.defaultProps = {
  value: '',
}
RadioButtons.propTypes = {
  handleClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
}
export default compose(
  pure,
  withHandlers({
    handleClick:
      ({ onChange, value }) =>
      (option) =>
      () => {
        onChange(option.value !== value ? option.value : undefined)
      },
  }),
)(RadioButtons)
