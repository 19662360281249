import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { isEqual } from 'lodash'
import Spinner from '~/app/assets/svg/Spinner'
import Checkmark from '~/app/assets/svg/Checkmark'
import { theme } from '~/app/settings/colors'
import { ButtonContainer, IconContainer } from './styled'

interface Props {
  center?: boolean
  isNew: boolean
  handleClick?: () => void
  hasChanges: boolean
  isSaving?: boolean
  saved?: boolean
  isValid: boolean
  model?: object
  width?: number
}

function SaveButton({
  center,
  isNew,
  handleClick,
  hasChanges,
  isSaving,
  saved,
  isValid,
  width,
}: Props) {
  return (
    <ButtonContainer
      // @ts-ignore
      className={`${isSaving && !saved ? 'saving' : ''} ${saved ? 'saved' : ''}`}
      onClick={handleClick}
      disabled={!hasChanges || !isValid}
      primary
      // @ts-ignore
      large
      center={center}
      width={width}
    >
      {(isSaving || saved) && (
        <IconContainer>
          {saved && <Checkmark color={theme.color.green} />}
          {isSaving && !saved && <Spinner />}
        </IconContainer>
      )}
      {!isSaving && !saved && (isNew ? 'Create' : 'Save')}
      {isSaving && !saved && 'Saving'}
      {saved && 'Saved'}
    </ButtonContainer>
  )
}

export default compose<Props, { handleConfirm?: () => void } & Props>(
  withState('isSaving', 'setSaving', false),
  withState('saved', 'setSaved', false),
  withHandlers({
    // @ts-ignore
    handleClick:
      ({ setSaving, handleConfirm, isValid }) =>
      () => {
        if (isValid) {
          setSaving(() => true)
        }
        handleConfirm()
      },
  }),
  lifecycle({
    componentDidUpdate() {
      if (this.props.saved) {
        this.props.setSaved(() => false)
      }
    },
    // TODO: Refactor in https://littlespoon.atlassian.net/browse/LS-3473
    // eslint-disable-next-line sort-keys
    UNSAFE_componentWillReceiveProps(newProps: any) {
      if (!isEqual(this.props.model, newProps.model)) {
        this.props.setSaving(() => false)
        this.props.setSaved(() => true)
      } else if (this.props.isValid !== newProps.isValid && !newProps.isValid) {
        this.props.setSaving(() => false)
        this.props.setSaved(() => false)
      }
    },
  }),
  // @ts-ignore
)(SaveButton)
