import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'
import { fetchWarehouses } from '~/app/store/warehouses/actions'
import { selectWarehouses } from '~/app/store/warehouses/selectors'
import { selectCarriers, selectCarrierServices } from './selectors'
import { fetchCarriers, fetchCarrierServices } from './actions'

const mapStateToProps = (state) => ({
  carrierServices: selectCarrierServices(state),
  carriers: selectCarriers(state),
  warehouses: selectWarehouses(state),
})

const mapDispatchToProps = {
  fetchCarrierServices,
  fetchCarriers,
  fetchWarehouses,
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ user }) => ({
    billingAddress: user.addresses.find((address) => address.type === 'billing'),
    shippingAddress: user.addresses.find((address) => address.type === 'shipping'),
  })),
  lifecycle({
    componentDidMount() {
      const { user, fetchCarriers, fetchCarrierServices, fetchWarehouses } = this.props
      const shipping = user.addresses.find((address) => address.type === 'shipping')
      const carrier = user.carrier || (shipping && shipping.shippingInfo.carrier)

      if (carrier) {
        fetchCarriers()
        fetchCarrierServices(user.carrier || (shipping && shipping.shippingInfo.carrier))
        fetchWarehouses()
      }
    },
  }),
)
