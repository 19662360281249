import styled from 'styled-components'
export const Wrap = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow: hidden;
  width: calc(100vw - 226px);
`
export const Body = styled.div`
  flex-grow: 1;
  overflow: auto;
`
export const Container = styled.div`
  max-width: 100%;
  padding: 0 70px 70px 70px;
  margin: 50px auto 0 auto;
  min-width: 1100px;
`
