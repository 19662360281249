import { createAction } from '~/app/common/actions'
import {
  FETCH_CARRIERS,
  FETCH_CARRIERS_SUCCESS,
  FETCH_CARRIERS_FAIL,
  FETCH_CARRIER_SERVICES,
  FETCH_CARRIER_SERVICES_SUCCESS,
  FETCH_CARRIER_SERVICES_FAIL,
} from './constants'
export const fetchCarriers = () => createAction(FETCH_CARRIERS)
export const fetchCarriersSuccess = (data) =>
  createAction(FETCH_CARRIERS_SUCCESS, {
    data,
  })
export const fetchCarriersFail = () => createAction(FETCH_CARRIERS_FAIL)
export const fetchCarrierServices = (carrier) =>
  createAction(FETCH_CARRIER_SERVICES, {
    carrier,
  })
export const fetchCarrierServicesSuccess = (data) =>
  createAction(FETCH_CARRIER_SERVICES_SUCCESS, {
    data,
  })
export const fetchCarrierServicesFail = () => createAction(FETCH_CARRIER_SERVICES_FAIL)
