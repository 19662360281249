import styled from 'styled-components'
import Button from '~/app/components/Button/Button'

export const IconWrapper = styled.figure`
  display: block;
  margin-bottom: 37px;
`

export const Content = styled.div`
  position: relative;
  padding: 40px 30px;
  margin: 40px auto;
  background: #fff;
  border-radius: 20px;
  width: ${(props: any) => (props.small ? '320px' : '550px')};
  max-width: 90vw;
  text-align: center;
`

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 28px;
  color: ${(props) => props.theme.darkGray};
  ${(props) =>
    // @ts-ignore
    props.slim &&
    `
    font-weight: 500;
  `};
`

export const Text = styled.p`
  padding: 0 15px;
  margin-bottom: 50px;
  color: ${(props) => props.theme.gray};
  font-size: 15px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${(props) =>
    // @ts-ignore
    props.slim &&
    `
    justify-content: space-between;
    button {
      width: calc(50% - 7px);
    }
  `};
`

export const SecondaryButton = styled(Button)`
  margin-right: 4px;
`
