import { Wrap, Element, Text, Title } from './styled'

type Props = {
  title?: string
  text?: string
  textFontSize?: number
  textIfFalse?: string
  textIfTrue?: string
  value: boolean
  onChange: (event: any) => void
  disabled?: boolean
}
export default function Switch({
  value = false,
  title = '',
  text = '',
  textIfTrue = '',
  textIfFalse = '',
  textFontSize = 14,
  onChange,
  disabled = false,
}: Props) {
  const self = useController(onChange, value, disabled)

  return (
    <Wrap>
      <Title>{title}</Title>
      <Element on={value} onClick={self.handleChange}>
        <div />
      </Element>
      <Text textFontSize={textFontSize}>
        {text || null}
        {value ? textIfTrue : textIfFalse}
      </Text>
    </Wrap>
  )
}

function useController(onChange: (event: any) => void, value: boolean, disabled: boolean) {
  const handleChange = () => {
    return !disabled && onChange(value ? undefined : true)
  }

  return {
    handleChange,
  }
}
