interface Props {
  className?: string
}

export default function ArrowUp({ className }: Props) {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Arrow up</title>
      <path
        d="M6.635.284L.325 6.207c-.412.386-.437 1.03-.05 1.467.386.413 1.03.438 1.467.05L7.33 2.447 12.944 7.7c.412.385 1.056.36 1.468-.052.386-.413.36-1.056-.052-1.468L8.05.258C7.846.103 7.588 0 7.33 0c-.258 0-.515.103-.695.284z"
        fill="#6C8498"
        fillRule="evenodd"
      />
    </svg>
  )
}
