import { merge } from 'ramda'
import {
  FETCH_CARRIERS,
  FETCH_CARRIERS_SUCCESS,
  FETCH_CARRIER_SERVICES,
  FETCH_CARRIER_SERVICES_SUCCESS,
} from './constants'
const initialState = {
  carrierServices: [],
  carrierServicesLoaded: false,
  carrierServicesLoading: false,
  carriers: [],
  carriersLoaded: false,
  carriersLoading: false,
}

function deliveryInfoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CARRIERS:
      return merge(state, {
        carriers: [],
        carriersLoaded: false,
        carriersLoading: true,
      })

    case FETCH_CARRIERS_SUCCESS:
      return merge(state, {
        carriers: action.payload.data,
        carriersLoaded: true,
        carriersLoading: false,
      })

    case FETCH_CARRIER_SERVICES:
      return merge(state, {
        carrierServices: [],
        carrierServicesLoaded: false,
        carrierServicesLoading: true,
      })

    case FETCH_CARRIER_SERVICES_SUCCESS:
      return merge(state, {
        carrierServices: action.payload.data,
        carrierServicesLoaded: true,
        carrierServicesLoading: false,
      })

    default:
      return state
  }
}

export default deliveryInfoReducer
