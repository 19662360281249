import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Row = styled.div`
  display: flex;
  padding: 3px 0;

  select {
    margin-right: 18px;
  }

  > div:nth-child(2) {
    margin-right: 18px;
  }
`

export const DeleteButton = styled.button`
  border: 0;
  height: 50px;
`
