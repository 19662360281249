import { makeModel } from '~/app/utils/storeManager'

const loader$ = makeModel({
  prefix: 'showAppLoader',
  reducers: () => ({
    hide() {
      return false
    },
    show() {
      return true
    },
  }),
  selectors: {
    get: (state: any) => state.showAppLoader,
  },
  state: false,
})

export default loader$
