export default function LogoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <title>Logout</title>
      <path
        fill="#949AAE"
        d="M392 216c-17.8 17.6-36.3 34.7-53.3 53-11.2 12-22.7 28.2-39.3 15.8-20.1-15-.3-27 8.8-38.7 3.8-4.9 10.3-8.7 6.1-21.5-46.9 0-95.4.4-143.9-.2-14.7-.2-37.4 5.9-37.8-16.9-.4-23.5 21.7-22.2 39.1-22.2 47.4-.1 94.9 0 142.2 0 0-5.6.9-8.5-.1-10.4-8.1-15.7-37.6-29.3-14.4-48.7 20.3-17 31.3 10.4 43.6 21.9 17.4 16.2 32.7 34.6 49 52V216z"
      />
      <path
        fill="#949AAF"
        d="M31.9 206.2c0-44 1.3-88-.4-132-1.2-30.4 8.5-44.4 40.9-42.6 37.2 2 74.6.5 112 .5 14.7 0 33.6-2.1 36.7 15.7 4 22.8 11.6 49.6-6.1 68.9-15.4 16.7-29.9 1.7-29.4-13.7.9-28.9-12.7-31.7-36.2-31.7-78.1-.1-78.1-.8-78.1 77 0 54.7.8 109.3-.3 164-.4 21.9 6 32.4 29.3 30.4 15.9-1.4 32-1 48-.1 22.6 1.4 40.3-.7 36.4-31-1.5-11.5 4.6-21.7 17.6-22.3 15.3-.8 19.3 10.3 21.4 23.4 8.5 53.4-.9 65.2-53.3 65.3-33.3.1-66.7-1.2-100 .4-30 1.5-40-11.2-38.9-40.2 1.7-44 .4-88 .4-132z"
      />
    </svg>
  )
}
