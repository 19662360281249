import { Observable } from 'rxjs/Observable'
import { noop } from '~/app/common/actions'
import { showNotification } from '~/app/store/notifications/actions'
import {
  initializeSuccess,
  loginSuccess,
  loginFailure,
  refreshAccessToken,
  refreshAccessTokenSuccess,
  refreshAccessTokenFailure,
} from './actions'
import { INITIALIZE, LOGIN, LOGOUT, REFRESH_TOKEN } from './constants'

const initializationEpic = (action$, store, { Api }) =>
  action$.ofType(INITIALIZE).switchMap(() => {
    const refreshToken = Api.getRefreshToken()

    if (refreshToken) {
      return Observable.of(refreshAccessToken(refreshToken))
    }

    return Observable.of(initializeSuccess())
  })

const loginEpic = (action$, store, { Api }) =>
  action$.ofType(LOGIN).switchMap((action) =>
    Observable.fromPromise(Api.login(action.payload))
      .switchMap((result) => Observable.of(loginSuccess(result), initializeSuccess()))
      .catch(() =>
        Observable.of(showNotification('Unauthorized Access'), loginFailure(), initializeSuccess()),
      ),
  )

const logoutEpic = (action$, store, { Api }) => action$.ofType(LOGOUT).do(Api.logout).map(noop)

const refreshTokenEpic = (action$, store, { Api }) =>
  action$.ofType(REFRESH_TOKEN).switchMap((action) =>
    Observable.fromPromise(Api.refreshAccessToken(action.payload))
      .switchMap(() => Observable.of(refreshAccessTokenSuccess(), initializeSuccess()))
      .catch((e) =>
        Observable.of(showNotification(e), refreshAccessTokenFailure(), initializeSuccess()),
      ),
  )

export default [initializationEpic, loginEpic, logoutEpic, refreshTokenEpic]
