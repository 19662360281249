import ReactPaginate, { type ReactPaginateProps } from 'react-paginate'
import PagingPreviousIcon from '~/app/assets/svg/PagingPrevious'
import PagingNextIcon from '~/app/assets/svg/PagingNext'

interface Props {
  activePage: number
  pageCount: number
  pageRangeDisplayed: number
  onClick: ReactPaginateProps['onClick']
  onPageChange: ReactPaginateProps['onPageChange']
}

export function Pagination(props: Props) {
  return (
    <ReactPaginate
      forcePage={props.activePage}
      pageCount={props.pageCount}
      pageRangeDisplayed={props.pageRangeDisplayed}
      onClick={props.onClick}
      onPageChange={props.onPageChange}
      previousLabel={<PagingPreviousIcon />}
      nextLabel={<PagingNextIcon />}
    />
  )
}
