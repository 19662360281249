import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { ApiAbbreviatedState } from '~/app/common/api/types'

/**
 * fetches all available boxes with state
 */
export function fetchActiveBoxesByState(store: any, self$: any) {
  return async (): Promise<void> => {
    await self$.call.setStatus('loading')
    try {
      const response = await Api.fetchBoxesByState()
      await store.dispatch(self$.setActiveBoxesByState(response.data))
      await self$.call.setStatus('loaded')
    } catch (error: any) {
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}

/**
 * updates link between shipping box and state
 *
 * @param payload - object contain boxId and boxState to update
 */
export function editBoxFromState(store: any, self$: any) {
  return async (payload: { boxId: number; boxState: ApiAbbreviatedState }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.editBoxesFromState({ boxId: payload.boxId, state: payload.boxState })
      const response = await Api.fetchBoxesByState()
      self$.call.setActiveBoxesByState(response.data)
      self$.call.setStatus('loaded')
    } catch (error: any) {
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}
