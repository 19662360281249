import styled from 'styled-components'
import EditIcon from '~/app/assets/svg/EditIconFull'

export const Title = styled.h3`
  color: ${(props) => props.theme.color.darkGray};
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.5px;
  margin: 30px 0 45px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`

export const SpacerRow = styled.tr`
  height: 38px;
`

export const SpacerCol = styled.td`
  width: 21px;
`

export const EditBtn = styled(EditIcon)`
  cursor: pointer;
  margin-left: 8px;
`

export const WarningText = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.color.red};
`
