import { PureComponent } from 'react'
import Api from '~/app/common/api'
import PreviewProductImageIcon from '~/app/assets/svg/PreviewProductImageIcon'
import EditIcon from '~/app/assets/svg/EditIcon'
import LightBox from '~/app/components/LightBox'
import Tooltip from '~/app/components/Tooltip'
import Loader from '~/app/components/Loader'
import { Wrap, AddImageButton, HiddenInput, ImageOptions, ImageError } from './styled'

interface Props {
  value: string
  imageType: string
  onChange: (event: any) => void
  name: string
  productId?: number
}

interface State {
  loading: boolean
  showPreview: boolean
  uploadError: string
}

export default class Image extends PureComponent<Props, State> {
  defaultProps = {
    value: '',
  }

  state = {
    loading: false,
    showPreview: false,
    uploadError: '',
  }

  showUploadError(message: string) {
    return this.setState({
      loading: false,
      uploadError: message,
    })
  }

  handleChange = async (e: any) => {
    this.setState({
      loading: true,
      uploadError: '',
    })

    const [file] = e.target.files
    const { type: fileType, name: filename, size: fileSize } = file
    const { imageType, productId } = this.props

    const KB_MULTIPLIER = 1024
    const MAX_FILE_SIZE_DEFAULT_KB = 500 * KB_MULTIPLIER
    const MAX_FILE_SIZE_GIF_KB = 1024 * KB_MULTIPLIER
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/svg', 'image/gif']

    if (!allowedImageTypes.includes(fileType)) {
      return this.showUploadError('Invalid file type')
    }

    if (fileType === 'image/gif' && fileSize > MAX_FILE_SIZE_GIF_KB) {
      return this.showUploadError(
        `Maximum file size for GIF is ${MAX_FILE_SIZE_GIF_KB / 1024 / KB_MULTIPLIER}MB`,
      )
    }
    if (fileType !== 'image/gif' && fileSize > MAX_FILE_SIZE_DEFAULT_KB) {
      return this.showUploadError(
        `Maximum file size for images is ${MAX_FILE_SIZE_DEFAULT_KB / 1024}KB`,
      )
    }

    const { finalUrl, url } = await Api.imgUploadLink({
      filename,
      imageType,
      id: productId,
    })
    await fetch(url, {
      body: file,
      headers: { 'Content-Type': fileType },
      method: 'PUT',
    })
    this.props.onChange(finalUrl)
    this.setState({ loading: false })
  }

  togglePreview = () => {
    this.setState(({ showPreview }) => ({ showPreview: !showPreview }))
  }

  renderContent = () => {
    if (this.state.loading) {
      return <Loader />
    }

    if (!this.props.value) {
      return (
        // @ts-ignore
        <AddImageButton secondary>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <HiddenInput
              ref={(input: any) => {
                // @ts-ignore
                this.fileInput = input
              }}
              type="file"
              onChange={this.handleChange}
            />
            Add Image
          </label>
        </AddImageButton>
      )
    }

    return <img src={this.props.value} alt={this.props.name} />
  }

  render() {
    return (
      <Wrap>
        {this.props.value && (
          <ImageOptions>
            <Tooltip text="Preview">
              <div role="presentation" onClick={this.togglePreview}>
                <PreviewProductImageIcon />
              </div>
            </Tooltip>
            <Tooltip text="Replace">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <EditIcon />
                <HiddenInput type="file" onChange={this.handleChange} />
              </label>
            </Tooltip>
          </ImageOptions>
        )}
        {this.state.uploadError && <ImageError>{this.state.uploadError}</ImageError>}
        {this.renderContent()}
        {/* @ts-ignore */}
        <LightBox isOpen={this.state.showPreview} closeHandler={this.togglePreview}>
          <img src={this.props.value} alt="Preview Full" />
        </LightBox>
      </Wrap>
    )
  }
}
