import moment from 'moment-timezone'
import { UserCoupon } from './types'

export const setUserCoupons = (state: any) => (userCoupons: UserCoupon[]) => {
  return {
    ...state,
    coupons: sortByScopeAndNewest(userCoupons),
  }
}

export function sortByScopeAndNewest(userCoupons: UserCoupon[]) {
  return userCoupons
    .sort((a, b) => {
      if (a.templateId === null) {
        return 1
      }

      if (b.templateId === null) {
        return -1
      }

      return a.templateId - b.templateId
    })
    .sort((a, b) => {
      if (a.templateId !== b.templateId) {
        return 0
      }

      return moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1
    })
}
