import styled from 'styled-components'
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Note = styled.p`
  margin: 20px 0;
  color: #4e5564;
`
export const Label = styled.p`
  text-transform: capitalize;
  font-weight: bold;
`
export const SwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-row-gap: 20px;
  max-width: 260px;
  width: 100%;
  margin: 20px 0;
  text-align: left;

  > div {
    margin: 0 auto;
  }
`
export const Title = styled.span`
  grid-column-start: 2;
  grid-column-end: 3;
`
