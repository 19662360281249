import ConfirmationModal from '~/app/components/Modal/ConfirmationModal'
import { ValidProductType, StatusType, ReasonType } from '~/app/common/types'
import { SUSPENSION_ACTION } from '~/app/store/user/subscriptions/constants'
import { defaultOption, option, switchOn } from '~/app/utils/switchOn'
import CancellationForm from './CancellationForm'
import PostponeForm from './PostponeForm'

interface Reason {
  account: ReasonType[]
  delivery: ReasonType[]
  general: ReasonType[]
  food: ReasonType[]
  admin: ReasonType[]
}

interface Props {
  pause: {
    showPauseModal: boolean
    showUnPauseModal: boolean
    handlePauseModal: () => void
    handleUnPauseModal: () => void
    handlePauseSubscriptions: () => void
    handleUnPauseSubscriptions: () => void
    reasons: Reason[]
    reasonsStatus: StatusType
  }
  cancel: {
    showActivationModal: boolean
    showCancellationModal: boolean
    handleActivationModal: () => void
    handleReactivation: () => void
    handleCancellationModal: () => void
    handleCancel: (reasonsSelected: number[], value: string) => void
    reasons: Reason[]
    reasonsStatus: StatusType
  }
  postpone: {
    showPostponeModal: boolean
    handlePostponeFirstOrder: () => void
    handlePostponeModal: () => void
  }
  type: ValidProductType
}

export default function ActionModals({ pause, cancel, postpone, type }: Props) {
  return switchOn(
    option(
      cancel.showActivationModal && (
        <ConfirmationModal
          isOpen
          handleCancel={cancel.handleActivationModal}
          handleConfirm={cancel.handleReactivation}
          title="Activate Subscription(s)"
          text="Are you sure that you want to re-activate this subscription and add-on subscriptions?"
          confirmText="Yes, re-activate"
          cancelText="Cancel"
        />
      ),
    ),
    option(
      pause.showUnPauseModal && (
        <ConfirmationModal
          isOpen
          handleCancel={pause.handleUnPauseModal}
          handleConfirm={pause.handleUnPauseSubscriptions}
          title="Un-Pause Subscription(s)"
          text="Are you sure that you want to re-activate this subscription and any add-on subscriptions?"
          confirmText="Yes, re-activate"
          cancelText="No, don't reactive"
        />
      ),
    ),
    option(
      pause.showPauseModal && (
        <CancellationForm
          isOpen
          closeHandler={pause.handlePauseModal}
          handleCancel={pause.handlePauseSubscriptions}
          type={type}
          reasons={pause.reasons}
          reasonsStatus={pause.reasonsStatus}
          suspensionAction={SUSPENSION_ACTION.PAUSE}
        />
      ),
    ),
    option(
      cancel.showCancellationModal && (
        <CancellationForm
          isOpen
          closeHandler={cancel.handleCancellationModal}
          handleCancel={cancel.handleCancel}
          type={type}
          reasons={cancel.reasons}
          reasonsStatus={cancel.reasonsStatus}
          suspensionAction={SUSPENSION_ACTION.CANCEL}
        />
      ),
    ),
    option(
      postpone.showPostponeModal && (
        <PostponeForm
          isOpen
          closeHandler={postpone.handlePostponeModal}
          handleConfirm={postpone.handlePostponeFirstOrder}
        />
      ),
    ),
    defaultOption(null),
  )
}
