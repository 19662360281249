import { Header, Content, TabList, Wrapper } from './styled'
import Tab from './Tab'

interface Props {
  activeTab: string
  tabs: {
    name: string
    title: string
    content: object
    to: string
  }[]
  handleTabChange?: () => void
}

export default function Tabs(props: Props) {
  return (
    <Wrapper>
      <Header>
        {!props.tabs[0].to ? (
          <TabList>
            {props.tabs.map((tab) => (
              <Tab
                key={tab.name}
                // @ts-ignore
                to={tab.to}
                activeTab={props.activeTab}
                tabName={tab.name}
                tabText={tab.title}
                handleTabChange={props.handleTabChange}
              />
            ))}
          </TabList>
        ) : (
          props.tabs.map((tab) => (
            <Tab
              key={tab.name}
              // @ts-ignore
              to={tab.to}
              activeTab={props.activeTab}
              tabName={tab.name}
              tabText={tab.title}
              handleTabChange={props.handleTabChange}
            />
          ))
        )}
      </Header>
      <Content>{props.tabs.find((tab) => tab.name === props.activeTab)?.content}</Content>
    </Wrapper>
  )
}

Tabs.defaultProps = {
  activeTab: 'details',
}
