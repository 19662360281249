import styled from 'styled-components'
import Button from '~/app/components/Button/Button'

export const AddButton = styled(Button)`
  border-radius: 100%;
  bottom: 30px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  position: fixed;
  right: 30px;
  z-index: 20;
`
