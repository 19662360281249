import { Position } from '..'

const styles = {
  bottom: bottomStyle,
  top: topStyle,
}

export default function placeTooltip(
  position: Position = 'bottom',
  { verticalOffset } = { verticalOffset: '0px' },
) {
  return styles[position](verticalOffset)
}

function bottomStyle(verticalOffset: string) {
  return `
    top: calc(100% + ${verticalOffset});
    left: 50%;
  `
}

function topStyle(verticalOffset: string) {
  return `
    bottom: calc(100% + ${verticalOffset} + 5px);
    left: 50%;
  `
}
