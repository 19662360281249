const makeEffects =
  (effects: any): ((state: any, self: any) => (method: any) => any) =>
  (state, self) =>
    // @ts-ignore
    Object.keys(effects).reduce((obj, key) => {
      // @ts-ignore
      obj[key] = effects[key](state, self)
      return obj
    }, {})

export default makeEffects
