import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import {
  fetchIngredientsSuccess,
  fetchIngredientsFail,
  createIngredientSuccess,
  fetchIngredientDetailSuccess,
  editIngredientSuccess,
  deleteIngredientSuccess,
} from './actions'
import {
  FETCH_INGREDIENTS,
  FETCH_INGREDIENT_DETAIL,
  EDIT_INGREDIENT,
  CREATE_INGREDIENT,
  DELETE_INGREDIENT,
} from './constants'

const fetchIngredientsEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_INGREDIENTS).switchMap((action) =>
    Observable.fromPromise(
      Api.fetchIngredients(
        action.payload.limit,
        action.payload.offset,
        action.payload.orderBy,
        action.payload.direction,
      ),
    )
      .map((result) => fetchIngredientsSuccess(result))
      .catch((e) => Observable.of(fetchIngredientsFail(), showNotification(e))),
  )

const fetchIngredientDetailEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_INGREDIENT_DETAIL).switchMap((action) =>
    Observable.fromPromise(Api.fetchIngredientDetail(action.payload.id))
      .map((result) => fetchIngredientDetailSuccess(result))
      .catch((e) => Observable.of(showNotification(e.message))),
  )

const editIngredientEpic = (action$, store, { Api }) =>
  action$.ofType(EDIT_INGREDIENT).switchMap((action) =>
    Observable.fromPromise(Api.editIngredient(action.payload.id, action.payload.data))
      .switchMap((result) => Observable.of(editIngredientSuccess(action.payload.id, result)))
      .catch((e) => Observable.of(showNotification(e))),
  )

const createIngredientEpic = (action$, store, { Api }) =>
  action$.ofType(CREATE_INGREDIENT).switchMap((action) =>
    Observable.fromPromise(Api.createIngredient(action.payload.data))
      .switchMap((result) => Observable.of(createIngredientSuccess(result)))
      .catch((e) => Observable.of(showNotification(e))),
  )

const deleteIngredientEpic = (action$, store, { Api }) =>
  action$.ofType(DELETE_INGREDIENT).switchMap((action) =>
    Observable.fromPromise(Api.deleteIngredient(action.payload.id))
      .switchMap(() =>
        Observable.of(deleteIngredientSuccess(), showNotification('Ingredient deleted')),
      )
      .catch((e) => Observable.of(showNotification(e))),
  )

export default [
  fetchIngredientsEpic,
  fetchIngredientDetailEpic,
  editIngredientEpic,
  createIngredientEpic,
  deleteIngredientEpic,
]
