import * as React from 'react'
import PropTypes from 'prop-types'
// @ts-expect-error TODO: Fix error
import queryString from 'query-string'
import { debounce } from 'lodash'
import { useMappedDispatch, useMappedState } from '~/app/hooks/useReduxStore'
import { fetchPromocodes } from '~/app/store/promocodes/actions'
import { selectDataLoading } from '~/app/store/promocodes/selectors'
import SearchIcon from '~/app/assets/svg/SearchIcon'
import { Wrap, SearchBarInput } from './styled'

type $Props = {
  history: any
  placeholder: string
}

const PromocodeSearchBar = (props: $Props) => {
  const self = useController(props.history)

  return (
    <Wrap>
      <SearchIcon />
      <SearchBarInput
        value={self.searchTerm}
        placeholder={props.placeholder}
        onChange={self.onChange}
      />
    </Wrap>
  )
}

PromocodeSearchBar.propTypes = {
  history: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default PromocodeSearchBar

// @ts-expect-error TODO: Fix error
function useController(history) {
  const [searchTerm, setSearchTerm] = React.useState('')

  const dispatcher = useMappedDispatch({ fetchPromocodes })
  const { loading } = useMappedState((state) => ({
    loading: selectDataLoading(state) as boolean,
  }))

  useInitialPromocodeState(setSearchTerm, loading, dispatcher)

  const debounceChange = React.useCallback(
    (() => {
      const debounced = debounce((term) => {
        if ((term.length > 2 || !term.length) && !loading) {
          dispatcher.fetchPromocodes(50, 0, false, term)
        }
      }, 500)
      return (term: string) => debounced(term)
    })(),
    [],
  )

  const onChange = (event: any) => {
    const term = event.target.value.trim() || ''
    history.replace({
      search: queryString.stringify({
        'code-search': term || undefined,
      }),
    })
    debounceChange(term)
    setSearchTerm(term)
  }

  return {
    onChange,
    searchTerm,
  }
}

// @ts-expect-error TODO: Fix error
function useInitialPromocodeState(setSearchTerm, loading, dispatcher) {
  React.useEffect(() => {
    const term = getCurrentSearchTerm()
    setSearchTerm(term)
    if (!loading) {
      dispatcher.fetchPromocodes(50, 0, false, term)
    }
  }, [])
}

function getCurrentSearchTerm() {
  return queryString.parse(location.search)['code-search'] || ''
}
