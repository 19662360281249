import * as React from 'react'
import eatingStyleMap from '~/app/common/dataMaps/eatingStyle'
import BabyFace from './BabyFace'

type $Props = {
  value: 0 | 1 | 2 | 3
}

const EatingStyle = (props: $Props) => {
  switch (eatingStyleMap[props.value]) {
    case 'very picky':
      return <BabyFace type="veryPicky" />
    case 'somewhat picky':
      return <BabyFace type="picky" />
    case 'good eater':
      return <BabyFace type="goodEater" />
    case 'adventurous':
      return <BabyFace type="adventurous" />
    default:
      return null
  }
}

export default EatingStyle
