interface Props {
  onClick?: () => void
  className?: string
  fillColor: string
}

export default function SauceIcon(props: Props) {
  return (
    <svg
      width="16px"
      height="14px"
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Plate Icon</title>
      <desc>Little Spoon plate icon</desc>
      <g id="v" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Iocns-/-Mini-/-Sauce-Mini" transform="translate(0.000000, -1.000000)">
          <g id="Bounds">
            <rect x="0" y="0" width="16" height="16" />
          </g>
          <g id="Group" transform="translate(0.000000, 1.000000)" fill={props.fillColor}>
            <path
              d="M6.34159392e-13,5.56548109 L1.80204778,11.4199638 C1.91272371,11.7795272 2.21583872,12.0465481 2.58648876,12.1109959 L13.1820812,13.9533328 C13.6497967,14.0346581 14.1102279,13.7759306 14.2839929,13.3341419 L15.963202,9.06483819 L15.963202,9.06483819 C15.757436,8.87668091 15.5223113,8.72985964 15.2578278,8.62437439 C14.9933442,8.51888914 14.6263313,8.42555693 14.1567891,8.34437776 L2.00989919,5.34828492 L6.34159392e-13,5.56548109 Z"
              id="Path-14"
            />
            <path
              d="M0.943386713,6.63017954 C0.591880623,6.54347876 0.295993687,6.30914084 0.133094953,5.9884409 C-0.175717001,5.38048052 0.0711377565,4.63947846 0.684460486,4.33336648 L9.10267325,0.131800765 C9.6628636,-0.147792617 10.3454234,0.029969638 10.6946281,0.546401029 L15.7894509,8.08102644 C15.9848282,8.3699658 16.0479352,8.72728974 15.963202,9.06483819 C15.797391,9.72537263 15.1227828,10.1276005 14.4564223,9.96323924 L0.943386713,6.63017954 Z M2.00989919,5.34828492 L14.1567891,8.34437776 L9.57704449,1.57149059 L2.00989919,5.34828492 Z"
              id="Path-2"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
