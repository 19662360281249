import ActivityTag from '~/app/components/ActivityTag'
import formatProductType from '~/app/utils/formatProductType'
import getSubscriptionStatus from '~/app/utils/getSubscriptionStatus'
import formatDate from '~/app/utils/formatDate'
import { MISSING_INFO_TEXT } from '~/app/utils/formatStringsforFE'
import { ProductType } from '~/app/common/types'
import { Wrapper, IconWrapper, PlanInfo, Name, Detail, ActionsWrapper } from './styled'
import Icon from './Icon'
import PlanActions from './PlanActions'
import iconSizes from './sizeByPlan'
import useIsPCHEnabled from '~/app/hooks/useIsPCHEnabled'

interface Props {
  kidName: string
  type: ProductType
  isActive: boolean
  mealsPerDay?: number
  mealsPerDelivery?: number
  templateId: number
  userId: number
  deactivatedAt?: string
  parentSubscription?: number
  deletedAt?: string
  createdAt: string
  showPlanActionsButton: boolean
  firstOrderStatus: string
}

export default function Plan(props: Props) {
  const status = getSubscriptionStatus({
    active: props.isActive,
    deactivatedAt: props.deactivatedAt,
    deletedAt: props.deletedAt,
  })

  const icons =
    props.type === ProductType.blend || props.type === ProductType.sauce
      ? iconSizes.mealsPerDay
      : iconSizes.mealsPerDelivery

  const size =
    props.type === ProductType.blend || props.type === ProductType.sauce
      ? // @ts-ignore
        icons[props.mealsPerDay]
      : // @ts-ignore
        icons[props.mealsPerDelivery]

  // TODO: remove when PCH reaches GA https://littlespoon.atlassian.net/browse/LS-3770
  const enablePCH = useIsPCHEnabled()

  return (
    <Wrapper>
      <IconWrapper>
        <Icon type={props.type} size={size} />
      </IconWrapper>

      <PlanInfo>
        <Name>{`${props.kidName || MISSING_INFO_TEXT}'s ${
          enablePCH ? 'Plan' : formatProductType(props.type)
        }`}</Name>
        <Detail>Plan id: {props.templateId}</Detail>
        <Detail>
          Status: <ActivityTag status={status} />{' '}
          {props.firstOrderStatus && <ActivityTag status={props.firstOrderStatus} color="orange" />}
        </Detail>

        {/* @ts-ignore */}
        <Detail>Created On: {formatDate(props.createdAt, { timestamp: false })}</Detail>

        {props.deletedAt && (
          // @ts-ignore
          <Detail>Cancelled On: {formatDate(props.deletedAt, { timestamp: false })}</Detail>
        )}

        {props.deactivatedAt && (
          // @ts-ignore
          <Detail>Paused On: {formatDate(props.deactivatedAt, { timestamp: false })}</Detail>
        )}
      </PlanInfo>

      {props.showPlanActionsButton && (
        <ActionsWrapper>
          <PlanActions
            templateId={props.templateId}
            userId={props.userId}
            status={status}
            type={props.type}
            // @ts-ignore
            parentSubscription={props.parentSubscription}
          />
        </ActionsWrapper>
      )}
    </Wrapper>
  )
}
