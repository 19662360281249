export default function ProductsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 23 12" xmlns="http://www.w3.org/2000/svg">
      <title>Products</title>
      <g fillRule="nonzero" fill="#838A9D">
        <path d="M20.427 4l-1.058 6.84c-.104.67-.658 1.16-1.307 1.16H5c-.648 0-1.2-.492-1.305-1.16L2.637 4h17.79zM2.58.01C2.6.01 4.437.006 6.91.004h1.087c5.115-.005 12.018-.004 12.097.008 1.523.25 2.293 1.188 2.31 2.817 0 .095-.073.172-.164.173H.826C.736 3 .66 2.924.66 2.83c0-.01.002-.02.003-.028.27-1.72.91-2.65 1.918-2.79z" />
      </g>
    </svg>
  )
}
