import { compose, withState } from 'recompose'
import user$ from '~/app/store/user'
import withHandlers from '~/app/hocs/withHandlers'
import withForm from '~/app/hocs/withForm'
import formDefinition from './EditKid/formDefinition'
export default compose(
  withState('showEditKidForm', 'setShowEditKidForm', {}),
  withForm(formDefinition),
  withHandlers({
    handleConfirm:
      ({ user }) =>
      (data) => {
        user$.call.editUserDetails({
          data,
          id: user.id,
        })
      },
    handleSave:
      ({ serialize, onConfirm }) =>
      () => {
        serialize(onConfirm)
      },
    handleToggleEdit: (props) => (kid) => {
      props.setShowEditKidForm(() => {
        const showEditKidForm = { ...props.showEditKidForm }
        showEditKidForm[kid.id] = !showEditKidForm[kid.id]
        return showEditKidForm
      })
    },
    handleToggleSequenceForm: (props) => () => {
      props.setSequenceVisibility(() => !props.showSequenceForm)
    },
  }),
)
