interface Props {
  onClick?: () => void
  className?: string
  fillColor: string
}

export default function PlateIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      className={props.className}
      onClick={props.onClick}
    >
      <path
        fill={props.fillColor}
        fillRule="evenodd"
        d="M9 0c4.97 0 9 2.239 9 5 0 .833-.367 1.619-1.016 2.31C16.73 9.927 13.252 12 9 12S1.271 9.927 1.015 7.31C.367 6.619 0 5.833 0 5c0-2.761 4.03-5 9-5zm0 1.5C4.748 1.5 1.5 3.304 1.5 5S4.748 8.5 9 8.5s7.5-1.804 7.5-3.5S13.252 1.5 9 1.5z"
      />
    </svg>
  )
}
