import { get } from 'lodash'
import ShiftIcon from '~/app/assets/svg/ShiftIcon'
import useShiftController from './useShiftController'
import ShiftingModal from '../../../ShiftingModal'
import { Option, Text } from '../../styled'

type $Props = {
  index: number
  data: {
    groupedOrders: Array<any> // eslint-disable-line react/no-unused-prop-types
  }
  groupIndex: number
  userId: number
}

export default function ShiftButton(props: $Props) {
  const { data, userId } = props

  const shift = useShiftController({
    groupedOrders: data.groupedOrders,
    userId,
  })

  return (
    <>
      <Option onClick={() => shift.toggleShiftingModalVisibility(props.index, props.groupIndex)}>
        <ShiftIcon />
        <Text>Shift</Text>
      </Option>
      {shift.orderToShift !== null && (
        <ShiftingModal
          isOpen={shift.orderToShift !== null}
          closeHandler={shift.toggleShiftingModalVisibility}
          shiftedBy={get(shift, 'orderToShift.shiftedBy')}
          deliveryDate={get(shift, 'orderToShift.deliveryDate')}
          editableTo={get(shift, 'orderToShift.editableTo')}
          onShift={shift.handleOrderShift}
        />
      )}
    </>
  )
}
