import { createSelector } from 'reselect'

const selectPromoBanners = (state: Record<string, any>) => state.promoBanners

export const getPromoBanners = createSelector(
  selectPromoBanners,
  (promoBannersState) => promoBannersState.data,
)
export const selectStatus = createSelector(
  selectPromoBanners,
  (promoBannersState) => promoBannersState.status,
)
