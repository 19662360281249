import { capitalize } from 'lodash'
import { ProductType } from '~/app/common/types'
import pluralize from '~/app/utils/pluralize'

export default function formatProductType(type: ProductType) {
  if (type === ProductType.blend) {
    return 'Babyblends'
  } else if (type === ProductType.misc) {
    return capitalize(type)
  }

  return capitalize(pluralize(type, 2))
}
