import { makeModel } from '~/app/utils/storeManager'
import effects from './effects'
import reducers from './reducers'
import * as selectors from './selectors'

const user$ = makeModel({
  effects,
  prefix: 'faultyOrderReasons',
  reducers,
  selectors,
  state: {
    reasons: [],
  },
})

export default user$
