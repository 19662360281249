import { createSelector } from 'reselect'

export const selectProducts = (state: any) => state.products

export const selectDataLoaded = createSelector(selectProducts, (p) => p.dataLoaded)
export const selectDataLoading = createSelector(selectProducts, (p) => p.dataLoading)
export const selectDetailLoaded = createSelector(selectProducts, (p) => p.detailLoaded)
export const selectDetailLoading = createSelector(selectProducts, (p) => p.detailLoading)

export const selectData = createSelector(selectProducts, (p) => p.data)
export const selectSource = createSelector(selectProducts, (p) => p.source)
export const selectIsUpdated = createSelector(selectProducts, (p) => p.dataIsUpdated)

export const selectPage = createSelector(selectProducts, (p) => p.activePage)

export const selectProduct = createSelector(selectProducts, (p) => p.detail)

export const selectProductsReplacements = createSelector(selectProducts, (p) => p.replacements)

export const selectProductsReplacementLoaded = createSelector(
  selectProducts,
  (p) => p.replacementsLoaded,
)

export const selectProductsWithReplacements = createSelector(
  selectProducts,
  selectProductsReplacements,
  selectProductsReplacementLoaded,
  (products, replacements, loadedReplacements) => ({
    data: products.data.map((product: any) => ({
      ...product,
      productReplacements: replacements.filter((r: any) => r.productId === product.id) || [],
    })),
    loaded: products.dataLoaded && loadedReplacements,
    loading: products.dataLoading || !loadedReplacements,
  }),
)

export const selectGiftProducts = createSelector(selectProducts, (p) => p.giftProducts)

export const selectGiftProductsLoaded = createSelector(selectProducts, (p) => p.giftProductsLoaded)
export const selectGiftProductsLoading = createSelector(
  selectProducts,
  (p) => p.giftProductsLoading,
)

export const selectSequenceUpdated = createSelector(selectProducts, (p) => p.sequenceUpdated)

export const selectAllProducts = createSelector(selectProducts, (p) => p.allProducts)

export const selectAllProductsLoading = createSelector(selectProducts, (p) => p.allProductsLoading)
export const selectAllProductsLoaded = createSelector(selectProducts, (p) => p.allProductsLoaded)
