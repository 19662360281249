interface Props {
  onClick?: () => void
  className?: string
  fillColor?: string
}

export default function PuffIcon(props: Props) {
  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.1 18.1"
      width="14px"
      fill="none"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Puffs Icon</title>
      <desc>Little Spoon puffs icon</desc>
      <g id="Layer_3" data-name="Layer 3">
        <g>
          <path
            strokeWidth="0px"
            d="M6.03,4.71c-.4-.32-.86-.44-1.37-.34-.59.12-1.17.54-1.64.9C1.56,6.35.69,7.93.19,9.67c-.25.88-.36,2.06.31,2.79.62.68,1.69.52,2.29-.1.68-.71.64-1.69.89-2.58.34-1.24,1.38-2.03,2.35-2.79.35-.27.71-.61.73-1.09.02-.5-.36-.92-.73-1.21Z"
            fill={props.fillColor}
          />
          <g>
            <polygon points="5.31 13.87 5.31 13.87 5.31 13.87 5.31 13.87" />
            <path
              d="M9.48,12.14c-1.36-.41-3.1-.33-4.35.42-.82.5-1.17,1.47-.66,2.34.53.92,1.4.88,2.3.64,1.32-.34,2.57-.3,3.5.79.52.61.98,1.49,1.8,1.71.86.23,1.81-.28,1.9-1.2.11-1.09-.75-2.2-1.48-2.92-.78-.77-1.86-1.42-3.01-1.77Z"
              fill={props.fillColor}
            />
          </g>
          <path
            d="M11.9,10.82c.19-.04.38-.1.56-.18,1.21-.58,1.48-2.07,1.6-3.26.14-1.29-.07-2.59-.51-3.8-.45-1.26-1.19-2.91-2.52-3.45-.43-.17-.9-.18-1.32,0-.67.29-1.07,1.03-1.08,1.74-.02,1.13.78,1.91,1.27,2.84.44.84.48,1.82.41,2.74-.04.48-.12.95-.19,1.42-.08.51-.12,1.06.24,1.48.38.45.99.56,1.55.46Z"
            fill={props.fillColor}
          />
        </g>
      </g>
    </svg>
  )
}
