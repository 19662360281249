interface Props {
  className?: string
}

export default function MediumPlanIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="79" height="77" viewBox="0 0 79 77" {...props}>
      <title>Medium plan</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.778 42.328L.974 52.765l8.876.263zM63.93 33.573l-5.407 7.782a1.98 1.98 0 0 1-2.528.633l-11.496-5.883a1.98 1.98 0 0 1-1.017-2.248l2.331-9.223 18.117 8.94z"
        />
        <path
          fill="#49C5B1"
          d="M69.785 30.234L50.97 20.897a2.333 2.333 0 0 0-3.174 1.153l-.603 1.376a.99.99 0 0 0 .467 1.284l21.422 10.631a.99.99 0 0 0 1.347-.49l.565-1.289a2.565 2.565 0 0 0-1.209-3.328z"
        />
        <path
          stroke="#302D1F"
          strokeWidth="1.5"
          d="M66.483 31.034a1.815 1.815 0 0 1 .856 2.355l-.566 1.29-.005.01a.24.24 0 0 1-.321.108L45.024 24.166a.24.24 0 0 1-.113-.311l.603-1.376.032-.068a1.583 1.583 0 0 1 2.122-.715l18.815 9.338z"
        />
        <path fill="#F0A83A" d="M41.452 33.137v42.764l29.588-9.378V42.076z" />
        <path
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M38.452 33.137v42.764l29.588-9.378V42.076z"
        />
        <path
          fill="#F7D148"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.864 42.234v24.547l29.588 9.12V33.137z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M38.452 33.137l10.346 16.206 29.105 3.685-9.725-11.444z"
        />
        <path
          fill="#B6D042"
          d="M18.169 13.776l5.408 7.782a1.98 1.98 0 0 0 2.528.632L37.6 16.308a1.98 1.98 0 0 0 1.017-2.248l-2.331-9.223-18.117 8.939z"
        />
        <path
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15.295 13.065l5.408 7.782a1.98 1.98 0 0 0 2.528.633l11.495-5.883a1.98 1.98 0 0 0 1.018-2.248l-2.332-9.223-18.117 8.94z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeWidth="1.5"
          d="M13.419 10.51a1.815 1.815 0 0 0-.855 2.355l.565 1.29a.24.24 0 0 0 .326.118l21.423-10.63a.24.24 0 0 0 .113-.312l-.603-1.376-.032-.068a1.583 1.583 0 0 0-2.122-.714L13.42 10.51z"
        />
      </g>
    </svg>
  )
}
