import { Route } from 'react-router-dom'
import SearchBar from '~/app/containers/SearchBar'
import PromocodeSearchBar from '~/app/pages/PromoCodes/PromocodeSearchBar'
import AdminImage from '~/app/assets/img/admin-image.png'
import { Container, AdminIcon } from './styled'

export default function Header() {
  return (
    <Container>
      <Route
        path="/promocodes/promocodes"
        render={({ history }) => (
          <PromocodeSearchBar history={history} placeholder="Search for a promocode" />
        )}
      />
      <Route
        path="/users/list"
        render={({ history }) => <SearchBar history={history} placeholder="Search for a user" />}
      />
      <AdminIcon>
        <img src={AdminImage} alt="Admin Logo" />
        <span>Admin</span>
      </AdminIcon>
    </Container>
  )
}
