import { Link } from 'react-router-dom'
import useHasUserRole from '~/app/hooks/useHasUserRole'
import Page from '~/app/components/Page'
import { Title } from '~/app/components/Titles/PageTitle'
import List from './List'
import { AddButton } from '~/app/components/Button/AddResourceButton'
import { NEW } from '~/app/common/constants'

type Props = {
  history: {
    push: (value: string) => void
  }
}

export default function PromoBanners({ history }: Props) {
  const isSuperAdmin = useHasUserRole(['superadmin'])

  if (!isSuperAdmin) {
    return null
  }

  return (
    <Page>
      <Title>Manage Promo Banners</Title>
      <List history={history} />
      <Link to={`promo-banners/${NEW}`}>
        <AddButton
          // @ts-expect-error TODO: Fix error
          round
          primary
          large
          aria-label="Add Form Factor"
        >
          +
        </AddButton>
      </Link>
    </Page>
  )
}
