export default function MagicWand() {
  return (
    <svg
      height="20"
      width="20"
      fill="#49c5b1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      enableBackground="new 0 0 48 48"
    >
      <g>
        <rect
          x="-1.5140343"
          y="28.7120667"
          transform="matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -16.9989967 21.3849277)"
          fill="none"
          stroke="#49c5b1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          width="37.6568527"
          height="5"
        />
        <polygon
          fill="none"
          stroke="#49c5b1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="   44.137516,4.3638396 41.2147026,10.1001768 45.767086,14.6525602 39.3128433,13.7408934 36.3423004,19.5249634    35.3113022,13.1900511 28.9405918,12.1948528 34.7664261,9.1825438 33.848793,2.7342665 38.4011765,7.2866497  "
        />
        <line
          fill="none"
          stroke="#49c5b1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="24.6176891"
          y1="20.3732185"
          x2="28.153223"
          y2="23.9087524"
        />
        <polygon
          fill="none"
          stroke="#49c5b1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="   36,33.2917976 37.3110466,35.6889534 39.7082024,37 37.3110466,38.3110466 36,40.7082024 34.6889534,38.3110466 32.2917976,37    34.6889534,35.6889534  "
        />
        <polygon
          fill="none"
          stroke="#49c5b1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="   12,8.2917957 14.3717079,12.6282921 18.7082043,15 14.3717079,17.3717079 12,21.7082043 9.6282921,17.3717079 5.2917962,15    9.6282921,12.6282921  "
        />
      </g>
    </svg>
  )
}
