const setKidDetails = (state: any) => (kid: any) => {
  const kidsData = state.details.kids
  const newKidsData = kidsData.map((kidData: any) => {
    if (kidData.id === kid.id) {
      return kid
    }
    return kidData
  })
  return {
    ...state,
    details: {
      ...state.details,
      kids: newKidsData,
    },
  }
}

export default setKidDetails
