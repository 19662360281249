interface Props {
  onClick?: () => void
  className?: string
  fillColor: string
}

export default function BlendIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="10"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Babyblend Icon</title>
      <desc>Little Spoon babyblend container icon</desc>
      <path
        fill={props.fillColor}
        d="M16.398 3l-.88 5.905c-.088.577-.55 1-1.09 1H3.538c-.54 0-1-.423-1.087-1L1.57 3h14.828zM1.59.01C1.606.005 3.125.003 5.174 0h.9c4.234 0 9.948 0 10.014.01 1.26.195 1.898.943 1.912 2.24 0 .075-.06.137-.135.138H.137A.132.132 0 0 1 0 2.254c0-.008 0-.015.002-.022C.226.862.756.12 1.59.01z"
      />
    </svg>
  )
}
