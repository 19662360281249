import { compose, pure, withHandlers } from 'recompose'
import Checkmark from '~/app/assets/svg/Checkmark'
import { Wrap, Box, Text } from './styled'

interface Props {
  value?: boolean
  defaultValue?: boolean
  text?: string
  handleChange: () => void
  disabled?: boolean
}

function Checkbox({ value, defaultValue, text, handleChange }: Props) {
  return (
    <Wrap onClick={handleChange}>
      <Box checked={value != null ? value : defaultValue}>
        <Checkmark color="#fff" />
      </Box>
      <Text>{text}</Text>
    </Wrap>
  )
}

export default compose(
  pure,
  withHandlers({
    handleChange: (props: any) => () => !props.disabled && props.onChange(!props.value),
  }),
  // @ts-ignore
)(Checkbox)
