import { ProductListGrid } from './styled'
import Product from './Product'
import { ProductType } from '~/app/common/types'
import useIsWeekendBillingEnabled from '~/app/hooks/useIsWeekendBillingEnabled'

interface Props {
  isAddOn: boolean
  isFirst: boolean
  mealsLeftToPick: number
  onlyQuantityEditor: boolean
  isManualOrder: boolean
  products: {
    id: number
    type: string
    packetCount: number
    count: number
    name: string
    inStock: boolean
    top: string
    shadow: string
    simple: string
  }[]
  type: ProductType
}

export default function ProductList(props: Props) {
  const self = useController(props)

  return (
    <ProductListGrid>
      {props.products.map((product) => (
        <Product
          key={product.id}
          // @ts-ignore
          id={product.id}
          type={props.type}
          packetCount={product.packetCount}
          name={product.name}
          thumbnail={
            [ProductType.blend, ProductType.misc].includes(props.type)
              ? product.simple
              : product.top
          }
          quantityInOrder={product.count}
          enableAddBtn={self.enableAddButton}
          mealsLeftToPick={props.mealsLeftToPick}
          onlyQuantityEditor={props.onlyQuantityEditor}
          inStock={product.inStock}
          isManualOrder={props.isManualOrder}
        />
      ))}
    </ProductListGrid>
  )
}

function useController(props: Props) {
  const maxSmoothiesAddOnCount = 8
  const isWeekendBillingEnabled = useIsWeekendBillingEnabled()

  const enableAddButton = (() => {
    if (!props.isAddOn || (props.isFirst && !isWeekendBillingEnabled)) {
      return props.mealsLeftToPick > 0
    } else if (props.isAddOn && props.type === ProductType.smoothie) {
      const smoothiesCount = props.products.reduce((total, { count }) => total + count, 0)
      return smoothiesCount < maxSmoothiesAddOnCount
    }

    return true
  })()

  return {
    enableAddButton,
  }
}
