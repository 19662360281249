import styled, { css } from 'styled-components'

export const Wrap = styled.div`
  cursor: pointer;
  margin: 13px 22px 13px 0;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
`

export const Box = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid ${(props) => props.theme.color.lightGray};
  position: relative;

  ${(props: any) =>
    props.checked &&
    css`
      background: ${(props) => props.theme.color.green};
      border-color: ${(props) => props.theme.color.green};
    `}

  & > svg {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${(props: any) =>
      !props.checked
        ? css`
            display: none;
          `
        : ''}
  }
`

export const Text = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-left: 15px;
`
