import Modal from '..'

interface Props {
  isOpen: boolean
  text: string
  title: string
  confirmText: string
  cancelText: string
  handleCancel: () => void
  handleConfirm: (id?: number) => void
  danger?: boolean
  paused?: boolean
  postpone?: boolean
  pending?: boolean
}

export default function ConfirmationModal({
  isOpen,
  title,
  text,
  confirmText,
  cancelText,
  handleCancel,
  handleConfirm,
  danger,
  paused,
  postpone,
  pending,
}: Props) {
  return (
    <Modal
      title={title}
      text={text}
      mainBtn={confirmText}
      secondaryBtn={cancelText}
      isOpen={isOpen}
      onMainBtnClick={handleConfirm}
      onSecondaryBtnClick={handleCancel}
      danger={danger}
      paused={paused}
      postpone={postpone}
      pending={pending}
      largeButtons
    />
  )
}
