import Tooltip from '~/app/components/Tooltip'
import InputSelect from '~/app/components/InputTypes/Select'
import InputNumber from '~/app/components/InputTypes/Number'
import TrashIcon from '~/app/assets/svg/TrashIcon'
import Button from '~/app/components/Button/Button'
import { ProductDefault } from '~/app/store/productDefaults/types'
import { ProductType } from '~/app/common/types'
import { Wrapper, Row, DeleteButton } from './styled'

interface Props {
  productDefaults: ProductDefault[]
  productOptions: { label: string; value: number }[]
  onChange: (updatedDefaults: ProductDefault[]) => void
  mealsPerDelivery: number
  minMonths: number
  type: ProductType
}

export default function DeliveryForm(props: Props) {
  const handleChange = (updatedDefaults: ProductDefault[]) => {
    props.onChange(updatedDefaults)
  }

  return (
    <Wrapper>
      {props.productDefaults.map((productDefault, index) => (
        <div key={index}>
          <Row>
            <InputSelect
              options={props.productOptions}
              value={productDefault.productId}
              onChange={(value) => {
                const newDefaults = [...props.productDefaults]
                newDefaults[index] = { ...newDefaults[index], productId: Number(value) }
                handleChange(newDefaults)
              }}
              hasEmptyOption
              emptyLabel=" -- Select a product -- "
              defaultValue=""
              disabled={false}
            />
            <InputNumber
              // @ts-ignore
              value={productDefault.count}
              onChange={(value: number) => {
                const newDefaults = [...props.productDefaults]
                newDefaults[index] = { ...newDefaults[index], count: value }
                handleChange(newDefaults)
              }}
              minValue={1}
              maxValue={99}
            />
            <Tooltip text="Delete">
              <DeleteButton
                onClick={() => {
                  const newDefaults = props.productDefaults.filter((_, i) => i !== index)
                  handleChange(newDefaults)
                }}
              >
                <TrashIcon />
              </DeleteButton>
            </Tooltip>
          </Row>
        </div>
      ))}
      <Button
        secondary
        small
        onClick={() => {
          const newDefaults = [
            ...props.productDefaults,
            {
              count: 1,
              productId: 0,
              minMonths: props.minMonths,
              subscriptionType: props.type,
              mealsPerDelivery: props.mealsPerDelivery,
            },
          ]
          handleChange(newDefaults)
        }}
        style={{ width: 'fit-content' }}
      >
        Add Product
      </Button>
    </Wrapper>
  )
}
