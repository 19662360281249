import { groupBy, sumBy } from 'lodash'
import { type AddOnType, ProductType } from '~/app/common/types'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { DiscountType, Order, Quote, QuoteOrder } from './types'
import { isUniversalShippingEnabled } from '~/app/utils/toggles'

/**
 * Gets data for user 'Order Detail'.
 */
export function getChargeDetails(
  order: Order,
  productIdToProductMap: Record<number, any>,
): {
  addOnTypeToTotalValueMap: Map<AddOnType, string>
  anchorProductTotalValue: string
  orderDiscountValueCents: string
  orderProductTierDiscount?: string
  orderValueCentsWithDiscount: string
  shippingCents: string | undefined
} {
  return order.charge.receiptJson
    ? getChargeDetailsFromReceipt(
        order.id,
        order.type,
        order.charge.receiptJson,
        productIdToProductMap,
        order.templateId,
      )
    : getChargeDetailsFromChargeObject(order.charge, order.addOns)
}

/**
 * Splits the order subtotals from the receipt json, which could contain subtotals for multiple orders
 * @param orderId - The order ID.
 * @param orderType - The order product type.
 * @param receiptJson - The receipt JSON.
 * @param productIdToProductMap - The product id to product map object
 * @param templateId - The order template id of related order
 */
function getChargeDetailsFromReceipt(
  orderId: number,
  orderType: ProductType,
  receiptJson: Quote,
  productIdToProductMap: Record<number, any>,
  templateId: number,
) {
  const currentOrder = receiptJson.orders.find(({ id }) => id === orderId)!
  const addOnTotalEntries = (() => {
    const addOnsByType = groupBy(
      currentOrder.itemizedList,
      ({ id }) =>
        (productIdToProductMap[id] &&
          (productIdToProductMap[id][orderType === ProductType.biteable ? 'subType' : 'type'] ||
            productIdToProductMap[id].type)) || // added for post-migration case when biteables might not have a subType property anymore
        orderType,
    )
    return Object.keys(addOnsByType).reduce<[AddOnType, number][]>((entries, type) => {
      if (type !== orderType) {
        entries.push([type as AddOnType, sumBy(addOnsByType[type], 'totalPriceCents')])
      }
      return entries
    }, [])
  })()

  const addOnTotalValueCents = sumBy(addOnTotalEntries, ([, total]) => total)
  const anchorProductTotalValue = currentOrder.orderValueCents - addOnTotalValueCents
  const shippingCents = isUniversalShippingEnabled()
    ? currentOrder?.shippingCents || receiptJson?.shippingCents // for single shipping orders shiping fee is not stored in order object so we have to take it from receipt
    : currentOrder?.shippingCents
  const { orderProductTierDiscount, orderDiscountValueCents } = getOrderDiscounts(
    receiptJson,
    currentOrder,
    templateId,
  )
  const orderValueCentsWithDiscount = Math.max(
    currentOrder.orderValueCents -
      (orderDiscountValueCents + orderProductTierDiscount) +
      (shippingCents || 0),
    0,
  )

  return {
    addOnTypeToTotalValueMap: new Map(
      addOnTotalEntries.map(([type, total]) => [type, convertCentsToDollars(total)]),
    ),
    anchorProductTotalValue: convertCentsToDollars(anchorProductTotalValue),
    orderDiscountValueCents: convertCentsToDollars(orderDiscountValueCents || null),
    orderProductTierDiscount: convertCentsToDollars(orderProductTierDiscount || null),
    orderValueCentsWithDiscount: convertCentsToDollars(orderValueCentsWithDiscount),
    shippingCents: shippingCents != null ? convertCentsToDollars(shippingCents) : shippingCents,
  }
}

/**
 * Returns separated order discount types.
 * @param receiptJson - The receipt JSON.
 * @param currentOrder - The current order object from the receipt JSON.
 * @param templateId - Order's template id
 */
function getOrderDiscounts(receiptJson: Quote, currentOrder: QuoteOrder, templateId: number) {
  const orderCount = receiptJson.orders.length || 1
  let orderDiscountValueCents
  const orderProductTierDiscount = receiptJson?.discounts?.reduce(
    (totalTierDiscounts, discount) => {
      if (
        currentOrder.id === discount.metadata?.orderId &&
        discount.type === DiscountType.productTier
      ) {
        const tierDiscountAmount = discount.amountCents || 0
        return totalTierDiscounts + tierDiscountAmount
      }

      return totalTierDiscounts
    },
    0,
  )

  const hasTemplateLevelCoupon = receiptJson?.discounts?.find(
    (discount) => discount.templateId === templateId,
  )
  if (hasTemplateLevelCoupon) {
    // when order has template level coupon, only apply that.
    orderDiscountValueCents = receiptJson?.discounts?.reduce((discountsTotal, discount) => {
      return discount.type === DiscountType.coupon &&
        discount.templateId &&
        discount.templateId === templateId
        ? discountsTotal + discount.amountCents
        : discountsTotal
    }, 0)
  } else {
    const orderCountWithUserLevelCoupons =
      orderCount - receiptJson?.discounts?.filter((discount) => discount.templateId).length
    orderDiscountValueCents =
      receiptJson?.discounts?.reduce((discountsTotal, discount) => {
        return discount.type === DiscountType.coupon && !discount.templateId
          ? discountsTotal + discount.amountCents
          : discountsTotal
      }, 0) / orderCountWithUserLevelCoupons
  }

  // apply other discounts to all orders (gift cards, credits, referral)
  const otherDiscounts =
    receiptJson?.discounts?.reduce((discountsTotal, discount) => {
      return discount.type !== DiscountType.coupon && discount.type !== DiscountType.productTier
        ? discountsTotal + discount.amountCents
        : discountsTotal
    }, 0) / orderCount
  orderDiscountValueCents += otherDiscounts || 0

  return {
    orderProductTierDiscount: orderProductTierDiscount || 0,
    orderDiscountValueCents: orderDiscountValueCents || 0,
  }
}

/**
 * @deprecated (eddkmm) This applies to orders that don't have a receipt JSON (prior to Pricing Service rollout May 2022). Data may be inaccurate.
 *
 * Gets charge details from the charge object.
 * @param charge - The charge object.
 * @param addOns - The add-ons list from the past order.
 */
function getChargeDetailsFromChargeObject(charge: Order['charge'], addOns: Order['addOns']) {
  // Dollar based coupons are already converted to credits,
  // so we get percentage based coupons and add them to totalDiscount
  const percentageBasedCouponDiscount = charge.coupon?.percentage
    ? charge.amountRaw * (charge.coupon.discount / 100)
    : 0
  // Compare max discount to percantage discount and apply smaller value
  const effectiveDiscount = charge.coupon?.maxDiscountCents
    ? Math.min(percentageBasedCouponDiscount, charge.coupon.maxDiscountCents)
    : percentageBasedCouponDiscount

  const creditsApplied = Math.abs(charge.credits || 0)
  const creditsAppliedAddOns = addOns.reduce(
    (sum, addOn) => (!addOn.skipped ? sum + addOn.charge.credits : sum),
    0,
  )
  const anchorProductTotalValue = charge.amountRaw
  const shippingCents = charge.shippingCost
  const orderDiscountValueCents = creditsApplied + creditsAppliedAddOns + effectiveDiscount || null
  const orderValueCentsWithDiscount = charge.amount

  return {
    addOnTypeToTotalValueMap: new Map(
      addOns.map((addOn) => [
        addOn.type,
        convertCentsToDollars(
          // @ts-ignore
          (addOn.charge.amount || addOn.charge.subscriptionTotal) + (addOn.charge.credits || 0),
        ),
      ]),
    ),
    anchorProductTotalValue: convertCentsToDollars(anchorProductTotalValue),
    orderDiscountValueCents: convertCentsToDollars(orderDiscountValueCents),
    orderValueCentsWithDiscount: convertCentsToDollars(orderValueCentsWithDiscount),
    shippingCents: convertCentsToDollars(shippingCents),
  }
}
