import styled from 'styled-components'
export const Title = styled.h2`
  color: ${(props) => props.theme.color.gray};
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.5px;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 50px 0;
`
export const DarkTitle = styled.h2`
  color: ${(props) => props.theme.color.dark};
  display: block;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 36px;
`
export const DarkCenteredTitle = DarkTitle.extend`
  text-align: center;
`
export const InformationalTitle = styled.h2`
  color: ${(props) => props.theme.color.dark};
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
  ${(props) =>
    props.center &&
    `
    text-align: center;
    `}
`
