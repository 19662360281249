import { colors } from '~/app/settings/colors'

export default function getFillColor(status: string) {
  switch (status) {
    case 'active':
      return colors.active
    case 'paused':
      return colors.paused
    case 'cancelled':
      return colors.cancelled
    case 'incomplete':
      return colors.incomplete
    default:
      return colors.default
  }
}
