import ModifyIcon from '~/app/assets/svg/ModifyIcon'
import { ProductType, validAnchorProductTypesMap } from '~/app/common/types'
import { Option, Text } from '../../styled'
import EditedOrderLightBox from './EditedOrderLightBox'
import ModifyAllProductsModal from './ModifyAllProductsModal'
import useEditOrderController from './useEditOrderController'

interface Props {
  data: {
    groupedOrders: any[]
    editedOrder: any
    editedOrderAddOnsQ: number
    products: { id: number; count: number; type: string }[]
    addOnProducts: { id: number; count: number; type: string }[]
    orderSummary: any
  }
  id: number
  groupIndex: number
  index: number
  kidId: number
  userId: number
  type: validAnchorProductTypesMap
}

export default function ModifyButton(props: Props) {
  const { data, userId } = props

  const edit = useEditOrderController({
    editedOrder: data.editedOrder,
    groupedOrders: data.groupedOrders,

    userId,
  })

  return (
    <>
      <Option
        onClick={() => edit.openLightBox(props.groupIndex, props.index, props.kidId, props.id)}
      >
        {/* @ts-expect-error: TODO: Fix type error */}
        <ModifyIcon />
        <Text>Modify</Text>
      </Option>
      {edit.openEditedOrder !== null && (
        <EditedOrderLightBox
          isOpen={edit.openEditedOrder !== null}
          closeHandler={edit.closeLightBox}
          order={data.editedOrder}
          plan={data.editedOrder.plan}
          addOnsQ={data.editedOrderAddOnsQ}
          // @ts-expect-error TODO: Fix error
          handleTabChange={edit.handleTabChange}
          // @ts-expect-error: TODO: Fix type error
          activeTab={edit.activeTab}
          products={data.products}
          addOnProducts={data.addOnProducts}
          modifyAddOnTemplate={data.editedOrder.modifyAddOnTemplate || false}
          // TODO: Revert after pricing migration LS-3442
          initialProductCount={edit.initialProductCount}
          orderSummary={data.orderSummary}
        />
      )}
      {edit.openModifyAllProductsModal && (
        <ModifyAllProductsModal
          isOpen
          updateOrder={edit.updateOrder}
          showSwitch={
            edit.openModifyAllProductsModal &&
            !edit.openEditedOrder?.addOnProducts.some(
              (product) => product.type === ProductType.misc,
            )
          }
        />
      )}
    </>
  )
}
