interface Props {
  className?: string
  color: string
}

export default function EditIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={props.className}
      {...props}
    >
      <title>Edit</title>
      <path
        fill={props.color || '#60CFC2'}
        d="M12.059 0c-.326 0-.647.124-.897.37l-1.43 1.431a.5.5 0 0 0 0 .707l3.757 3.76a.5.5 0 0 0 .707 0l1.43-1.432a1.252 1.252 0 0 0 0-1.784L12.95.37A1.262 1.262 0 0 0 12.06 0zM8.428 3.105L2.696 8.841a.5.5 0 0 0 .299.85l.709.08a.5.5 0 0 1 .443.458l.084 1.084a.5.5 0 0 0 .46.46l1.08.083a.5.5 0 0 1 .459.442l.08.712a.5.5 0 0 0 .85.297l5.733-5.737a.5.5 0 0 0 0-.707L9.135 3.105a.5.5 0 0 0-.707 0zM1.35 10.786L0 16l5.212-1.398a.5.5 0 0 0 .367-.54l-.11-.977a.5.5 0 0 0-.459-.443l-1.105-.084a.5.5 0 0 1-.46-.46l-.087-1.11a.5.5 0 0 0-.443-.458l-1.025-.115a.5.5 0 0 0-.54.371z"
      />
    </svg>
  )
}
