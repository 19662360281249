import styled from 'styled-components'

export const Wrap = styled.div`
  margin-bottom: 20px;
  ${(props: any) =>
    props.inline &&
    `
    display: flex;
    align-items: center;
  `};
`

export const Label = styled.label`
  display: block;
  width: ${(props: any) => (props.inline ? 'auto' : '100%')};
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 15px;
  color: ${(props) => props.theme.color.darkGray};
  text-transform: uppercase;
  margin: ${(props) => (props.inline ? '0' : props.margins || '40px 0 10px 0')};
`

export const Note = styled.p`
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: -4px;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.5px;
  font-variant: all-small-caps;
  color: ${(props) => props.theme.color.darkGray};
`

export const ImageLabel = styled.label`
  display: block;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${(props) => props.theme.color.dark};
  margin: 40px 0 10px 0;
`

export const Error = styled.small`
  color: ${(props) => props.theme.color.errorRed};
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  margin: 6px 0 0 6px;
`

// @ts-ignore
export const ImageError = Error.extend`
  text-align: center;
`
