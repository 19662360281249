import { branch, renderComponent } from 'recompose'
import Loader from '~/app/components/Loader'

type $Fn = <P extends Record<string, unknown>>(
  predicate: (props: P) => boolean,
) => (Component: any) => any

const withLoader: $Fn = (predicate) => (branch as any)(predicate, renderComponent(Loader))

export default withLoader
