import styled from 'styled-components'

type Props = {
  type: string
}

export default function QtyButtonIcon({ type = 'increase' }: Props) {
  return <Wrapper>{type === 'increase' ? <PlusSign /> : <MinusSign />}</Wrapper>
}

const PlusSign = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
    <path
      fill="#60CFC2"
      d="M6.75 5.25h4.75a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H6.75v4.75a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5V6.75H.5a.5.5 0 0 1-.5-.5v-.5a.5.5 0 0 1 .5-.5h4.75V.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v4.75z"
    />
  </svg>
)

const MinusSign = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2">
    <rect width="12" height="2" fill="#60CFC2" rx="0.5" />
  </svg>
)

export const Wrapper = styled.span`
  width: 2.2rem;
  height: 2.2rem;
  border: 0.2rem solid ${(props) => props.theme.color.green};
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100%;
`
