export default function DashboardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <title>Dashboard</title>
      <g fill="#838A9D">
        <path d="M358.2 191.5H231.3c-11.6 0-21.1-9.4-21.1-21.1V43.6c0-11.6 9.4-21.1 21.1-21.1h126.9c11.6 0 21.1 9.4 21.1 21.1v126.9c-.1 11.6-9.5 21-21.1 21zM168.7 191.5H41.8c-11.6 0-21.1-9.4-21.1-21.1V43.6c0-11.6 9.4-21.1 21.1-21.1h126.9c11.6 0 21.1 9.4 21.1 21.1v126.9c0 11.6-9.5 21-21.1 21zM358.2 377.5H231.3c-11.6 0-21.1-9.4-21.1-21.1V229.6c0-11.6 9.4-21.1 21.1-21.1h126.9c11.6 0 21.1 9.4 21.1 21.1v126.9c-.1 11.6-9.5 21-21.1 21zM168.7 377.5H41.8c-11.6 0-21.1-9.4-21.1-21.1V229.6c0-11.6 9.4-21.1 21.1-21.1h126.9c11.6 0 21.1 9.4 21.1 21.1v126.9c0 11.6-9.5 21-21.1 21z" />
      </g>
    </svg>
  )
}
