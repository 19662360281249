import api from '~/app/common/api'
import alert$ from '~/app/store/alert'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { OrderRefundsReducers } from '~/app/store/user/orderRefunds/types'

export function fetchOrderRefunds(store: any, self: object & OrderRefundsReducers) {
  return async (orderId: number): Promise<void> => {
    store.dispatch(self.setOrderRefundsLoading())

    try {
      const response = await api.fetchOrderRefunds(orderId)
      await store.dispatch(self.setOrderRefundsLoaded({ data: response }))
    } catch (error) {
      store.dispatch(self.setOrderRefundsError())
      alert$.call.setNotification(String(error))
    }
  }
}

export function fetchOrderRefundSummary(store: any, self: object & OrderRefundsReducers) {
  return async ({
    orderId,
    products,
  }: {
    orderId: number
    products: { id: number; count: number }[]
  }): Promise<void> => {
    try {
      const response = await api.fetchOrderRefundSummary(orderId, products)
      await store.dispatch(self.setOrderRefundsSummaryLoaded({ data: response }))
    } catch (error) {
      store.dispatch(self.setOrderRefundsError())
      alert$.call.setNotification(String(error))
    }
  }
}

export function createOrderRefund(store: any, self: object & OrderRefundsReducers) {
  return async (payload: {
    products: { id: number; count: number }[]
    orderId: number
    customRefundReason?: string
    customRefundAmount?: number
  }): Promise<void> => {
    const { products, orderId, customRefundReason, customRefundAmount } = payload
    try {
      store.dispatch(self.setOrderRefundsLoading())
      const refundResponse = await api.refundOrder(orderId, {
        products,
        customRefundReason,
        customRefundAmount,
      })
      alert$.call.setNotification(
        `${refundResponse.message}. $${convertCentsToDollars(refundResponse.amount)} refunded`,
      )
    } catch (error) {
      alert$.call.setNotification(String(error))
      store.dispatch(self.setOrderRefundsError())
    } finally {
      const response = await api.fetchOrderRefunds(orderId)
      await store.dispatch(self.setOrderRefundsLoaded({ data: response }))
    }
  }
}
