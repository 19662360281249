import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div<{ isActiveOrder?: boolean }>`
  font-size: 14px;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  ${(props) => (props.isActiveOrder ? `color: ${props.theme.color.green};` : '')}
  ${media.md} {
    width: auto;
    flex-direction: column;
  }
`

// @ts-expect-error: TODO: Fix type error
export const Option = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: 1px solid ${(props: any) => props.theme.color.green};
  border-radius: 5px;
  color: ${(props: any) => (props.hasIssues ? props.theme.color.red : props.theme.color.green)};
  width: 150px;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  outline: none;
  path,
  circle {
    fill: currentColor;
  }
  svg {
    min-height: 30px;
    max-height: 30px;
    fill: ${(props: any) => (props.hasIssues ? props.theme.color.red : props.theme.color.darkGray)};
  }
  ${(props: any) =>
    props.showDangerMode &&
    `
    color: #ff4c4c;
  `};
`

export const Text = styled.span`
  display: block;
  white-space: nowrap;
  text-transform: capitalize;
`

export const Products = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`

export const Product = styled.li`
  margin-bottom: 7px;
  padding-right: 8px;
  width: 50%;
  ${(props: any) =>
    !props.inStock
      ? `
    color: red;
  `
      : ''};
`

export const Skipped = styled.li`
  margin-bottom: 7px;
  padding-right: 8px;
  width: 50%;
`

export const IconContainer = styled.div`
  margin-bottom: 7px;
  margin-left: 13px;
`
