import Button from '~/app/components/Button/Button'
import user$ from '~/app/store/user'
import { ProductBlock, FlexWrapper, ProductName, Img, OutOfStockLabel } from './styled'
import QuantityEditor from './QuantityEditor'
import { ProductType } from '~/app/common/types'

interface Props {
  id: number
  thumbnail: string
  name: string
  quantityInOrder: number
  handleAdd: () => void
  handleRemove: () => void
  enableAddBtn: boolean
  onlyQuantityEditor: boolean
  mealsLeftToPick: number
  orderIndex: number
  inStock: boolean
  type: ProductType
  packetCount: number
  isManualOrder: boolean
}

export default function Product(props: Props) {
  const self = useController(props)
  const selected = props.quantityInOrder > 0

  return (
    <ProductBlock>
      <FlexWrapper>
        {!props.inStock && <OutOfStockLabel>Temporarily Unavailable</OutOfStockLabel>}
        <Img src={props.thumbnail} fade={props.onlyQuantityEditor && !selected} />
        <ProductName>{props.name}</ProductName>
        {props.onlyQuantityEditor || props.quantityInOrder > 0 ? (
          <QuantityEditor
            quantity={props.quantityInOrder}
            onIncrease={props.isManualOrder ? self.handleManualOrderAdd : self.handleAdd}
            onDecrease={props.isManualOrder ? self.handleManualOrderRemove : self.handleRemove}
            disabled={!props.enableAddBtn}
            selected={props.onlyQuantityEditor && selected}
            packetCount={props.packetCount}
            type={props.type}
          />
        ) : (
          <Button onClick={props.handleAdd} disabled={!props.enableAddBtn}>
            Add
          </Button>
        )}
      </FlexWrapper>
    </ProductBlock>
  )
}

function useController(props: Props) {
  const { id, mealsLeftToPick, type, inStock } = props
  const handleAdd = () => {
    user$.call.addToOrder({
      inStock,
      mealsLeftToPick,
      productId: id,
      type,
    })
  }
  const handleRemove = () => {
    user$.call.removeFromOrder({
      productId: id,
      type,
    })
  }

  const handleManualOrderAdd = () => {
    user$.call.addToManualOrder({
      inStock,
      mealsLeftToPick,
      productId: id,
      type,
    })
  }
  const handleManualOrderRemove = () => {
    user$.call.removeFromManualOrder({
      productId: id,
      type,
    })
  }

  return {
    handleAdd,
    handleRemove,
    handleManualOrderAdd,
    handleManualOrderRemove,
  }
}
