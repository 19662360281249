interface Props {
  width?: number
  height?: number
}

export default function CancelIcon(props: Props) {
  return (
    <svg
      width={props.width ?? 10}
      height={props.height ?? 10}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cancel</title>
      <path
        d="M9.65.35c-.467-.467-1.224-.467-1.69 0L5 3.31 2.04.35C1.575-.117.818-.117.35.35c-.467.467-.467 1.224 0 1.69L3.31 5 .35 7.96c-.467.466-.467 1.223 0 1.69.467.467 1.224.467 1.69 0L5 6.69l2.96 2.96c.466.467 1.223.467 1.69 0 .467-.467.467-1.224 0-1.69L6.69 5l2.96-2.96c.467-.466.467-1.223 0-1.69z"
        fill="#939AA6"
        fillRule="evenodd"
      />
    </svg>
  )
}
