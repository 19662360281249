import styled from 'styled-components'
export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;

  svg {
    height: 25px;
    margin-left: 76px;
    width: 25px;
  }
`
export const SelectContainer = styled.div`
  max-width: 100;
  margin-left: 20px;
`
export const SearchBarInput = styled.input`
  border: 0;
  flex: 1;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  height: 100%;
  outline: none;
  padding: 28px;
`
