import { Observable } from 'rxjs/Observable'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/takeUntil'
import { showNotification } from '~/app/store/notifications/actions'
import { fetchUsersSuccess, fetchUsersFail } from './actions'
import { FETCH_USERS } from './constants'

const fetchUsersEpic = (action$, store, { Api }) =>
  action$
    .ofType(FETCH_USERS)
    .debounceTime(200)
    .switchMap((action) => {
      const searchTerm = action.payload.searchTerm
      const filter = action.payload.filter
      const type = action.payload.type
      const searchFilter = action.payload.searchFilter
      return Observable.fromPromise(
        Api.fetchUsers({
          filter,
          limit: action.payload.limit,
          offset: action.payload.offset,
          searchFilter,
          term: searchTerm.length > 2 ? action.payload.searchTerm : '',
          type,
        }),
      )
        .takeUntil(action$.ofType(FETCH_USERS))
        .map((result) => fetchUsersSuccess(result))
        .catch((e) => Observable.of(fetchUsersFail(), showNotification(e.message)))
    })

export default [fetchUsersEpic]
