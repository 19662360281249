import { Wrap, LoaderDot1, LoaderDot2, LoaderDot3 } from './styled'

export default function DotLoader(props: any) {
  return (
    <Wrap {...props}>
      <LoaderDot1 />
      <LoaderDot2 />
      <LoaderDot3 />
    </Wrap>
  )
}
