import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

const effects = (store: any, self$: any) => ({
  async fetchAllergens() {
    loader$.call.show()
    await self$.call.setStatus('loading')
    try {
      const response = await Api.fetchAllergens()
      // @ts-expect-error TODO: Fix error
      const allergens = response.map(({ id, name }) => ({ id, name }))
      await self$.call.setAllergens(allergens)
      await self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
      await self$.call.setStatus('error')
    }
    loader$.call.hide()
  },
})

export default effects
