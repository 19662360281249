import Rattle from './Rattle'
import Shoe from './Shoe'
import Book from './Book'

type Props = {
  value: string
  className: string
}

export default function KidType(props: Props) {
  switch (props.value) {
    case 'baby':
      return <Rattle {...props} />
    case 'toddler':
      return <Shoe {...props} />
    case 'bigKid':
      return <Book {...props} />
    default:
      throw new Error(`${props.value} is an invalid value`)
  }
}
