import styled, { css } from 'styled-components'

const getSize = (props: any) => {
  if (props.small) {
    return css`
      font-size: 14px;
      height: 42px;
      padding: 0 12px;
    `
  }

  return css`
    font-size: 16px;
    height: 50px;
    padding: 0 18px;
  `
}

export const StyledInput = styled.input`
  ${getSize} -webkit-appearance: none;
  background: none;
  border: solid 1px ${(props) => props.theme.color.lightGray};
  border-radius: 4px;
  box-shadow: none;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  width: 100%;

  &::placeholder {
    color: ${(props) => props.theme.color.gray};
  }
  ${(props) =>
    props.disabled &&
    `
   {
    pointer-events: none;
    background: #f3f3f3!important;
   }
    `};
  ${(props) =>
    props.disabled &&
    props.name === 'type' &&
    `
     {
      pointer-events: none;
      background: unset!important;
      border: none;
      text-transform: capitalize;
     }
      `};
`
