type Props = {
  className?: string
}

export default function FingerFoodAndPuree(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="36"
      viewBox="0 0 53 36"
      className={props.className}
    >
      <title>Finger food and puree</title>
      <defs>
        <circle id="FingerFoodAndPuree__circle" cx="28" cy="28" r="28" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-1 -11)">
        <mask id="FingerFoodAndPuree__mask" fill="#fff">
          <use xlinkHref="#FingerFoodAndPuree__circle" />
        </mask>
        <g mask="url(#FingerFoodAndPuree__mask)">
          <g stroke="#302D1F" strokeLinejoin="round" strokeWidth="1.5">
            <path
              fill="#F7D148"
              fillRule="nonzero"
              strokeLinecap="round"
              d="M33.691 27.98c-5.274-3.301-9.166-7.626-11.084-11.833a.499.499 0 0 0-.93.07 16.074 16.074 0 0 0-.165 7.685c1.945 8.43 10.126 13.874 18.635 12.4a15.981 15.981 0 0 0 5.782-2.268.812.812 0 0 0-.335-1.452 31.417 31.417 0 0 1-11.903-4.601z"
            />
            <path d="M21.615 17.096l.051-4.027a1.812 1.812 0 0 1 2.009-.386c-.005 1.42-.93 4.751-.93 4.751l-1.13-.338z" />
          </g>
          <path
            fill="#E66889"
            d="M36.052 35.13a1.22 1.22 0 0 0 .085.914c.146.282.396.493.695.586 2.143.677 4.89.437 7.52-.816 2.854-1.374 4.872-3.648 5.597-5.943a1.22 1.22 0 0 0-.09-.919 1.183 1.183 0 0 0-.703-.582c-2.143-.677-4.89-.437-7.52.816-2.854 1.374-4.868 3.64-5.584 5.943z"
          />
          <path
            stroke="#302D1F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M38.052 35.13a1.22 1.22 0 0 0 .085.914c.146.282.396.493.695.586 2.143.677 4.89.437 7.52-.816 2.854-1.374 4.872-3.648 5.597-5.943a1.22 1.22 0 0 0-.09-.919 1.183 1.183 0 0 0-.703-.582c-2.143-.677-4.89-.437-7.52.816-2.854 1.374-4.868 3.64-5.584 5.943z"
          />
          <path fill="#49C5B1" d="M39.589 45.241L50.234 35H1l10.459 10.241z" />
          <path
            stroke="#302D1F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M42.589 45.241L53.234 35H4l10.459 10.241z"
          />
          <path
            fill="#FFF"
            fillOpacity="0.8"
            d="M8.997 33.298c0-.189.23-1.33.69-3.424l2.006-.625 1.848-2.4L15.038 26l1.338.849 1.907 2.4h1.773l2.261-.738 1.753 1.138.334.66 1.672-1.06h1.338l1.338 1.06.67 2.705-.67.284c-13.17.19-19.755.19-19.755 0z"
          />
          <path
            fill="#F7D148"
            d="M8.997 33.268h20.588c-.16-2.38-.358-3.571-.594-3.571-.235 0-.932-.267-2.091-.802l-2.06.802-1.816-1.298h-1.64c-.183.433-.416.649-.7.649-.285 0-1.115-.051-2.492-.153l-1.222-2.098h-2.25l-2.382 1.602-.85 1.298-2.14 1.044-.351 2.527z"
          />
          <path
            fill="#302D1F"
            stroke="#302D1F"
            strokeWidth="0.5"
            d="M28.872 37.447a.352.352 0 0 1-.343-.398l.687-5.417a2.544 2.544 0 0 0-.493-1.863 2.438 2.438 0 0 0-1.637-.96c-.759-.102-1.508.157-2.053.71a.34.34 0 0 1-.488-.002.358.358 0 0 1 .002-.5 3.095 3.095 0 0 1 2.628-.908c.837.11 1.58.547 2.095 1.23.515.682.738 1.529.63 2.383l-.686 5.417a.348.348 0 0 1-.342.308M8.38 34.845c-.016 0-.033 0-.05-.003a.377.377 0 0 1-.327-.422l.435-3.332a2.829 2.829 0 0 1 1.095-1.893 2.87 2.87 0 0 1 2.123-.57.378.378 0 0 1 .328.422.381.381 0 0 1-.426.326 2.108 2.108 0 0 0-1.562.419 2.081 2.081 0 0 0-.806 1.392l-.434 3.333a.379.379 0 0 1-.376.328"
          />
          <path
            fill="#302D1F"
            stroke="#302D1F"
            strokeWidth="0.5"
            d="M11.55 30.882a4.419 4.419 0 0 1-.03-1.27c.276-2.266 2.228-3.87 4.353-3.578 1.362.189 2.485.988 3.038 2.33a.401.401 0 0 1-.188.516.357.357 0 0 1-.483-.201c-.449-1.09-1.356-1.717-2.461-1.87-1.724-.237-3.308 1.064-3.531 2.902-.042.346-.035.693.023 1.032.036.212-.096.416-.295.454a.342.342 0 0 1-.066.006.374.374 0 0 1-.36-.321z"
          />
          <path
            fill="#302D1F"
            stroke="#302D1F"
            strokeWidth="0.5"
            d="M21.58 31.203c-.144 0-.28-.1-.343-.27-.385-1.049-1.17-1.762-2.1-1.91-.745-.118-1.474.14-2.052.726-.16.162-.396.134-.527-.06a.53.53 0 0 1 .05-.645c.728-.738 1.685-1.077 2.625-.928 1.19.188 2.195 1.1 2.687 2.442.085.23 0 .501-.188.605a.314.314 0 0 1-.153.04"
          />
          <path
            fill="#302D1F"
            stroke="#302D1F"
            strokeWidth="0.5"
            d="M25.073 30.683a.363.363 0 0 1-.363-.297c-.183-1.093-1.114-1.957-2.264-2.099a2.712 2.712 0 0 0-1.9.47.38.38 0 0 1-.514-.075.342.342 0 0 1 .078-.493 3.466 3.466 0 0 1 2.431-.6c1.472.182 2.662 1.286 2.896 2.686a.354.354 0 0 1-.306.403.363.363 0 0 1-.058.005"
          />
          <path
            d="M7.864 32.89l1.684 11.885c.06.42.418.731.841.731h17.222a.85.85 0 0 0 .841-.73l1.684-11.887H7.864z"
            fill="#77D3E1"
            stroke="#302D1F"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  )
}
