import type { ReactNode } from 'react'
import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Content = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.color.midGray};
  padding: 0 15px 61px;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.color.darkGray};
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 29px;
  }
  ${media.lg} {
    padding: 0 75px 61px;
  }
`

export const Header = styled.header`
  background: ${(props) => props.theme.color.paleBlue};
  padding: 49px;
  margin-bottom: 70px;
  position: relative;
`

export const Title = styled.h1`
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 18px;
`

export const OrdersDate = styled.h2`
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const Info = styled.p`
  font-size: 13px;
`

interface RowProps {
  block?: boolean
  noBorder?: boolean
  theme?: {
    color: {
      lightGray: string
    }
  }
  left?: boolean
}

export const Row = styled.div<RowProps>`
  display: ${(props) => (props.block ? 'block' : 'flex')};
  justify-content: space-between;
  margin-bottom: 22px;
  ${(props) =>
    !props.noBorder
      ? `
    padding-bottom: 22px;
    border-bottom: 1px solid ${props.theme.color.lightGray};`
      : ''}
  text-align: ${(props) => (props.left ? 'left' : 'center')};
`

interface CellProps {
  children: ReactNode
  danger?: true
  noBorder?: true
  strong?: true
}

export const Cell = styled.span<CellProps>`
  ${(props) => (props.strong ? 'font-weight: bold; color: black;' : '')}
  ${(props) => (props.danger ? 'font-weight: bold; color: red;' : '')}
  text-transform: capitalize;
`

export const DiscountLabel = styled.span`
  background: ${(props) => props.theme.color.green};
  display: inline-block;
  color: white;
  padding: 6px 9px;
  border-radius: 5px;
`

export const DeliveryIssuesWrapper = styled.span`
  text-align: left;
`

export const Code = styled.pre`
  color: ${(props) => props.theme.color.darkGray};
  text-align: left;
`

export const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 0.1rem solid #ccc;
  padding: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  border-radius: 0.5rem;
`

export const ImageWrapper = styled.a`
  flex: 1 1 calc(30% - 1rem);
  max-width: calc(30% - 1rem);
  box-sizing: border-box;
  border: 0.1rem solid #ccc;
  overflow: hidden;
  background: #f0f0f0;

  img {
    width: 100%;
    height: auto;
  }
`
