import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import {
  fetchShippingBoxes,
  editShippingBox,
  createShippingBox,
  deleteShippingBox,
} from './effects'
import { setShippingBoxes, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const shippingBoxes$ = makeModel({
  effects: makeEffects({
    createShippingBox,
    deleteShippingBox,
    editShippingBox,
    fetchShippingBoxes,
  }),
  prefix: 'shippingBoxes',
  reducers: makeReducers({
    setShippingBoxes,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default shippingBoxes$
