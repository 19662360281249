import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { ShippingBoxType, ShippingBoxWithIdType } from '~/app/common/types'

/**
 * fetches all available shipping boxes
 */
export function fetchShippingBoxes(store: any, self$: any) {
  return async (): Promise<void> => {
    await self$.call.setStatus('loading')
    try {
      const response = await Api.fetchShippingBoxes()
      await store.dispatch(self$.setShippingBoxes(response.data))
      await self$.call.setStatus('loaded')
    } catch (error: any) {
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}

/**
 * Edits a shipping box
 *
 * @param store - Root store
 * @param self$ - shippingBoxes store
 */
export function editShippingBox(store: any, self$: any) {
  return async (payload: { id: number; data: ShippingBoxWithIdType }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.editShippingBox(payload.id, payload.data)
      const response = await Api.fetchShippingBoxes()
      self$.call.setShippingBoxes(response.data)
      self$.call.setStatus('loaded')
      alert$.call.setNotification('Successfully edited shipping box')
    } catch (error) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}

/**
 * Creates shipping box
 *
 * @param store - Root store
 * @param self$ - shippingBoxes store
 */
export function createShippingBox(store: any, self$: any) {
  return async (payload: {
    data: ShippingBoxType
    onSuccess: (id: number) => void
  }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const { id } = await Api.createShippingBox(payload.data)
      const response = await Api.fetchShippingBoxes()
      self$.call.setShippingBoxes(response.data)
      self$.call.setStatus('loaded')
      payload.onSuccess(id)
      alert$.call.setNotification('Successfully created new shipping box')
    } catch (error) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}

/**
 * Deletes shipping box
 *
 * @param store - Root store
 * @param self$ - shippingBoxes store
 */
export function deleteShippingBox(store: any, self$: any) {
  return async (id: number): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.deleteShippingBox(id)
      const response = await Api.fetchShippingBoxes()
      self$.call.setShippingBoxes(response.data)
      self$.call.setStatus('loaded')
    } catch (error) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}
