import { camelCase, omit, pick, merge } from 'lodash'
import { ALT_TEXT_SUFFIX } from '~/app/common/constants'
import { ProductImageType } from '~/app/common/types'
import { type ProductImagesMetaData } from './types'

export const remapPicturesToKeys = (product: any) =>
  merge(omit(product, ['images', 'productPrices']), {
    ...product.images.reduce(
      (accumulator: any, current: any) => ({
        ...accumulator,
        [current.imageType]: current.url,
        [`${current.imageType}${ALT_TEXT_SUFFIX}`]: current.altText || null,
      }),
      {},
    ),
    nutritionHighlight:
      product.nutritionHighlight && product.nutritionHighlight.highlight
        ? Object.entries(product.nutritionHighlight.highlight).map(([name, val]) => ({
            name,
            // @ts-ignore
            val: Object.keys(val).map((valKey) => ({
              highlightName: valKey,
              // @ts-ignore
              highlightValue: val[valKey],
            })),
          }))
        : [],
    price: product.productPrices.length > 0 && product.productPrices[0].price / 100,
  })

const imgKeys = Object.values(ProductImageType)
const imgKeyImgAltTextKeyMap = imgKeys.reduce(
  (obj: { [key in ProductImageType]?: string }, imgKey: ProductImageType) => ({
    ...obj,
    [imgKey]: `${imgKey}${ALT_TEXT_SUFFIX}`,
  }),
  {},
)
const imgAltTextKeys = Object.values(imgKeyImgAltTextKeyMap)

const heavyMetalsFields = ['arsenicMgPerKg', 'cadmiumMgPerKg', 'mercuryMgPerKg', 'leadMgPerKg']

export const mapProductForSubmission = (product: any) =>
  Object.assign.apply({}, [
    omit(product, [...imgKeys, ...imgAltTextKeys, ...heavyMetalsFields]),
    {
      heavyMetals: {
        arsenicMgPerKg: product.arsenicMgPerKg || null,
        cadmiumMgPerKg: product.cadmiumMgPerKg || null,
        mercuryMgPerKg: product.mercuryMgPerKg || null,
        leadMgPerKg: product.leadMgPerKg || null,
      },
    },
    {
      ingredients: product.ingredients
        ? product.ingredients.map((ingredient: any) => ingredient.id)
        : [],
    },
    { freeGift: !!product.freeGift },
    { isNew: !!product.isNew },
    {
      symbols: product.symbols ? product.symbols.map((symbol: any) => symbol.id) : [],
    },
    {
      nutritionHighlight:
        product.nutritionHighlight &&
        Object.fromEntries(
          product.nutritionHighlight.map(({ name, val }: any) => [
            name,
            Object.fromEntries(
              val.map(({ highlightName, highlightValue }: any) => [
                camelCase(highlightName),
                highlightValue,
              ]),
            ),
          ]),
        ),
    },
    { images: pick(product, imgKeys) },
    {
      imagesMetaData: imgKeys.reduce((obj: ProductImagesMetaData, imgKey: ProductImageType) => {
        const imgAltTextKey = imgKeyImgAltTextKeyMap[imgKey]
        const altText = imgAltTextKey && product[imgAltTextKey]
        if (altText) {
          obj[imgKey] = {
            altText,
          }
        }
        return obj
      }, {}),
    },
    {
      productDietaryTags: product.productDietaryTags
        ? product.productDietaryTags.map((dietaryTag: any) => dietaryTag.dietaryTagId)
        : [],
    },
    {
      productAllergens: product.productAllergens
        ? product.productAllergens.map((allergen: any) => allergen.id)
        : [],
    },
    { price: product.price * 100 },
    {
      labelId: product.labelId ? Number(product.labelId) : null,
    },
    {
      heatingInstructionId: product.heatingInstructionId
        ? Number(product.heatingInstructionId)
        : null,
    },
  ])
