import { makeReducers } from '~/app/utils/storeManager'
import { setMagicLink, clearMagicLink } from './magicLink/reducers'
import setDetails from './details/reducers'
import setUserSubscriptions from './subscriptions/reducers'
import { setUserCoupons } from './coupons/reducers'
import { setCreditsReasons, setUserCredits } from './credits/reducers'
import { setUserProductCoupons } from './productCoupons/reducers'
import {
  setUpcomingOrders,
  setOrderSummary,
  setUpcomingWeekOrdersSummary,
  setSecondUpcomingWeekOrdersSummary,
  setEditedOrder,
  removeFromOrder,
  addToOrder,
  addToManualOrder,
  removeFromManualOrder,
} from './deliverySchedule/reducers'
import { setPastOrders } from './pastOrders/reducers'
import setKidDetails from './kids/reducers'
import {
  setUserFeatureFlagsLoaded,
  setUserFeatureFlagsLoading,
  setUserFeatureFlagsError,
} from './userFeatureFlags/reducers'
import {
  setUserLogsLoading,
  setUserLogsLoaded,
  setUserLogsError,
  setUserLogsInitialState,
} from './logs/reducers'
import {
  setOrderRefundsLoading,
  setOrderRefundsLoaded,
  setOrderRefundsError,
  setOrderRefundsInitialState,
  setOrderRefundsSummaryLoaded,
} from './orderRefunds/reducers'

const reducers = makeReducers({
  addToManualOrder,
  addToOrder,
  clearMagicLink,
  removeFromManualOrder,
  removeFromOrder,
  setCreditsReasons,
  setDetails,
  setEditedOrder,
  setKidDetails,
  setMagicLink,
  setPastOrders,
  setUpcomingOrders,
  setOrderSummary,
  setUpcomingWeekOrdersSummary,
  setSecondUpcomingWeekOrdersSummary,
  setUserCoupons,
  setUserCredits,
  setUserFeatureFlagsError,
  setUserFeatureFlagsLoaded,
  setUserFeatureFlagsLoading,
  setUserProductCoupons,
  setUserSubscriptions,
  setUserLogsLoading,
  setUserLogsLoaded,
  setUserLogsError,
  setUserLogsInitialState,
  setOrderRefundsLoading,
  setOrderRefundsLoaded,
  setOrderRefundsError,
  setOrderRefundsInitialState,
  setOrderRefundsSummaryLoaded,
})

export default reducers
