import DoesNotEatMeat from './DoesNotEatMeat'
import EatsMeat from './EatsMeat'

type Props = {
  value: boolean
}

export default function EatsMeatIcon(props: Props) {
  return props.value ? <EatsMeat /> : <DoesNotEatMeat />
}
