import Tag from '../Tag'
import { colors, theme } from '~/app/settings/colors'
import { firstOrderStatus } from '~/app/common/constants'

const tagColors = { ...colors, ...theme.color } as const

const statusToColor = {
  active: tagColors.active,
  cancelled: tagColors.cancelled,
  incomplete: tagColors.lightGray,
  paused: tagColors.paused,
  'pending cancellation': tagColors.orange,
  [firstOrderStatus.canceled]: tagColors.red,
  [firstOrderStatus.charged]: tagColors.black,
}

interface Props {
  status: string
  color?: [keyof typeof tagColors][0]
}

export default function ActivityTag({ status, color }: Props) {
  // @ts-ignore
  const tagColor = statusToColor[status] || tagColors[color]
  return (
    // @ts-ignore
    <Tag color={tagColor} status={status}>
      {status}
    </Tag>
  )
}
