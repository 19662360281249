import { useMappedState } from './useReduxStore'
import { selectUserRoles } from '~/app/store/app/selectors'
import { UserRole as UserRoles } from '~/app/common/constants'

type State = Record<string, any>

interface UserRole {
  id: number
  name: UserRoles
}

/**
 * Checks if state matches one of the user roles.
 */
function hasUserRole(state: State, roles: UserRole['name'][]): boolean {
  // @ts-ignore
  const userRoles: UserRole['name'][] = (selectUserRoles(state) || []).map((role) => role.name)
  const foundRole = userRoles.some((role) => roles.includes(role))
  return Boolean(foundRole)
}

/**
 * Checks if user has one of the roles.
 */
export default function useHasUserRole(roles: UserRole['name'][]): boolean {
  return useMappedState((state: State) => hasUserRole(state, roles))
}
