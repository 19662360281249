interface Props {
  color: string
}

export default function Caret({ color }: Props) {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" xmlns="http://www.w3.org/2000/svg">
      <title>Caret</title>
      <path d="M0 0l4 5 4-5" fill={color} fillRule="evenodd" />
    </svg>
  )
}
