export const setCreditsReasons = (state: any) => (reasons: any) => ({
  ...state,
  credits: {
    ...state.credits,
    creditReasons: reasons,
  },
})

export const setUserCredits = (state: any) => (params: { balance: number; history: any }) => {
  const { balance, history } = params
  return {
    ...state,
    credits: {
      ...state.credits,
      balance,
      history,
    },
  }
}
