interface Props {
  className?: string
}

export default function MediumPlanIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="65" viewBox="0 0 100 65" {...props}>
      <title>Medium plan</title>
      <g fill="none" fillRule="evenodd" transform="translate(-12 -30)">
        <circle cx="62" cy="62" r="62" opacity="0.1" />
        <g transform="translate(13 32)">
          <ellipse cx="34.736" cy="31" fill="#49C5B1" rx="30.264" ry="31" />
          <ellipse
            cx="30.264"
            cy="31"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="30.264"
            ry="31"
          />
          <path
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M.992 22.36c12.815 4.744 22.49 7.115 29.024 7.115 6.533 0 16.208-2.371 29.023-7.114"
          />
          <path
            fill="#FFF"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M30.016 29.475v32.017"
          />
          <path
            fill="#FACE4E"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M23.084 14.738c-.646 2.88-2.398 3.84-5.256 2.88-4.286-1.44-4.534.205-4.41 2.698.124 2.492 3.124 6.407 6.395 4.45 3.27-1.958 4.961.53 7.442-4.75 1.653-3.519.263-5.278-4.171-5.278z"
          />
          <path
            fill="#FACE4E"
            d="M48.983 15.025c-3.265 2.51-5.458 2.253-6.58-.77-1.684-4.535-3.461-3.015-5.795-.357-2.333 2.658-3.98 8.829.275 9.138 4.256.31 3.054 4.077 9.97.4 4.61-2.452 5.32-5.256 2.13-8.411z"
          />
          <ellipse cx="11.659" cy="33.033" fill="#FF979D" rx="1.736" ry="2.033" />
          <ellipse cx="19.101" cy="34.049" fill="#FF979D" rx="1.736" ry="2.033" />
          <ellipse cx="15.132" cy="37.098" fill="#FF979D" rx="1.736" ry="2.033" />
          <ellipse cx="17.612" cy="43.705" fill="#FF979D" rx="1.736" ry="2.033" />
          <ellipse cx="21.085" cy="40.656" fill="#FF979D" rx="1.736" ry="2.033" />
          <ellipse cx="22.078" cy="47.77" fill="#FF979D" rx="1.736" ry="2.033" />
          <ellipse cx="11.659" cy="42.689" fill="#FF979D" rx="1.736" ry="2.033" />
          <path
            stroke="#2A313F"
            strokeWidth="1.3"
            d="M23.07 45.738c.959 0 1.736.91 1.736 2.032 0 1.123-.777 2.033-1.736 2.033-.96 0-1.737-.91-1.737-2.033 0-1.122.778-2.032 1.737-2.032zm-4.465-4.066c.959 0 1.736.91 1.736 2.033 0 1.123-.777 2.033-1.736 2.033-.96 0-1.737-.91-1.737-2.033 0-1.123.778-2.033 1.737-2.033zm-5.954-1.016c.96 0 1.737.91 1.737 2.033 0 1.122-.778 2.032-1.737 2.032-.959 0-1.736-.91-1.736-2.032 0-1.123.777-2.033 1.736-2.033zm9.427-2.033c.959 0 1.736.91 1.736 2.033 0 1.122-.777 2.033-1.736 2.033-.96 0-1.737-.91-1.737-2.033 0-1.123.778-2.033 1.737-2.033zm-5.954-3.557c.959 0 1.736.91 1.736 2.032 0 1.123-.777 2.033-1.736 2.033s-1.736-.91-1.736-2.033c0-1.122.777-2.032 1.736-2.032zm3.969-3.05c.959 0 1.736.91 1.736 2.033 0 1.123-.777 2.033-1.736 2.033s-1.736-.91-1.736-2.033c0-1.122.777-2.033 1.736-2.033zM12.651 31c.96 0 1.737.91 1.737 2.033 0 1.122-.778 2.033-1.737 2.033-.959 0-1.736-.91-1.736-2.033 0-1.123.777-2.033 1.736-2.033z"
          />
          <path
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M46.998 15.025c-3.264 2.51-5.458 2.253-6.58-.77-1.683-4.535-3.46-3.015-5.794-.357-2.334 2.658-3.98 8.829.275 9.138 4.255.31 3.054 4.077 9.969.4 4.61-2.452 5.32-5.256 2.13-8.411z"
          />
          <path
            fill="#FACE4E"
            d="M29.232 5.572c.702 4.12-.517 6.004-3.657 5.656-4.709-.522-4.194 1.79-2.941 5.126 1.253 3.337 5.873 7.642 8.074 3.898 2.2-3.743 4.932-.936 4.873-8.917-.04-5.32-2.156-7.242-6.349-5.763z"
          />
          <path
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M28.51 4.157c.7 4.119-.518 6.004-3.658 5.656-4.709-.522-4.194 1.79-2.941 5.126 1.253 3.336 5.873 7.641 8.074 3.898 2.2-3.744 4.932-.937 4.873-8.918-.04-5.32-2.156-7.241-6.349-5.762z"
          />
          <rect width="3.473" height="17.279" x="34.233" y="34.557" fill="#DECDE7" rx="1.736" />
          <path
            fill="#C5ABDB"
            d="M47.306 31.174c.718.662.776 1.775.132 2.508l-8.97 10.204a1.714 1.714 0 01-2.449.128 1.798 1.798 0 01-.131-2.508l8.969-10.204a1.714 1.714 0 012.45-.128z"
          />
          <path
            fill="#FF979D"
            d="M39.123 34.598a1.711 1.711 0 012.393.509l7.314 11.537c.52.82.29 1.907-.52 2.446a1.711 1.711 0 01-2.393-.509l-7.314-11.537a1.788 1.788 0 01.52-2.446z"
          />
          <rect
            width="3.473"
            height="17.279"
            x="35.225"
            y="33.541"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.736"
          />
          <path
            stroke="#2A313F"
            strokeWidth="1.3"
            d="M48.795 30.157a1.8 1.8 0 01.131 2.51l-8.97 10.203a1.714 1.714 0 01-2.449.128 1.798 1.798 0 01-.131-2.509l8.97-10.203a1.714 1.714 0 012.449-.129z"
          />
          <path
            stroke="#302D1F"
            strokeWidth="1.7"
            d="M40.115 33.582a1.711 1.711 0 012.393.509l7.314 11.536c.52.821.29 1.908-.52 2.446a1.711 1.711 0 01-2.392-.508l-7.314-11.537a1.788 1.788 0 01.52-2.446z"
          />
        </g>
        <g transform="translate(49 31)">
          <ellipse cx="32" cy="31.5" fill="#F4AAAE" rx="31" ry="31.5" />
          <ellipse
            cx="31"
            cy="31.5"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="31"
            ry="31.5"
          />
        </g>
        <g transform="rotate(-11 279.809 -297.99)">
          <ellipse
            cx="18.277"
            cy="19.387"
            fill="#F4C389"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            rx="6.281"
            ry="12.136"
            transform="rotate(-33 18.277 19.387)"
          />
          <ellipse
            cx="3.25"
            cy="3.415"
            fill="#F4C389"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="2.75"
            ry="2.794"
          />
          <ellipse
            cx="18.25"
            cy="3.415"
            fill="#F4C389"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="2.75"
            ry="2.794"
          />
          <ellipse
            cx="11.25"
            cy="7.988"
            fill="#F4C389"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            rx="7.25"
            ry="7.367"
          />
          <path fill="#9DEFE6" d="M5.672 11.928l19.792 20.826-2.158 2.118L3.514 14.046z" />
          <path
            stroke="#2A313F"
            strokeWidth="1.3"
            d="M3.9 10.565L23.692 31.39l-2.158 2.118L1.74 12.683z"
          />
          <path
            fill="#F4C389"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M15.227 22.153c-2.052 1.877-4.39 2.638-5.221 1.7-.832-.938.158-3.221 2.21-5.099 2.052-1.877 4.39-2.638 5.221-1.7.831.938-.158 3.221-2.21 5.099z"
          />
          <ellipse
            cx="18.721"
            cy="28.074"
            fill="#F4C389"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            rx="5.036"
            ry="2.27"
            transform="rotate(-42 18.721 28.074)"
          />
        </g>
      </g>
    </svg>
  )
}
