import { compose, lifecycle, pure } from 'recompose'
import { connect } from 'react-redux'
import faultyOrderReasons$ from '~/app/store/faultyOrderReasons'
import { fetchGiftProducts } from '~/app/store/products/actions'
import { selectGiftProducts, selectGiftProductsLoaded } from '~/app/store/products/selectors'
import { formatDate, getUserFriendlyName } from '../helpers'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { OrderDeliveryIssues } from '~/app/pages/User/UserOrders/types'
import { ISSUES_OPTION_IDS_TO_SHOW_PRODUCTS_SELECTOR } from '../constants'
import { Cell, Code, DeliveryIssuesWrapper, ImageWrapper, ImagesContainer, Row } from './styled'

interface Props {
  deliveryIssues: OrderDeliveryIssues[]
  faultyOrderReasons: Record<string, string>[]
  giftProducts: Record<string, any>[]
}

function DeliveryIssues({ deliveryIssues, faultyOrderReasons, giftProducts }: Props) {
  return (
    <>
      {deliveryIssues.map((deliveryIssue) => (
        <DeliveryIssuesWrapper key={`${deliveryIssue.createdAt}`}>
          <Row>
            <Cell strong danger>
              Delivery Issues reported on: {formatDate(deliveryIssue.createdAt)}
            </Cell>
          </Row>
          <Row>
            <Cell style={{ textAlign: 'left' }}>
              Issue #1: {getUserFriendlyName(deliveryIssue.issueIds[0], faultyOrderReasons)}
              <DeliveryIssuesProducts
                deliveryIssueId={deliveryIssue.issueIds[0]}
                deliveryIssue={deliveryIssue}
              />
            </Cell>
            <Cell>Intercom Link: {deliveryIssue.intercomLink || 'None'}</Cell>
          </Row>
          <Row>
            <Cell style={{ textAlign: 'left' }}>
              Issue #2:{' '}
              {getUserFriendlyName(deliveryIssue.issueIds[1], faultyOrderReasons) || 'None'}{' '}
              <DeliveryIssuesProducts
                deliveryIssueId={deliveryIssue.issueIds[1]}
                deliveryIssue={deliveryIssue}
              />
            </Cell>
            <Cell>Order Resent? {deliveryIssue.resent ? 'Yes' : 'No'}</Cell>
          </Row>
          <Row>
            <Cell style={{ textAlign: 'left' }}>
              Issue #3:{' '}
              {getUserFriendlyName(deliveryIssue.issueIds[2], faultyOrderReasons) || 'None'}{' '}
              <DeliveryIssuesProducts
                deliveryIssueId={deliveryIssue.issueIds[2]}
                deliveryIssue={deliveryIssue}
              />
            </Cell>
            <Cell>Photos provided? {deliveryIssue.photosProvided ? 'Yes' : 'No'}</Cell>
          </Row>
          <Row>
            <Cell>Note: {deliveryIssue.note || 'None'}</Cell>
            <Cell>
              Free Gift:{' '}
              {deliveryIssue.extraProductIds
                ? getUserFriendlyName(deliveryIssue.extraProductIds[0], giftProducts)
                : 'None'}
            </Cell>
          </Row>
          {(deliveryIssue.photos?.length ?? 0) > 0 && (
            <Row block left>
              <details>
                <summary>Photos</summary>
                <Code>
                  <ImagesContainer>
                    {deliveryIssue.photos?.map((src, index) => (
                      <ImageWrapper
                        key={index}
                        href={src}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={src} alt="Order delivery issue" />
                      </ImageWrapper>
                    ))}
                  </ImagesContainer>
                </Code>
              </details>
            </Row>
          )}
        </DeliveryIssuesWrapper>
      ))}
    </>
  )
}

function DeliveryIssuesProducts({
  deliveryIssue,
  deliveryIssueId,
}: {
  deliveryIssue: OrderDeliveryIssues
  deliveryIssueId: number
}) {
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))

  if (!ISSUES_OPTION_IDS_TO_SHOW_PRODUCTS_SELECTOR.includes(deliveryIssueId)) {
    return null
  }

  return (
    <>
      <br />
      {deliveryIssue.products?.map((product) => (
        <span key={product.sku}>
          {product.count} x {productsByIdOrSku[product.sku]?.name ?? product.sku}
          <br />
        </span>
      ))}
    </>
  )
}

const mapStateToProps = (state: Record<string, any>) => ({
  faultyOrderReasons: faultyOrderReasons$.getReasons(state),
  giftProducts: selectGiftProducts(state),
  giftProductsLoaded: selectGiftProductsLoaded(state),
})

const mapDispatchToProps = {
  fetchGiftProducts,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      // @ts-ignore
      if (!this.props.giftProductsLoaded) {
        // @ts-ignore
        this.props.fetchGiftProducts(1000, 0)
      }
    },
  }),
  pure,
  // @ts-ignore
)(DeliveryIssues)
