import { useState } from 'react'
import { ProductType } from '~/app/common/types'
import user$ from '~/app/store/user'
import { ReasonsArrayType } from './types'
import useCancellationReasons from './useCancellationReasons'

interface Props {
  userId: number
  templateId: number
  type: ProductType
}

export default function useCancellationController(props: Props) {
  const { userId, templateId } = props

  const [showCancellationModal, setCancellationModalVisibility] = useState(false)
  const [showActivationModal, setActivationModalVisibility] = useState(false)

  const { reasons, reasonsStatus } = useCancellationReasons({ type: props.type })

  const handleCancellationModal = () => {
    setCancellationModalVisibility(!showCancellationModal)
  }

  const handleActivationModal = () => {
    setActivationModalVisibility(!showActivationModal)
  }

  const handleCancel = (reasonsSelected: ReasonsArrayType[], note: string) => {
    const cancellationReasonData = {
      note,
      reasons: reasonsSelected,
    }
    user$.call.cancelUserSubscription({
      cancellationReasonData,
      data: { cancel: true, templateId },
      userId,
    })
    setCancellationModalVisibility(false)
  }

  const handleReactivation = () => {
    user$.call.cancelUserSubscription({
      data: { cancel: false, templateId },
      userId,
    })
    setActivationModalVisibility(false)
  }

  return {
    handleActivationModal,
    handleCancel,
    handleCancellationModal,
    handleReactivation,
    reasons,
    reasonsStatus,
    setActivationModalVisibility,
    setCancellationModalVisibility,
    showActivationModal,
    showCancellationModal,
  }
}
