import { theme } from '~/app/settings/colors'

export default function EditIconFull(props: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Edit</title>
      <path
        d="M13.385.476C13.083.173 12.67 0 12.238 0c-.432 0-.843.173-1.147.476L1.766 9.802c-.195.194-.325.433-.41.692L.032 15.016c-.086.26 0 .54.195.758.15.15.346.216.54.216.066 0 .152 0 .217-.022l4.522-1.32c.26-.087.498-.217.693-.41l9.326-9.328c.303-.303.476-.714.476-1.147 0-.432-.172-.843-.475-1.145L13.385.476zM2.565 11.75l1.688 1.687-2.38.693.692-2.38zm10.41-6.427L10.68 3.028l1.47-1.47c.023-.023.066-.023.087-.023.022 0 .044 0 .087.022L14.466 3.7c.022.02.022.064.022.085 0 .023-.022.044-.043.066l-1.47 1.473z"
        fill={theme.color.green}
        fillRule="evenodd"
      />
    </svg>
  )
}
