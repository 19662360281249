import InputSmallText from '~/app/components/InputTypes/SmallText'
import InputSelect from '~/app/components/InputTypes/Select'
import { email, phoneNumber } from '~/app/common/validators'

export default function formDefinition(type: string) {
  switch (type) {
    case 'email':
      return [
        {
          name: 'email',
          type: InputSmallText,
          validators: [email],
        },
      ]
    case 'lastName':
      return [
        {
          name: 'lastName',
          type: InputSmallText,
        },
      ]
    case 'firstName':
      return [
        {
          name: 'firstName',
          type: InputSmallText,
        },
      ]
    case 'smsNotification':
      return [
        {
          name: 'smsNotification',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          small: true,
          type: InputSelect,
        },
      ]
    case 'phone':
      return [
        {
          name: 'phone',
          type: InputSmallText,
          validators: [phoneNumber],
        },
      ]
    default:
      return null
  }
}
