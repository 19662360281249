import { merge } from 'ramda'
import { FETCH_WAREHOUSES, FETCH_WAREHOUSES_SUCCESS } from './constants'
const initialState = {
  data: [],
  loaded: false,
  loading: false,
}

function warehousesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WAREHOUSES:
      return merge(state, {
        data: [],
        loaded: false,
        loading: true,
      })

    case FETCH_WAREHOUSES_SUCCESS:
      return merge(state, {
        data: action.payload.data.map((warehouse) => ({
          ...warehouse,
          shortName: warehouse.name.toLocaleLowerCase(),
        })),
        loaded: true,
        loading: false,
      })

    default:
      return state
  }
}

export default warehousesReducer
