import { ProductType } from '~/app/common/types'

export const productTypeNameSingular = {
  [ProductType.biteable]: 'Biteable',
  [ProductType.blend]: 'Babyblend',
  [ProductType.booster]: 'Booster',
  [ProductType.cereal]: 'Baby Cereal',
  [ProductType.luncher]: 'Luncher',
  [ProductType.misc]: 'Miscellaneous',
  [ProductType.plate]: 'Plate',
  [ProductType.puff]: 'Puff',
  [ProductType.sauce]: 'Sauce',
  [ProductType.smoothie]: 'Smoothie',
  [ProductType.snack]: 'Snack',
}

export const productTypeNamePlural = {
  [ProductType.biteable]: 'Biteables',
  [ProductType.blend]: 'Babyblends',
  [ProductType.booster]: 'Boosters',
  [ProductType.cereal]: 'Baby Cereal',
  [ProductType.luncher]: 'Lunchers',
  [ProductType.misc]: 'Miscellaneous',
  [ProductType.plate]: 'Plates',
  [ProductType.puff]: 'Puffs',
  [ProductType.sauce]: 'Sauces',
  [ProductType.smoothie]: 'Smoothies',
  [ProductType.snack]: 'Snacks',
}
