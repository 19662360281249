import moment from 'moment'
import { useState, useEffect } from 'react'
import { useMappedState } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'

interface Props {
  userId: number
  templateId: number
}

export default function usePostponeController(props: Props) {
  const { userId, templateId } = props
  const [showPostponeModal, setPostponeModalVisibility] = useState(false)
  const [showPostponeButton, setPostponeButtonVisibility] = useState(false)
  const { upcomingOrders } = useMappedState((rootState: any) => ({
    upcomingOrders: user$.getUpcomingOrders(rootState, templateId) as Array<any>,
  }))

  useEffect(() => {
    user$.call.fetchUpcomingOrders(userId)
  }, [])

  useEffect(() => {
    if (upcomingOrders?.length) {
      const subscription = upcomingOrders.find((item) => item.templateId === templateId)
      if (!subscription) {
        return
      }

      const firstOrder = subscription.orders.find((order) => order.isFirst)
      if (moment().isBefore(firstOrder?.editableTo)) {
        setPostponeButtonVisibility(true)
      }
    }
  }, [upcomingOrders])

  const handlePostponeModal = () => {
    setPostponeModalVisibility(() => !showPostponeModal)
  }

  const handlePostponeFirstOrder = (editableTo: string) => {
    user$.call.postponeFirstOrder({
      data: { editableTo },
      templateId,
      userId,
    })
  }

  return {
    handlePostponeFirstOrder,
    handlePostponeModal,
    setPostponeModalVisibility,
    showPostponeModal,
    showPostponeButton,
  }
}
