import Input from '~/app/components/FormInput/Input'
export const formDefinition = [
  {
    label: 'First Name',
    name: 'name',
    type: Input,
  },
  {
    label: 'Gender',
    name: 'gender',
    type: Input,
  },
]
export default formDefinition
