import { useState } from 'react'
import useForm from '~/app/hooks/useForm'
import { Row } from '~/app/components/FormGrid'
import SaveButton from '~/app/components/Button/SaveButton'
import { LargeCell } from '~/app/components/Table'
import { MISSING_INFO_TEXT } from '~/app/utils/formatStringsforFE'
import user$ from '~/app/store/user'
import formDefinition from './formDefinition'
import { EditBtn, WarningText } from '../styled'

interface Props {
  initialValues: any
  type: 'birthdate' | 'name'
  kid: any
  userId: number
}

const birthdate = 'birthdate'

export default function EditForm(props: Props) {
  const self = useController(props)

  if (self.editField) {
    return (
      <>
        <EditBtn onClick={self.toggleEditField} />
        <Row>{self.renderField(props.type)}</Row>

        {props.type === birthdate && (
          <WarningText>Note: this will not update the kid's blueprint</WarningText>
        )}

        <SaveButton
          // @ts-ignore
          isNew={false}
          handleConfirm={self.handleSave}
          hasChanges={self.hasChanges}
          isValid={self.isValid}
          model={props.initialValues}
          width={200}
        />
      </>
    )
  }

  return (
    <LargeCell>
      {props.initialValues[props.type] ? props.initialValues[props.type] : MISSING_INFO_TEXT}{' '}
      <EditBtn onClick={self.toggleEditField} />
    </LargeCell>
  )
}

function useController(props: Props) {
  const [editField, setEditField] = useState(false)
  const toggleEditField = () => {
    setEditField(!editField)
  }

  const form = useForm({
    // @ts-ignore
    fieldDefinitions: formDefinition(props.type),
    formData: props.initialValues,
  })

  const handleSave = () => {
    form.serialize((data) => {
      user$.call.patchKid({ data, kidId: props.kid.id, userId: props.userId })
      toggleEditField()
    })
  }

  return { ...form, editField, handleSave, toggleEditField }
}
