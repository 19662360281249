import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { createEpicMiddleware } from 'redux-observable'
import { registerStore, effectsMiddleWare } from '~/app/utils/storeManager/setUp'
import { logger } from 'redux-logger'
import { createSelector } from 'reselect'
import { loadState, saveState } from '~/app/utils/localStorage'
import { NodeEnv } from '~/app/common/constants'
import Api from '~/app/common/api'
import { selectPersistableData } from '~/app/store/persistable'
import createReducer from './reducers'
import setupEpics from './epics'

const epicSetup = setupEpics()
// @ts-ignore
const epicMiddleware = createEpicMiddleware(epicSetup.rootEpic, {
  dependencies: { Api },
})

export default function configureStore(initialState = {}, history?: any) {
  const middlewares = [
    process.env.NODE_ENV !== NodeEnv.production
      ? logger
      : () => (next: any) => (action: any) => next(action),
  ]

  // When making a test store for spec files, epicMiddleware causes an error.
  // Since we also don't provide browser history for the test store, these middlewares
  // should only be included when the store is configured with history in app.tsx
  if (history) {
    middlewares.push(epicMiddleware, routerMiddleware(history))
  }

  // @ts-ignore
  const enhancers = [applyMiddleware(...middlewares, effectsMiddleWare)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose

  /* eslint-enable */
  const localState = loadState() || {}
  const state = { ...initialState, ...localState }
  // @ts-ignore
  const store = createStore(createReducer(), state, composeEnhancers(...enhancers))
  registerStore(store as any)

  const persistData = createSelector(selectPersistableData, saveState)
  store.subscribe(() => {
    persistData(store.getState())
  })

  // Extensions
  // @ts-ignore
  store.injectedReducers = {} // Reducer registry
  // @ts-ignore
  store.epic$ = epicSetup.epic$
  // @ts-ignore
  store.injectedEpics = {} // Epics registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      import('./reducers').then((reducerModule) => {
        const createReducers = reducerModule.default
        // @ts-ignore
        const nextReducers = createReducers(store.injectedReducers)

        store.replaceReducer(nextReducers)
      })
    })
  }

  return store
}
