import { Row } from '~/app/components/FormGrid'
import SaveButton from '~/app/components/Button/SaveButton'
import { LargeCell } from '~/app/components/Table'
import { EditBtn, Field } from './styled'
import useEditInfoController from './useEditInfoController'

interface Props {
  type: string
  formData: {
    smsNotification?: boolean
    [key: string]: any
  }
  userId: number
  displayMethod?: (value: string) => string
}

export default function EditForm({ type, formData, userId, displayMethod }: Props) {
  const self = useEditInfoController({
    formData,
    type,
    userId,
  })

  const { renderField, hasChanges, isValid, handleSave, handleDisplay } = self
  let smsNotificationsCopy = ''

  if (typeof formData[type] === 'boolean') {
    smsNotificationsCopy = formData[type] ? 'Yes' : 'No'
  }

  if (self.editField) {
    return (
      <LargeCell>
        <EditBtn onClick={self.toggleEdit} />
        <Row>
          <Field>{renderField(type)}</Field>
        </Row>
        <SaveButton
          // @ts-ignore
          center={false}
          isNew={false}
          handleConfirm={handleSave}
          hasChanges={hasChanges}
          isValid={isValid}
          model={formData}
          width={0}
        />
      </LargeCell>
    )
  }

  return (
    <LargeCell>
      {smsNotificationsCopy || handleDisplay(formData[type], displayMethod)}
      <EditBtn onClick={self.toggleEdit} />
    </LargeCell>
  )
}
