import PropTypes from 'prop-types'
import eatingStyle from '~/app/common/dataMaps/eatingStyle'
import eatingPhase from '~/app/common/dataMaps/eatingPhase'
import allergies from '~/app/common/dataMaps/allergies'
import AllergiesIcon from '~/app/components/Icons/Allergies'
import KidTypeIcon from '~/app/components/Icons/KidType'
import EatingPhaseIcon from '~/app/components/Icons/EatingPhase'
import EatingStyleIcon from '~/app/components/Icons/EatingStyle'
import EatsMeatIcon from '~/app/components/Icons/EatsMeat'
import { MISSING_INFO_TEXT } from '~/app/utils/formatStringsforFE'
import { Wrapper, Detail, DetailData, Label, Value, SectionName, Section } from './styled'
export default function KidDetails({ kid }) {
  return (
    <Wrapper>
      <Section>
        <SectionName>Your Kid Info</SectionName>
        {/* <Options kid={kid} handleEdit={handleEdit} /> */}
      </Section>
      {kid.type != null && (
        <Detail>
          <KidTypeIcon value="baby" />
          <DetailData>
            <Label>{kid.name || MISSING_INFO_TEXT} is a...</Label>
            <Value>{kid.type}</Value>
          </DetailData>
        </Detail>
      )}
      {kid.eatingStyle != null && (
        <Detail>
          <EatingStyleIcon value={kid.eatingStyle} />
          <DetailData>
            <Label>{kid.name} is ...</Label>
            <Value>{eatingStyle[kid.eatingStyle]}</Value>
          </DetailData>
        </Detail>
      )}
      {kid.phase != null && (
        <Detail>
          <EatingPhaseIcon value={kid.phase} />
          <DetailData>
            <Label>{kid.name || MISSING_INFO_TEXT}</Label>
            <Value>{eatingPhase[kid.phase]}</Value>
          </DetailData>
        </Detail>
      )}
      {kid.allergy != null && (
        <Detail>
          <AllergiesIcon value={kid.allergy} />
          <DetailData>
            <Label>{kid.name || MISSING_INFO_TEXT} has ... </Label>
            <Value>{allergies[kid.allergy]}</Value>
          </DetailData>
        </Detail>
      )}
      <Detail>
        <EatsMeatIcon value={kid.eatsMeat} />
        <DetailData>
          <Label>{kid.name || MISSING_INFO_TEXT} </Label>
          <Value>{kid.eatsMeat ? 'eats meat' : "doesn't eat meat"}</Value>
        </DetailData>
      </Detail>
    </Wrapper>
  )
} // TODO: make type more specific

KidDetails.propTypes = {
  kid: PropTypes.any,
}
