import styled from 'styled-components'
import { colors } from '~/app/settings/colors'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.5rem;
  border-bottom: 0.1rem solid ${colors.lightGray};
  padding: 1.9rem 0;
  align-items: center;
`

export const Label = styled.label`
  width: 40%;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: black;
`

export const Error = styled.small`
  color: ${colors.error};
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  margin: 6px 0 0 6px;
`

export const StyledInput = styled.input`
  background: none;
  border: none;
  box-shadow: none;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  width: 100%;
  &::placeholder {
    color: ${colors.lightGray};
  }
  ${(props) =>
    props.disabled
      ? `
   {
    pointer-events: none;
    background: #f3f3f3!important;
   }
    `
      : ''};
`
