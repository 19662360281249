import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { is, map } from 'ramda'

/**
 * FETCH_USER_DETAILS
 */
export function fetchUserDetails(store: any, self$: any) {
  return async (id: number): Promise<void> => {
    loader$.call.show()
    try {
      const [userDetails, ltv] = await Promise.all([Api.fetchUserDetail(id), Api.fetchUserLTV(id)])
      await store.dispatch(self$.setDetails({ ...userDetails, ltv }))
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function editUserDetails(store: any, self$: any) {
  return async (payload: { id: number; data: any }): Promise<void> => {
    loader$.call.show()
    try {
      const response = await Api.editUser(payload.id, getApiFriendly(payload.data))
      const ltv = await Api.fetchUserLTV(payload.id)
      await store.dispatch(self$.setDetails({ ...response, ltv }))
      alert$.call.setNotification('User details have been updated')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

// @ts-ignore
function getApiFriendly(data: any) {
  return map((val) => {
    if (is(Object, val)) {
      return getApiFriendly(val)
    } else if (val === '') {
      return null
    }
    return val
  }, data)
}
