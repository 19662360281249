import moment from 'moment'
import { createSelector } from 'reselect'
import { find, merge, propEq } from 'ramda'
import { get, flatMap, groupBy } from 'lodash'
import { selectData as selectProducts } from '~/app/store/products/selectors'
import { get as getRootSelector } from '../rootSelector'
import { getDetails } from '../details/selectors'

export const getDeliverySchedule = createSelector(
  getRootSelector,
  (user): string => user.deliverySchedule,
)

export const getUpcomingOrders = createSelector(
  getDeliverySchedule,
  // @ts-ignore
  (deliverySchedule): any => deliverySchedule.upcomingOrders,
)

export const getOrderSummary = createSelector(
  getDeliverySchedule,
  // @ts-ignore
  (deliverySchedule): any => deliverySchedule.orderSummary,
)

export const getUpcomingWeekOrdersSummary = createSelector(
  getDeliverySchedule,
  // @ts-ignore
  (deliverySchedule): any => deliverySchedule.upcomingWeekOrdersSummary,
)

export const getSecondUpcomingWeekOrdersSummary = createSelector(
  getDeliverySchedule,
  // @ts-ignore
  (deliverySchedule): any => deliverySchedule.secondUpcomingWeekOrdersSummary,
)

export const getEditedOrder = createSelector(
  getDeliverySchedule,
  // @ts-ignore
  (deliverySchedule): any => deliverySchedule.editedOrder,
)

export const getOrdersByDate = createSelector(
  getUpcomingOrders,
  selectProducts,
  getDetails,
  (groupedOrders: any, selectProducts: any, user: any) => {
    const userWarehouseId = Number(get(user, 'shippingInfo.warehouseId'))
    const ordersWithKid = flatMap(groupedOrders, ({ templateId, active, kidId, type, orders }) =>
      orders.map((order) => {
        const now = moment()
        const kid = user && find(propEq('id', kidId))(user.kids)
        const kidsName = kid ? kid.name : '...'
        const extendedEditableTo = order.isFirst
          ? moment(order.editableTo).add(process.env.EDITABLE_TO_EXTENSION_IN_HOURS || 0, 'h')
          : null
        const editableTo = moment(extendedEditableTo || order.editableTo)
        const editable = !order.hasBeenSentToShipStation && editableTo.isAfter(now)
        const deliveryDate = moment.utc(order.editableTo).add(4, 'days')
        const addOns = order.addOns.map((addOn: any, index: number) => ({
          active: addOn.active !== undefined ? Boolean(addOn.active) : true,
          products: ((addOn || {}).products || []).map(({ id, count }: any) => {
            const product = find(propEq('id', id))(selectProducts)
            if (product) {
              // @ts-ignore
              const { name, images, top, shadow, packetCount } = product
              return {
                count,
                id,
                images,
                inStock: isInStock(product, userWarehouseId, editableTo),
                name,
                packetCount,
                shadow,
                top,
              }
            }
            return { count, id, inStock: false }
          }),
          skippable: order.addOns[index].changeable
            ? Boolean(order.addOns[index].changeable.skip)
            : false,
          skipped: Boolean(order.addOns[index].skipped),
          templateId: addOn.templateId,
          type: addOn.type || null,
        }))

        const products = (order.products || []).map(({ id, count, name, images }: any) => {
          const product = find(propEq('id', id))(selectProducts)
          if (product) {
            return {
              count,
              id,

              // @ts-ignore
              images: product.images,

              inStock: isInStock(product, userWarehouseId, editableTo),
              // @ts-ignore
              name: product.name,
            }
          }
          // In the case that a product was removed from the babyblends api but is on the order
          // we mark it as out of stock to prevent this to blow up
          return { count, id, images, inStock: false, name }
        })

        return {
          ...order,
          kidsName,
          kidId,
          active,
          addOns,
          products,
          deliveryDate,
          editable,
          id: templateId,
          editableTo,
          extendedEditableTo,
          type,
          skippable: order.changeable.skip && editable,
          mealsPerDelivery: order.products.reduce((sum, { count }) => sum + count, 0),
        }
      }),
    )
    const ordersByDate = groupBy(ordersWithKid, 'deliveryEstimate')
    const orderDates = Object.keys(ordersByDate).sort((a, b) => new Date(a) - new Date(b))

    return orderDates.map((date) => ({
      date,
      orders: ordersByDate[date],
    }))
  },
)

export const getProductsWithCounts = createSelector(
  // @ts-ignore
  selectProducts,
  getEditedOrder,
  getDetails,
  (products: any, editedOrder: any, user: any) =>
    getProductCounts(products, editedOrder, user, 'products'),
)

export const getAddOnProductsWithCounts = createSelector(
  // @ts-ignore
  selectProducts,
  getEditedOrder,
  getDetails,
  (products: any, editedOrder: any, user: any) =>
    getProductCounts(products, editedOrder, user, 'addOnProducts'),
)

// @ts-ignore
export const getAddOnsQInOrder = createSelector(getEditedOrder, (state) =>
  (state.addOnProducts || []).reduce((sum: number, product: any) => sum + product.count, 0),
)

/**
 * Checks if product is in stock.
 *
 * @param product - Product.
 * @param userWarehouseId - User warehouse id.
 * @param editableTo - Editable to.
 */
function isInStock(product: any, userWarehouseId: number, editableTo: moment.Moment): boolean {
  const replacement = product.productReplacements.find(
    ({ warehouseId }: { warehouseId: number }) => Number(warehouseId) === userWarehouseId,
  )
  if (replacement && moment(replacement.availability).isAfter(editableTo)) {
    return false
  }
  return true
}

const getProductCounts = (products: any, editedOrder: any, user: any, type: string) => {
  if (!editedOrder.id) {
    return []
  }
  const userWarehouseId = Number(get(user, 'shippingInfo.warehouseId'))
  return products.map((product: any) => {
    const productInOrder = find(propEq('id', product.id))(editedOrder[type])
    return merge(product, {
      count: (productInOrder && (productInOrder as unknown as { count: number }).count) || 0,
      inStock: isInStock(product, userWarehouseId, moment(editedOrder.editableTo)),
    })
  })
}
