import { useEffect, useState } from 'react'
import { RowTable, Header } from '~/app/components/Table'
import Loader from '~/app/components/Loader'
import OrderRow from './Order'
import OrderDetailsLightBox from './OrderDetails'
import { SpacerCol } from './styled'
import withFaultyOrderController from './withFaultyOrderController'
import { Order } from './types'
import type { Props as WithFaultyOrderProps } from './withFaultyOrderController'
import { getOrderProductCount, OrderProductCountReturnType } from './helpers'
import type { Plan } from '~/app/store/plans/types'
import RefundOrderDialog from './RefundOrderDialog'
import { useMappedState } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'
import type { ApiUpcomingOrder } from '~/app/store/user/deliverySchedule/types'

interface Props extends WithFaultyOrderProps {
  userId: number
  orders: Order[] | null
  plans: Plan[] | null
}

function UserOrders(props: Props) {
  const self = useController(props)

  if (!props.orders || !props.plans) {
    return <Loader />
  }

  return (
    <RowTable>
      <thead>
        <tr>
          <Header>Order Info</Header>
          <Header>Date</Header>
          <Header>Status</Header>
          <Header>Coupon</Header>
          <Header>Items</Header>
          <Header>Total</Header>
          <Header>Actions</Header>
          <SpacerCol />
        </tr>
      </thead>
      <tbody>
        {props.orders?.map((order, i) => (
          <OrderRow
            key={i} // different orders can have the same id if they are not altered
            index={i}
            onClick={self.setOrderDetailsOrderId}
            onSetAsFaulty={props.setFaultyOrder}
            order={order}
            productCount={self.orderIdToProductCountMap.get(order.id)!}
            onSetAsRefund={self.setRefundOrder}
            userId={props.userId}
            isFirst={self.isFirstOrder(order.id)}
          />
        ))}
      </tbody>
      {self.orderDetailsOrderId !== null && (
        <OrderDetailsLightBox
          closeHandler={self.closeLightBox}
          order={self.orderIdToOrderMap.get(self.orderDetailsOrderId)!}
          productCount={self.orderIdToProductCountMap.get(self.orderDetailsOrderId)!}
        />
      )}
      {self.refundOrder && (
        <RefundOrderDialog onClose={self.closeRefundOrderDialog} order={self.refundOrder} />
      )}
    </RowTable>
  )
}

function useController(props: Props) {
  const { upcomingOrders } = useMappedState((rootState: any) => ({
    upcomingOrders: user$.getUpcomingOrders(rootState) as Array<ApiUpcomingOrder>,
  }))
  const [orderDetailsOrderId, setOrderDetailsOrderId] = useState<number | null>(null)
  const [refundOrder, setRefundOrder] = useState<Order | null>(null)
  const orderIdToOrderMap = new Map<number, Order>(props.orders?.map((order) => [order.id, order]))
  const planIdToPlanMap = new Map<number, Plan>(props.plans?.map((plan) => [plan.id, plan]))
  const orderIdToProductCountMap = new Map<number, OrderProductCountReturnType>(
    props.orders?.map((order) => [order.id, getOrderProductCount(planIdToPlanMap, order)]),
  )
  const closeLightBox = () => setOrderDetailsOrderId(null)
  const closeRefundOrderDialog = () => setRefundOrder(null)

  const isFirstOrder = (orderId: number) => {
    const upcomingOrder = upcomingOrders
      .flatMap((upcomingOrder) => upcomingOrder.orders)
      .find((order) => order.orderId === orderId)

    return Boolean(upcomingOrder?.isFirst)
  }

  useEffect(() => {
    user$.call.fetchUpcomingOrders(props.userId)
  }, [])

  return {
    closeLightBox,
    orderDetailsOrderId,
    orderIdToOrderMap,
    orderIdToProductCountMap,
    setOrderDetailsOrderId,
    refundOrder,
    setRefundOrder,
    closeRefundOrderDialog,
    isFirstOrder,
  }
}

export default withFaultyOrderController(UserOrders)
