import ConfirmationModal from '~/app/components/Modal/ConfirmationModal'
import SkipIcon from '~/app/assets/svg/SkipIcon'
import useSkipController from './useSkipController'
import { Option, Text } from '../../styled'

type $Props = {
  buttonText: string
  data: {
    groupedOrders: Array<any> // eslint-disable-line react/no-unused-prop-types
  }
  parentTemplateId?: number
  groupIndex: number
  index: number
  kidId: number
  skippable: boolean
  userId: number
  type?: string
}

export default function SkipButton(props: $Props) {
  const { data, userId, buttonText } = props
  const skip = useSkipController({
    groupedOrders: data.groupedOrders,
    userId,
  })

  const onClick = () =>
    props.parentTemplateId
      ? skip.handleSkipAddOnToggle(props.index, props.parentTemplateId, props.type)
      : skip.handleSkipToggle(props.index, props.kidId, props.groupIndex)

  return (
    <>
      <Option onClick={onClick} showDangerMode={!props.skippable}>
        <SkipIcon />
        <Text>{buttonText}</Text>
      </Option>
      {skip.orderToUnskip !== null && (
        <ConfirmationModal
          isOpen={skip.orderToUnskip !== null}
          handleCancel={skip.unsetOrderToUnskip}
          handleConfirm={skip.forceUnskip}
          title="Forced Unskip"
          text="Are you sure that you want to unskip this order"
          confirmText="Yes, unskip it"
          cancelText="No"
          danger
        />
      )}
    </>
  )
}
