import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchActiveBoxesByState, editBoxFromState } from './effects'
import { setActiveBoxesByState, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const boxesByStates$ = makeModel({
  effects: makeEffects({
    editBoxFromState,
    fetchActiveBoxesByState,
  }),
  prefix: 'boxesByStates',
  reducers: makeReducers({
    setActiveBoxesByState,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default boxesByStates$
