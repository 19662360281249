import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const CustomizedDatePicker = styled(DatePicker)`
  ${(props) => (props.disabled ? 'background: #f3f3f3 !important' : '')}
`

export const PostponeDatePickerWrapper = styled.div`
  .react-datepicker {
    font-size: 1.6rem;
  }
  .react-datepicker__header {
    padding-top: 1.2rem;
  }
  .react-datepicker__month {
    margin: 0.6rem 1.5rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 3rem;
    line-height: 3.5rem;
    margin: 0.366rem;
  }
  .react-datepicker__current-month {
    font-size: 1.7rem;
  }
  .react-datepicker__navigation {
    top: 1.5em;
    line-height: 2.5rem;
    border: 0.67rem solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: ${(props) => props.theme.color.midGray};
    left: 1.5rem;
  }
  .react-datepicker__navigation--next {
    border-left-color: ${(props) => props.theme.color.midGray};
    right: 1.5rem;
  }
`
