import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: opacity 1s;
  ${(props: any) =>
    props.isVisible &&
    `
    opacity: 1;
    pointer-events: all;
  `}
`

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  ${(props: any) =>
    props.closeHandler &&
    `
    cursor: pointer;
  `}
`

export const Window = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 15px;
  width: calc(100% - 20px);
  max-width: ${(props: any) => props.maxWidth || '90%'};
  max-height: 90%;
  padding: ${(props: any) => props.padding};
  ${(props: any) =>
    props.noRoundBorders &&
    `
    border-radius: 0;
  `}
  ${media.md} {
    width: ${(props: any) => (props.width ? props.width : '550px')};
  }
  height: auto;
  @media (min-height: 700px) {
    height: auto;
  }
`
