import styled, { css } from 'styled-components'

const getSize = (props: any) => {
  if (props.small) {
    return css`
      font-size: 14px;
      height: 42px;
      padding: 0 12px;
    `
  }

  return css`
    font-size: 16px;
    height: 50px;
    padding: 0 18px;
  `
}

export const StyledSelect = styled.select`
  ${getSize}
  border: solid 1px ${(props) => props.theme.color.lightGray};
  border-radius: 4px;
  box-shadow: none;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  width: 100%;
  background: ${(props) => (props.disabled ? `#f3f3f3!important` : props.background)};
`
