export const MISSING_INFO_TEXT = 'unknown'

export function getGender(gender: 'male' | 'female') {
  if (gender) {
    return gender === 'male' ? 'Baby boy' : 'Baby girl'
  }
  return 'Unknown'
}

export function getEatsMeat(eatsMeat: boolean) {
  if (eatsMeat !== null) {
    return eatsMeat ? 'eats meat' : "doesn't eat meat"
  }
  return 'Unknown'
}
