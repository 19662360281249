import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'
export const ProductListGrid = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 0;
  > li {
    width: 46%;
  }
  &:after {
    content: '';
    width: 46%;
  }
  ${media.md} {
    > li {
      width: 32%;
    }
    &:after {
      width: 32%;
    }
  }
`
export const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
export const ProductBlock = styled.li`
  display: flex;
  text-align: center;
  margin-bottom: 5.1rem;
  position: relative;
  justify-content: center;
`
export const ProductName = styled.span`
  display: block;
  margin-bottom: 2.2rem;
  font-weight: 600;
  font-size: 0.9rem;
  color: #2a313f;
`

interface ImgProps {
  fade?: boolean
}

export const Img = styled.img<ImgProps>`
  width: 70%;
  display: block;
  margin: 0 auto;
  user-select: none;
  flex-shrink: 0;
  transition: opacity 1s;
  ${(props) => (props.fade ? 'opacity: 0.5;' : '')}
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')};
`

export const OutOfStockLabel = styled.p`
  color: #ff757c;
  border: 2px solid #ff757c;
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 86%;
  font-weight: bold;
  padding: 12px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.6);
`
