import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import type { ProductDefault } from './types'

export function fetchProductDefaults(_: any, self$: any) {
  return async (): Promise<void> => {
    loader$.call.show()
    self$.call.setLoading()
    try {
      const response = await Api.fetchProductDefaults()
      self$.call.setLoaded(response)
    } catch (err: any) {
      alert$.call.setNotification(err.message)
      self$.call.setError()
    }
    loader$.call.hide()
  }
}

export function editProductDefaults(_: any, self$: any) {
  return async (payload: { id: number; data: ProductDefault[] }): Promise<void> => {
    loader$.call.show()
    self$.call.setLoading()
    try {
      await Api.updateProductDefaults(payload.data)
      const response = await Api.fetchProductDefaults()
      self$.call.setLoaded(response)
      alert$.call.setNotification('Successfully edited product defaults!')
    } catch (err: any) {
      alert$.call.setNotification(err.message)
      self$.call.setError()
    }
    loader$.call.hide()
  }
}
