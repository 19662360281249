import styled from 'styled-components'

export const StyledTextarea = styled.textarea`
  box-shadow: none;
  background: none;
  -webkit-appearance: none;
  border: 1px solid ${(props) => props.theme.color.lightGray};
  border-radius: 4px;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100px;
  margin-bottom: 0;
  padding: 10px;
  padding: 15px 20px;
  ${(props: any) =>
    props.disabled &&
    `
   {
    pointer-events: none;
    background: #f3f3f3!important;
   }`};
`
