import { useEffect } from 'react'
import { useMappedState } from '~/app/hooks/useReduxStore'
import {
  getCancellationReasons,
  selectCancellationStatus,
} from '~/app/store/cancellationReasons/selectors'

import cancellationReasons$ from '~/app/store/cancellationReasons'
import { ProductType } from '~/app/common/types'

export default function useCancellationReasons({ type }: { type: ProductType }) {
  const { reasons, reasonsStatus } = useMappedState((state: any) => ({
    // @ts-ignore
    reasons: getCancellationReasons(state, { type }),

    // @ts-ignore
    reasonsStatus: selectCancellationStatus(state, { type }),
  }))

  useEffect(() => {
    cancellationReasons$.call.fetchCancellationReasons({ planType: type })
  }, [])

  return {
    reasons,
    reasonsStatus,
  }
}
