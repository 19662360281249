import { DataStatus } from '~/app/common/types'

export const setLoading = (state: Record<string, any>) => () => ({
  ...state,
  data: null,
  status: DataStatus.loading,
})

export const setLoaded = (state: Record<string, any>) => (data: Record<string, any>[]) => ({
  ...state,
  data,
  status: DataStatus.loaded,
})

export const setError = (state: Record<string, any>) => () => ({
  ...state,
  data: null,
  status: DataStatus.error,
})
