import type React from 'react'
import { StyledButton } from './styled'
import DotsLoader from './DotsLoader'

interface Props extends React.ComponentProps<'button'> {
  children: React.ReactNode
  danger?: boolean
  large?: boolean
  loading?: boolean
  onClick?: () => void
  primary?: boolean
  round?: boolean
  secondary?: boolean
  small?: boolean
}

export default function Button({
  children,
  disabled = false,
  onClick,
  loading,
  primary = false,
  secondary = false,
  danger,
  ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...rest
}: Props) {
  return (
    <StyledButton
      {...rest}
      loading={loading}
      onClick={!loading ? onClick : undefined}
      primary={primary}
      secondary={secondary}
      danger={danger}
      disabled={disabled}
    >
      {loading ? <DotsLoader primary={primary} secondary={secondary} /> : children}
    </StyledButton>
  )
}
