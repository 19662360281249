type Props = {
  className: string
}

export default function Shoe(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="30"
      viewBox="0 0 44 30"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E9EEFC"
          d="M19.856 1.833V3.11a6.984 6.984 0 0 1-6.983 6.984H1.698v13.968h40.508c0-12.941-9.78-21.169-22.35-22.229z"
        />
        <path
          fill="#FFF"
          d="M21.952 10.094l1.397 2.794 2.793.698-2.095 2.095.698 2.794-2.793-1.397-2.794 1.397.698-2.794-2.095-2.095 2.794-.698zM1.698 24.062h40.508v4.19H1.698zM19.856 3.11V1.833a33.127 33.127 0 0 0-2.793-.12h-.698a8.106 8.106 0 0 1-6.984 4.19c-3.558 0-4.897-2.22-5.362-3.428a1.191 1.191 0 0 0-1.116-.762H2.9c-.664 0-1.202.538-1.202 1.201v7.18h11.175a6.984 6.984 0 0 0 6.983-6.984z"
        />
        <path
          fill="#FFD782"
          d="M25.443 7.999a.698.698 0 0 1-.536-1.146l3.493-4.19a.698.698 0 1 1 1.072.894l-3.492 4.19a.697.697 0 0 1-.537.252zM30.332 10.792a.698.698 0 0 1-.536-1.145l3.492-4.191a.698.698 0 0 1 1.073.895l-3.492 4.19a.694.694 0 0 1-.537.251zM34.523 14.983a.699.699 0 0 1-.447-1.236l4.19-3.492a.698.698 0 1 1 .894 1.074l-4.19 3.492a.697.697 0 0 1-.447.162z"
        />
        <path
          fill="#93A8EF"
          fillRule="nonzero"
          stroke="#93A8EF"
          strokeWidth="0.5"
          d="M25.182 19.02a.7.7 0 0 0 .241-.715l-.603-2.41 1.816-1.815a.698.698 0 0 0-.324-1.171l-2.482-.621-1.254-2.507a.727.727 0 0 0-1.249 0l-1.253 2.507-2.482.62a.698.698 0 0 0-.324 1.172l1.815 1.815-.602 2.41a.698.698 0 0 0 .99.794l2.481-1.24 2.48 1.24a.7.7 0 0 0 .75-.08zm-1.813-3.17l.33 1.32-1.435-.717a.699.699 0 0 0-.625 0l-1.435.718.33-1.32a.698.698 0 0 0-.183-.664l-1.224-1.223 1.597-.4a.7.7 0 0 0 .456-.364l.772-1.544.772 1.544a.698.698 0 0 0 .455.364l1.597.4-1.223 1.223a.698.698 0 0 0-.184.663z"
        />
        <path
          fill="#93A8EF"
          fillRule="nonzero"
          stroke="#93A8EF"
          strokeWidth="0.5"
          d="M39.16 11.329a.698.698 0 0 0-.889-1.076 21.926 21.926 0 0 0-3.939-3.868l.029-.034a.698.698 0 1 0-1.073-.895l-.093.111a24.678 24.678 0 0 0-3.707-2.04.695.695 0 0 0-1.089-.865l-.256.307a29.907 29.907 0 0 0-7.588-1.765V1.19l-.638-.054h-.002l-.757-.064v.016a33.513 33.513 0 0 0-2.095-.075l-1.128.003-.194.382a7.341 7.341 0 0 1-6.36 3.806c-3.1 0-4.281-1.867-4.71-2.98a1.88 1.88 0 0 0-1.772-1.21A1.902 1.902 0 0 0 1 2.915v25.337c0 .386.312.698.698.698h40.508a.698.698 0 0 0 .698-.698v-4.19A22.15 22.15 0 0 0 39.1 11.378l.06-.05zM2.397 2.914c0-.278.227-.503.505-.502a.492.492 0 0 1 .465.315C3.816 3.89 5.3 6.602 9.381 6.602a8.685 8.685 0 0 0 7.389-4.19h.293c.682 0 1.384.023 2.095.069v.629a6.292 6.292 0 0 1-6.285 6.285H2.397v-6.48zm39.11 24.64H2.397V24.76h39.11v2.794zm-.01-4.19h-39.1V10.791h10.476a7.691 7.691 0 0 0 7.682-7.682v-.508c2.264.237 4.492.744 6.635 1.511l-2.283 2.74a.7.7 0 0 0 1.073.895l2.577-3.092a23.47 23.47 0 0 1 3.727 2.005l-2.488 2.986a.698.698 0 1 0 1.073.894l2.558-3.07a20.654 20.654 0 0 1 3.756 3.687l-3.107 2.59a.699.699 0 0 0 .894 1.073l3.042-2.535a20.62 20.62 0 0 1 3.485 11.077z"
        />
      </g>
    </svg>
  )
}
