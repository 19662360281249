import * as React from 'react'
import { isEqual } from 'lodash'
import useLocalCtx from '~/app/hooks/useLocalContext'
import { getStore } from './getStore'

const useMappedState = <M>(fnMapper: (state: any) => M): M => {
  const { getState, subscribe } = getStore()
  const [state, setState] = React.useState(() => fnMapper(getState()))
  const ctx = useLocalCtx({ state })
  React.useEffect(() => {
    const unsubscribe = subscribe(() => {
      const newState = fnMapper(getState())
      if (!isEqual(ctx.state, newState)) {
        setState(newState)
      }
    })
    return unsubscribe
  }, [])
  return state
}

export default useMappedState
