import * as React from 'react'
import PropTypes from 'prop-types'

const theme = {
  caution: '#F7D148',
  ok: '#49C5B1',
}

const Checkmark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="33" viewBox="0 0 42 33">
    <g fill="none" fillRule="evenodd">
      <path
        fill={theme[props.theme]}
        d="M22.018 28.415a5.06 5.06 0 0 1-7.356 0l-9.16-9.556c-2.003-2.088-2.003-5.462 0-7.55a5.06 5.06 0 0 1 7.356 0L18.315 17c.018.02.032.02.05.001L33.142 1.585a5.06 5.06 0 0 1 7.356 0A5.458 5.458 0 0 1 42 5.36c0 1.413-.539 2.77-1.502 3.776"
      />
      <path
        stroke="#302D1F"
        strokeWidth="1.5"
        d="M17.296 30.723a4.06 4.06 0 0 1-5.912 0l-9.16-9.556C.592 19.465.592 16.702 2.224 15a4.06 4.06 0 0 1 5.912 0l5.457 5.693c.412.429 1.081.429 1.494 0L29.864 5.277a4.06 4.06 0 0 1 5.912 0A4.459 4.459 0 0 1 37 8.36c0 1.157-.44 2.266-1.224 3.084l-18.48 19.279z"
      />
    </g>
  </svg>
)

Checkmark.propTypes = {
  theme: PropTypes.string,
}

export default Checkmark
