import { ProductDefault } from '~/app/store/productDefaults/types'
import { get, post, patch, put, del, getRefreshToken, logout } from './helpers'
import type {
  ApiFeatureFlags,
  ApiAbbreviatedState,
  ApiPastOrders,
  ApiUpcomingOrder,
  ApiUserAuditLogs,
  ApiOrderRefund,
  ApiOrderRefundSummary,
} from './types'
import {
  FormFactorType,
  ProductType,
  ShippingBoxType,
  ShippingBoxWithIdType,
  type PromoBanner,
} from '~/app/common/types'

const Api = {
  getRefreshToken,
  login: (data: object) => post('/sign-in', data),
  logout,
  refreshAccessToken: (data: object) => post('/refresh-token', data),

  // USER
  fetchUsers: ({ limit, offset, term, filter, searchFilter }: any) => {
    const onlyActive = !term ? 'true' : 'false'
    return get(
      '/admin/users?' +
        `limit=${limit}` +
        `&offset=${offset}` +
        `${term ? `&${searchFilter}=${term}` : ''}` +
        `${filter.zipCode ? `&zip=${filter.zipCode}` : ''}` +
        `${filter.region ? `&region=${filter.region}` : ''}` +
        `${filter.email ? `&email=${filter.email}` : ''}` +
        `${filter.active ? `&active=${filter.active}` : `&active=${onlyActive}`}`,
    )
  },

  fetchUserByEmail: (limit: number, offset: number, email: string) =>
    get(
      '/admin/users?' +
        `limit=${limit}` +
        `&offset=${offset}` +
        `${email ? `&email=${email}` : ''}`,
    ),
  fetchUserDetail: (id: number) => get(`/admin/users/${id}`),
  editUser: (id: number, data: object) => patch(`/admin/users/${id}`, data),
  patchKid: (userId: number, kidId: number, data: object) =>
    patch(`/users/${userId}/kids/${kidId}`, data),
  fetchUserAuditLogs: (userId: number, limit: number, offset: number) =>
    get(
      `/admin/user-audit-logs/${userId}?limit=${limit}&offset=${offset}`,
    ) as Promise<ApiUserAuditLogs>,

  // PRODUCTS
  fetchProducts: ({ type }: any) => {
    if (type) {
      return get(`/admin/products?orderBy=sequence&direction=desc&type=${type}`)
    }
    return get(`/admin/products?orderBy=sequence&direction=desc`)
  },
  fetchProductDetail: (id: number) => get(`/admin/products/${id}`),
  editProduct: (id: number, data: object) => patch(`/admin/products/${id}`, data),
  createProduct: (data: object) => post('/admin/products', data),
  imgUploadLink: (data: object) => post('/admin/products/actions/upload-url', data),
  uploadOrderDeliveryIssuePhoto: (data: object) =>
    post('/admin/delivery-issues/actions/upload-url', data),
  deleteProduct: (id: number) => del(`/admin/products/${id}`),
  fetchGiftProducts: (limit: number, offset: number) =>
    get(`/admin/free-gift-products?limit=${limit}&offset=${offset}`),
  sequenceProducts: (data: object) => post('/admin/products/actions/sequence-products', data),

  // SHIPPING
  fetchCarriers: () => get('/admin/carriers'),
  fetchCarrierServices: (carrier: string) =>
    post('/admin/carriers/actions/services', { code: carrier }),
  fetchWarehouses: () => get('/admin/warehouses'),

  // ORDERS
  fetchOrders: (userId: number): Promise<ApiPastOrders> =>
    get(
      `/users/${userId}/orders/past?excludeDeleted=false`,
      undefined,
      'v3',
    ) as Promise<ApiPastOrders>,
  fetchUpcomingOrders: (userId: number): Promise<ApiUpcomingOrder[]> =>
    get(`/users/${userId}/orders`, undefined, 'v3'),
  fetchOrderRefunds: (orderId: number): Promise<ApiOrderRefund[]> =>
    get(`/admin/orders/refund/${orderId}`, undefined, 'v1'),
  fetchOrderRefundSummary: (
    orderId: number,
    products: { id: number; count: number }[],
  ): Promise<ApiOrderRefundSummary> =>
    post(`/admin/orders/refund-summary/${orderId}`, { products }, 'v1'),
  refundOrder: (orderId: number, data: object) =>
    post(`/admin/orders/refund/${orderId}`, data, 'v1'),
  fetchOrderSummary: (userId: number, cart: object, type: ProductType) =>
    post(`/users/${userId}/order-summary`, { cart, type }),
  fetchOrdersSummaryByEditableTo: (userId: number, editableTo: string) =>
    get(`/users/${userId}/orders-summary/${editableTo}`),
  skipOrder: (userId: number, orderId: number, editableTo: string, skip: boolean) =>
    post(`/users/${userId}/orders/${orderId}/skip`, { skip, editableTo }),
  skipLinkedOrder: (userId: number, templateId: number, editableTo: string, skip: boolean) =>
    post(`/users/${userId}/orders/${templateId}/skip-linked`, {
      skip,
      editableTo,
    }),
  // Process order, regardless of the editable too
  forceUnskip: (templateId: number, editableTo: string) =>
    post(`/admin/orders/force-unskip/${templateId}/`, { editableTo }),
  fetchFaultyOrderReasons: () => get('/admin/delivery-issues'),
  setFaultyOrder: (
    orderId: number,
    issueIds: number[],
    resend: boolean,
    note: string,
    intercomLink: string,
    photosProvided: boolean,
    extraProductIds: number[],
    products: { sku: string; count: number }[],
    photos?: string[],
  ) =>
    post(`/admin/orders/report-issue/${orderId}`, {
      issueIds,
      resend,
      note,
      intercomLink,
      photosProvided,
      extraProductIds,
      products,
      photos,
    }),
  /**
   * POST /v1/users/:userId/orders/:orderId/cancel
   */
  cancelOrder: ({ userId, orderId }: { userId: number; orderId: number }) =>
    post(`/users/${userId}/orders/${orderId}/cancel`, {}, 'v1'),
  /**
   * POST /v2/users/:userId/orders/:orderId/change
   */
  editOrder: (userId: number, orderId: number, customizedOrder: object) =>
    post(`/users/${userId}/orders/${orderId}/change`, customizedOrder, 'v2'),
  /**
   * PUT /v3/users/:userId/orders/:orderId/change
   */
  editOrderV3: (userId: number, orderId: number, customizedOrder: object) =>
    put(`/users/${userId}/orders/${orderId}/change`, customizedOrder, 'v3'),
  shiftOrder: (userId: number, orderId: number, editableTo: string, shiftBy: number) => {
    // TODO: Remove check once issue with huge spike on malformed
    // shift request where userId, and orderId were undefined.
    // Note: We add this check to prevent the faulty request
    // to ever hit the server and instead just throw an error
    // https://puu.sh/AuunU/393b560980.png
    if (!userId || !orderId) {
      return Promise.reject('userId or orderId is not valid')
    }
    return post(`/users/${userId}/orders/${orderId}/shift`, {
      editableTo,
      shiftBy,
    })
  },
  shiftAllOrders: (userId: number, editableTo: string, shiftBy: number) => {
    return post(`/users/${userId}/orders/shift-all`, {
      editableTo,
      shiftBy,
    })
  },

  // PLANS
  fetchPlans: ({ groupName }: any) => {
    if (groupName !== undefined) {
      return get(`/plans-group/plans?groupName=${groupName}`)
    }
    return get('/plans-group/plans')
  },
  fetchAllPlans: () => {
    return get('/plans-group/plans')
  },
  // PRODUCT DEFAULTS
  fetchProductDefaults: () => {
    return get('/product-defaults')
  },
  updateProductDefaults: (data: ProductDefault[]) => {
    return post('/product-defaults', data)
  },
  // SUBSCRIPTIONS
  // @TODO: [LS-3138] remove FF check once `enable_removed_linked_templates` is fully rolled out
  fetchUserSubscriptions: (id: number, version: 'v2' | 'v3' = 'v2') => {
    if (version === 'v3') {
      return get(`/users/${id}/subscriptions`, undefined, version)
    }
    return get(`/users/${id}/kids/subscriptions`, undefined, version)
  },
  pauseUserSubscriptions: (id: number, data: object) =>
    patch(`/users/${id}/subscriptions/pause`, data),
  unpauseUserSubscriptions: (id: number, templateId: number) =>
    patch(`/users/${id}/subscriptions/pause`, { templateId, pause: false }),
  cancelUserSubscriptions: (id: number, data: object) =>
    patch(`/users/${id}/subscriptions/cancel`, data),
  reactivateUserSubscriptions: (id: number, templateId: number) =>
    patch(`/users/${id}/subscriptions/cancel`, { templateId, cancel: false }),
  postAddOnSubscription: (userId: number, templateId: number, data: object) =>
    post(`/users/${userId}/add-linked-subscription/${templateId}`, data),
  postponeFirstOrder: (templateId: number, data: object) =>
    post(`/admin/template/${templateId}/actions/postpone-first-order`, data),

  // INGREDIENTS
  fetchIngredients: (limit: number, offset: number) =>
    get(`/admin/ingredients?limit=${limit}&offset=${offset}`),
  fetchIngredientDetail: (id: number) => get(`/admin/ingredients/${id}`),
  editIngredient: (id: number, data: object) => patch(`/admin/ingredients/${id}`, data),
  createIngredient: (data: object) => post('/admin/ingredients', data),
  ingredientIconUploadLink: (data: object) => post('/admin/ingredients/actions/upload-url', data),
  deleteIngredient: (id: number) => del(`/admin/ingredients/${id}`),

  // LABELS
  fetchLabels: () => get('/labels'),
  editLabel: (id: number, data: object) => patch(`/admin/labels/${id}`, data),
  createLabel: (data: object) => post('/admin/labels', data),
  deleteLabel: (id: number) => del(`/admin/labels/${id}`),

  // PROMOCODES
  fetchPromocodes: (limit: number, offset: number, referral: string, codeString: string) =>
    get(
      '/admin/coupons?' +
        `limit=${limit}` +
        `&offset=${offset}` +
        `&referral=${referral}` +
        `${codeString ? `&codeString=${codeString}` : ''}`,
    ),

  createPromocode: (data: object) => post('/admin/coupons', data),
  deletePromocode: (id: number) => del(`/admin/coupons/${id}`),
  updatePromocode: (id: number, data: object) => patch(`/admin/coupons/${id}`, data),

  // BLUEPRINTS
  fetchBlueprints: (limit: number, offset: number) =>
    get(`/admin/blueprints?limit=${limit}&offset=${offset}`),
  fetchBlueprintDetail: (id: number) => get(`/admin/blueprints/${id}`),
  createBlueprint: (data: object) => post('/admin/blueprints', data),
  updateBlueprint: (id: number, data: object) => patch(`/admin/blueprints/${id}`, data),

  // PRODUCT REPLACEMENTS
  // HACK: Temporarily increase the limit of fetched product replacements to 400 to fix WEB-1929
  fetchProductsReplacements: (limit: number = 400) =>
    get(`/admin/products-replacements?limit=${limit}`),
  createProductReplacement: (data: object) => post('/admin/products-replacements', data),
  updateProductReplacement: (id: number, data: object) =>
    patch(`/admin/products-replacements/${id}`, data),
  deleteProductReplacement: (id: number) => del(`/admin/products-replacements/${id}`),

  // USER CREDITS
  fetchUserCredits: (id: number) => get(`/users/${id}/credits`),
  postUserCredits: (id: number, data: object) => post(`/users/${id}/credits`, data),
  fetchCreditsReasons: () => get('/admin/credits-reasons'),

  // USER COUPONS
  postUserCoupon: (data: object) => post('/admin/user-coupons', data),
  fetchUserCoupons: (userId: number) => get(`/admin/user-coupons/${userId}`),
  fetchUserProductCoupons: (userId: number) => get(`/admin/product-coupons/${userId}`),

  // CANCELLATION REASONS
  fetchCancellationReasons: (planType: string) =>
    get(`/cancellation-reasons?subscriptionType=${planType}`),
  suspendSubscription: ({ action, templateId, note, reasons }: any) =>
    post(`/subscriptions/${templateId}/suspend`, {
      action,
      reasons,
      note,
    }),

  fetchReportStatuses: () => get('/admin/report-statuses'),

  // PRODUCT SYMBOLS
  fetchSymbols: () => get('/admin/symbols'),

  // DASHBOARD
  fetchOrderTotals: ({
    viewType,
    fromDate,
    toDate,
    salesOrderType,
    totalType,
    chargeType = 'blends',
  }: any) =>
    get(
      `/admin/dashboard-order-totals?viewType=${viewType}&fromDate=${fromDate}&toDate=${toDate}&totalType=${totalType}&salesOrderType=${salesOrderType}&chargeType=${chargeType}`,
    ),

  // DIETARY TAGS
  fetchDietaryTags: () => get('/admin/dietary-tags'),
  editDietaryTag: (id: number, data: object) => patch(`/admin/dietary-tags/${id}`, data),
  createDietaryTag: (data: object) => post('/admin/dietary-tags', data),
  deleteDietaryTag: (id: number) => del(`/admin/dietary-tags/${id}`),

  // ALLERGENS
  fetchAllergens: () => get('/allergies'),

  // FORM FACTORS
  fetchFormFactors: () => get('/admin/form-factors'),
  editFormFactor: (id: number, data: FormFactorType) => patch(`/admin/form-factors/${id}`, data),
  createFormFactor: (data: FormFactorType) => post('/admin/form-factors', data),
  deleteFormFactor: (id: number) => del(`/admin/form-factors/${id}`),

  // MAGICLINK
  fetchMagicLink: (data: object) => post('/admin/magic-link', data),

  // DEVELOPER TOOLS
  forceCharge: (userId: number, data: { editableTo: string }) =>
    post(`/user/${userId}/charge-order`, data),
  forceCartonizeShip: (userId: number, data: { editableTo: string }) =>
    post(`/user/${userId}/force-cartonize-ship-orders`, data) as Promise<{
      shipmentsCount: number
    }>,
  forceShip: (userId: number, orderId: number) =>
    post(`/user/${userId}/order/${orderId}/ship-order`),
  markShipped: (userId: number, orderId: number) =>
    post(`/user/${userId}/order/${orderId}/marked-shipped`),
  createLabelsForShipments: (userId: number, data: { editableTo: string }) =>
    post(`/user/${userId}/create-labels-for-shipments`, data) as Promise<{ labelsCount: number }>,
  sendTrackingNotifications: (userId: number, data: { editableTo: string }) =>
    post(`/user/${userId}/send-tracking-notifications`, data),
  invalidateBackendCache: (data: object) => post(`/cache-invalidation/backend/?pattern=${data}`),
  invalidateFrontendCache: (data: object) => post('/cache-invalidation/frontend', data),

  // SHIPPING BOXES
  fetchShippingBoxes: () => get('/admin/shipping-boxes'),
  editShippingBox: (id: number, data: ShippingBoxWithIdType) =>
    patch(`/admin/shipping-boxes/${id}`, data),
  createShippingBox: (data: ShippingBoxType) => post('/admin/shipping-boxes', data),
  deleteShippingBox: (id: number) => del(`/admin/shipping-boxes/${id}`),

  // BOXES BY STATE
  fetchBoxesByState: () => get('/admin/boxes-by-state'),
  editBoxesFromState: (data: { state: ApiAbbreviatedState; boxId: number }) =>
    patch(`/admin/boxes-by-state/${data.boxId}`, data),

  // FEATURE FLAGS
  fetchUserFeatureFlags: (userId: number) =>
    get(`/users/${userId}/feature-flags`) as Promise<ApiFeatureFlags>,

  // USER LTV
  fetchUserLTV: (userId: number) =>
    get(`/admin/users/${userId}/ltv`) as Promise<{
      totalAmountChargedCents: number
      weeksWithCharges: number
    }>,

  // CARTONIZATION
  cartonizeOrder: (data: { isFirstTimeOrder: boolean; orderShape: Record<string, number> }) =>
    post(`/test-cartonization`, data),
  fetchPaccurateConfig: () => get(`/admin/cartonizer-version`),

  // PRICING GROUP
  fetchPricingGroup: (userId: number) => get(`/users/${userId}/pricing-group`),

  // PROMO BANNERS
  fetchPromoBanners: () => get('/admin/banners'),
  editPromoBanner: (id: number, data: PromoBanner) => patch(`/admin/banners/${id}`, data),
  createPromoBanner: (data: PromoBanner) => post('/admin/banners', data),

  // MANUAL ORDERS
  createManualOrder: (data: { products: { id: number; count: number }[] }, userId: number) =>
    post(`/admin/users/${userId}/actions/create-order`, data),
}

export default Api
