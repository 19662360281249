import styled from 'styled-components'
import Button from '~/app/components/Button/Button'
export const Wrap = styled.div`
  background: ${(props) => props.theme.color.dark};
  border-right: 1px solid #eee;
  display: flex;
  flex: 0 0 226px;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: 126px;
`
export const Bottom = styled.div`
  margin: auto 0 0 0;
  padding: 0 0 56px 0;
  text-align: center;
`
export const LogoutButton = styled(Button)`
  width: 100%;
`
