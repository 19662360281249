import { StyledSelect } from './styled'

interface BaseProps {
  emptyLabel?: string
  hasEmptyOption?: boolean
  value?: string | number
  defaultValue: string | number
  onChange: (val: string) => void
  small?: boolean
  disabled: boolean
}

interface PropsWithOptions extends BaseProps {
  options: { label: string; value: number | string }[]
}

interface PropsWithOptionGroups extends BaseProps {
  optionsGroups: { name: string; options: { label: string; value: number | string }[] }[]
}

type Props = PropsWithOptions | PropsWithOptionGroups

export default function Select({
  value,
  defaultValue,
  small = false,
  emptyLabel = ' -- Select an option -- ',
  hasEmptyOption = false,
  disabled,
  onChange,
  ...restProps
}: Props) {
  const isOptionsWithGroups = 'optionsGroups' in restProps

  const handleChange = (event: any) => {
    onChange(event.target.value)
  }

  return (
    <StyledSelect
      {...restProps}
      value={value != null ? value : defaultValue}
      // @ts-ignore
      background={value || ''}
      onChange={handleChange}
      // @ts-ignore
      small={small}
      disabled={disabled}
    >
      {hasEmptyOption && <option value="">{emptyLabel}</option>}
      {isOptionsWithGroups
        ? restProps.optionsGroups?.map((group) => (
            <optgroup label={group.name} key={group.name}>
              {group.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </optgroup>
          ))
        : restProps.options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
    </StyledSelect>
  )
}
