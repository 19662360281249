import { combineEpics } from 'redux-observable'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import AppEpics from '~/app/store/app/epics'
import ProductsEpics from '~/app/store/products/epics'
import IngredientsEpics from '~/app/store/ingredients/epics'
import UsersEpics from '~/app/store/users/epics'
import BlueprintEpics from '~/app/store/blueprints/epics'
import WarehousesEpics from '~/app/store/warehouses/epics'
import PromocodesEpics from '~/app/store/promocodes/epics'
import DeliveryInfoEpics from '~/app/pages/User/UserInfo/DeliveryInfo/epics'
import UserOrdersEpics from '~/app/pages/User/UserOrders/epics'
import SymbolsEpics from '~/app/store/symbols/epics'
import DashboardEpics from '~/app/store/dashboardOld/epics'

export default function setupEpics() {
  const epic$ = new BehaviorSubject(
    combineEpics(
      // App level epics that are always available
      ...AppEpics,
      ...ProductsEpics,
      ...IngredientsEpics,
      ...UsersEpics,
      ...DeliveryInfoEpics,
      ...UserOrdersEpics,
      ...BlueprintEpics,
      ...PromocodesEpics,
      ...WarehousesEpics,
      ...SymbolsEpics,
      ...DashboardEpics,
    ),
  )

  const rootEpic = (action$: any, store: any, dependencies: any) =>
    epic$.mergeMap((epic) => epic(action$, store, dependencies))

  return { epic$, rootEpic }
}
