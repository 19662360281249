import moment from 'moment-timezone'
import Button from '~/app/components/Button/Button'
import LightBox from '~/app/components/LightBox'
import { FieldSet } from '~/app/components/FieldSet'
import { CenteredRow } from '~/app/components/FormGrid'
import { Block } from '~/app/components/Block'
import useForm from '~/app/hooks/useForm'
import formDefinition from './formDefinition'

interface Props {
  isOpen: boolean
  handleConfirm: (date: string, reason?: string) => void
  closeHandler: () => void
}

export default function PostponeForm(props: Props) {
  const { form, clearForm, handleClick } = useController(props.closeHandler, props.handleConfirm)

  return (
    // @ts-ignore
    <LightBox
      isOpen={props.isOpen}
      closeHandler={clearForm}
      height={830}
      maxWidth="600px"
      width="90%"
      aria-label="Postpone order form"
    >
      {/* @ts-ignore */}
      <Block paddingBottom={0}>
        <FieldSet legend="Select Week to Postpone Orders To">
          <CenteredRow>
            {form.renderField('postponeDate', {
              value: form.formState.postponeDate.value,
            })}
          </CenteredRow>
        </FieldSet>
        <CenteredRow>
          <Button
            // @ts-ignore
            large
            postpone
            onClick={handleClick}
            disabled={form.formState.postponeDate.value === undefined}
          >
            Postpone Orders
          </Button>
        </CenteredRow>
      </Block>
    </LightBox>
  )
}

function useController(closeHandler: Props['closeHandler'], handleConfirm: Props['handleConfirm']) {
  const form = useForm({
    // @ts-ignore
    fieldDefinitions: formDefinition,
    formData: {},
  })

  function clearForm() {
    form.formState.postponeDate.value = undefined
    closeHandler()
  }

  function handleClick() {
    handleConfirm(moment(form.formState.postponeDate.value).day(-1).format('YYYY-MM-DD'))
    clearForm()
  }

  return {
    clearForm,
    form,
    handleClick,
  }
}
