import moment from 'moment-timezone'
export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const FE_DATE_FORMAT = 'MM/DD/YYYY'
export const FE_DATE_FORMAT_DETAIL = 'MM/DD/YYYY hh:mm:ss'
export const FE_DATE_FORMAT_MONTH = 'MM/YYYY'
const FE_DATE_FORMAT_QUARTER = 'Q/YYYY'
export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export function getDate(params = {}) {
  const { day, week, month, year, type } = params

  if (type === '4week') {
    return moment().isoWeekYear(year).isoWeek(week).startOf('isoWeek').format(FE_DATE_FORMAT)
  }

  if (type === 'month' || type === 'quarter') {
    return moment().isoWeekYear(year).month(month).format(FE_DATE_FORMAT_MONTH)
  }

  if (type === 'year') {
    return Number(moment().isoWeekYear(year).format('YYYY'))
  }

  return moment()
    .year(year)
    .month(month || '')
    .isoWeek(week || '')
    .isoWeekday(day || '')
    .format(FE_DATE_FORMAT)
}
export function getStartOfWeek(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).startOf('isoWeek').format(API_DATE_FORMAT)
  }

  return moment().startOf('isoWeek').format(API_DATE_FORMAT)
}
export function getEndOfWeek(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).endOf('isoWeek').format(API_DATE_FORMAT)
  }

  return moment().endOf('isoWeek').format(API_DATE_FORMAT)
}
export function getStartOfFourWeek(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT)
      .subtract(3, 'weeks')
      .startOf('isoWeek')
      .format(API_DATE_FORMAT)
  }

  return moment().subtract(3, 'weeks').startOf('isoWeek').format(API_DATE_FORMAT)
}
export function getEndOfFourWeek(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).endOf('isoWeek').format(API_DATE_FORMAT)
  }

  return moment().endOf('isoWeek').format(API_DATE_FORMAT)
}
export function getStartOfMonth(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).startOf('month').format(API_DATE_FORMAT)
  }

  return moment().startOf('month').format(API_DATE_FORMAT)
}
export function getEndOfMonth(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).endOf('month').format(API_DATE_FORMAT)
  }

  return moment().endOf('month').format(API_DATE_FORMAT)
}
export function getStartOfQuarter(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).startOf('quarter').format(API_DATE_FORMAT)
  }

  return moment().startOf('quarter').format(API_DATE_FORMAT)
}
export function getEndOfQuarter(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).endOf('quarter').format(API_DATE_FORMAT)
  }

  return moment().endOf('quarter').format(API_DATE_FORMAT)
}
export function getStartOfYear(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).startOf('year').format(API_DATE_FORMAT)
  }

  return moment().startOf('year').format(API_DATE_FORMAT)
}
export function getEndOfYear(date) {
  if (date) {
    return moment(date, API_DATE_FORMAT).endOf('year').format(API_DATE_FORMAT)
  }

  return moment().endOf('year').format(API_DATE_FORMAT)
}
export function convertToApiFormat(date, type) {
  if (type === 'quarter') {
    return moment(date, FE_DATE_FORMAT_QUARTER).endOf('quarter').format(API_DATE_FORMAT)
  }

  return moment(date, FE_DATE_FORMAT_MONTH).endOf('month').format(API_DATE_FORMAT)
}
