import PropTypes from 'prop-types'
import { pure } from 'recompose'
import EditIcon from '~/app/components/Icons/EditIcon'
import { Wrapper, Option, LinkButton } from './styled'

// TODO: Move this into a new folder
function Options({ kid, handleEdit, subscription }) {
  const handleEditClick = () => {
    handleEdit(kid, subscription)
  }

  return (
    <Wrapper>
      {kid.subscriptions && (
        <>
          <Option>
            <EditIcon />
            <LinkButton onClick={handleEditClick}>Edit </LinkButton>
          </Option>
        </>
      )}
    </Wrapper>
  )
}

Options.propTypes = {
  handleEdit: PropTypes.func,
  kid: PropTypes.object,
  subscription: PropTypes.any,
}
export default pure(Options)
