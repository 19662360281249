import styled from 'styled-components'
import EditIcon from '~/app/assets/svg/EditIconFull'

export const SpacerRow = styled.tr`
  height: 38px;
`

export const SpacerCol = styled.td`
  width: 21px;
`

export const EditBtn = styled(EditIcon)`
  cursor: pointer;
  margin-left: 8px;
`

export const Field = styled.div`
  width: 90%;
`
