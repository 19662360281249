export default function SearchIcon() {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27">
      <title>Search</title>
      <g
        transform="translate(1 1)"
        stroke="#505B6D"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
      >
        <circle cx="10.5" cy="10.5" r="10.5" />
        <path d="M17.5 18.5l6 6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
