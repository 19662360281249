import PlanIcon from '~/app/components/Icons/PlanIcon'
import Boosters from './svgs/Boosters'
import useIsPCHEnabled from '~/app/hooks/useIsPCHEnabled'
import LogoImage from '~/app/assets/img/logo.png'
import { AddOnType, ProductType } from '~/app/common/types'

interface Props {
  type: ProductType
  size: 'small' | 'medium' | 'big'
}

// TODO: remove when PCH reaches GA https://littlespoon.atlassian.net/browse/LS-3770
const enablePCH = useIsPCHEnabled()

export default function Icon({ type, size }: Props) {
  if (enablePCH) {
    return <img src={LogoImage} alt="Little Spoon Logo" />
  }

  return type === ProductType.booster ? (
    <Boosters />
  ) : (
    <PlanIcon
      type={type as Exclude<ProductType, AddOnType> | ProductType.smoothie}
      size={size}
      custom
    />
  )
}
