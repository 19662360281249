const Puree = () => (
  <svg width="27" height="37" viewBox="0 0 50 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Puree</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M7 11.503h41.314c0-2.797-.397-4.632-1.19-5.503-.794-.871-2.193-1.283-4.198-1.236L38.794 6l-3.646-2h-3.292c-.366.667-.834 1-1.404 1s-2.237-.079-5-.236C24.824 3.268 24.007 2.191 23 1.532c-1.007-.659-2.511-.659-4.514 0-2.003.047-3.542.537-4.618 1.468-1.077.931-1.7 1.931-1.868 3-1.752-.152-3.184.384-4.296 1.609C6.59 8.833 6.357 10.13 7 11.503z"
        fill="#FFB3AB"
      />
      <path
        d="M43.884 22a.681.681 0 0 1-.687-.764l1.376-10.412a4.739 4.739 0 0 0-.987-3.58A4.939 4.939 0 0 0 40.3 5.397a4.982 4.982 0 0 0-4.121 1.366.703.703 0 0 1-.98-.004.667.667 0 0 1 .005-.959 6.376 6.376 0 0 1 5.273-1.747 6.323 6.323 0 0 1 4.204 2.363 6.064 6.064 0 0 1 1.264 4.582L44.57 21.409a.688.688 0 0 1-.686.591M3.246 14.183a.764.764 0 0 1-.099-.005c-.417-.052-.711-.415-.657-.811l.388-3.589c.198-1.451.979-2.744 2.198-3.637a5.947 5.947 0 0 1 4.26-1.095c.417.052.711.415.658.811-.054.397-.441.675-.854.626a4.368 4.368 0 0 0-3.134.805c-.898.657-1.472 1.608-1.617 2.676L4 13.552c-.05.365-.377.631-.754.631 2.54 1.168 3.082 1.28 1.627.34"
        fill="#302D1F"
      />
      <path
        d="M9.123 9.383a8.138 8.138 0 0 1-.058-2.443c.553-4.354 4.469-7.435 8.733-6.874 2.734.362 4.986 1.898 6.098 4.479a.758.758 0 0 1-.378.99.728.728 0 0 1-.969-.386c-.901-2.094-2.722-3.3-4.94-3.593-3.458-.457-6.638 2.044-7.086 5.577a6.604 6.604 0 0 0 .048 1.982.75.75 0 0 1-.725.885.74.74 0 0 1-.723-.617z"
        fill="#302D1F"
      />
      <path
        d="M29.25 10c-.288 0-.562-.194-.687-.52-.772-2.015-2.348-3.386-4.215-3.67-1.495-.227-2.957.269-4.118 1.396-.319.31-.793.258-1.057-.117-.265-.374-.22-.928.1-1.238 1.462-1.42 3.381-2.07 5.268-1.785 2.389.363 4.405 2.117 5.393 4.694.17.443 0 .964-.378 1.163a.651.651 0 0 1-.307.077"
        fill="#302D1F"
      />
      <path
        d="M36.26 9c-.357 0-.67-.237-.728-.57-.367-2.102-2.235-3.761-4.543-4.035a5.627 5.627 0 0 0-3.811.903.785.785 0 0 1-1.034-.144c-.242-.3-.172-.724.157-.946 1.41-.951 3.138-1.362 4.88-1.156 2.951.35 5.341 2.474 5.81 5.164.064.37-.21.716-.614.775A.76.76 0 0 1 36.26 9"
        fill="#302D1F"
      />
      <g>
        <path
          d="M3.166 11h45.668a1 1 0 0 1 .989 1.152l-3.301 21.456A4 4 0 0 1 42.568 37H9.432a4 4 0 0 1-3.954-3.392l-3.3-21.456A1 1 0 0 1 3.165 11z"
          fill="#DECDE7"
        />
        <path
          d="M1.166 11.75a.25.25 0 0 0-.247.288l3.3 21.456a3.25 3.25 0 0 0 3.213 2.756h33.136a3.25 3.25 0 0 0 3.213-2.756l3.3-21.456a.25.25 0 0 0-.247-.288H1.166z"
          stroke="#302D1F"
          strokeWidth="1.5"
        />
        <path d="M2.458 16.39h42.856" stroke="#302D1F" strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <g>
        <path
          d="M22.073 21.036c-.495.685-.355 1.45.38 2.073"
          stroke="#302D1F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.273 23.036l-3.205 1.347v3.445l3.205 2.6h4.179l-.856-3.063c.398-1.988.398-2.982 0-2.982-.397 0-1.277-.45-2.638-1.347h-.685z"
          fill="#FFD241"
        />
        <path
          d="M22.228 23.68c.389-.352 1.814-1.4 3.706.298.426.448 1.17 1.264.997 3.04a4.676 4.676 0 0 0 .243 2c.053.125.104.24.152.347.07.162.113.336.124.514.039.594-.392 1.327-1.47 1.434-.688.047-1.2 0-2.35-.484a6.913 6.913 0 0 1-1.549-.89 6.44 6.44 0 0 1-1.706-2.218 3.14 3.14 0 0 1-.282-1.746c.099-.748.445-1.623 1.87-2.143a.788.788 0 0 0 .265-.151z"
          stroke="#302D1F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
)

export default Puree
