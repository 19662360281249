import styled, { css } from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  height: 50px;

  ${(props) =>
    props.small &&
    css`
      height: 44px;
    `};
`
export const StyledInput = styled.input`
  border: solid 1px ${(props) => props.theme.color.lightGray};
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  padding: 0 18px;
  text-align: center;
  width: 150px;

  ${(props) =>
    props.small &&
    css`
      font-size: 14px;
    `};
`
export const Button = styled.button`
  border: solid 1px ${(props) => props.theme.color.lightGray};
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  height: 100%;
  padding: 0 18px;

  &:active {
    background-color: ${(props) => props.theme.color.green};
    color: ${(props) => props.theme.color.white};
  }
`
export const PlusButton = Button.extend`
  border-radius: 0 4px 4px 0;
  font-size: 22px;
  position: relative;
  right: 1px;
`
export const MinusButton = Button.extend`
  border-radius: 4px 0 0 4px;
  font-size: 30px;
  left: 1px;
  position: relative;
`
