import { apiUrlRoot, accessTokenKey, refreshTokenKey } from './settings'

function getAccessToken() {
  return localStorage.getItem(accessTokenKey) || ''
}

export function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey) || ''
}

export function setAccessToken(token: string) {
  localStorage.setItem(accessTokenKey, token)
}

export function setRefreshToken(token: string) {
  localStorage.setItem(refreshTokenKey, token)
}

export function getNewAccessToken() {
  return post('/refresh-token', { refreshToken: getRefreshToken() })
}

export function logout() {
  localStorage.removeItem(refreshTokenKey)
  localStorage.removeItem(accessTokenKey)
}

/**
 * Sends a request.
 *
 * @param url - The relative URL.
 * @param options - The fetch options.
 * @param version - The API version.
 *
 * @returns The request object
 */
// @ts-ignore
async function request(url: string, options: object, version: string) {
  const headers = new Headers({ 'Content-Type': 'application/json' })
  const accessToken = getAccessToken()

  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`)
  }

  const response = await fetch(`${apiUrlRoot}/${version}${encodeURI(url)}`, {
    headers,
    ...options,
  })

  switch (response.status) {
    case 204: // 204 No Content
      // avoid parsing non-JSON responses with 'OK' or empty text
      return {}
    case 440: // 440 Login Time-out
      await getNewAccessToken()
      return request(url, options, version)
    default:
      break
  }

  const responseAccessToken = response.headers.get('x-access-token') || ''
  if (responseAccessToken) {
    setAccessToken(responseAccessToken)
  }

  const refreshToken = response.headers.get('x-refresh-token')
  if (refreshToken) {
    setRefreshToken(refreshToken)
  }

  const text = await response.text()
  let data

  try {
    data = JSON.parse(text)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    data = text
  }

  if (!response.ok) {
    throw new Error(
      typeof data === 'string'
        ? `${response.status} ${response.statusText}: ${data}`
        : data.message,
    )
  }

  return data
}

export function get(url: string, body?: any, version = 'v1') {
  return request(url, { body: JSON.stringify(body), method: 'GET' }, version)
}

export function post(url: string, body?: any, version = 'v1') {
  return request(url, { body: JSON.stringify(body), method: 'POST' }, version)
}

export function patch(url: string, body?: any, version = 'v1') {
  return request(url, { body: JSON.stringify(body), method: 'PATCH' }, version)
}

export function put(url: string, body?: any, version = 'v1') {
  return request(url, { body: JSON.stringify(body), method: 'PUT' }, version)
}

export function del(url: string, version = 'v1') {
  return request(url, { method: 'DELETE' }, version)
}
