export default function BoxWithSparklesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="94" height="90">
      <title>Box with sparkles</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#706B8E"
          fillRule="nonzero"
          d="M32.361 45.774l17.41 8.615 12.9-6.249-18.54-8.615z"
        />
        <path
          fill="#A09DB4"
          fillRule="nonzero"
          d="M44.132 41.783l1.129-4.82-1.13-4.822-21.002 8.812 6.109 7.08z"
        />
        <path fill="#8682A0" fillRule="nonzero" d="M59.283 48.14l11.49-4.82-26.64-11.18v9.642z" />
        <path fill="#41BAAC" fillRule="nonzero" d="M23.13 41.857v16.55l26.64 11.178V52.131z" />
        <path fill="#21DCA9" fillRule="nonzero" d="M49.771 52.131v17.454l21.003-8.812V43.32z" />
        <path fill="#B4EFDF" d="M23.662 40.786l25.819 11.485 20.84-8.836-26.547-10.849z" />
        <path
          fill="#4E5563"
          fillRule="nonzero"
          d="M42.95 31.642l-20.258 8.27a1.592 1.592 0 0 0-.174.093l-.134.1c-.125.13-.145.155-.164.18a1.589 1.589 0 0 0-.14.252 1.867 1.867 0 0 0-.067.254c-.007.05-.011 6.147-.013 18.29 0 .455.273.865.692 1.041l26.635 11.175a1.115 1.115 0 0 0 .645.072c.098-.018.172-.041.243-.072l20.995-8.809a1.13 1.13 0 0 0 .693-1.04V43.322s.043-.364-.693-.715c-2.909-1.387-12.073-5.004-26.873-11.113-.24-.099-.702-.05-1.386.147zm16.333 15.274l-2.918 1.224-6.594 2.767L34.62 44.55l9.718-10.958 23.518 9.728-8.572 3.596zM34.62 44.55l-8.572-3.597 18.29-7.36L34.62 44.55zm35.026-2.929l-24.436-9.98 24.436 9.98zm-45.387 1.03l24.384 10.231v15.679L24.258 58.33V42.651zm45.387 18.045L50.9 68.561V52.882l18.745-7.864v15.678z"
        />
        <circle cx="91" cy="48" r="2.5" stroke="#CEDAF7" />
        <circle cx="31" cy="3" r="2.5" stroke="#CEDAF7" />
        <circle cx="3" cy="34" r="2.5" stroke="#CEDAF7" />
        <path
          stroke="#CEDAF7"
          strokeLinecap="round"
          strokeWidth="2"
          d="M79 9.5h5M81.5 12V7M4 15.5h5M6.5 18v5"
        />
        <ellipse cx="47" cy="88" fill="#CEDBF7" opacity=".438" rx="15.5" ry="2" />
        <path
          fill="#4E5563"
          d="M53.256 36.764L33.9 44.574l4.527 1.925 19.017-8.059zM33 46v6.089a1 1 0 0 0 .611.921l1.731.731a1 1 0 0 0 1.39-.921v-5.14L33 46z"
        />
        <path stroke="#4E5563" strokeWidth="2" d="M61.187 65.262V48.106L35 36" />
      </g>
    </svg>
  )
}
