import styled, { css } from 'styled-components'

const getBackgroundColor = (value: any) => {
  switch (value.bgColor) {
    case 'lightRed':
      return value.theme.color.lightRed
    case 'midRed':
      return value.theme.color.midRed
    case 'dashDarkRed':
      return value.theme.color.dashDarkRed
    case 'paleGreen':
      return value.theme.color.paleGreen
    case 'lightGray':
      return value.theme.color.lightGray
    case 'paleGreenDash':
      return value.theme.color.paleGreenDash
    case 'palerGreenDash':
      return value.theme.color.palerGreenDash
    case 'tint':
      return value.theme.color.tint
    case 'gray':
      return value.theme.color.gray
    case 'transparent':
      return 'transparent'
    case 'black':
      return 'black'
    default:
      return value.theme.color.white
  }
}

const getBorderColor = (props: any) => {
  if (props.danger) {
    return props.theme.color.errorRed
  }
  if (props.informative) {
    return props.theme.color.blue
  }
  return props.theme.color.lightGray
}

const panelRules = `
  th, td {
    &:first-child {
      padding-left: 30px;
    }
  }

  .cell {
    line-height: 19px;
    min-width: 120px;
    padding: 22px 26px 22px 0;
  }
`

const paginationRules = `
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    margin-top: 0;
  }

  .page, .break {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    height: 24px;
    justify-content: center;
    margin: 0 3px;
    width: 24px;
  }

  .page, .previous, .next {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .05);
    font-size: 12px;
    font-weight: 700;
    margin: 0 3px;

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 24px;
      justify-content: center;
      width: 24px;
    }

    &.selected {
      background-color: #4f5b6d;
      color: #fff;
    }
  }

  .previous {
    margin-right: 10px;
  }

  .next {
    margin-left: 10px;
  }
`

export const Header = styled.th`
  color: #4f5b6d;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  text-align: ${(props: any) => props.align};
  ${(props: any) => css`
    ${props.large &&
    `
      font-size: 20px;
    `};
    ${props.medium &&
    `
      font-size: 16px;
    `};
  `};
`

export const HeaderFlex = styled.th`
  color: #4f5b6d;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  width: ${(props: any) => props.percent}%;
`

// TODO: Refactor in https://littlespoon.atlassian.net/browse/LS-3590
// @ts-ignore
export const Cell = styled.td.attrs({
  className: 'cell',
})`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props: any) => css`
    &.greyed {
      color: ${props.theme.color.gray};
    }
    text-align: ${props.align};
    p {
      margin: 0px 0px 0px 20px;
      ${props.bold &&
      `
        font-weight: 800;
      `};
    }
    ${props.differenceCell &&
    `
        background: ${props.theme.color.paleYellow};
    `};
    ${props.showOverflow &&
    `
      overflow: visible;
    `};
    ${props.bgColor &&
    `
        background: ${getBackgroundColor(props)};
    `};
    ${props.verticalAlign &&
    `
        vertical-align: ${props.verticalAlign};
    `};
  `};
`

export const OptionCell = styled.td`
  > div {
    display: inline-block;
    width: 22px !important;
    margin: 0 2px;
  }
`

// @ts-ignore
export const LargeCell = Cell.extend`
  font-size: 16px;
  line-height: 22px;
`

const Row = styled.tr`
  background: ${(props) => getBackgroundColor(props)};
  transition: box-shadow 0.25s ease;
`

// @ts-ignore
export const ListRow = Row.extend`
  border-radius: 4px;

  box-shadow: 0;
  cursor: pointer;

  td.last {
    padding-right: 50px;
    min-width: 130px;
  }

  &:hover {
    box-shadow: 0 1px 0 ${(props: any) => props.theme.color.green};
  }

  ${(props: any) =>
    props.large &&
    `
    td {
      height: 110px;
    }
  `} ${(props: any) =>
    props.transparent &&
    `
    opacity: .7;
  `};
`

// @ts-ignore
export const BlockRow = Row.extend`
  box-shadow: inset 0 0 0 1px ${(props: any) => getBorderColor(props)};
  border-radius: 4px;
  position: relative;
  ${(props: any) =>
    props.skipped &&
    `
    opacity: 0.8;
  `} ${(props: any) =>
    props.borderless &&
    css`
      box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
      transition: none;
    `};
`

// @ts-ignore
export const InfoRow = Row.extend`
  td {
    padding: 3px 0 30px 0;
  }
  ${(props: any) =>
    props.centered &&
    `
      td {
        padding: 10px 0 10px 0;
        margin: auto;
      }
  `};
  ${(props: any) =>
    props.bold &&
    `
   font-weight: 1000;
  `};

  ${(props: any) =>
    props.border &&
    `
      border: 2px solid black;
   `};

  ${(props: any) =>
    props.large &&
    `
    th {
      height: 50px;
    }
  `};
  ${(props: any) =>
    props.color &&
    `
   color: ${props.color};
  `};
`
const Table = styled.table`
  border-collapse: separate;
`

// @ts-ignore
export const ListTable = Table.extend`
  ${paginationRules} border-spacing: 0 5px;
  padding-right: 25px;

  th,
  td {
    padding: 22px 26px 22px 0;

    &:first-child {
      padding-left: 26px;
    }
  }

  td {
    min-width: 80px;
  }
`

// @ts-ignore
export const BlockTable = Table.extend`
  ${panelRules} border-spacing: 0 20px;
`

// @ts-ignore
export const RowTable = Table.extend`
  ${panelRules} border-spacing: 0 5px;

  th {
    padding: 16px 0;
  }
`

// @ts-ignore
export const InfoTable = Table.extend`
  padding-bottom: 48px;

  tbody {
    box-shadow: 0 0 0 1px ${(props: any) => props.theme.color.lightGray};
  }

  tbody:first-child {
    border-radius: 4px 4px 0 0;
  }

  tbody + tbody {
    border-radius: 0;
    clip-path: inset(0 -1px -1px -1px);
  }

  tbody:last-child {
    border-radius: 0 0 4px 4px;
  }

  tbody:only-child {
    border-radius: 4px;
  }
`

// @ts-ignore
export const DashboardTable = Table.extend`
  border-collapse: collapse;
`

export const GroupHeader = styled.th`
  text-align: center;
  padding: 20px 0px 20px 0px;
  margin: auto;
`

export const HeaderMultiLine = styled.th`
  color: #4f5b6d;
  font-size: 12px;
  font-weight: 700;
  ${(props: any) =>
    props.days &&
    `
  width: 10%;
  `} padding: 15px 15px;
  letter-spacing: 0.5px;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;
  text-align: ${(props: any) => props.align};
  ${(props: any) =>
    props.large &&
    `
      font-size: 20px;
  `};
`
