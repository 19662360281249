import type { ApiUserAuditLogs } from '~/app/common/api/types'
import { UserLogsStatus, type RootState, type UserLogsState } from './types'
import initialState from './initialState'

export function setUserLogsLoading(state: RootState) {
  return () =>
    ({
      ...state,
      logs: {
        ...state.logs,
        status: UserLogsStatus.loading,
      },
    }) as RootState
}
export function setUserLogsLoaded(state: RootState) {
  return ({ data, page }: { data: ApiUserAuditLogs; page: number }) =>
    ({
      ...state,
      logs: {
        ...state.logs,
        data: structuredClone(data),
        page,
        status: UserLogsStatus.loaded,
      } as UserLogsState,
    }) as RootState
}

export function setUserLogsError(state: RootState) {
  return () =>
    ({
      ...state,
      logs: {
        ...state.logs,
        data: [],
        status: UserLogsStatus.error,
      },
    }) as RootState
}

export function setUserLogsInitialState(state: RootState) {
  return () =>
    ({
      ...state,
      logs: initialState,
    }) as RootState
}
