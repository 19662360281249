import { compose } from 'recompose'
import withHandlers from '~/app/hocs/withHandlers'
export default compose(
  withHandlers({
    handleConfirm:
      ({ serialize, onConfirm }) =>
      () => {
        serialize((formState) => {
          onConfirm({
            billing: {
              address: formState.billingAddress,
              apt: formState.billingApt,
              city: formState.billingCity,
              firstName: formState.billingFirstName,
              lastName: formState.billingLastName,
              state: formState.billingState,
              zip: formState.billingZip,
            },
          })
        })
      },
  }),
)
