export default function EditIcon({ title = 'Edit' }: { title?: string }) {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <path
        d="M21.146 2.513l-1.66-1.66c-1.137-1.137-2.974-1.137-4.11 0L2.2 14.052c-.32.323-.524.73-.582 1.167L.044 20.525c-.116.407 0 .844.292 1.165.233.234.525.35.816.35.116 0 .234-.03.32-.058L6.78 20.41c.407-.06.816-.263 1.165-.584L21.146 6.652c1.136-1.136 1.136-3.003 0-4.14zM3.22 17.99l.817.816-1.136.35.32-1.167zm3.295 0L4.037 15.51 14.442 5.107l2.478 2.477L6.515 17.99zm12.998-13l-.932.933-2.476-2.477.932-.933c.233-.233.583-.233.816 0l1.66 1.66c.147.147.177.322.177.41 0 .087-.03.262-.177.408z"
        fill="#505B6D"
        fillRule="evenodd"
      />
    </svg>
  )
}
