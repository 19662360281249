interface Props {
  title: string
}

export default function GrayBox({ title = 'Gray box' }: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill="#939AA6" fillRule="evenodd">
        <path d="M6.81 0L0 4.93l3.25 2.343L10 2.34M13.19 0L20 4.93l-3.25 2.343L10 2.34M13.19 14.545L20 9.615l-3.25-2.342-6.75 4.93M6.81 14.545L0 9.615l3.25-2.342 6.75 4.93" />
        <path d="M14.42 15.528l-.743.516-.68-.516-2.542-1.663-2.542 1.663-.68.516-.745-.516-2.852-1.892v1.777L10.456 20l6.817-4.587v-1.777" />
      </g>
    </svg>
  )
}
