export default function ShiftIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#585D61" viewBox="0 0 16 16">
      <title>Shift</title>
      <g
        fill="none"
        stroke="#585D61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      >
        <path d="M13.5 6.5v3h-3m3 0l-5-5M2.5 9.5v-3h3m-3 0l5 5" />
      </g>
    </svg>
  )
}
