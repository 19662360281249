export default function Spinner() {
  return (
    <svg
      width="17"
      height="17"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className="lds-rolling"
    >
      <title>Spinner</title>
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#939aa6"
        strokeWidth="10"
        r="36"
        strokeDasharray="169.64600329384882 58.548667764616276"
        transform="rotate(53.333 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="0.9s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  )
}
