import { makeModel } from '~/app/utils/storeManager'
import reducers from './reducers'
import effects from './effects'
import * as selectors from './selectors'

const allergens$ = makeModel({
  effects,
  prefix: 'allergens',
  reducers,
  selectors,
  state: {
    allergens: [],
    status: 'pending',
  },
})

export default allergens$
