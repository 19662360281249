import styled from 'styled-components'

export const Wrapper = styled.span`
  display: inline-block;
  margin: 0 8px;
`

export const TooltipWrapper = styled.div`
  white-space: nowrap;
`

export const TooltipHeader = styled.div`
  margin-bottom: 8px;
`

export const FlagListItem = styled.div`
  text-transform: lowercase;
`
