import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/merge'
import moment from 'moment'
import { showNotification } from '~/app/store/notifications/actions'
import {
  fetchProductsSuccess,
  fetchProductsFail,
  createProductSuccess,
  fetchProductDetailSuccess,
  editProductSuccess,
  deleteProductSuccess,
  createProductReplacementSuccess,
  fetchProductsReplacementsSuccess,
  updateProductReplacementSuccess,
  deleteProductReplacementSuccess,
  fetchGiftProductsSuccess,
  setProductSequenceSuccess,
  fetchAllProductsSuccess,
  fetchProducts,
} from './actions'
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT_DETAIL,
  EDIT_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCTS_REPLACEMENTS,
  SET_PRODUCT_REPLACEMENTS,
  FETCH_GIFT_PRODUCTS,
  SET_PRODUCT_SEQUENCE,
  FETCH_ALL_PRODUCTS,
} from './constants'

const fetchProductsEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(FETCH_PRODUCTS).switchMap((action: any) =>
    Observable.fromPromise(
      Api.fetchProducts({
        type: action.payload.type,
      }),
    )
      .map((result) => fetchProductsSuccess(result))
      .catch((e) => Observable.of(fetchProductsFail(), showNotification(e))),
  )

const fetchProductDetailEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(FETCH_PRODUCT_DETAIL).switchMap((action: any) =>
    Observable.fromPromise(Api.fetchProductDetail(action.payload.id))
      .map((result) => fetchProductDetailSuccess(result))
      .catch((e) => Observable.of(showNotification(e.message))),
  )

const editProductEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(EDIT_PRODUCT).switchMap((action: any) =>
    Observable.fromPromise(Api.editProduct(action.payload.id, action.payload.data))
      .switchMap((result) => Observable.of(editProductSuccess(action.payload.id, result)))
      .catch((e) => Observable.of(showNotification(e))),
  )

const createProductEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(CREATE_PRODUCT).switchMap((action: any) =>
    Observable.fromPromise(Api.createProduct(action.payload.data))
      .switchMap((result) =>
        Observable.of(
          createProductSuccess(result),
          showNotification('Product created'),
          fetchProducts({
            // @ts-ignore
            type: result.type,
          }),
        ),
      )
      .catch((e) => Observable.of(showNotification(e))),
  )

const deleteProductEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(DELETE_PRODUCT).switchMap((action: any) =>
    Observable.fromPromise(Api.deleteProduct(action.payload.id))
      .switchMap(() => Observable.of(deleteProductSuccess(), showNotification('Product deleted')))
      .catch((e) => Observable.of(showNotification(e))),
  )

const fetchProductReplacementsEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(FETCH_PRODUCTS_REPLACEMENTS).switchMap(() =>
    Observable.fromPromise(Api.fetchProductsReplacements())
      // @ts-ignore
      .switchMap((res) => Observable.of(fetchProductsReplacementsSuccess(res.data)))
      .catch((e) => Observable.of(showNotification(e))),
  )

const setProductReplacementEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(SET_PRODUCT_REPLACEMENTS).switchMap((action: any) => {
    const { regionsData, productId } = action.payload.data
    const regionsList = Object.keys(regionsData)
    return Observable.merge(
      // @ts-ignore
      ...regionsList
        .map((region) => {
          const {
            replacementId, // Id of the replacement record
            inStock,
            warehouseId,
            replacementProductId, // Id the product to replace the out of stock one
            availabilityDate,
          } = regionsData[region]
          if (inStock) {
            // return Observable.fromPromise(Promise.resolve({ type: 'Delete or NOOP' }))
            // Send a DELETE replacement
            if (replacementId) {
              return Observable.fromPromise(Api.deleteProductReplacement(replacementId))
                .switchMap(() =>
                  Observable.of(
                    deleteProductReplacementSuccess(replacementId),
                    showNotification('Product replacement deleted'),
                  ),
                )
                .catch((e) => Observable.of(showNotification(e)))
            }
            return null // NO-OOP
          } else if (replacementId) {
            // Send and update
            // return Observable.fromPromise(Promise.resolve({ type: 'Replacement update' }))
            const serverFriendlyData = {
              availability: moment(availabilityDate).format('YYYY-MM-DD'),
              replacementProductId,
            }
            return Observable.fromPromise(
              Api.updateProductReplacement(replacementId, serverFriendlyData),
            )
              .switchMap((result) =>
                Observable.of(
                  updateProductReplacementSuccess(result),
                  showNotification('Product replacement updated'),
                ),
              )
              .catch((e) => Observable.of(showNotification(e)))
          }
          // return Observable.fromPromise(Promise.resolve({ type: 'all good' }))
          // Send a create replacement
          const serverFriendlyData = {
            availability: moment(availabilityDate).format('YYYY-MM-DD'),
            productId,
            replacementProductId,
            warehouseId,
          }
          return Observable.fromPromise(Api.createProductReplacement(serverFriendlyData))
            .switchMap((result) => Observable.of(createProductReplacementSuccess(result)))
            .catch((e) => Observable.of(showNotification(e)))
        })
        .filter((observable) => observable), // Removes nulls
    )
  })

const fetchGiftProductsEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(FETCH_GIFT_PRODUCTS).switchMap((action: any) =>
    Observable.fromPromise(Api.fetchGiftProducts(action.payload.limit, action.payload.offset))
      .map((result) => fetchGiftProductsSuccess(result))
      .catch((e) => Observable.of(showNotification(e.message))),
  )

const setProductSequenceEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(SET_PRODUCT_SEQUENCE).switchMap((action: any) =>
    Observable.fromPromise(Api.sequenceProducts(action.payload.data))
      // @ts-ignore
      .map((result) => setProductSequenceSuccess(result))
      .catch((e) => Observable.of(showNotification(e.message))),
  )

const fetchAllProductsEpic = (action$: any, store: any, { Api }: any) =>
  action$.ofType(FETCH_ALL_PRODUCTS).switchMap((action: any) =>
    Observable.fromPromise(
      Api.fetchProducts({
        type: action.payload.type,
      }),
    )
      .map((result) => fetchAllProductsSuccess(result))
      .catch((e) => Observable.of(showNotification(e.message))),
  )

export default [
  fetchProductsEpic,
  fetchProductDetailEpic,
  editProductEpic,
  createProductEpic,
  deleteProductEpic,
  fetchProductReplacementsEpic,
  setProductReplacementEpic,
  fetchGiftProductsEpic,
  setProductSequenceEpic,
  fetchAllProductsEpic,
]
