import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { FormFactorType } from '~/app/common/types'

/**
 * fetches all form factors
 */
export function fetchFormFactors(store: any, self$: any) {
  return async (): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const response = await Api.fetchFormFactors()
      self$.call.setFormFactors(response.data)
      self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

/**
 * edits form factor
 *
 * @param store - Root store
 * @param self$ - formFactors store
 */
export function editFormFactor(store: any, self$: any) {
  return async (payload: { id: number; data: FormFactorType }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.editFormFactor(payload.id, payload.data)
      const response = await Api.fetchFormFactors()
      self$.call.setFormFactors(response.data)
      self$.call.setStatus('loaded')
      alert$.call.setNotification('Successfully edited form factor')
    } catch (err: any) {
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

/**
 * creates form factor
 *
 * @param store - Root store
 * @param self$ - formFactors store
 */
export function createFormFactor(store: any, self$: any) {
  return async (payload: {
    data: FormFactorType
    onSuccess: (id: number) => void
  }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const { id } = await Api.createFormFactor(payload.data)
      const response = await Api.fetchFormFactors()
      self$.call.setFormFactors(response.data)
      self$.call.setStatus('loaded')
      payload.onSuccess(id)
      alert$.call.setNotification('Successfully created new form factor')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

/**
 * deletes form factor
 *
 * @param store - Root store
 * @param self$ - formFactors store
 */
export function deleteFormFactor(store: any, self$: any) {
  return async (id: number): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.deleteFormFactor(id)
      const response = await Api.fetchFormFactors()
      self$.call.setFormFactors(response.data)
      self$.call.setStatus('loaded')
    } catch (error: any) {
      alert$.call.setNotification(error.message)
    }
    loader$.call.hide()
  }
}
