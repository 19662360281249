import { compose, pure, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import { StyledLink, StyledTab, StyledContentTab } from './styled'

function Tab({ activeTab, to, tabName, tabText, handleTabClick }) {
  if (to) {
    return (
      <StyledLink to={to}>
        <StyledTab active={activeTab === tabName}>{tabText}</StyledTab>
      </StyledLink>
    )
  }

  return (
    <StyledContentTab onClick={handleTabClick(tabName)} isActive={activeTab === tabName}>
      {tabText}
    </StyledContentTab>
  )
}

Tab.propTypes = {
  activeTab: PropTypes.string,
  handleTabClick: PropTypes.func,
  tabName: PropTypes.string.isRequired,
  tabText: PropTypes.string.isRequired,
  to: PropTypes.string,
}
export default compose(
  pure,
  withHandlers({
    handleTabClick: (props) => (value) => () => {
      props.handleTabChange(value)
    },
  }),
)(Tab)
