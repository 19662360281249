import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import { showNotification } from '~/app/store/notifications/actions'

const effects = (store: any, self$: any) => ({
  async fetchFaultyOrderReasons() {
    loader$.call.show()
    try {
      const response = await Api.fetchFaultyOrderReasons()
      await store.dispatch(self$.setFaultyOrderReasons(response.data))
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      showNotification(err.message)
    }
    loader$.call.hide()
  },
})

export default effects
