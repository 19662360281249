export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')

    if (serializedState === null) {
      return
    }

    return JSON.parse(serializedState)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    // Ignore errors
  }
}
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    // Ignore write errors.
  }
} // Safari private browsing does not let you set data, so we
// monkey patch the Storage prototype with a NOOP
// to avoid getting QUOTA_EXCEEDED_ERR errors
;(() => {
  if (typeof localStorage === 'object') {
    try {
      localStorage.setItem('localStorage_test_123', 1)
      localStorage.removeItem('localStorage_test_123')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      Storage.prototype._setItem = Storage.prototype.setItem

      Storage.prototype.setItem = () => {}
    }
  }
})()
