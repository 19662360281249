import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function fetchMagicLink(store: any, self$: any) {
  return async (email: string): Promise<void> => {
    loader$.call.show()
    try {
      const response = await Api.fetchMagicLink({ email })
      await store.dispatch(self$.setMagicLink(response.magicLink))
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function clearMagicLink(store: any, self$: any) {
  return async (): Promise<void> => {
    store.dispatch(self$.clearMagicLink())
  }
}
