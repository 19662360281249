import { createAction } from '~/app/common/actions'
import {
  INITIALIZE,
  INITIALIZE_SUCCESS,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
} from './constants'
export const initialize = () => createAction(INITIALIZE)
export const initializeSuccess = () => createAction(INITIALIZE_SUCCESS)
export const login = (formData) => createAction(LOGIN, formData)
export const loginSuccess = (user) => createAction(LOGIN_SUCCESS, user)
export const loginFailure = () => createAction(LOGIN_FAILURE)
export const logout = () => createAction(LOGOUT)
export const refreshAccessToken = (refreshToken) =>
  createAction(REFRESH_TOKEN, {
    refreshToken,
  })
export const refreshAccessTokenSuccess = () => createAction(REFRESH_TOKEN_SUCCESS)
export const refreshAccessTokenFailure = () => createAction(REFRESH_TOKEN_FAILURE)
