import { merge } from 'ramda'
import { FETCH_SYMBOLS, FETCH_SYMBOLS_SUCCESS, FETCH_SYMBOLS_FAIL } from './constants'
const initialState = {
  dataLoaded: false,
  dataLoading: false,
  symbols: [],
}

function symbolsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYMBOLS:
      return merge(state, {
        dataLoaded: false,
        dataLoading: true,
      })

    case FETCH_SYMBOLS_SUCCESS:
      return merge(state, {
        dataLoaded: true,
        dataLoading: false,
        symbols: action.payload.data,
      })

    case FETCH_SYMBOLS_FAIL:
      return merge(state, {
        dataLoaded: false,
        dataLoading: false,
        symbols: [],
      })

    default:
      return state
  }
}

export default symbolsReducer
