import React from 'react'

import { prop, map, equals, filter, pipe } from 'ramda'
import Input from '~/app/components/Input'
import {
  getNewFieldValue,
  getInitialFormStateHook,
  getFieldValidity,
  isFormValid,
  mergeArrayKeys,
} from '~/app/hocs/withForm/helpers'
import { type FormField, FieldNames, type FieldName } from '../pages/Product/DetailForm/types'

interface Props {
  isNewUI?: boolean
  fieldDefinitions: {
    name: string
    label?: string
    validators?: any[]
    [key: string]: any
  }[]
  formData: Record<string, any>
}

export default function useForm({ fieldDefinitions, formData, isNewUI = false }: Props) {
  const [stateFormData, setFormData] = React.useState<Record<string, FormField>>(formData)
  const [fieldSet, setFieldSet] = React.useState<Record<string, FormField>>(
    getInitialFormStateHook(fieldDefinitions, formData, () => onFieldChange),
  )
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const lastFieldTouched = React.useRef<FieldName | null>(null)

  const setField = React.useCallback(
    (name: FieldNames, value: string | number | boolean) =>
      setFieldSet((state) => ({ ...state, [name]: { ...state[name], value } })),
    [],
  )

  const onFieldChange = (name: FieldName, ...rest: any[]): void => {
    const [value] = rest
    setHasChanges(true)
    lastFieldTouched.current = name
    setFieldSet((state) => {
      if (name === FieldNames.maxDiscountCents) {
        const checkoutMessageValue = fieldSet[FieldNames.checkoutMessage].value || ''
        const maxDiscountCentsField = fieldSet[FieldNames.maxDiscountCents]
        // The fieldSet contains the previous value of maxDiscountCents,
        // so we need to send the new value when revalidating checkoutMessage
        const fieldSetWithIncomingChange = {
          ...fieldSet,
          [FieldNames.maxDiscountCents]: { ...maxDiscountCentsField, value },
        }
        return {
          ...state,
          [FieldNames.maxDiscountCents]: getNewFieldValue(maxDiscountCentsField, value, fieldSet),
          [FieldNames.checkoutMessage]: getNewFieldValue(
            fieldSet[FieldNames.checkoutMessage],
            checkoutMessageValue,
            fieldSetWithIncomingChange,
          ),
        }
      }
      return {
        ...state,
        [name]: getNewFieldValue(fieldSet[name], value, fieldSet),
      }
    })
  }

  React.useEffect(() => {
    if (!equals(stateFormData, formData)) {
      reset(formData)
    }
  }, [formData])

  const reset = (formData: { [key: string]: any } = {}) => {
    setHasChanges(false)
    setFormData(formData)

    setFieldSet(getInitialFormStateHook(fieldDefinitions, formData, () => onFieldChange))
  }

  const renderField = (name: FieldName, props: Record<string, any> = {}) => {
    const fieldData = fieldSet[name]
    const data = {
      ...fieldData,
      ...props,
      onChange: (...args: any) => {
        fieldData.onChange(...args)
        if (onFieldChange) {
          onFieldChange(name, ...args)
        }
        if (props.onChange) {
          props.onChange(...args)
        }
      },
    }

    if (!data) {
      throw new Error(`Missing field definition for ${name}`)
    }
    const Component = isNewUI ? fieldData.type : Input

    return <Component {...data} />
  }

  const setSubmittedState = () => {
    // set pristine to false for every field, which will result in showing error on invalid fields
    setFieldSet(
      map<Record<string, FormField>, Record<string, FormField>>(
        (field: FormField) => ({
          ...field,
          ...getFieldValidity(field.value, field.validators, fieldSet),
          pristine: false,
          timesSubmitted: (field.timesSubmitted ?? 0) + 1,
        }),
        fieldSet,
      ),
    )
  }

  const getSerializedFormState = () => mergeArrayKeys(map(prop('value'), fieldSet))

  const serialize = (cb: (options: object) => void) => {
    setSubmittedState()
    setTimeout(() => {
      const serialized = getSerializedFormState()
      if (isFormValid(fieldSet)) {
        setHasChanges(false)
        cb(serialized)
      }
    }, 0)
  }

  return {
    errors: pipe(
      filter((field: any) => !field.valid),
      map((field) => field.errorMsg),
    )(fieldSet),
    formState: fieldSet,
    hasChanges,
    isValid: isFormValid(fieldSet),
    onFieldChange,
    renderField,
    serialize,
    setField,
    lastFieldTouched: lastFieldTouched.current,
  }
}
