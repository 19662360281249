import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchFormFactors, editFormFactor, createFormFactor, deleteFormFactor } from './effects'
import { setFormFactors, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const formFactors$ = makeModel({
  effects: makeEffects({
    createFormFactor,
    deleteFormFactor,
    editFormFactor,
    fetchFormFactors,
  }),
  prefix: 'formFactors',
  reducers: makeReducers({
    setFormFactors,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default formFactors$
