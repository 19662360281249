import { compose, pure } from 'recompose'
import { Wrapper, List, Item } from './styled'
import SubscriptionCard from './SubscriptionCard'
import EditKid from './EditKid'
import withEditKidController from './withEditKidController'

interface Props {
  handleConfirm: () => void
  handleSave: () => void
  handleToggleEdit: () => void
  plans: any[]
  showEditKidForm: object
  subscriptions: any[]
  user: any
}

function KidsAndPlans({
  user,
  subscriptions,
  plans,
  showEditKidForm,
  handleToggleEdit,
  handleConfirm,
  handleSave,
}: Props) {
  const subscriptionsWithoutKid = subscriptions.filter((subscription) => !subscription.kidId)
  return (
    <Wrapper>
      <List>
        {user.kids.map((kid: any) => (
          <Item key={kid.id}>
            {/* @ts-ignore */}
            {!showEditKidForm[kid.id] ? (
              <SubscriptionCard
                kid={kid}
                user={user}
                subscriptions={subscriptions}
                plans={plans}
                handleEdit={handleToggleEdit}
              />
            ) : (
              <EditKid
                kid={kid}
                handleEdit={handleToggleEdit}
                subscriptions={subscriptions}
                plans={plans}
                handleConfirm={handleConfirm}
                handleSave={handleSave}
              />
            )}
          </Item>
        ))}

        {subscriptionsWithoutKid.length && (
          <Item>
            <SubscriptionCard
              user={user}
              subscriptions={subscriptionsWithoutKid}
              plans={plans}
              handleEdit={handleToggleEdit}
            />
          </Item>
        )}
      </List>
    </Wrapper>
  )
}

// @ts-ignore
export default compose(pure, withEditKidController)(KidsAndPlans)
