let reduxStore: any = null

export const setStoreOnce = (store: any) => {
  if (store) {
    reduxStore = store
  }
}

export const getStore = () => {
  if (reduxStore) {
    return reduxStore
  }
  throw new Error(`
    setStoreOnce() must be called before
    calling getStore, set it on the first
    component connected to the store where
    the redux provider is defined.
  `)
}
