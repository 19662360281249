import { useState } from 'react'
import { ProductType } from '~/app/common/types'
import user$ from '~/app/store/user'
import { ReasonsArrayType } from './types'
import useCancellationReasons from './useCancellationReasons'

interface Props {
  userId: number
  templateId: number
  type: ProductType
}

export default function usePauseController(props: Props) {
  const { userId, templateId } = props

  const [showPauseModal, setPauseModalVisibility] = useState(false)
  const [showUnPauseModal, setUnPauseModalVisibility] = useState(false)

  const { reasons, reasonsStatus } = useCancellationReasons({ type: props.type })

  const handlePauseModal = () => {
    setPauseModalVisibility(() => !showPauseModal)
  }

  const handleUnPauseModal = () => {
    setUnPauseModalVisibility(() => !showUnPauseModal)
  }

  const handleUnPauseSubscriptions = () => {
    user$.call.pauseUserSubscription({
      data: { pause: false, templateId },
      userId,
    })
    setUnPauseModalVisibility(false)
  }

  const handlePauseSubscriptions = (reasonsSelected: ReasonsArrayType[], note: string) => {
    const cancellationReasonData = {
      note,
      reasons: reasonsSelected,
    }
    user$.call.pauseUserSubscription({
      cancellationReasonData,
      data: { pause: true, templateId },
      userId,
    })
    setPauseModalVisibility(false)
  }

  return {
    handlePauseModal,
    handlePauseSubscriptions,
    handleUnPauseModal,
    handleUnPauseSubscriptions,
    reasons,
    reasonsStatus,
    setPauseModalVisibility,
    setUnPauseModalVisibility,
    showPauseModal,
    showUnPauseModal,
  }
}
