import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchPricingGroup } from './effects'
import { setLoading, setLoaded, setError } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const pricingGroup$ = makeModel({
  effects: makeEffects({ fetchPricingGroup }),
  prefix: 'pricingGroup',
  reducers: makeReducers({
    setError,
    setLoaded,
    setLoading,
  }),
  selectors,
  state: initialState,
})

export default pricingGroup$
