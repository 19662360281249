export default function TrashIcon() {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" xmlns="http://www.w3.org/2000/svg">
      <title>Trash</title>
      <g fill="#505B6D" fillRule="evenodd">
        <path d="M19.032 2.903h-4.775v-.84c0-1-.806-1.806-1.807-1.806L7.516.258c-1 0-1.807.807-1.807 1.807v.838H.903a.855.855 0 0 0-.839.84c0 .45.387.839.84.839h1.225v14.74a3.028 3.028 0 0 0 3.032 3.032h9.677a3.028 3.028 0 0 0 3.032-3.031V4.549h1.226c.451 0 .84-.387.84-.84 0-.45-.388-.806-.904-.806zM7.516 1.871h4.936c.097 0 .194.097.194.194l-.001.838H7.322v-.839c0-.096.097-.193.194-.193zM3.774 19.29V4.549H16.16V19.29c0 .742-.613 1.355-1.355 1.355H5.128c-.74-.033-1.354-.613-1.354-1.355z" />
        <path d="M9.967 18.387c.45 0 .806-.356.806-.84V7.613c0-.45-.355-.84-.806-.84a.855.855 0 0 0-.84.84v9.967c.002.451.389.806.84.806zM13.612 18.387c.451 0 .84-.387.84-.84V7.613a.855.855 0 0 0-.84-.84.855.855 0 0 0-.839.84v9.967c.066.484.42.806.84.806zM6.354 18.387c.451 0 .84-.387.84-.84V7.613a.855.855 0 0 0-.84-.84.855.855 0 0 0-.839.84v9.967c.001.451.355.806.84.806z" />
      </g>
    </svg>
  )
}
