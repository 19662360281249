import { makeModel } from '~/app/utils/storeManager'
import effects from './effects'
import reducers from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const user$ = makeModel({
  effects,
  prefix: 'user',
  reducers,
  selectors,
  state: initialState,
})

export default user$
