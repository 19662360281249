import type { ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'

const getSize = (props: any) => {
  if (props.round) {
    if (props.icon) {
      return css`
        height: 30px;
        width: 30px;
        margin: 0 10px;
      `
    }
    if (props.large) {
      return css`
        height: 60px;
        width: 60px;
      `
    }
    return css`
      height: 45px;
      width: 45px;
    `
  }

  if (props.small) {
    return css`
      font-size: 14px;
      height: 42px;
      padding: 0 52px;
    `
  }
  if (props.large) {
    return css`
      height: 50px;
      min-width: 200px;
      padding: 0 30px;
    `
  }
  if (props.big) {
    return css`
      height: auto;
      padding: 15px;
    `
  }

  return css`
    height: 50px;
    padding: 0 30px;
  `
}

const getTheme = (props: any) => {
  if (props.primary) {
    return css`
      background-color: ${(props) => props.theme.color.green};
      color: ${(props) => props.theme.color.white};

      &:hover {
        box-shadow: 0 0 6px ${(props) => props.theme.color.green};
      }

      &:focus {
        background-color: ${(props) => props.theme.color.darkGreen};
      }
    `
  }
  if (props.secondary) {
    return css`
      color: ${(props) => props.theme.color.green};
      border: 1px solid ${(props) => props.theme.color.green};

      &:hover {
        color: ${(props) => props.theme.color.white};
        background-color: ${(props) => props.theme.color.green};
      }
    `
  }

  if (props.danger) {
    return css`
      background-color: ${(props) => props.theme.color.red};
      color: ${(props) => props.theme.color.white};

      &:hover {
        box-shadow: 0 0 6px ${(props) => props.theme.color.red};
      }
    `
  }
  if (props.paused) {
    return css`
      background-color: ${(props) => props.theme.color.yellow};
      color: ${(props) => props.theme.color.white};

      &:hover {
        box-shadow: 0 0 6px ${(props) => props.theme.color.yellow};
      }
    `
  }
  if (props.postpone) {
    return css`
      background-color: ${(props) => props.theme.color.blue};
      color: ${(props) => props.theme.color.white};

      &:hover {
        box-shadow: 0 0 6px ${(props) => props.theme.color.blue};
      }
    `
  }
  if (props.pending) {
    return css`
      background-color: ${(props) => props.theme.color.orange};
      color: ${(props) => props.theme.color.white};

      &:hover {
        box-shadow: 0 0 6px ${(props) => props.theme.color.orange};
      }
    `
  }

  return css``
}

interface StyledButtonProps {
  danger?: boolean
  disabled?: boolean
  children: ReactNode
  icon?: boolean
  isValid?: boolean
  loading?: boolean
  primary?: boolean
  secondary?: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  transition: background 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  opacity: ${(props) => (props.isValid !== false ? '1' : '0.5')};
  ${(props) => (props.icon ? 'border-radius: 0;' : '')}
  ${getSize}
  ${getTheme}

  &:disabled {
    background-color: ${(props) => props.theme.color.lightGray};
    color: white;
    border-color: white;
    cursor: default;
  }

  &:disabled:hover {
    box-shadow: none;
  }
`

export const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
`

export const LoaderDot3 = styled.div`
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
`

export const LoaderDot1 = styled(LoaderDot3)`
  animation-delay: -0.32s;
`

export const LoaderDot2 = styled(LoaderDot3)`
  animation-delay: -0.16s;
`
