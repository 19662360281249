interface Props {
  className?: string
}

export default function SmallPlanIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="69" viewBox="0 0 70 69" {...props}>
      <title>Small plan</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#B6D042"
          d="M19.472 29.996a3.846 3.846 0 0 0-3.457.482 1.618 1.618 0 0 1-1.728 0 3.814 3.814 0 0 0-3.44-.488c-2.346.888-3.311 4.205-2.189 7.468a8.243 8.243 0 0 0 2.357 3.793c1.633 1.282 2.958 0 4.641.559 3.092.976 5.05-1.423 6.033-4.31 1.122-3.258.134-6.61-2.217-7.504z"
        />
        <path
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M23.472 30.496a3.846 3.846 0 0 0-3.457.482 1.618 1.618 0 0 1-1.728 0 3.814 3.814 0 0 0-3.44-.488c-2.346.888-3.311 4.205-2.189 7.468a8.243 8.243 0 0 0 2.357 3.793c1.633 1.282 2.958 0 4.641.559 3.092.976 5.05-1.423 6.033-4.31 1.122-3.258.134-6.61-2.217-7.504z"
        />
        <path
          stroke="#302D1F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M17.426 26.781a4.346 4.346 0 0 1 1.745 3.5"
        />
        <path
          fill="#E1EFD5"
          fillRule="nonzero"
          d="M25.608 33.918a1.94 1.94 0 0 0-.57.445c-.875.99-1.463 2.269-2.194 3.368-.829 1.244-1.605 2.54-2.508 3.732-.266.4-.356.894-.247 1.364.108.47.405.873.818 1.112.43.233.912.35 1.4.342a7.886 7.886 0 0 0 4.135-1.458c2.097-1.36 3.999-4.207 2.856-6.782a3.878 3.878 0 0 0-1.713-1.793 2.342 2.342 0 0 0-1.714-.446c-.091.03-.18.068-.263.116z"
        />
        <path
          fill="#BDD8A8"
          fillRule="nonzero"
          d="M27.7 34.244a3.638 3.638 0 0 0-1.04-.463c.485.374.873.876 1.126 1.458 1.079 2.574-.722 5.42-2.696 6.78a7.184 7.184 0 0 1-3.872 1.446h-.302c.136.197.311.36.513.474.406.233.86.35 1.32.342a7.191 7.191 0 0 0 3.873-1.481c1.979-1.36 3.774-4.206 2.696-6.78a3.786 3.786 0 0 0-1.618-1.776z"
        />
        <path
          stroke="#495464"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.55"
          d="M24.25 38.156l-.716.875"
        />
        <path
          stroke="#495464"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.32"
          d="M25.608 33.918a1.94 1.94 0 0 0-.57.445c-.875.99-1.463 2.269-2.194 3.368-.829 1.244-1.605 2.54-2.508 3.732-.266.4-.356.894-.247 1.364.108.47.405.873.818 1.112.43.233.912.35 1.4.342a7.886 7.886 0 0 0 4.135-1.458c2.097-1.36 3.999-4.207 2.856-6.782a3.878 3.878 0 0 0-1.713-1.793 2.342 2.342 0 0 0-1.714-.446c-.091.03-.18.068-.263.116z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.32"
          d="M7.695 38.156L.848 47.55l7.853.231z"
        />
        <path fill="#8165C2" d="M37.005 30.281v37.625l26.176-8.25v-21.51z" />
        <path
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M34.005 30.281v37.625l26.176-8.25v-21.51z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.828 38.284v21.598l26.177 8.024V30.281zM34.005 30.281l9.152 14.258 25.749 3.242-8.603-10.068z"
        />
        <path fill="#8165C2" d="M8.848 38.621l24.406-7.34v35.71L8.848 59.435z" />
        <g>
          <path
            fill="#49C5B1"
            d="M12.424 15.777a.825.825 0 0 1 .312-1.213l16.03-7.87a.825.825 0 0 1 1.163.538l2.056 8.096a2.585 2.585 0 0 1-1.332 2.94l-10.16 5.172a2.585 2.585 0 0 1-3.291-.823l-4.778-6.84z"
          />
          <path
            stroke="#302D1F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M13.518 12.624l4.778 6.84a1.76 1.76 0 0 0 2.241.56l10.159-5.172a1.76 1.76 0 0 0 .907-2.002l-2.055-8.096-16.03 7.87z"
          />
          <path
            fill="#FFF"
            stroke="#302D1F"
            strokeWidth="1.5"
            d="M11.913 9.333a2.02 2.02 0 0 0-.942 2.63l.703 1.58.003.005a.13.13 0 0 0 .174.058l18.9-9.453a.13.13 0 0 0 .061-.17l-.736-1.651-.034-.072a1.784 1.784 0 0 0-2.393-.798L11.913 9.333z"
          />
        </g>
        <g>
          <path
            fill="#F7D148"
            d="M51.026 20.041l-.004.011a25.433 25.433 0 0 0-1.263 10.112 1.476 1.476 0 0 1-.821 1.416c-.536.262-1.173.17-1.661-.262a14.612 14.612 0 0 1-3.075-4.54c-2.972-7.022-.117-15.054 6.525-18.293a13.356 13.356 0 0 1 6.478-1.284 1.245 1.245 0 0 1 .682 2.214c-2.878 2.243-5.381 6.063-6.861 10.626z"
          />
          <path
            stroke="#302D1F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M47.967 18.275c1.57-4.84 4.21-8.74 7.14-11.023a.41.41 0 0 0 .13-.446.42.42 0 0 0-.366-.294c-2.1-.11-4.18.305-6.061 1.206-6.237 3.04-8.925 10.603-6.122 17.226a13.78 13.78 0 0 0 2.899 4.28.651.651 0 0 0 .715.103.651.651 0 0 0 .36-.627c-.27-3.55.175-7.098 1.305-10.425z"
          />
          <path
            fill="#FFF"
            stroke="#302D1F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M52.381 6.656l5.163-1.998c.617.628 1.078 1.621 1.145 2.468-1.06.036-3.506.229-3.506.229l-2.802-.699z"
          />
        </g>
      </g>
    </svg>
  )
}
