import PropTypes from 'prop-types'
import { compose, pure, withHandlers } from 'recompose'
import { Wrapper, StyledInput, PlusButton, MinusButton } from './styled'

const Number = ({ value, handleChange, small }) => (
  <Wrapper small={small}>
    <MinusButton onClick={handleChange(value - 1)}>-</MinusButton>
    <StyledInput small={small} type="text" value={value} onChange={handleChange} readonly />
    <PlusButton onClick={handleChange(value + 1)}>+</PlusButton>
  </Wrapper>
)

Number.defaultProps = {
  small: false,
}
Number.propTypes = {
  handleChange: PropTypes.func.isRequired,
  small: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
export default compose(
  pure,
  withHandlers({
    handleChange:
      ({ onChange, minValue, maxValue }) =>
      (value) =>
      () => {
        if (
          (minValue === undefined || value >= minValue) &&
          (maxValue === undefined || value <= maxValue)
        ) {
          onChange(value)
        }
      },
  }),
)(Number)
