import { Wrap, Body, Container } from './styled'

type Props = {
  children: React.ReactNode
}

export default function Page({ children }: Props) {
  return (
    <Wrap>
      <Body>
        <Container>{children}</Container>
      </Body>
    </Wrap>
  )
}
