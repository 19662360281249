import { merge } from 'ramda'
import { FETCH_ORDERS, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAIL } from './constants'
const initialState = {
  data: [],
  loaded: false,
  loading: false,
}
export default function upcomingOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS:
      return merge(state, {
        loading: true,
      })

    case FETCH_ORDERS_SUCCESS: {
      return merge(state, {
        data: action.payload.data.map((order) => ({
          ...order,
          addOns: order.addOns.map((addOn) => ({
            ...addOn,
            amount: addOn.skipped ? 0 : getAddOnsTotalAmount(addOn.products),
            totalCount: addOn.skipped ? 0 : getAddOnsTotalCount(addOn.products, addOn.type),
          })),
        })),
        loaded: true,
        loading: false,
      })
    }

    case FETCH_ORDERS_FAIL: {
      return merge(state, {
        loaded: false,
        loading: false,
      })
    }

    default:
      return state
  }
}

function getAddOnsTotalCount(products, type) {
  const multiplier = type === 'booster' ? 5 : 1
  const count = products.reduce((sum, prd) => {
    sum += prd.count // eslint-disable-line no-param-reassign

    return sum
  }, 0)
  return count * multiplier
}

function getAddOnsTotalAmount(products) {
  return products.reduce((sum, prd) => {
    // 5.99 price per booster set
    sum += (prd.price ? prd.price : 599) * prd.count // eslint-disable-line no-param-reassign

    return sum
  }, 0)
}
