interface Props {
  onClick?: () => void
  className?: string
  fillColor?: string
}

export default function SmoothieIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0, 0, 400,784.0133222314737"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Smoothie Icon</title>
      <desc>Little Spoon smoothie pouch icon</desc>
      <path
        d="M149.875 0.175 C 149.600 0.229,148.251 0.453,146.878 0.673 C 129.262 3.493,115.030 17.411,110.850 35.904 C 109.847 40.342,109.452 91.420,110.414 92.381 C 110.902 92.870,113.005 92.923,131.914 92.923 L 152.873 92.923 152.873 96.919 L 152.873 100.916 148.943 100.916 C 144.257 100.916,144.213 100.944,144.213 103.980 L 144.213 106.245 130.915 106.245 C 115.147 106.245,116.570 105.802,116.570 110.712 L 116.570 114.132 108.638 114.355 C 104.276 114.477,98.693 114.808,96.232 115.090 C 43.566 121.123,2.845 164.745,0.491 217.652 C 0.262 222.798,7.864 734.951,8.193 736.553 C 10.060 745.636,24.967 756.422,46.328 764.146 C 104.223 785.078,228.208 789.981,312.906 774.688 C 357.723 766.595,387.988 752.223,392.368 736.954 C 392.738 735.665,400.000 252.927,400.000 229.646 C 400.000 203.083,397.793 192.157,388.800 174.188 C 370.313 137.253,335.242 115.569,292.028 114.355 L 284.097 114.132 284.097 110.712 C 284.097 105.802,285.519 106.245,269.751 106.245 L 256.453 106.245 256.453 103.980 C 256.453 100.944,256.409 100.916,251.724 100.916 L 247.794 100.916 247.794 96.919 L 247.794 92.923 268.931 92.923 C 289.855 92.923,290.072 92.916,290.431 92.244 C 291.189 90.828,290.668 39.333,289.860 35.803 C 285.620 17.287,271.614 3.742,253.506 0.646 C 250.131 0.069,152.677 -0.374,149.875 0.175 "
        fill={props.fillColor}
        fillRule="evenodd"
      />
    </svg>
  )
}
