import styled, { css } from 'styled-components'

export const StyledButton = styled.button`
  ${(props: {
    theme:
      | undefined
      | {
          color: { gray: string }
        }
  }) => css`
    border: 1px solid ${props.theme?.color?.gray || ''};
    border-style: dashed;
    border-radius: 4px;
    color: ${props.theme?.color.gray || ''};
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    padding: 22px 0;
    text-align: center;
    width: calc(100% - 100px);

    > svg {
      height: 16px;
      margin-left: -22px;
      margin-right: 16px;
      position: relative;
      top: -1px;
      width: 16px;
    }
  `}
`
