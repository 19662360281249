import QtyButtonIcon from '~/app/components/Icons/QtyButtonIcon'
import ContainerIcon from '~/app/assets/svg/ContainerSmall'
import styled from 'styled-components'
import { colors } from '~/app/settings/colors'
import { ProductType } from '~/app/common/types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  margin-bottom: 30px;
  color: ${colors.default};
  justify-content: space-around;
  color: ${(props) => props.theme.color.green};
`
const Quantity = styled.div`
  margin: 0 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${
    // @ts-ignore
    (props: { active: boolean }) =>
      props.active &&
      `
    color: ${
      // @ts-ignore
      (props) => props.theme.color.green
    };
  `
  } path {
    fill: currentColor;
  }
`
const QuantityText = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 6px;
  color: ${(props) => props.theme.color.green};
`
// @ts-expect-error: TODO: Fix type error
export const Button = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  outline: none;
  transition: opacity 1s;
  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `};
`

interface Props {
  disabled: boolean
  onDecrease: () => void
  onIncrease: () => void
  packetCount: number
  quantity: number
  selected: boolean
  type: ProductType
}

export default function QuantityEditor(props: Props) {
  return (
    <Wrapper>
      <Button disabled={props.quantity === 0} onClick={props.onDecrease}>
        <QtyButtonIcon type="decrease" />
      </Button>
      {/* @ts-ignore */}
      <Quantity active={props.selected}>
        <QuantityText>
          {getUIProductCount(props.type, props.quantity, props.packetCount)}
        </QuantityText>
        <ContainerIcon />
      </Quantity>
      <Button disabled={props.disabled} onClick={props.onIncrease}>
        <QtyButtonIcon type="increase" />
      </Button>
    </Wrapper>
  )
}

function getUIProductCount(type: ProductType, quantity: number, packetCount: number) {
  if (packetCount != null && [ProductType.booster, ProductType.smoothie].includes(type)) {
    return quantity * packetCount
  }

  return quantity
}
