import InputTypeDate from '~/app/components/InputTypes/Date'
import InputSmallText from '~/app/components/InputTypes/SmallText'
import { required, maxLength } from '~/app/common/validators'

export default function formDefinition(type: string) {
  switch (type) {
    case 'birthdate':
      return [
        {
          name: 'birthdate',
          type: InputTypeDate,
        },
      ]

    case 'name':
      return [
        {
          name: 'name',
          type: InputSmallText,
          validators: [required, maxLength(255)],
        },
      ]

    default:
      return null
  }
}
