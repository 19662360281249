import { NodeEnv } from '~/app/common/constants'

/**
 * Check environment hook.
 */
export default function useIsEnvironment(environmentToCheck: NodeEnv): boolean {
  if (environmentToCheck in NodeEnv) {
    return process.env.NODE_ENV === environmentToCheck
  }

  throw new Error(
    `Unsupported environment passed to useIsEnvironment hook. Expected: ${Object.values(
      NodeEnv,
    )}. Received: ${environmentToCheck}`,
  )
}
