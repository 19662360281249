type Props = {
  className: string | undefined
}

export default function Crossmark(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="31"
      viewBox="0 0 34 31"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd" transform="translate(-13 -13)">
        <circle cx="28" cy="28" r="28" />
        <path
          fill="#E66889"
          d="M45.698 36.412a4.445 4.445 0 0 1-6.286 6.286L32 35.286l-7.412 7.412a4.445 4.445 0 0 1-6.286-6.286L25.714 29l-7.412-7.412a4.445 4.445 0 0 1 6.286-6.286L32 22.714l7.412-7.412a4.445 4.445 0 0 1 6.286 6.286L38.286 29l7.412 7.412z"
        />
        <path
          stroke="#302D1F"
          strokeWidth="1.5"
          d="M32.872 28l8.119 8.12a3.445 3.445 0 0 1-4.872 4.871L28 32.871l-8.12 8.12a3.445 3.445 0 0 1-4.871-4.872L23.129 28l-8.12-8.12a3.445 3.445 0 0 1 4.872-4.871L28 23.129l8.12-8.12a3.445 3.445 0 0 1 4.871 4.872L32.871 28z"
        />
      </g>
    </svg>
  )
}
