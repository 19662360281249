const NAME_SPACE = 'app/Blueprints'
export const FETCH_BLUEPRINTS = `${NAME_SPACE}/FETCH_BLUEPRINTS`
export const FETCH_BLUEPRINTS_SUCCESS = `${NAME_SPACE}/FETCH_BLUEPRINTS_SUCCESS`
export const FETCH_BLUEPRINTS_FAIL = `${NAME_SPACE}/FETCH_BLUEPRINTS_FAIL`
export const FETCH_BLUEPRINT_DETAIL = `${NAME_SPACE}/FETCH_BLUEPRINT_DETAIL`
export const FETCH_BLUEPRINT_DETAIL_SUCCESS = `${NAME_SPACE}/FETCH_BLUEPRINT_DETAIL_SUCCESS`
export const CREATE_BLUEPRINT = `${NAME_SPACE}/CREATE_BLUEPRINT`
export const CREATE_BLUEPRINT_SUCCESS = `${NAME_SPACE}/CREATE_BLUEPRINT_SUCCESS`
export const UPDATE_BLUEPRINT = `${NAME_SPACE}/UPDATE_BLUEPRINT`
export const UPDATE_BLUEPRINT_SUCCESS = `${NAME_SPACE}/UPDATE_BLUEPRINT_SUCCESS`
export const UPDATE_BLUEPRINT_FAIL = `${NAME_SPACE}/UPDATE_BLUEPRINT_FAIL`
export const UPDATE_BLUEPRINTS_PAGE = `${NAME_SPACE}/UPDATE_BLUEPRINTS_PAGE`
export const BLUEPRINTS_PER_PAGE = 10
