import { validStageIds } from '~/app/enums/eatingStages'

export const required = {
  fn: (value: any) => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    return Boolean(value) || value === false
  },
  msg: 'This field is mandatory',
}

export const isNumber = {
  fn: (value: any) => !value || !isNaN(value),
  msg: 'Must be a number',
}

export const email = {
  fn: (value: string) => {
    const regex = /\S+@\S+\.\S+/
    return regex.test(value)
  },
  msg: 'Email is invalid',
}

export const minLength = (length: number) => ({
  fn: (value: string) => !value || (value && value.trim().length >= length),
  msg: `Must be at least ${length} character(s) long`,
})

export const maxLength = (length: number) => ({
  fn: (value: string) => (value ? value.trim().length <= length : true),
  msg: `Cannot exceed ${length} characters`,
})

export const keyHighlightName = {
  fn: (keyHighlightArray: any) =>
    // @ts-ignore
    Object.values(keyHighlightArray || []).every(
      ({ name }) => typeof name === 'string' && name.trim(),
    ),
  msg: 'All Key Highlights names must be populated',
}

export const keyHighlightDescription = {
  fn: (keyHighlightArray: any) =>
    // @ts-ignore
    Object.values(keyHighlightArray || []).every(
      ({ description }) => typeof description === 'string' && description.trim(),
    ),
  msg: 'All Key Highlights descriptions must be populated',
}

export const nutritionName = {
  fn: (highlightArray: any) =>
    // @ts-ignore
    Object.values(highlightArray || []).every(
      ({ name }) => typeof name === 'string' && name.trim(),
    ),
  msg: 'All Nutrient names must be populated',
}

export const nutritionHighlightName = {
  fn: (highlightArray: any) =>
    // @ts-ignore
    Object.values(highlightArray || []).every(({ val }) =>
      val.every(
        ({ highlightName }: any) => typeof highlightName === 'string' && highlightName.trim(),
      ),
    ),
  msg: 'All Highlight names must be populated',
}

export const nutritionHighlightValue = {
  fn: (highlightArray: any) =>
    // @ts-ignore
    Object.values(highlightArray || []).every(({ val }) =>
      val.every(({ highlightValue }: any) => highlightValue && !isNaN(+highlightValue)),
    ),
  msg: 'All Highlight values must be numbers',
}

export const conditionalValidator = (
  { ifField, equals, valueExists }: { ifField: string; equals?: any; valueExists?: boolean },
  validator: any,
) => ({
  fn(val: any, state: any) {
    const conditionalField = state[ifField] || {}
    if (conditionalField.valid) {
      if (valueExists !== undefined && Boolean(conditionalField.value) === valueExists) {
        return validator.fn(val)
      } else if (equals !== undefined && conditionalField.value === equals) {
        return validator.fn(val)
      }
    }
    return true
  },
  msg: validator.msg,
})

export const notPoBox = {
  fn: (value: string) => {
    const regex =
      /(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i
    return !regex.test(value)
  },
  msg: 'Cannot include PO Box',
}

export const isValidStageId = {
  fn: (value: string, { type }: any) =>
    // Only blends use stage.
    type !== 'blend' ||
    // Value can be stored as a string. parseInt to convert to number for lookup.
    validStageIds.indexOf(parseInt(value, 10)) > -1,
  msg: 'Must pick a stage to save!',
}

/**
 * Validate if provided phone number is in valid US format
 *
 * @param value - User phone number
 */
export const phoneNumber = {
  fn: (value: string) => {
    const cleanedPhoneNumber = value.replace(/\D/g, '')
    const validUSPhoneNumberFormat = /^(0|[1-9][0-9]{9})$/i
    return validUSPhoneNumberFormat.test(cleanedPhoneNumber)
  },
  msg: 'Phone number must be 10 numbers',
}
