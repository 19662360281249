import styled from 'styled-components'
import Button from '~/app/components/Button/Button'
import { colors } from '~/app/settings/colors'

export const Wrap = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  img {
    height: 200px;
    object-fit: contain;
    width: 200px;
  }
`

export const AddImageButton = styled(Button)`
  display: block;
  line-height: 0;
  margin: 20px 0 0;

  label {
    cursor: pointer;
    padding: 26px 32px;
  }
`

export const HiddenInput = styled.input`
  display: none;
`

export const ImageOptions = styled.div`
  display: flex;
  margin: 12px 0 26px;

  svg {
    cursor: pointer;
    height: 22px;
    margin: 0 14px;
    width: 22px;
  }
`

export const ImageError = styled.small`
  color: ${colors.error};
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
`
