import { Label, Error, StyledInput } from './styled'

type Props = {
  label: string
  alignLabel: boolean
  labelMargins: string

  // Validation related
  onChange: (value: any) => void
  pristine: boolean
  valid: boolean
  errorMsg: string
}

export default function Input({
  label,
  pristine,
  valid,
  errorMsg,
  alignLabel,
  labelMargins,
  onChange,
  ...rest
}: Props) {
  const showError = !pristine && !valid
  const handleChange = (event: any) => onChange(event.target.value)
  return (
    <>
      {label && (
        <Label
          // @ts-expect-error: TODO: Fix me
          inline={alignLabel}
          margins={labelMargins}
        >
          {label}
        </Label>
      )}
      <StyledInput
        {...rest}
        // @ts-expect-error: TODO: Fix me
        pristine={pristine}
        onChange={handleChange}
      />
      {showError && <Error>{errorMsg}</Error>}
    </>
  )
}
