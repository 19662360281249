import moment from 'moment-timezone'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { AddOnType, ProductType } from '~/app/common/types'
import { Order, OrderProduct } from './types'
import sumBy from 'lodash/sumBy'
import type { Plan } from '~/app/store/plans/types'

export interface OrderProductCountReturnType {
  mealsPerDay?: number
  mealsPerDelivery?: number
}

export const getUserFriendlyName = (id: string, mappings?: Array<{ [key: string]: string }>) => {
  if (mappings) {
    const pair = mappings.find((map) => map.value === id)
    return pair ? pair.label : id
  }
  return id
}

export const getProductText = (type: ProductType | AddOnType) => {
  if (type === ProductType.blend) {
    return 'Babyblends'
  } else if (type === ProductType.luncher) {
    return 'Lunchers + Plates'
  }
  return `${type}s`
}

export const formatDate = (date: string | Date): string => moment(date).format('YYYY-MM-DD')

export const getAddOnTotal = (
  addOn: { [key: string]: any },
  isRemovedLinkedTemplatesEnabled = false,
) => {
  if (isRemovedLinkedTemplatesEnabled) {
    return convertCentsToDollars(addOn.charge.amount + addOn.charge.credits)
  }
  if (addOn.charge) {
    if (addOn.charge.credits > 0) {
      return convertCentsToDollars(addOn.charge.credits + addOn.charge.subscriptionTotal)
    }
    return convertCentsToDollars(addOn.charge.subscriptionTotal)
  }
  return 0
}

/**
 * Checks for order mealsPerDay and mealsPerDelivery values from the plans map. If none is found, calculates the mealsPerDelivery by the sum of the order product count.
 * @param planIdToPlanMap - Plans map
 * @param order - Order
 */
export function getOrderProductCount(
  planIdToPlanMap: Map<number, Plan>,
  order: Order,
): OrderProductCountReturnType {
  const plan = planIdToPlanMap.get(order.planId)

  if (plan) {
    const { mealsPerDay, mealsPerDelivery } = plan

    return { mealsPerDay, mealsPerDelivery }
  }

  const mealsPerDelivery = sumBy(order.products, 'count')

  return {
    // mealsPerDay is normally displayed for blends, but given flexible cadence the wording related to mealsPerDay will eventually go away
    mealsPerDay: undefined,
    mealsPerDelivery,
  }
}

/**
 * Gets the order product count text to display in the UI (meals/day or meals/delivery)
 */
export function getOrderProductCountText(orderProductCount: OrderProductCountReturnType): string {
  if (typeof orderProductCount.mealsPerDay === 'number') {
    return `${orderProductCount.mealsPerDay} meals/day`
  }

  return `${orderProductCount.mealsPerDelivery} meals/delivery`
}

/** Gets total number of products in order */
export function getTotalProductCount(products: OrderProduct[]): number {
  return products.reduce((total: number, product: OrderProduct) => (total += product.count), 0)
}
