import { createAction } from '~/app/common/actions'
import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  UPDATE_USERS_PAGE,
  SEARCH_USER,
  FETCH_USER_TEMPLATE,
  FETCH_USER_TEMPLATE_SUCCESS,
  FETCH_USER_TEMPLATE_FAIL,
} from './constants'

// TODO: refactor to be params
export const fetchUsers = ({ limit, offset, searchTerm, filter, type, searchFilter }: any) =>
  createAction(FETCH_USERS, {
    filter,
    limit,
    offset,
    searchFilter,
    searchTerm,
    type,
  })

export const fetchUsersSuccess = (data: any) => createAction(FETCH_USERS_SUCCESS, data)

export const fetchUsersFail = () => createAction(FETCH_USERS_FAIL)

export const updatePage = () => createAction(UPDATE_USERS_PAGE)

export const searchUser = () => createAction(SEARCH_USER)

export const fetchUserTemplate = (email: string) =>
  createAction(FETCH_USER_TEMPLATE, {
    email,
  })

export const fetchUserTemplateSuccess = (data: any) =>
  createAction(FETCH_USER_TEMPLATE_SUCCESS, data)

export const fetchUserTemplateFail = () => createAction(FETCH_USER_TEMPLATE_FAIL)
