import { pure } from 'recompose'
import InputTypeText from '~/app/components/InputTypes/Text'
import InputImage from '~/app/components/InputTypes/Image'
import { Wrap, Label, ImageLabel, Error, ImageError, Note } from './styled'

interface Props {
  label?: string
  note?: string
  type: () => void | string
  alignLabel?: boolean
  labelMargins?: string

  // Validation related
  pristine: boolean
  valid: boolean
  errorMsg?: string
}

function InputField({
  label,
  note,
  type,
  pristine,
  valid,
  errorMsg,
  alignLabel,
  labelMargins,
  ...rest
}: Props) {
  const Component = type || InputTypeText
  const showError = !pristine && !valid

  return (
    // @ts-ignore
    <Wrap inline={alignLabel}>
      {label &&
        // @ts-ignore
        (type !== InputImage ? (
          // @ts-ignore
          <Label inline={alignLabel} margins={labelMargins}>
            {label}
          </Label>
        ) : (
          <ImageLabel>{label}</ImageLabel>
        ))}
      {note && <Note>{note}</Note>}
      {/* @ts-ignore */}
      <Component {...rest} label={label} pristine={pristine} />
      {showError &&
        // @ts-ignore
        (type === InputImage ? <ImageError>{errorMsg}</ImageError> : <Error>{errorMsg}</Error>)}
    </Wrap>
  )
}

InputField.defaultProps = {
  type: InputTypeText,
}

// @ts-ignore
export default pure(InputField)
