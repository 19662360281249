import getMouth from './getMouth'

const themeColors = {
  adventurous: '#FDB43F',
  goodEater: '#4F9BE4',
  picky: '#93A8EF',
  veryPicky: '#60CFC2',
} as const

type Props = {
  className?: string
  type: keyof typeof themeColors
}

export default function BabyFace(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="44"
      viewBox="0 0 44 44"
      className={props.className}
    >
      <title>Baby face</title>
      <g fill={themeColors[props.type]} fillRule="evenodd">
        {props.type === 'adventurous' ? (
          <path
            fillRule="nonzero"
            d="M29.114 4.628l-1.977 6.406a1.02 1.02 0 0 1-.975.716h-8.324a1.02 1.02 0 0 1-.975-.716l-1.977-6.406c-.12-.388.005-.808.316-1.07.308-.258.74-.312 1.104-.138l2.453 1.176 2.473-3.75C21.426.626 21.706.5 22 .5c.294 0 .574.126.81.402l2.432 3.694 2.452-1.176c.363-.174.796-.12 1.104.139.31.261.435.681.316 1.069zm-2.635 1.624l-1.102.528c-.414.199-.91.099-1.257-.303L22 3.212l-2.164 3.323a1.026 1.026 0 0 1-1.213.245l-1.102-.528 1.072 3.473h6.814l1.072-3.473z"
          />
        ) : (
          <path
            fillRule="nonzero"
            d="M23.514 12.892c-3.491 0-6.624-2.755-6.624-6.022 0-3.328 2.305-6.37 5.817-6.37 3.464 0 5.427 2.298 5.427 5.176 0 2.007-1.682 3.63-3.743 3.63-1.773 0-3.222-1.399-3.222-3.128 0-.624.517-1.123 1.146-1.123.629 0 1.146.499 1.146 1.123 0 .482.414.882.93.882.803 0 1.451-.626 1.451-1.384 0-1.562-1.154-2.93-3.135-2.93-2.265 0-3.525 1.873-3.525 4.124 0 2.04 2.128 3.993 4.332 3.993.63 0 1.146.5 1.146 1.123 0 .674-.465.906-1.146.906z"
          />
        )}
        <g transform="translate(0 10.244)">
          <path
            fillRule="nonzero"
            d="M21.999 33.256c-7.897 0-14.87-5.034-16.9-12.14C2.52 20.88.5 18.733.5 16.13c0-2.764 2.276-5.01 5.071-5.01h.05C8.177 4.723 14.664.5 22 .5c7.336 0 13.823 4.222 16.38 10.62h.05c2.794 0 5.07 2.246 5.07 5.01 0 2.603-2.02 4.75-4.597 4.986-2.033 7.106-9.008 12.14-16.904 12.14zM5.57 13.376c-1.543 0-2.798 1.239-2.798 2.754s1.255 2.752 2.798 2.752h.418c.532 0 .995.367 1.109.884 1.457 6.502 7.722 11.236 14.9 11.236 7.18 0 13.446-4.734 14.903-11.236a1.136 1.136 0 0 1 1.109-.884h.417c1.544 0 2.798-1.236 2.798-2.752 0-1.517-1.254-2.754-2.798-2.754h-.842c-.487 0-.919-.307-1.077-.766-2.011-5.89-7.84-9.855-14.51-9.855S9.5 6.721 7.49 12.608c-.155.46-.59.768-1.076.768H5.57z"
          />
          <path
            fillOpacity="0.05"
            d="M6.603 20.86l4.95 8.896 7.184 1.246 6.491.952 5.512-3.134 5.404-5.283 2.464-3.966 3.278-1.88v-3.935L37 12.186l-6.26-7.667-6.555-1.763c-3.434-.167-5.508-.25-6.223-.25-.715 0-2.45.671-5.207 2.013L8.81 8.259l-2.208 2.982L4 12.186c-.922-.044-1.383.48-1.383 1.57v3.474C3.54 18.78 4 19.558 4 19.563c0 .005.868.438 2.603 1.298z"
          />
          {getMouth(props.type)}
          <ellipse cx="27.182" cy="18.048" fillRule="nonzero" rx="1.529" ry="1.523" />
          <ellipse cx="17.161" cy="18.048" fillRule="nonzero" rx="1.529" ry="1.523" />
        </g>
      </g>
    </svg>
  )
}
