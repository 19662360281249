import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Container = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border-bottom: solid 1px ${(props) => props.theme.color.lightGray};
  height: 80px;
  position: relative;
  width: 100%;
`

export const AdminIcon = styled.div`
  display: none;
  position: absolute;
  right: 40px;
  top: 20px;

  img {
    height: 40px;
    width: 40px;
  }

  span {
    color: ${(props) => props.theme.color.gray};
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    margin-left: 14px;
  }

  ${media.lg} {
    display: inline;
  }
`
