import { createSelector } from 'reselect'
import type { ProductDefaultsState } from './types'

const selectProductDefaults = (state: any): ProductDefaultsState => state.productDefaults

export const getProductDefaults = createSelector(
  selectProductDefaults,
  (productDefaultsState: ProductDefaultsState) => productDefaultsState.data,
)

export const selectStatus = createSelector(
  selectProductDefaults,
  (productDefaultsState: ProductDefaultsState) => productDefaultsState.status,
)
