// @ts-nocheck
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closeNotification } from '~/app/store/notifications/actions'
import { selectTopmostNotification } from '~/app/store/notifications/selectors'
import alert$ from '~/app/store/alert'
import { Notification } from './styled'

const NOTIFICATION_SHOW_DURATION = 10000

class Notifications extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { currentClosing: false }
    this.init(props)
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (this.props.notification !== next.notification) {
      this.init(next)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  setClosing = () => {
    this.setState({ currentClosing: true })
    this.timeout = setTimeout(() => {
      this.props.closeNotification()
      alert$.call.hideNotification()
      this.setState({ currentClosing: false })
    }, 200)
  }

  init = (props) => {
    if (props.notification) {
      this.timeout = setTimeout(this.setClosing, NOTIFICATION_SHOW_DURATION)
    }
  }

  handleClick = () => {
    clearTimeout(this.timeout)
    this.setClosing()
  }

  render() {
    const { notification } = this.props

    if (!notification) {
      return null
    }

    let text = ''

    if (notification.text === 'string') {
      text = notification.text
    } else if (notification.text instanceof Error) {
      text = notification.text.message
    } else {
      text = JSON.stringify(notification.text)
    }

    return (
      <Notification
        key={notification.id}
        onClick={this.handleClick}
        closing={this.state.currentClosing}
        isError={notification.text instanceof Error}
      >
        {text}
      </Notification>
    )
  }
}

Notifications.propTypes = {
  closeNotification: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
}

const mapStateToProps = (state) => ({
  notification: selectTopmostNotification(state) || alert$.getNotification(state),
})

const mapDispatchToProps = {
  closeNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
