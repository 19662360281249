import type { ProductType } from '~/app/common/types'
import usePauseController from './usePauseController'
import useCancellationController from './useCancellationController'
import usePostponeController from './usePostponeController'
import { SubmitButton, ButtonContainer } from './styled'
import ActionModals from './ActionModals'

enum PlanStatusType {
  active = 'active',
  cancelled = 'cancelled',
  incomplete = 'incomplete',
  paused = 'paused',
}

interface Props {
  status: PlanStatusType
  type: ProductType
  userId: number
  templateId: number
  parentSubscription?: number
}

export default function PlanActions(props: Props) {
  const { status, parentSubscription } = props
  const { pause, cancel, postpone } = useController(props)

  return (
    <ButtonContainer>
      {status !== PlanStatusType.active && status !== PlanStatusType.paused && (
        <SubmitButton primary onClick={cancel.handleActivationModal}>
          Restore
        </SubmitButton>
      )}

      {status === PlanStatusType.paused && (
        <SubmitButton secondary onClick={pause.handleUnPauseModal}>
          Unpause
        </SubmitButton>
      )}

      {status !== PlanStatusType.paused &&
        status !== PlanStatusType.cancelled &&
        postpone.showPostponeButton &&
        !parentSubscription && (
          // @ts-ignore
          <SubmitButton postpone onClick={postpone.handlePostponeModal}>
            Postpone
          </SubmitButton>
        )}

      {status !== PlanStatusType.paused && status !== PlanStatusType.cancelled && (
        // @ts-ignore
        <SubmitButton paused onClick={pause.handlePauseModal}>
          Pause
        </SubmitButton>
      )}

      {status !== PlanStatusType.cancelled && !parentSubscription && (
        // @ts-ignore
        <SubmitButton danger onClick={cancel.handleCancellationModal}>
          Cancel
        </SubmitButton>
      )}

      {/* @ts-ignore */}
      <ActionModals postpone={postpone} pause={pause} cancel={cancel} type={props.type} />
    </ButtonContainer>
  )
}

function useController(props: Props) {
  return {
    cancel: { ...useCancellationController(props) },
    pause: { ...usePauseController(props) },
    postpone: { ...usePostponeController(props) },
  }
}
