import { InfoTable, Header, InfoRow, LargeCell } from '~/app/components/Table'
import { MISSING_INFO_TEXT, getGender, getEatsMeat } from '~/app/utils/formatStringsforFE'
import {
  getAge,
  getChildPhase,
  getFoodOrigin,
  getEatingStyle,
  getAllergies,
  getAgeAtSignup,
  getBirthdate,
} from './dataHelpers'
import { SpacerRow, SpacerCol } from '../styled'
import EditForm from './EditForm'
interface Props {
  kid: {
    id: number
    name: string
    gender: 'male' | 'female'
    eatingStyle: 0 | 1 | 2 | 3
    allergy: 'severe' | 'mild' | 'none'
    birthdate: string
    phase: 'both' | 'finger' | 'pureed' | 'notSolids'
    foodOrigin: 'both' | 'store' | 'homemade'
    eatsMeat: boolean
  }
  userId: number
  createdAt: string
}

const defaultBirthdate = '01/01/2020'

export default function Kid({ kid, userId, createdAt }: Props) {
  return (
    <InfoTable>
      <tbody>
        <SpacerRow />
        <tr>
          <SpacerCol />
          <Header>First Name</Header>
          <Header>Gender</Header>
          <Header>BirthDate</Header>
          <Header>Age</Header>
          <Header />
          <SpacerCol />
        </tr>
        <InfoRow>
          <SpacerCol />
          <EditForm initialValues={{ name: kid.name }} type={'name'} kid={kid} userId={userId} />
          <LargeCell>{getGender(kid.gender)}</LargeCell>
          <EditForm
            initialValues={{
              birthdate: kid.birthdate ? getBirthdate(kid.birthdate) : defaultBirthdate,
            }}
            type="birthdate"
            kid={kid}
            userId={userId}
          />
          <LargeCell>{kid.birthdate ? getAge(kid.birthdate) : MISSING_INFO_TEXT}</LargeCell>
          <LargeCell>
            {kid.birthdate ? getAgeAtSignup(kid.birthdate, createdAt) : MISSING_INFO_TEXT}
          </LargeCell>
          <SpacerCol />
        </InfoRow>
      </tbody>
      <tbody>
        <SpacerRow />
        <tr>
          <SpacerCol />
          <Header colSpan={1}></Header>
          <Header colSpan={2}>Eating Habits</Header>
          <Header colSpan={3}>Mainly eats</Header>
          <SpacerCol />
        </tr>
        <InfoRow>
          <SpacerCol />
          <LargeCell></LargeCell>
          <LargeCell colSpan="2">{getChildPhase(kid.phase) || MISSING_INFO_TEXT}</LargeCell>
          <LargeCell colSpan="3">
            {kid.foodOrigin ? getFoodOrigin(kid.foodOrigin) : MISSING_INFO_TEXT}
          </LargeCell>
          <SpacerCol />
        </InfoRow>
        <tr>
          <SpacerCol />
          <Header colSpan={1}>Eating Style</Header>
          <Header colSpan={2}>Allergies</Header>
          <Header colSpan={3}>Meat</Header>
          <SpacerCol />
        </tr>
        <InfoRow>
          <SpacerCol />
          <LargeCell colSpan="1">{getEatingStyle(kid.eatingStyle) || MISSING_INFO_TEXT}</LargeCell>
          <LargeCell colSpan="2">
            {kid.allergy ? getAllergies(kid.allergy) : MISSING_INFO_TEXT}
          </LargeCell>
          <LargeCell colSpan="3">{getEatsMeat(kid.eatsMeat)}</LargeCell>
          <SpacerCol />
        </InfoRow>
      </tbody>
    </InfoTable>
  )
}
