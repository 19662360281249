import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function fetchDietaryTags(store: any, self$: any) {
  return async (): Promise<void> => {
    await self$.call.setStatus('loading')
    try {
      const response = await Api.fetchDietaryTags()
      await store.dispatch(self$.setDietaryTags(response.data))
      await self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

export function editDietaryTag(store: any, self$: any) {
  return async (payload: { id: number; data: { name: string } }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.editDietaryTag(payload.id, payload.data)
      const response = await Api.fetchDietaryTags()
      self$.call.setDietaryTags(response.data)
      self$.call.setStatus('loaded')
      alert$.call.setNotification('Successfully edited dietary tag')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

export function createDietaryTag(store: any, self$: any) {
  return async (payload: {
    data: { name: string }
    onSuccess: (id: number) => void
  }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const { id } = await Api.createDietaryTag(payload.data)
      const response = await Api.fetchDietaryTags()
      self$.call.setDietaryTags(response.data)
      self$.call.setStatus('loaded')
      payload.onSuccess(id)
      alert$.call.setNotification('Successfully created new dietary tag')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

export function deleteDietaryTag(store: any, self$: any) {
  return async (id: number): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.deleteDietaryTag(id)
      const response = await Api.fetchDietaryTags()
      self$.call.setDietaryTags(response.data)
      self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}
