import user$ from '~/app/store/user'
import { useMappedState } from '../useReduxStore'

export enum FeatureFlag {
  enable_cartonization = 'enable_cartonization',
  enable_cadence_sync = 'enable_cadence_sync',
  enable_weekly_orders = 'enable_weekly_orders',
}

/**
 * Checks whether a feature flag is enabled for user.
 */
export default function useFeatureFlag(featureFlagName: string): boolean {
  const userFeatureFlags = useMappedState((state: Record<string, any>) =>
    user$.getUserFeatureFlags(state),
  )
  if (!userFeatureFlags?.data) {
    return false
  }
  return userFeatureFlags.data[featureFlagName]
}

/**
 * Checks whether feature flag 'enable_cadence_sync' is enabled.
 */
export function useFeatureFlagEnableCadenceSync(): boolean {
  return useFeatureFlag(FeatureFlag.enable_cadence_sync)
}

/**
 * Checks whether feature flag 'enable_weekly_orders' is enabled.
 */
export function useFeatureFlagEnableWeeklyOrders(): boolean {
  return useFeatureFlag(FeatureFlag.enable_weekly_orders)
}
