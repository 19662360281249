import { combineReducers } from 'redux'

import alert$ from '~/app/store/alert'
import allergens$ from '~/app/store/allergens'
import appReducer from '~/app/store/app/reducer'
import blueprintsReducer from '~/app/store/blueprints/reducer'
import boxesByStates$ from '~/app/store/boxesByStates'
import cancellationReasons$ from '~/app/store/cancellationReasons'
import creditReasons$ from '~/app/store/creditReasons'
import dashboardReducer from '~/app/store/dashboardOld/reducer'
import dietaryTags$ from '~/app/store/dietaryTags'
import deliveryInfoReducer from '~/app/pages/User/UserInfo/DeliveryInfo/reducer'
import faultyOrderReasons$ from '~/app/store/faultyOrderReasons'
import formFactors$ from '~/app/store/formFactors'
import filterReducer from '~/app/store/filters/reducer'
import ingredientsReducer from '~/app/store/ingredients/reducer'
import labels$ from '~/app/store/labels'
import loader$ from '~/app/store/loader'
import notificationsReducer from '~/app/store/notifications/reducer'
import paccurateConfig$ from '~/app/store/paccurateConfig'
import plans$ from '~/app/store/plans'
import pricingGroup$ from '~/app/store/pricingGroup'
import productsReducer from '~/app/store/products/reducer'
import promocodesReducer from '~/app/store/promocodes/reducer'
import routeReducer from '~/app/store/route/reducer'
import shippingBoxes$ from '~/app/store/shippingBoxes'
import symbolsReducer from '~/app/store/symbols/reducer'
import usersReducer from '~/app/store/users/reducer'
import userOrdersReducer from '~/app/pages/User/UserOrders/reducer'
import warehousesReducer from '~/app/store/warehouses/reducer'
import user$ from '~/app/store/user'
import promoBanners$ from '~/app/store/promoBanners'
import productDefaults$ from '~/app/store/productDefaults'

export default function createReducer(injectedReducers: any) {
  return combineReducers({
    alert: alert$.reducer,
    allergens: allergens$.reducer,
    app: appReducer,
    blueprints: blueprintsReducer,
    boxesByStates: boxesByStates$.reducer,
    cancellationReasons: cancellationReasons$.reducer,
    creditReasons: creditReasons$.reducer,
    dashboardOld: dashboardReducer,
    deliveryInfo: deliveryInfoReducer,
    dietaryTags: dietaryTags$.reducer,
    faultyOrderReasons: faultyOrderReasons$.reducer,
    filter: filterReducer,
    formFactors: formFactors$.reducer,
    ingredients: ingredientsReducer,
    labels: labels$.reducer,
    notifications: notificationsReducer,
    paccurateConfig: paccurateConfig$.reducer,
    plans: plans$.reducer,
    pricingGroup: pricingGroup$.reducer,
    products: productsReducer,
    promocodes: promocodesReducer,
    route: routeReducer,
    shippingBoxes: shippingBoxes$.reducer,
    showAppLoader: loader$.reducer,
    symbols: symbolsReducer,
    user: user$.reducer,
    userOrders: userOrdersReducer,
    users: usersReducer,
    warehouses: warehousesReducer,
    promoBanners: promoBanners$.reducer,
    productDefaults: productDefaults$.reducer,
    ...injectedReducers,
  })
}
