import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { PromoBanner } from '~/app/common/types'

/**
 * fetches all promo banners
 */
export function fetchPromoBanners(store: any, self$: any) {
  return async (): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const response = await Api.fetchPromoBanners()
      self$.call.setPromoBanners(response)
      self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

/**
 * edits promo banner
 *
 * @param store - Root store
 * @param self$ - promoBanners store
 */
export function editPromoBanner(store: any, self$: any) {
  return async (payload: { id: number; data: PromoBanner }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.editPromoBanner(payload.id, payload.data)
      const response = await Api.fetchPromoBanners()
      self$.call.setPromoBanners(response)
      self$.call.setStatus('loaded')
      alert$.call.setNotification('Successfully edited promo banner')
    } catch (err: any) {
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

/**
 * creates promo banner
 *
 * @param store - Root store
 * @param self$ - promoBanners store
 */
export function createPromoBanner(store: any, self$: any) {
  return async (payload: { data: PromoBanner; onSuccess: (id: number) => void }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const { id } = await Api.createPromoBanner(payload.data)
      const response = await Api.fetchPromoBanners()
      self$.call.setPromoBanners(response)
      self$.call.setStatus('loaded')
      payload.onSuccess(id)
      alert$.call.setNotification('Successfully created new promo banner')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}
