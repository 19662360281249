import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
`

export const StyledInput = styled.input`
  background: none;
  border: solid 1px ${(props) => props.theme.color.lightGray};
  border-radius: 4px;
  box-shadow: none;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  height: 50px;
  padding: 0 18px;
  &::placeholder {
    color: ${(props) => props.theme.color.gray};
  }
`
