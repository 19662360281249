import * as React from 'react'

const useLocalContext = <D extends Record<string, unknown>>(data: D): D => {
  const [ctx] = React.useState({})
  Object.keys(data).forEach((key) => {
    // @ts-expect-error TODO: Fix error
    ctx[key] = data[key]
  })
  return ctx as any
}

export default useLocalContext
