import Checkmark from './Checkmark'
import Crossmark from './Crossmark'

type Props = {
  className: string | undefined
  value: string
}

export default function Allergies(props: Props) {
  switch (props.value) {
    case 'none':
      return <Checkmark {...props} theme="ok" />
    case 'mild':
      return <Checkmark {...props} theme="caution" />
    case 'severe':
      return <Crossmark {...props} />
    default:
      return null
  }
}
