import queryString from 'query-string'
import { merge } from 'ramda'
import {
  FETCH_INGREDIENTS,
  FETCH_INGREDIENTS_SUCCESS,
  FETCH_INGREDIENTS_FAIL,
  CREATE_INGREDIENT_SUCCESS,
  FETCH_INGREDIENT_DETAIL,
  FETCH_INGREDIENT_DETAIL_SUCCESS,
  EDIT_INGREDIENT_SUCCESS,
  DELETE_INGREDIENT_SUCCESS,
  UPDATE_INGREDIENTS_PAGE,
} from './constants'

const getCurrentPage = () => Number(queryString.parse(location.search)['ingredient-page']) || 1

const initialState = {
  activePage: getCurrentPage(),
  data: [],
  dataIsUpdated: true,
  dataLoaded: false,
  dataLoading: false,
  source: null,
}

function ingredientsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INGREDIENTS:
      return merge(state, {
        dataLoaded: false,
        dataLoading: true,
        source: action.payload.source,
      })

    case FETCH_INGREDIENTS_SUCCESS:
      return merge(state, {
        data: action.payload.data,
        dataIsUpdated: true,
        dataLoaded: true,
        dataLoading: false,
      })

    case FETCH_INGREDIENTS_FAIL:
      return merge(state, {
        data: [],
        dataLoaded: false,
        dataLoading: false,
      })

    case CREATE_INGREDIENT_SUCCESS:
    case EDIT_INGREDIENT_SUCCESS:
      return merge(state, {
        dataIsUpdated: false,
        detail: action.payload.data,
      })

    case DELETE_INGREDIENT_SUCCESS:
      return merge(state, {
        dataIsUpdated: false,
      })

    case FETCH_INGREDIENT_DETAIL:
      return merge(state, {
        detailLoaded: false,
        detailLoading: true,
      })

    case FETCH_INGREDIENT_DETAIL_SUCCESS:
      return merge(state, {
        detail: action.payload.data,
        detailLoaded: true,
        detailLoading: false,
      })

    case UPDATE_INGREDIENTS_PAGE:
      return merge(state, {
        activePage: getCurrentPage(),
      })

    default:
      return state
  }
}

export default ingredientsReducer
