import Button from '~/app/components/Button/Button'
import OverlayWindow from '~/app/components/OverlayWindow'
import CloseBtn from '../CloseBtn'
import { Content, IconWrapper, Title, Text, Footer, SecondaryButton } from './styled'

interface Props {
  slim?: boolean
  icon?: any
  title: string
  text?: string
  mainBtn: string
  secondaryBtn?: string
  isOpen?: boolean
  onMainBtnClick: () => void
  onSecondaryBtnClick: () => void
  onClose?: () => void
  disableBtn?: boolean
  children?: any
  small?: boolean
  danger?: boolean
  largeButtons?: boolean
  paused?: boolean
  postpone?: boolean
  pending?: boolean
}

export default function Modal(props: Props) {
  return (
    // @ts-ignore
    <OverlayWindow isOpen={Boolean(props.isOpen)}>
      {/* @ts-ignore */}
      <Content slim={props.slim} small={props.small}>
        {props.onClose && <CloseBtn onClick={props.onClose} />}
        {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
        {/* @ts-ignore */}
        {props.title && <Title slim>{props.title}</Title>}
        {props.text && <Text>{props.text}</Text>}
        {props.children}
        {/* @ts-ignore */}
        <Footer slim={props.slim}>
          {props.secondaryBtn && (
            <SecondaryButton
              onClick={props.onSecondaryBtnClick}
              secondary
              // @ts-ignore
              large={props.largeButtons}
              big={!props.largeButtons}
            >
              {props.secondaryBtn}
            </SecondaryButton>
          )}
          {props.mainBtn && (
            <Button
              primary={!props.danger && !props.paused && !props.pending && !props.postpone}
              danger={props.danger}
              // @ts-ignore
              paused={props.paused}
              postpone={props.postpone}
              pending={props.pending}
              onClick={props.onMainBtnClick}
              disabled={props.disableBtn}
              large={props.largeButtons}
              big={!props.largeButtons}
            >
              {props.mainBtn}
            </Button>
          )}
        </Footer>
      </Content>
    </OverlayWindow>
  )
}
