import { ProductType } from '~/app/common/types'

const productTypeSlug = {
  [ProductType.biteable]: 'biteables',
  [ProductType.blend]: 'babyblends',
  [ProductType.cereal]: 'cereal',
  [ProductType.luncher]: 'lunchers',
  [ProductType.misc]: 'misc',
  [ProductType.plate]: 'plates',
  [ProductType.smoothie]: 'smoothies',
  [ProductType.snack]: 'snacks',
  [ProductType.puff]: 'puffs',
}

export default productTypeSlug
