import styled from 'styled-components'
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
export const Balance = styled.div`
  font-size: 16px;
  padding: 10px;
  font-weight: 500;
  color: ${(props) => props.theme.color.green};
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 10px;
`

export const Title = styled.h3`
  color: ${(props) => props.theme.color.darkGray};
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.5px;
  margin: 0 0 0 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`
