import { compose } from 'recompose'
import withHandlers from '~/app/hocs/withHandlers'
export default compose(
  withHandlers({
    handleConfirm:
      ({ serialize, onConfirm }) =>
      () => {
        serialize((formState) => {
          onConfirm({
            shipping: {
              address: formState.shippingAddress,
              apt: formState.shippingApt,
              city: formState.shippingCity,
              firstName: formState.shippingFirstName,
              lastName: formState.shippingLastName,
              state: formState.shippingState,
              zip: formState.shippingZip,
            },
          })
        })
      },
  }),
)
