import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import { fetchSalesTotalsSuccess } from './actions'
import { FETCH_TOTAL_SALES } from './constants'

const fetchTotalSalesEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_TOTAL_SALES).switchMap((action) =>
    Observable.fromPromise(
      Api.fetchOrderTotals({
        fromDate: action.payload.fromDate,
        salesOrderType: action.payload.salesOrderType,
        toDate: action.payload.toDate,
        totalType: 'sales',
        viewType: action.payload.viewType,
      }),
    )
      .map((result) =>
        fetchSalesTotalsSuccess({
          data: result,
          salesOrderType: action.payload.salesOrderType,
        }),
      )
      .catch((e) => Observable.of(showNotification(e.message))),
  )

export default [fetchTotalSalesEpic]
