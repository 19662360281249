interface Props {
  onClick?: () => void
  className?: string
  fillColor?: string
}

export default function CerealIcon(props: Props) {
  return (
    <svg
      viewBox="0 0 444.05 459.33"
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      className={props.className}
      onClick={props.onClick}
      style={{ fill: props.fillColor }}
    >
      <title>Cereal Icon</title>
      <desc>Baby cereal bowl with spoon on top</desc>
      <defs>
        <clipPath id="clippath">
          <circle fill="none" cx="206.32" cy="206.32" r="206.3" />
        </clipPath>
      </defs>
      <g>
        <g clipPath="url(#clippath)">
          <ellipse cx="204.77" cy="206.32" rx="206.31" ry="206.31" />
          <circle fill="white" cx="204.77" cy="206.32" r="182.94" />
          <path
            stroke={props.fillColor}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="7px"
            fill="none"
            d="M282.7,65.1c-29.68-16.38-63.78-22.99-97.43-18.9C97.44,56.89,33.96,137.97,44.65,225.81c6.4,52.62,38.36,98.84,85.32,123.42"
          />
          <path
            stroke={props.fillColor}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="7px"
            fill="none"
            d="M111.71,261.41c16.16,48.88,61.56,81.29,111.25,79.41,61.91-2.35,110.84-57.18,108.4-121.47-1.26-33.33-16.16-64.6-40.91-85.91"
          />
          <path
            stroke={props.fillColor}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="7px"
            fill="none"
            d="M269.25,183.05c-7.96-28.14-32.73-48.48-61.88-50.82-38.35-3.08-72.45,25.95-75.53,64.3s25.95,72.45,64.3,75.53"
          />
          <g>
            <path
              fill="white"
              d="M220.05,464.33c-5.66,0-11.12-1.59-16.23-4.71-22.01-13.47-20.55-32.11-16.39-39.03,2.44-4.06,57.29-91.82,101.36-162.35,24.72-39.55,46.06-73.71,46.73-74.81,1.2-2,10.62-20.32,12.48-33.29l.22-1.55c2.1-14.95,6.48-46.07,39.14-63.3,7.4-3.91,14.77-5.89,21.92-5.89,11.21,0,21.54,4.95,29.08,13.95,6.8,8.11,10.69,18.79,10.68,29.32-.02,23.59-14.86,48.49-38.73,64.97-16.8,11.6-24.62,26.31-27.96,32.59-.55,1.03-.98,1.85-1.37,2.49-1.58,2.63-57.78,99.61-94.97,163.78-23.94,41.3-36.44,62.88-36.7,63.3-1.04,1.73-13.07,14.52-29.27,14.52Z"
            />
            <path
              fill="white"
              d="M409.28,84.41c20.97,0,34.78,19.67,34.77,38.26-.02,23.91-16.13,46.75-36.57,60.86-20.44,14.12-28.31,32.51-30.77,36.62-2.46,4.1-130.96,225.91-131.67,227.08-.54.89-11.23,12.1-24.98,12.1-4.31,0-8.93-1.1-13.62-3.98-19.65-12.03-17.53-27.5-14.71-32.19,3.87-6.44,146.68-234.8,148.09-237.15,1.41-2.34,11.15-21.21,13.15-35.16,2-13.95,5.21-44.5,36.74-61.14,7.01-3.7,13.59-5.31,19.59-5.31M409.28,74.41v10-10c-7.97,0-16.13,2.18-24.26,6.46-15.11,7.97-26.32,19.76-33.31,35.02-5.68,12.4-7.34,24.2-8.44,32.01l-.22,1.54c-1.72,11.98-10.68,29.5-11.83,31.43-.81,1.33-22.01,35.26-46.57,74.54-44.13,70.62-99.06,158.5-101.52,162.6-2.59,4.32-4.53,12.11-2.16,21.05,1.79,6.77,6.75,16.57,20.23,24.82,5.91,3.62,12.25,5.45,18.84,5.45,17.52,0,31.4-13.36,33.55-16.95.26-.44,9.71-16.73,36.63-63.19,37.18-64.16,93.37-161.12,95.04-163.89.45-.75.91-1.62,1.5-2.72,3.17-5.96,10.59-19.91,26.39-30.82,25.21-17.41,40.87-43.88,40.89-69.09,0-11.68-4.31-23.54-11.85-32.54-8.51-10.15-20.2-15.73-32.91-15.73h0Z"
            />
          </g>
        </g>
        <g>
          <path d="M191.71,423.16c3.87-6.44,146.68-234.8,148.09-237.15s11.15-21.21,13.15-35.16c2-13.95,5.21-44.5,36.74-61.14s54.37,9.04,54.35,32.96c-.02,23.91-16.13,46.75-36.57,60.86-20.44,14.12-28.31,32.52-30.77,36.62-2.46,4.1-130.96,225.91-131.67,227.08s-18.95,20.15-38.6,8.12c-19.65-12.03-17.53-27.5-14.71-32.19Z" />
          <path
            fill="white"
            d="M364.23,156.84c0-.24,0-.49,0-.72.06-7.85-.54-16.43,1.1-24.12,1.48-6.93,5.47-13.38,9.98-18.72,8.93-10.57,24.51-18.27,38.51-17.27,17.74,1.26,21.01,19.45,19.84,34.35-.43,5.49-1.85,10.97-4.48,15.83-1.03,1.89-1.94,4.28-3.79,5.54-.31.21-.67.4-1.04.36-.83-.09-1.11-1.15-1.17-1.99-.6-8.47,4.03-26.94-7.37-30.22-2.48-.72-5.15-.14-7.58.75-6.69,2.46-12.84,7.17-17.14,12.84-4.62,6.09-4.86,13.38-7.01,20.45-1.32,4.34-3.51,8.41-6.39,11.9-1.21,1.46-2.59,2.86-4.34,3.61-8.04,3.42-9.18-7.51-9.12-12.57Z"
          />
        </g>
      </g>
    </svg>
  )
}
