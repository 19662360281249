// Blends Icons
import BlendsSmallPlanIcon from './blends/SmallPlanIcon'
import BlendsMediumPlanIcon from './blends/MediumPlanIcon'
import BlendsBigPlanIcon from './blends/BigPlanIcon'

// Plates Icons
import PlatesSmallPlanIcon from './plates/SmallPlanIcon'
import PlatesMediumPlanIcon from './plates/MediumPlanIcon'
import PlatesBigPlanIcon from './plates/BigPlanIcon'

import SmoothieIcon from '../ProductTypeIcon/SmoothieIcon'
import LuncherIcon from '../ProductTypeIcon/LuncherIcon'
import SnackIcon from '../ProductTypeIcon/SnackIcon'
import PuffIcon from '../ProductTypeIcon/PuffIcon'
import BiteableIcon from '../ProductTypeIcon/BiteableIcon'

const icons = {
  blend: {
    big: <BlendsBigPlanIcon />,
    medium: <BlendsMediumPlanIcon />,
    small: <BlendsSmallPlanIcon />,
  },
  plate: {
    big: <PlatesBigPlanIcon />,
    medium: <PlatesMediumPlanIcon />,
    small: <PlatesSmallPlanIcon />,
  },
  smoothie: {
    big: <SmoothieIcon />,
    medium: <SmoothieIcon />,
    small: <SmoothieIcon />,
  },
  luncher: {
    big: <LuncherIcon />,
    medium: <LuncherIcon />,
    small: <LuncherIcon />,
  },
  biteable: {
    big: <BiteableIcon />,
    medium: <BiteableIcon />,
    small: <BiteableIcon />,
  },
  snack: {
    big: <SnackIcon />,
    medium: <SnackIcon />,
    small: <SnackIcon />,
  },
  puff: {
    big: <PuffIcon />,
    medium: <PuffIcon />,
    small: <PuffIcon />,
  },
} as const

export default icons
