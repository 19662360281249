import PropTypes from 'prop-types'
import { ListWrapper, Section, SectionName } from './styled'
import SubscriptionsList from './SubscriptionsList'

type $Props = {
  kid: any
  plans: Array<{
    id: number
    mealsPerDelivery: number
    mealsPerDay: number
    groupName: string
  }>
  user: any
}

export default function Plans({ kid, plans, user }: $Props) {
  return (
    <ListWrapper>
      <Section>
        <SectionName>Plans</SectionName>
      </Section>
      <SubscriptionsList kid={kid} plans={plans} user={user} />
    </ListWrapper>
  )
}

Plans.propTypes = {
  kid: PropTypes.any,
  plans: PropTypes.array,
  user: PropTypes.any,
}
