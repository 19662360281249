import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Content = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.midGray};
  padding: 0 1rem 2.5rem;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.darkGray};
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
  }
  width: 100rem;
`

export const Header = styled.header`
  padding: 2.5rem 2.5rem 0;
  position: relative;
  border-radius: inherit;
`

export const Heading = styled.h2<{ noTopMargin?: boolean }>`
  font-size: 1.875rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: ${({ noTopMargin }) => (noTopMargin ? '0' : '70px')};
  margin-bottom: 0.8rem;
`

export const SubTitle = styled.h6`
  font-size: 11px;
  margin-top: 0;
  color: ${(props) => props.theme.color.darkGray};
`

export const Products = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  font-size: 1.5rem;
`

export const Product = styled.li`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const ButtonsRow = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const PlusButton = styled.button`
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background-color: ${(props) => props.theme.color.green};
  border: none;
  color: ${(props) => props.theme.color.white};
`

export const MinusButton = styled(PlusButton)`
  background-color: ${(props) => props.theme.color.red};
`

export const Info = styled.p`
  font-size: 1.5rem;
`

export const ImageUploadSection = styled.div`
  margin-bottom: 5rem;
`

export const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 0.1rem solid #ccc;
  padding: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  border-radius: 0.5rem;
`

export const ImageWrapper = styled.a`
  flex: 1 1 calc(30% - 1rem);
  max-width: calc(30% - 1rem);
  box-sizing: border-box;
  border: 0.1rem solid #ccc;
  overflow: hidden;
  background: #f0f0f0;

  img {
    width: 100%;
    height: auto;
  }
`
