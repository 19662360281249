import styled, { keyframes } from 'styled-components'
import { theme } from '~/app/settings/colors'

export const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
`

export const Wrap = styled.div`
  div {
    background-color: ${(props: any) => (props.secondary ? theme.color.green : theme.color.white)};
  }
`

export const LoaderDot3 = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
`

export const LoaderDot1 = styled(LoaderDot3)`
  animation-delay: -0.32s;
`

export const LoaderDot2 = styled(LoaderDot3)`
  animation-delay: -0.16s;
`
