const iconSizes = {
  mealsPerDay: {
    1: 'small',
    2: 'medium',
    3: 'big',
  },
  mealsPerDelivery: {
    12: 'small',
    18: 'medium',
    24: 'big',
    8: 'small',
    7: 'small',
    11: 'small',
    14: 'small',
    5: 'small',
    9: 'small',
    15: 'small',
    6: 'small',
    10: 'small',
  },
} as const

export default iconSizes
