import { useEffect, useState } from 'react'
import Button from '~/app/components/Button/Button'
import { CenteredRow, ThreeFourth } from '~/app/components/FormGrid'
import { Order } from '../types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import {
  Products,
  Product,
  ButtonsRow,
  PlusButton,
  MinusButton,
  RefundSummary,
  TitleText,
  CustomRefundWrapper,
} from './styled'
import user$ from '~/app/store/user'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { OrderRefundsState, RootState } from '~/app/store/user/orderRefunds/types'
import useForm from '~/app/hooks/useForm'
import formDefinition from './formDefinition'

interface Props {
  order: Order
  onSubmit: (payload: {
    products: { id: number; count: number }[]
    customRefundReason?: string
    customRefundAmount?: number
  }) => void
  isLoading: boolean
}

export default function RefundOrderForm(props: Props) {
  const self = useController(props)

  if (self.productsToRefund.length === 0) {
    return <div>No products left to refund</div>
  }

  return (
    <div>
      <CenteredRow>
        <ThreeFourth>
          <TitleText>Products to refund</TitleText>
          <Products>
            {self.productsToRefund.map((product, index) => (
              <Product key={product.sku}>
                <span>
                  {self.productsToRefund[index].count}
                  {`/${product.maxCount} `}
                  {self.productsByIdOrSku?.[product.sku]?.name ?? product.sku}
                </span>

                <ButtonsRow>
                  {/* @ts-ignore */}
                  <MinusButton
                    disabled={self.formState.isCustomRefund.value}
                    onClick={() => self.decreaseProductCount(index)}
                  >
                    -
                  </MinusButton>
                  <PlusButton
                    disabled={self.formState.isCustomRefund.value}
                    onClick={() => self.increaseProductCount(index)}
                  >
                    +
                  </PlusButton>
                </ButtonsRow>
              </Product>
            ))}
          </Products>
          {self.orderRefundsSummary && (
            <RefundSummary>
              <TitleText>Refund summary</TitleText>
              <TitleText>
                {self.orderRefundsSummary.error
                  ? self.orderRefundsSummary.error
                  : `$${convertCentsToDollars(self.orderRefundsSummary.amount)}`}
              </TitleText>
            </RefundSummary>
          )}
          <Button secondary small onClick={self.selectAllProducts} disabled={props.isLoading}>
            Select All
          </Button>
        </ThreeFourth>
      </CenteredRow>
      <CenteredRow>
        <ThreeFourth>
          <CustomRefundWrapper>
            {self.renderField('isCustomRefund', {
              value: self.formState.isCustomRefund.value,
            })}
            {self.formState.isCustomRefund.value && (
              <>
                {self.renderField('customRefundAmount', {
                  value: self.formState.customRefundAmount.value,
                })}
                {self.renderField('customRefundReason', {
                  value: self.formState.customRefundReason.value,
                })}
              </>
            )}
          </CustomRefundWrapper>
        </ThreeFourth>
      </CenteredRow>
      <CenteredRow>
        <Button
          primary
          large
          onClick={self.handleConfirm}
          disabled={props.isLoading || (self.formState.isCustomRefund.value && !self.isValid)}
        >
          Submit
        </Button>
      </CenteredRow>
    </div>
  )
}

function useController(props: Props) {
  // @ts-ignore
  const form = useForm({
    fieldDefinitions: formDefinition,
  })
  const { productsByIdOrSku, orderRefundsSummary, orderRefunds } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
    orderRefundsSummary: state.user.orderRefunds.summary,
    orderRefunds: state.user.orderRefunds as RootState['orderRefunds'],
  }))

  const {
    productsToRefund,
    setProductsToRefund,
    decreaseProductCount,
    increaseProductCount,
    selectAllProducts,
    resetProductsToRefund,
  } = useProductsToRefundController(
    props,
    productsByIdOrSku,
    orderRefunds,
    form.formState.isCustomRefund.value,
  )

  const handleConfirm = () => {
    const formattedRefundProducts = productsToRefund
      .filter((product) => product.count > 0)
      .map((product) => ({
        id: product.id,
        count: product.count,
      }))

    if (formattedRefundProducts.length === 0 && !form.formState.isCustomRefund.value) {
      return alert('Please select at least one product.')
    }

    resetProductsToRefund()

    props.onSubmit({
      products: formattedRefundProducts,
      customRefundReason: form.formState.customRefundReason.value,
      customRefundAmount: form.formState.customRefundAmount.value,
    })
  }

  return {
    ...form,
    handleConfirm,
    productsToRefund,
    setProductsToRefund,
    decreaseProductCount,
    increaseProductCount,
    productsByIdOrSku,
    selectAllProducts,
    orderRefundsSummary,
  }
}

function useProductsToRefundController(
  props: Props,
  productsByIdOrSku: any,
  orderRefunds: OrderRefundsState,
  isCustomRefund: boolean,
) {
  const getInitialProductsToRefund = () => {
    const refundedProductCounts = (orderRefunds.data ?? []).reduce(
      (acc, refund) => {
        refund.products?.forEach((product: { id: number; count: number }) => {
          acc[product.id] = (acc[product.id] || 0) + product.count
        })
        return acc
      },
      {} as Record<number, number>,
    )

    const products = props.order?.products ?? []
    const addOnProducts = props.order?.addOns.flatMap((addOn) => addOn.products) ?? []

    return [...products, ...addOnProducts]
      .filter((product) => {
        const refundedCount = refundedProductCounts[product.id] || 0
        return refundedCount < product.count
      })
      .map((product) => {
        const refundedCount = refundedProductCounts[product.id] || 0
        const remainingCount = product.count - refundedCount
        return {
          id: product.id,
          sku: productsByIdOrSku[product.id]?.sku ?? product.id,
          count: 0,
          maxCount: remainingCount,
        }
      })
  }

  const [productsToRefund, setProductsToRefund] = useState(getInitialProductsToRefund())

  const decreaseProductCount = (index: number) => {
    if (isCustomRefund) {
      return
    }

    const updatedProducts = [...productsToRefund]
    if (updatedProducts[index].count === 0) {
      return
    }

    const product = updatedProducts[index]
    updatedProducts[index] = { ...product, count: product.count - 1 }
    setProductsToRefund(updatedProducts)
  }

  const increaseProductCount = (index: number) => {
    if (isCustomRefund) {
      return
    }

    const updatedProducts = [...productsToRefund]
    if (updatedProducts[index].count === updatedProducts[index].maxCount) {
      return
    }

    const product = updatedProducts[index]
    updatedProducts[index] = { ...product, count: product.count + 1 }
    setProductsToRefund(updatedProducts)
  }

  const selectAllProducts = () => {
    if (isCustomRefund) {
      return
    }

    setProductsToRefund(
      getInitialProductsToRefund().map((product) => ({ ...product, count: product.maxCount })),
    )
  }

  const resetProductsToRefund = () => {
    setProductsToRefund(getInitialProductsToRefund())
  }

  useEffect(() => {
    user$.call.fetchOrderRefundSummary({
      orderId: props.order.id,
      products: productsToRefund
        .filter((product) => product.count > 0)
        .map((product) => ({
          id: product.id,
          count: product.count,
        })),
    })
  }, [props.order.id, productsToRefund])

  useEffect(() => {
    if (isCustomRefund) {
      resetProductsToRefund()
    }
  }, [isCustomRefund])

  return {
    productsToRefund,
    setProductsToRefund,
    decreaseProductCount,
    increaseProductCount,
    selectAllProducts,
    resetProductsToRefund,
  }
}
