type Props = {
  className?: string
}

export default function BabyBottle(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="39"
      viewBox="0 0 39 39"
      className={props.className}
    >
      <title>Baby bottle</title>
      <defs>
        <circle id="BabyBottle__circle" cx="28" cy="28" r="28" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-10 -10)">
        <mask id="BabyBottle__mask" fill="#fff">
          <use xlinkHref="#BabyBottle__circle" />
        </mask>
        <g mask="url(#BabyBottle__mask)">
          <path
            fill="#77D3E1"
            d="M28.272 19.055c-9.808 9.454-15.058 14.597-15.752 15.427-.693.83-.693 1.975 0 3.433 5.917 6.058 9.343 9.386 10.277 9.982.934.597 2.075.185 3.423-1.234 9.147-9.193 14.076-14.265 14.787-15.217.71-.952.464-2.155-.739-3.61L31.168 18l-2.896 1.055z"
          />
          <path
            fill="#F7D148"
            d="M37.621 16.764l6.523 6.739.856-3.894c1.99-2.083 3.065-3.62 3.224-4.609.16-.99-.507-1.99-2-3l-3.301 3-3.678 1-1.624.764z"
          />
          <path
            fill="#302D1F"
            fillRule="nonzero"
            d="M46.1 10.827a2.825 2.825 0 0 0-2.005-.826c-.758 0-1.47.293-2.006.826l-.731.728c-1.214 1.207-1.993 1.374-2.655 1.374-.242 0-.483-.024-.738-.05a8.544 8.544 0 0 0-.86-.055c-.95 0-1.776.308-2.61.984a3.018 3.018 0 0 0-1.781-.575 3.013 3.013 0 0 0-3.024 3.006 4.904 4.904 0 0 0-1.25-.162 4.805 4.805 0 0 0-3.413 1.402L11.41 31.028a4.791 4.791 0 0 0 0 6.792l7.564 7.526a4.804 4.804 0 0 0 3.413 1.402 4.804 4.804 0 0 0 3.413-1.402l13.615-13.548a4.758 4.758 0 0 0 1.41-3.396c0-.426-.057-.844-.164-1.244a3.013 3.013 0 0 0 3.021-3.01c0-.647-.203-1.262-.578-1.774 1.138-1.397 1.027-2.524.934-3.45-.1-1.001-.187-1.866 1.33-3.377l.732-.727a2.817 2.817 0 0 0 0-3.993zM20.064 44.264L12.5 36.737a3.264 3.264 0 0 1 0-4.627l13.072-13.007 2.002 1.993a.769.769 0 0 0 1.088 0c.3-.3.3-.784 0-1.083l-1.937-1.928a3.29 3.29 0 0 1 1.716-.476c.88 0 1.706.338 2.324.953l7.564 7.527a3.24 3.24 0 0 1 .958 2.313c0 .614-.167 1.2-.479 1.707l-3.026-3.011a.772.772 0 0 0-1.088 0c-.3.299-.3.784 0 1.082l3.091 3.077-2.316 2.304-3.09-3.076a.772.772 0 0 0-1.089 0c-.3.299-.3.784 0 1.082l3.091 3.077-2.315 2.304-3.091-3.076a.772.772 0 0 0-1.089 0c-.3.299-.3.784 0 1.083l3.092 3.076-2.316 2.304-3.092-3.076a.772.772 0 0 0-1.088 0c-.3.299-.3.784 0 1.083l3.092 3.076-2.316 2.304-3.512-3.495a.772.772 0 0 0-1.088 0c-.3.299-.3.784 0 1.083l3.447 3.43c-.51.31-1.1.477-1.716.477a3.272 3.272 0 0 1-2.325-.953zm21.648-19.068a1.48 1.48 0 0 1-1.052.43c-.399 0-.772-.152-1.052-.43l-7.946-7.907a1.466 1.466 0 0 1-.433-1.047 1.48 1.48 0 0 1 1.485-1.478c.4 0 .774.154 1.053.431l.185.184s0 .002.002.002l7.572 7.535.002.002.185.184c.279.278.432.65.432 1.047 0 .397-.153.769-.433 1.047zm3.302-11.459l-.731.728c-2.034 2.023-1.89 3.458-1.775 4.611.078.77.137 1.364-.474 2.18l-6.412-6.38c.606-.447 1.07-.52 1.484-.52.225 0 .46.023.707.047.281.028.572.057.89.057 1.323 0 2.477-.562 3.744-1.822l.73-.728c.246-.244.572-.378.919-.378a1.293 1.293 0 0 1 .918 2.205z"
          />
        </g>
      </g>
    </svg>
  )
}
