import { merge } from 'ramda'
import {
  INITIALIZE_SUCCESS,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
} from './constants'
const initialState = {
  initialized: false,
  loggedIn: false,
  loggingIn: false,
  user: null,
}

function appReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_SUCCESS:
      return merge(state, {
        initialized: true,
      })

    case LOGIN:
    case REFRESH_TOKEN:
      return merge(state, {
        loggingIn: true,
      })

    case LOGIN_SUCCESS:
      return merge(state, {
        loggedIn: true,
        loggingIn: false,
        user: action.payload,
      })

    case LOGIN_FAILURE:
    case REFRESH_TOKEN_FAILURE:
      return merge(state, {
        loggingIn: false,
      })

    case LOGOUT:
      return merge(initialState, {
        initialized: true,
      })

    case REFRESH_TOKEN_SUCCESS:
      return merge(state, {
        loggedIn: true,
        loggingIn: false,
      })

    default:
      return state
  }
}

export default appReducer
