export default function PagingNextIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <title>Next Page</title>
      <defs>
        <rect id="b" width="24" height="24" rx="4" />
        <filter
          x="-20.8%"
          y="-16.7%"
          width="141.7%"
          height="141.7%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="rotate(-180 13.5 14)">
          <use fill="#000" filter="url(#a)" />
          <use fill="#fff" />
        </g>
        <path fill="#505b6d" d="M10 18l5-4-5-4" />
      </g>
    </svg>
  )
}
