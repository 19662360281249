import styled from 'styled-components'
import Button from '~/app/components/Button/Button'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const SubmitButton = styled(Button)`
  margin-top: 20px;
`
