import moment from 'moment'

function printTime(value: number, unit: 'year' | 'month' | 'day'): string {
  if (value === 0) {
    return ''
  }
  return `${value} ${unit}${value > 1 ? 's' : ''} `
}

export function getAge(birthdate: string): string {
  const years = Math.floor(moment().diff(birthdate, 'years', true))
  const months = Math.floor(moment().diff(moment(birthdate).add(years, 'years'), 'months', true))
  const days = Math.floor(
    moment().diff(moment(birthdate).add(months, 'months').add(years, 'years'), 'days', true),
  )
  return `${printTime(years, 'year')}${printTime(months, 'month')}${printTime(days, 'day')}`
}

export function getChildPhase(value: 'finger' | 'both' | 'pureed' | 'notSolids'): string {
  switch (value) {
    case 'finger':
      return 'is eating finger food'
    case 'both':
      return 'is eating pureed & finger food'
    case 'pureed':
      return 'is eating pureed food'
    case 'notSolids':
      return 'has not yet started solids'
    default:
      return ''
  }
}

export function getFoodOrigin(value: 'both' | 'store' | 'homemade'): string {
  switch (value) {
    case 'both':
      return 'homemade & store bought food'
    case 'store':
      return 'store bought food'
    case 'homemade':
      return 'homemade food'
    default:
      return ''
  }
}

export function getEatingStyle(value: 3 | 2 | 1 | 0): string {
  switch (value) {
    case 3:
      return 'adventurous'
    case 2:
      return 'good eater'
    case 1:
      return 'somewhat picky'
    case 0:
      return 'very picky'
    default:
      return ''
  }
}

export function getAllergies(value: 'severe' | 'mild' | 'none'): string {
  switch (value) {
    case 'severe':
      return 'severe food allergies'
    case 'mild':
      return 'mild food allergies'
    case 'none':
      return 'no known food allergies'
    default:
      return 'no known food allergies'
  }
}

export function getAgeAtSignup(birthdate: string, signupDate?: string): string {
  if (!signupDate) {
    return ''
  }
  const years = Math.floor(moment(signupDate).diff(birthdate, 'years', true))
  const months = Math.floor(
    moment(signupDate).diff(moment(birthdate).add(years, 'years'), 'months', true),
  )
  const days = Math.floor(
    moment(signupDate).diff(
      moment(birthdate).add(months, 'months').add(years, 'years'),
      'days',
      true,
    ),
  )
  return `${printTime(years, 'year')}${printTime(months, 'month')}${printTime(days, 'day')}`
}

export const getBirthdate = (birthdate: string): string => moment(birthdate).format('MM/DD/YYYY')
