import { makeModel } from '~/app/utils/storeManager'
import effects from './effects'
import reducers from './reducers'
import * as selectors from './selectors'

const creditsReasons$ = makeModel({
  effects,
  prefix: 'creditReasons',
  reducers,
  selectors,
  state: {
    reasons: [],
    status: 'pending',
  },
})

export default creditsReasons$
