import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withForm from '~/app/hocs/withForm'
import { FieldSet } from '~/app/components/FieldSet'
import { Row, Half } from '~/app/components/FormGrid'
import { Label } from '~/app/components/Input/styled'
import SaveButton from '~/app/components/Button/SaveButton'
import formDefinition from './formDefinition'
import { Wrapper } from './styled'
import withDeliveryBillingAddressController from './withDeliveryBillingAddressController'

function DeliveryBillingAddressForm({ renderField, handleConfirm, hasChanges, isValid, formData }) {
  return (
    <Wrapper>
      <FieldSet legend="Edit Billing Address">
        <Row>
          <Half>
            <Label>Billing Address</Label>
            {renderField('billingFirstName')}
            {renderField('billingLastName')}
            {renderField('billingAddress')}
            {renderField('billingApt')}
            {renderField('billingCity')}
            {renderField('billingState')}
            {renderField('billingZip')}
          </Half>
        </Row>
      </FieldSet>
      <SaveButton
        isNew={false}
        handleConfirm={handleConfirm}
        hasChanges={hasChanges}
        isValid={isValid}
        model={formData}
      />
    </Wrapper>
  )
}

DeliveryBillingAddressForm.propTypes = {
  formData: PropTypes.any,
  handleConfirm: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  renderField: PropTypes.func.isRequired,
}
export default compose(
  withForm(formDefinition),
  withDeliveryBillingAddressController,
)(DeliveryBillingAddressForm)
