import { useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import { groupBy } from 'lodash'
import Page from '~/app/components/Page'
import useHasUserRole from '~/app/hooks/useHasUserRole'
import { Title } from '~/app/components/Titles/PageTitle'
import { UserRole } from '~/app/common/constants'
import { $Product, ProductType } from '~/app/common/types'
import productDefaults$ from '~/app/store/productDefaults'
import { useMappedDispatch, useMappedState } from '~/app/hooks/useReduxStore'
import { type ProductDefault } from '~/app/store/productDefaults/types'
import { Block } from '~/app/components/Block'
import { FieldSet } from '~/app/components/FieldSet'
import { selectProductsWithReplacements } from '~/app/store/products/selectors'
import { fetchProducts } from '~/app/store/products/actions'
import Button from '~/app/components/Button/Button'
import DeliveryForm from './DeliveryForm'
import { productTypeNamePlural } from '~/app/common/dataMaps/productTypeName'

interface Props {
  match: {
    params: {
      type: ProductType
    }
  }
}

interface GroupedProductDefaults {
  [key: string]: ProductDefault[]
}

export default function ProductDefaults(props: Props) {
  const {
    isSuperAdmin,
    productDefaultsState,
    productsOptionsByType,
    updateProductsDefaultState,
    onSave,
    type,
    sortedProductDefaults,
  } = useController(props)

  if (!isSuperAdmin || !productDefaultsState) {
    return null
  }

  return (
    <Page>
      <Title>Manage Product Defaults for {productTypeNamePlural[type]}</Title>
      {sortedProductDefaults.map(([key, productDefaults]) => {
        const [mealsPerDelivery, minMonths] = key.split('-').map(Number)
        return (
          <Block key={key}>
            <FieldSet
              legend={`${mealsPerDelivery} meals per delivery, ${minMonths} months minimum`}
            >
              <DeliveryForm
                productDefaults={productDefaults}
                productOptions={productsOptionsByType}
                onChange={(updatedDefaults) => updateProductsDefaultState(key, updatedDefaults)}
                mealsPerDelivery={mealsPerDelivery}
                minMonths={minMonths}
                type={type}
              />
            </FieldSet>
            <Button
              primary
              onClick={() => onSave(mealsPerDelivery, minMonths)}
              style={{ display: 'block', margin: '10px auto' }}
            >
              Save
            </Button>
          </Block>
        )
      })}
    </Page>
  )
}

function useController(props: Props) {
  const [productDefaultsState, setProductDefaultsState] = useImmer<GroupedProductDefaults | null>(
    null,
  )
  const type = props.match.params.type
  const normalizedType = type === ProductType.biteable ? ProductType.plate : type
  const isSuperAdmin = useHasUserRole([UserRole.superadmin])
  const dispatcher = useMappedDispatch({ fetchProducts })
  const { productDefaults, products } = useMappedState((state: Record<string, any>) => ({
    productDefaults: productDefaults$.getProductDefaults(state) as {
      [key in ProductType]: ProductDefault[]
    },
    products: selectProductsWithReplacements(state),
  }))
  const productsOptionsByType = getProductsOptionsByType(products.data, normalizedType)

  const updateProductsDefaultState = (key: string, updatedDefaults: ProductDefault[]) => {
    setProductDefaultsState((draft) => {
      if (draft) {
        draft[key] = updatedDefaults
      }
    })
  }

  const onSave = async (mealsPerDelivery: number, minMonths: number) => {
    const key = `${mealsPerDelivery}-${minMonths}`
    const data = productDefaultsState![key]

    const productsCount = data.reduce((acc, productDefault) => acc + productDefault.count, 0)

    if (productsCount !== mealsPerDelivery) {
      alert('The total count of products should be equal to the meals per delivery')
      return
    } else if (data.find((productDefault) => !productDefault.productId || !productDefault.count)) {
      alert('Please fill all the fields')
      return
    }

    await productDefaults$.call.editProductDefaults({ data })
  }

  useEffect(() => {
    dispatcher.fetchProducts({})
    productDefaults$.call.fetchProductDefaults(type)
  }, [type])

  useEffect(() => {
    if (productDefaults) {
      const groupedDefaults = groupBy(
        productDefaults[type],
        (def) => `${def.mealsPerDelivery}-${def.minMonths}`,
      )
      setProductDefaultsState(groupedDefaults)
    }
  }, [productDefaults])

  const sortedProductDefaults = useMemo(() => {
    if (!productDefaultsState) {
      return []
    }

    return Object.entries(productDefaultsState).sort(([keyA], [keyB]) => {
      const [mealsA, monthsA] = keyA.split('-').map(Number)
      const [mealsB, monthsB] = keyB.split('-').map(Number)
      if (mealsA !== mealsB) {
        return mealsA - mealsB
      }
      return monthsA - monthsB
    })
  }, [productDefaultsState])

  return {
    isSuperAdmin,
    productDefaultsState,
    productsOptionsByType,
    updateProductsDefaultState,
    onSave,
    type,
    sortedProductDefaults,
  }
}

function getProductsOptionsByType(products: $Product[], normalizedType: ProductType) {
  if (normalizedType === ProductType.puff) {
    return products
      ?.filter((product) => [ProductType.cereal, ProductType.puff].includes(product.type))
      .map((product) => ({
        label: product.name,
        value: product.id,
      }))
  }

  return products
    ?.filter((product) => product.type === normalizedType)
    .map((product) => ({
      label: product.name,
      value: product.id,
    }))
}
