import { createSelector } from 'reselect'

const selectUsers = (state) => state.users

export const selectDataLoaded = createSelector(selectUsers, (u) => u.dataLoaded)
export const selectDataLoading = createSelector(selectUsers, (u) => u.dataLoading)
export const selectData = createSelector(selectUsers, (u) => u.data)
export const selectDataIsUpdated = createSelector(selectUsers, (p) => p.dataIsUpdated)
export const selectPage = createSelector(selectUsers, (u) => u.activePage)
export const selectSearchTerm = createSelector(selectUsers, (u) => u.searchTerm)
