export default function getMouth(type: string) {
  switch (type) {
    case 'veryPicky':
      return (
        <g>
          <path
            fillRule="nonzero"
            d="M22.001 26.085c1.521 0 3.017-.362 4.338-1.062l.232-.122c.53-.284.71-.931.358-1.42-.319-.441-.95-.568-1.439-.312l-.227.121c-2.01 1.064-4.513 1.064-6.525 0l-.23-.123c-.487-.256-1.12-.128-1.437.314-.35.488-.171 1.137.36 1.42l.23.121A9.308 9.308 0 0 0 22 26.085z"
          />
          <path d="M22.126 24.991c-1.16 0-2.126-.235-2.126-.235v2.82c0 1.429.952 2.587 2.126 2.587s2.126-1.158 2.126-2.586v-2.82s-.966.234-2.126.234" />
        </g>
      )
    case 'picky':
      return (
        <path
          fillRule="nonzero"
          d="M18.45 25.585c-.586 0-1.06-.458-1.06-1.024 0-.566.474-1.024 1.06-1.024h7.1c.586 0 1.06.458 1.06 1.024 0 .566-.474 1.024-1.06 1.024h-7.1z"
        />
      )
    case 'goodEater':
      return (
        <path
          fillRule="nonzero"
          d="M22.001 26.085c1.521 0 3.017-.362 4.338-1.062l.232-.122c.53-.284.71-.931.358-1.42-.319-.441-.95-.568-1.439-.312l-.227.121c-2.01 1.064-4.513 1.064-6.525 0l-.23-.123c-.487-.256-1.12-.128-1.437.314-.35.488-.171 1.137.36 1.42l.23.121A9.308 9.308 0 0 0 22 26.085z"
        />
      )
    case 'adventurous':
      return (
        <path
          stroke="#FDB43F"
          strokeWidth="2"
          d="M17.668 24.047H26.3c-1.438 2.088-2.877 3.132-4.316 3.132-1.438 0-2.877-1.044-4.316-3.132z"
        />
      )
    default:
      throw new Error(`${type} is not a valid face type`)
  }
}
