export default function RemoveIcon() {
  return (
    <svg width="60" height="60" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Remove Icon</title>
      <desc>Remove Icon</desc>
      <circle cx="10" cy="10.8091" r="6.5" stroke="#3AD7CA" fill="none" />
      <line x1="7.5" y1="10.8257" x2="12.5" y2="10.8257" stroke="#3AD7CA" strokeLinecap="round" />
    </svg>
  )
}
