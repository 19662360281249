import { Wrapper, StyledInput } from './styled'

type Props = {
  inputType?: string
  label: string
  placeholder: string
  value: string | number
  step: number
}

export default function Currency(props: Props) {
  const self = useController(props)
  return (
    <Wrapper>
      $
      <StyledInput
        type={props.inputType || 'tel'}
        // we set the default value to a non-null/non-undefined value to prevent this from becoming an uncontrolled component:
        // https://reactjs.org/docs/forms.html#controlled-components
        value={props.value || ''}
        label={props.label || '$'}
        placeholder={props.placeholder || '0.00'}
        onChange={self.onChange}
        onBlur={self.onBlur}
        step={props.step || '0.01'}
      />
    </Wrapper>
  )
}

function useController(props: any) {
  const onChange = (event: any) => {
    const value = event.target.value.replace(/[^\d]/g, '').replace(/(\d\d?)$/, '.$1')
    props.onChange(value)
  }
  const onBlur = (event: any) => {
    // rounds the floating point number to 2 decimal places (i.e. 1.00)
    const value = event.target.value > 0 ? Number(event.target.value).toFixed(2) : ''
    props.onChange(value)
  }

  return {
    onBlur,
    onChange,
  }
}
