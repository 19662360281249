import { StatusType } from '~/app/common/types'

export const setPromoBanners = (state: any) => (data: any) => ({
  ...state,
  data,
})

export const setStatus = (state: any) => (status: StatusType) => ({
  ...state,
  status,
})
