import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function fetchOrders(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    loader$.call.show()
    try {
      const response = await Api.fetchOrders(userId)
      await store.dispatch(self$.setPastOrders(response))
    } catch (error) {
      alert$.call.setNotification(String(error))
      self$.call.setPastOrders(null)
    }
    loader$.call.hide()
  }
}

export function setFaultyOrder(store: any, self$: any) {
  return async (payload: {
    issueIds: number[]
    orderId: number
    resend: boolean
    note: string
    intercomLink: string
    photosProvided: boolean
    extraProductIds: number[]
    products: { sku: string; count: number }[]
    photos?: string[]
  }): Promise<void> => {
    loader$.call.show()

    const {
      issueIds,
      orderId,
      resend,
      note,
      intercomLink,
      photosProvided,
      extraProductIds,
      products,
      photos,
    } = payload
    try {
      await Api.setFaultyOrder(
        orderId,
        issueIds,
        resend,
        note,
        intercomLink,
        photosProvided,
        extraProductIds,
        products,
        photos,
      )
      // @ts-ignore
      const response = await Api.fetchOrders(payload.userId)
      await store.dispatch(self$.setPastOrders(response))
      alert$.call.setNotification('Order issues have been reported succesfully')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function cancelOrder(store: any, self$: any) {
  return async ({ userId, orderId }: { userId: number; orderId: number }) => {
    loader$.call.show()
    try {
      await Api.cancelOrder({ userId, orderId })
      const response = await Api.fetchOrders(userId)
      await store.dispatch(self$.setPastOrders(response))
      alert$.call.setNotification('Order has been cancelled successfully!')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}
