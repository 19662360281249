import type { ApiOrderRefund } from 'app/common/api/types'
import type * as reducers from './reducers'

export type OrderRefunds = ApiOrderRefund[]

export enum OrderRefundsStatus {
  pending = 'pending',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export interface OrderRefundsState {
  data: OrderRefunds
  status: OrderRefundsStatus
}

export type RootState = object & { orderRefunds: OrderRefundsState }

export type OrderRefundsReducers = {
  [key in keyof typeof reducers]: ReturnType<(typeof reducers)[key]>
}
