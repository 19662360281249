import { assoc } from 'ramda'
import { LOCATION_CHANGE } from 'react-router-redux'
const initialState = {
  location: null,
}
export default function routeReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return assoc('location', action.payload, state)

    default:
      return state
  }
}
