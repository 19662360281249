import { connect } from 'react-redux'
import queryString from 'query-string'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import SmallSelect from '~/app/components/InputTypes/SmallSelect'
import { searchUser, fetchUsers, updatePage } from '~/app/store/users/actions'
import { selectSearchTerm, selectPage } from '~/app/store/users/selectors'
import { selectCurrentFilter } from '~/app/store/filters/selectors'
import { USERS_PER_PAGE } from '~/app/store/users/constants'
import SearchIcon from '~/app/assets/svg/SearchIcon'
import { Wrap, SearchBarInput, SelectContainer } from './styled'

const searchOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Customer Name',
    value: 'userName',
  },
  {
    label: 'Kid Name',
    value: 'kidName',
  },
  {
    label: 'Phone Number',
    value: 'phone',
  },
]

interface Props {
  onChange: () => void
  placeholder: string
  searchTerm: string
  searchFilter: string
  setSearchFilter: () => void
}

function SearchBar(props: Props) {
  return (
    <Wrap>
      <SearchIcon />

      <SelectContainer>
        <SmallSelect
          // TODO: LS-3279 save this in store as `users.searchFilter`
          // @ts-ignore
          id="usersSearchFilter"
          options={searchOptions}
          value={props.searchFilter}
          onChange={props.setSearchFilter}
        />
      </SelectContainer>

      <SearchBarInput
        value={props.searchTerm}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </Wrap>
  )
}

function mapStateToProps(state: any) {
  return {
    activePage: selectPage(state),
    filter: selectCurrentFilter(state),
    searchTerm: selectSearchTerm(state),
  }
}

const mapDispatchToProps = {
  fetchUsers,
  searchUser,
  updatePage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('searchFilter', 'setSearchFilter', 'email'),

  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps: any) {
      const { searchTerm, fetchUsers, updatePage } = this.props
      const nextSearchTerm = nextProps.searchTerm
      const searchFilter = nextProps.searchFilter

      if (
        searchTerm !== nextSearchTerm &&
        (nextSearchTerm.length > 2 || nextSearchTerm.length === 0)
      ) {
        this.props.history.push('/users/list')
        this.props.history.replace({
          search: queryString.stringify({
            'user-page': 1,
            'user-search': nextSearchTerm || undefined,
          }),
        })
        fetchUsers({
          filter: this.props.filter,
          limit: USERS_PER_PAGE,
          offset: 0,
          searchFilter,
          searchTerm: nextSearchTerm,
        })
        updatePage()
      }
    },
  }),

  withHandlers({
    onChange: (props: any) => (event: any) => {
      const term = event.target.value.trim()
      props.history.replace({
        search: queryString.stringify({
          'user-page': props.activePage,
          'user-search': term || undefined,
        }),
      })
      props.searchUser(term)
    },
  }),
  // @ts-ignore
)(SearchBar)
