import styled from 'styled-components'
import { colors } from '~/app/settings/colors'
import media from '~/app/utils/mediaQueries'
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
export const Option = styled.div`
  display: flex;
  width: 50%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0.1rem solid ${colors.lightGray};
  &:first-of-type {
    border-right: 0.1rem solid ${colors.lightGray};
    ${media.sm} {
      margin-right: 2rem;
    }
  }
  ${media.sm} {
    width: auto;
    border: none !important;
  }
`
export const LinkButton = styled.a`
  font-size: 1.5rem;
  margin-left: 1rem;
  background: transparent;
  color: ${colors.green};
  font-weight: 700;
  border: none;
  cursor: pointer;
`
