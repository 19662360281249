const nameSpace = 'app/pages/Promocodes'
export const CREATE_PROMOCODE = `${nameSpace}/CREATE_PROMOCODE`
export const CREATE_PROMOCODE_SUCCESS = `${nameSpace}/CREATE_PROMOCODE_SUCCESS`
export const CREATE_PROMOCODE_FAIL = `${nameSpace}/CREATE_PROMOCODE_FAIL`
export const DELETE_PROMOCODE = `${nameSpace}/DELETE_PROMOCODE`
export const DELETE_PROMOCODE_SUCCESS = `${nameSpace}/DELETE_PROMOCODE_SUCCESS`
export const DELETE_PROMOCODE_FAIL = `${nameSpace}/DELETE_PROMOCODE_FAIL`
export const FETCH_PROMOCODES = `${nameSpace}/FETCH_PROMOCODES`
export const FETCH_PROMOCODES_SUCCESS = `${nameSpace}/FETCH_PROMOCODES_SUCCESS`
export const FETCH_PROMOCODES_FAIL = `${nameSpace}/FETCH_PROMOCODES_FAIL`
export const UPDATE_PROMOCODE = `${nameSpace}/UPDATE_PROMOCODE`
export const UPDATE_PROMOCODE_SUCCESS = `${nameSpace}/UPDATE_PROMOCODE_SUCCESS`
export const UPDATE_PROMOCODE_FAIL = `${nameSpace}/UPDATE_PROMOCODE_FAIL`
