export default function PreviewProductImageIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <title>Preview Product Image</title>
      <g fill="#505B6D" fillRule="evenodd">
        <path d="M3.468 17.98H6.35c.27 0 .526.106.715.296.19.19.296.446.296.714 0 .268-.105.524-.295.714-.19.19-.447.296-.714.296H.98c-.26 0-.51-.103-.693-.287C.103 19.53 0 19.28 0 19.02v-5.37c0-.27.107-.526.296-.715.19-.19.447-.296.714-.296.27 0 .525.106.714.295.19.19.296.447.296.714v2.882L5.753 12.8c.4-.4 1.047-.4 1.447 0 .4.4.4 1.048 0 1.447L3.47 17.98zM3.468 2.02H6.35c.27 0 .526-.106.715-.296.19-.19.296-.446.296-.714 0-.268-.105-.524-.295-.714C6.875.106 6.618 0 6.35 0H.98C.72 0 .47.103.288.287.103.47 0 .72 0 .98v5.37c0 .27.107.526.296.715.19.19.447.296.714.296.27 0 .525-.106.714-.295.19-.19.296-.447.296-.714V3.47L5.753 7.2c.4.4 1.047.4 1.447 0 .4-.4.4-1.048 0-1.447L3.47 2.02zM16.532 17.98H13.65c-.27 0-.526.106-.715.296-.19.19-.296.446-.296.714 0 .268.105.524.295.714.19.19.447.296.714.296h5.37c.26 0 .51-.103.693-.287.184-.184.287-.434.287-.694v-5.37c0-.27-.107-.526-.296-.715-.19-.19-.447-.296-.714-.296-.27 0-.525.106-.714.295-.19.19-.296.447-.296.714v2.882L14.247 12.8c-.4-.4-1.047-.4-1.447 0-.4.4-.4 1.048 0 1.447 1.26 1.262 3.732 3.733 3.732 3.733zM16.532 2.02H13.65c-.27 0-.526-.106-.715-.296-.19-.19-.296-.446-.296-.714 0-.268.105-.524.295-.714.19-.19.447-.296.714-.296h5.37c.26 0 .51.103.693.287.184.184.287.434.287.694v5.37c0 .27-.107.526-.296.715-.19.19-.447.296-.714.296-.27 0-.525-.106-.714-.295-.19-.19-.296-.447-.296-.714V3.47L14.247 7.2c-.4.4-1.047.4-1.447 0-.4-.4-.4-1.048 0-1.447C14.06 4.49 16.53 2.02 16.53 2.02z" />
      </g>
    </svg>
  )
}
