/**
 * @deprecated (bnikom) this HOC is now refactored as a hook (useForm) [part of the move to refactor
 * HOC's and away from recompose]
 */

import { PureComponent } from 'react'
import { prop, map, equals, filter, pipe } from 'ramda'
import Input from '~/app/components/Input'
import {
  getNewFieldValue,
  getInitialFormState,
  getFieldValidity,
  isFormValid,
  mergeArrayKeys,
} from './helpers'

interface Props {
  formData: any
}

type State = Record<string, any>

const withForm = (fieldDefinitions: any) => (Component: any) =>
  class extends PureComponent<Props, State> {
    private hasChanges: boolean

    constructor(props: Props) {
      super(props)
      this.hasChanges = false
      this.state = getInitialFormState(fieldDefinitions, props.formData, this.onChange)
    }

    UNSAFE_componentWillReceiveProps({ formData }: any) {
      if (!equals(this.props.formData, formData)) {
        this.reset(formData)
      }
    }

    onChange = (name: string) => (value: any) => {
      this.hasChanges = true
      this.setState({
        [name]: getNewFieldValue(this.state[name], value, this.state),
      })
    }

    setSubmittedState = () => {
      // set pristine to false for every field, which will result in showing error on invalid fields
      this.setState(
        map(
          (x) => ({
            ...x,
            ...getFieldValidity(x.value, x.validators, this.state),
            pristine: false,
            timesSubmitted: x.timesSubmitted + 1 || 1,
          }),
          // @ts-ignore
          this.state,
        ),
      )
    }

    getSerializedFormState = () => mergeArrayKeys(map(prop('value'), this.state))

    reset = (formData = {}) => {
      this.hasChanges = false
      this.setState(getInitialFormState(fieldDefinitions, formData, this.onChange))
    }

    serialize = (cb: any) => {
      this.setSubmittedState()
      setTimeout(() => {
        const serialized = this.getSerializedFormState()
        if (isFormValid(this.state)) {
          this.hasChanges = false
          cb(serialized)
        }
      }, 0)
    }

    renderField = (name: string, props = {}) => {
      const fieldData = this.state[name]
      const data = {
        ...fieldData,
        ...props,
        onChange: (...args: any) => {
          fieldData.onChange(...args)
          // @ts-ignore
          if (typeof props.onChange === 'function') {
            // @ts-ignore
            props.onChange(...args)
          }
        },
      }

      if (!data) {
        throw new Error(`Missing field definition for "${name}"`)
      }

      // @ts-ignore
      const Component = props.isNewUI ? fieldData.type : Input
      return <Component {...data} />
    }

    render() {
      return (
        <Component
          {...this.props}
          formState={this.state}
          onChange={this.onChange}
          renderField={this.renderField}
          serialize={this.serialize}
          getSerializedFormState={this.getSerializedFormState}
          isValid={isFormValid(this.state)}
          hasChanges={this.hasChanges}
          reset={this.reset}
          errors={pipe(
            filter((field: any) => !field.valid),
            map((field) => field.errorMsg),
          )(this.state)}
        />
      )
    }
  }

export default withForm
