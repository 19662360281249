interface Props {
  className?: string
}

export default function Boosters(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="103"
      height="83"
      viewBox="0 0 103 83"
      className={props.className}
    >
      <title>Boosters</title>
      <g fill="none" fillRule="evenodd" transform="translate(-10 -20)">
        <circle cx="62" cy="62" r="62" opacity="0.1" />
        <g stroke="#495464" strokeWidth="1.35">
          <path
            fill="#F3E3A8"
            d="M31.584 36.806a408.14 408.14 0 0 0 6.296 27.187c2.41 8.991 5.199 18.169 8.369 27.533l16.166-4.332a432.976 432.976 0 0 0-6.517-28.015c-2.477-9.243-5.191-18.145-8.143-26.706l-16.171 4.333zM31.394 36.495l16.369-4.386-.163-9.664a.325.325 0 0 0-.54-.238l-1.337 1.188c-.395.35-.931.494-1.448.388l-3.374-.69a.325.325 0 0 0-.292.087l-2.135 2.083a1.675 1.675 0 0 1-1.61.418l-2.755-.751a.325.325 0 0 0-.302.07l-2.581 2.302a1.675 1.675 0 0 1-1.309.413l-3.003-.35a.325.325 0 0 0-.322.48l4.802 8.65z"
          />
          <path d="M34.476 25.679l.59-.158-.806-.415.216.573zm3.417 9.075l.262.697.457-.89-.719.193zm-.055.014l-.591.159.806.414-.215-.573zm-3.417-9.074l-.263-.697-.457.89.72-.193zm7.864 7.883l.1.603.49-.761-.59.158zm-.055.015l-.72.192.84.543-.12-.735zm-1.578-9.568l-.1-.603-.491.761.59-.158zm.055-.015l.72-.192-.842-.543.122.735z" />
          <path
            fill="#F3E3A8"
            d="M46.237 91.89l16.369-4.385 4.691 8.45a.325.325 0 0 1-.35.477l-1.75-.36a1.675 1.675 0 0 0-1.449.387l-2.577 2.284a.325.325 0 0 1-.296.072l-2.89-.737a1.675 1.675 0 0 0-1.604.443l-2.01 2.028a.325.325 0 0 1-.297.09l-3.386-.702a1.675 1.675 0 0 0-1.34.296l-2.425 1.805a.325.325 0 0 1-.52-.255l-.166-9.892z"
          />
          <path d="M54.314 99.717l.591-.158-.491.762-.1-.604zm-1.578-9.567l-.121-.735.84.542-.719.193zm-.055.014l-.59.159.49-.762.1.603zm1.578 9.568l.121.735-.84-.542.72-.193zm2.87-10.76l-.216-.572.806.414-.59.159zm-.056.016l-.72.192.457-.89.263.698zm3.417 9.074l.216.573-.807-.414.591-.159zm.055-.014l.72-.193-.457.89-.263-.697z" />
          <path
            fill="#FFF"
            strokeLinecap="round"
            d="M52.599 73.423c.568 2.977.435 5.02-.99 5.292-1.496.285-2.266-1.084-2.946-4.503-.307-2.71-3.644-17.166-4.832-20.873-1.796-.042-3.104-1.19-3.75-3.026-.89-2.557-.247-5.727 1.864-6.292 2.06-.552 4.685 1.706 5.546 4.179.641 1.823.26 3.484-1.212 4.43 1.68 4.113 6.038 18.213 6.32 20.793z"
          />
        </g>
        <g stroke="#495464" strokeWidth="1.35">
          <path
            fill="#9FE4E6"
            d="M53.63 31.675a408.14 408.14 0 0 0-.955 27.89c0 9.308.32 18.895.957 28.76H70.37c.637-9.582.956-19.164.956-28.747 0-9.57-.318-18.87-.954-27.903H53.63zM53.527 31.325h16.946l2.344-9.377a.325.325 0 0 0-.46-.37l-1.6.801a1.675 1.675 0 0 1-1.498.001l-3.08-1.54a.325.325 0 0 0-.305.008l-2.6 1.46c-.519.291-1.152.286-1.665-.013l-2.466-1.439a.325.325 0 0 0-.31-.01l-3.09 1.555a1.675 1.675 0 0 1-1.37.06l-2.81-1.115a.325.325 0 0 0-.435.381l2.399 9.598z"
          />
          <path d="M59.303 21.675h.612l-.672-.609.06.609zm.952 9.65l.073.741.672-.741h-.745zm-.057 0h-.612l.672.609-.06-.609zm-.952-9.65l-.073-.741-.672.741h.745zm5.556 9.65l-.06.609.672-.609h-.612zm-.057 0H64l.672.741.073-.741zm.952-9.65l.06-.609-.672.609h.612zm.057 0h.745l-.672-.741-.073.741z" />
          <path
            fill="#9FE4E6"
            d="M53.527 88.675h16.946l2.344 9.377a.325.325 0 0 1-.46.37l-1.6-.801a1.675 1.675 0 0 0-1.498-.001l-3.08 1.54a.325.325 0 0 1-.305-.008l-2.6-1.46a1.675 1.675 0 0 0-1.665.013l-2.466 1.439a.325.325 0 0 1-.31.01l-3.09-1.555a1.675 1.675 0 0 0-1.37-.06l-2.81 1.115a.325.325 0 0 1-.435-.381l2.399-9.598z"
          />
          <path d="M59.303 98.325h.612l-.672.609.06-.609zm.952-9.65l.073-.741.672.741h-.745zm-.057 0h-.612l.672-.609-.06.609zm-.952 9.65l-.073.741-.672-.741h.745zm5.556-9.65l-.06-.609.672.609h-.612zm-.057 0H64l.672-.741.073.741zm.952 9.65l.06.609-.672-.609h.612zm.057 0h.745l-.672.741-.073-.741z" />
          <path
            fill="#FFF"
            strokeLinecap="round"
            d="M64.451 72.483c-.221 3.023-.878 4.962-2.326 4.855-1.518-.112-1.907-1.633-1.68-5.112.405-2.696.924-17.524.736-21.412-1.724-.505-2.69-1.954-2.84-3.894-.197-2.7 1.244-5.595 3.43-5.595 2.132 0 4.083 2.86 4.275 5.472.147 1.927-.651 3.433-2.318 3.966.559 4.407 1.12 19.155.723 21.72z"
          />
        </g>
        <g transform="translate(9 20)">
          <g stroke="#495464" strokeWidth="1.35">
            <path
              fill="#B9C6F4"
              d="M67.246 12.474a408.14 408.14 0 0 0-8.14 26.692c-2.41 8.992-4.583 18.334-6.52 28.028l16.167 4.332a432.976 432.976 0 0 0 8.363-27.52c2.477-9.243 4.577-18.31 6.301-27.2l-16.17-4.332zM67.237 12.11l16.369 4.385 4.691-8.45a.325.325 0 0 0-.35-.477l-1.75.36a1.675 1.675 0 0 1-1.449-.387l-2.577-2.284a.325.325 0 0 0-.296-.072l-2.89.737a1.675 1.675 0 0 1-1.604-.443l-2.01-2.028a.325.325 0 0 0-.297-.09l-3.386.702a1.675 1.675 0 0 1-1.34-.296l-2.425-1.805a.325.325 0 0 0-.52.255l-.166 9.892z"
            />
            <path d="M75.314 4.283l.591.158-.491-.762-.1.604zm-1.578 9.567l-.121.735.84-.542-.719-.193zm-.055-.014l-.59-.159.49.762.1-.603zm1.578-9.568l.121-.735-.84.542.72.193zm2.87 10.76l-.216.572.806-.414-.59-.159zm-.056-.016l-.72-.192.457.89.263-.698zm3.417-9.074l.216-.573-.807.414.591.159zm.055.014l.72.193-.457-.89-.263.697z" />
            <path
              fill="#B9C6F4"
              d="M52.394 67.505l16.369 4.386-.163 9.664a.325.325 0 0 1-.54.238l-1.337-1.188a1.675 1.675 0 0 0-1.448-.388l-3.374.69a.325.325 0 0 1-.292-.087l-2.135-2.083a1.675 1.675 0 0 0-1.61-.418l-2.755.751a.325.325 0 0 1-.302-.07l-2.581-2.302a1.675 1.675 0 0 0-1.309-.413l-3.003.35a.325.325 0 0 1-.322-.48l4.802-8.65z"
            />
            <path d="M55.476 78.321l.59.158-.806.415.216-.573zm3.417-9.075l.262-.697.457.89-.719-.193zm-.055-.014l-.591-.159.806-.414-.215.573zm-3.417 9.074l-.263.697-.457-.89.72.193zm7.864-7.883l.1-.603.49.761-.59-.158zm-.055-.015l-.72-.192.84-.543-.12.735zm-1.578 9.568l-.1.603-.491-.761.59.158zm.055.015l.72.192-.842.543.122-.735z" />
            <path
              fill="#FFF"
              strokeLinecap="round"
              d="M67.137 54.692c-.996 2.862-2.133 4.565-3.504 4.088-1.437-.501-1.42-2.071-.299-5.373 1.089-2.5 5.428-16.688 6.252-20.492-1.534-.934-2.093-2.583-1.735-4.496.508-2.66 2.65-5.083 4.76-4.517 2.06.552 3.205 3.82 2.714 6.392-.356 1.9-1.517 3.147-3.265 3.23-.601 4.403-3.877 18.793-4.923 21.168z"
            />
          </g>
          <circle
            cx="101"
            cy="61"
            r="2.25"
            stroke="#93A8EF"
            strokeOpacity="0.812"
            strokeWidth="1.5"
            opacity="0.8"
          />
          <path
            fill="#FFEFB4"
            fillRule="nonzero"
            d="M3.744 48.65l1.115-.618a.6.6 0 0 1 .815.234l.025.044a.6.6 0 0 1-.234.815l-1.115.619.618 1.115a.6.6 0 0 1-.234.815l-.044.025a.6.6 0 0 1-.815-.234l-.619-1.115-1.115.618a.6.6 0 0 1-.815-.234L1.3 50.69a.6.6 0 0 1 .234-.815l1.115-.619-.618-1.115a.6.6 0 0 1 .234-.815l.044-.025a.6.6 0 0 1 .815.234l.619 1.115z"
          />
          <path
            fill="#C6EEE9"
            fillRule="nonzero"
            d="M20.642 79.549l1.115-.618a.6.6 0 0 1 .816.234l.024.043a.6.6 0 0 1-.234.816l-1.115.618.618 1.115a.6.6 0 0 1-.234.816l-.043.024a.6.6 0 0 1-.816-.234l-.618-1.115-1.115.618a.6.6 0 0 1-.816-.234l-.024-.043a.6.6 0 0 1 .234-.816l1.115-.618-.618-1.115a.6.6 0 0 1 .234-.816l.043-.024a.6.6 0 0 1 .816.234l.618 1.115z"
          />
          <path
            fill="#FFEFB4"
            fillRule="nonzero"
            d="M94.057 31.065l.496.06a.5.5 0 1 1-.121.993l-.497-.06-.06.495a.5.5 0 1 1-.993-.121l.06-.497-.495-.06a.5.5 0 1 1 .121-.993l.497.06.06-.495a.5.5 0 1 1 .993.121l-.06.497z"
          />
          <circle
            cx="16"
            cy="21"
            r="1.25"
            stroke="#93A8EF"
            strokeOpacity="0.812"
            strokeWidth="1.5"
            opacity="0.8"
          />
        </g>
      </g>
    </svg>
  )
}
