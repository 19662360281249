import { withHandlers as defaultWithHandlers } from 'recompose'

// This modification allows to access sibling handlers via "this"
const withHandlers = (handlersObj) =>
  defaultWithHandlers(
    Object.keys(handlersObj).reduce((bindedHandlers, name) => {
  bindedHandlers[name] = handlersObj[name].bind(handlersObj); // eslint-disable-line

      return bindedHandlers
    }, {}),
  )

export default withHandlers
