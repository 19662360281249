import PropTypes from 'prop-types'
import LightBox from '~/app/components/LightBox'
import Button from '~/app/components/Button/Button'
import Icon from '~/app/assets/svg/BoxWithSparklesIcon'
import moment from 'moment'
import { Wrapper, Question, Options, Option, Date } from './styled'
export default function ShiftingModal(props) {
  const dateFormat = 'dddd, MMMM Do, YYYY'
  const now = moment()
  const shiftedBackDate = moment(props.deliveryDate).add(-1, 'week').format(dateFormat)
  const shiftedForwardDate = moment(props.deliveryDate).add(1, 'week').format(dateFormat)
  const disableShiftBack =
    props.shiftedBy < 0 || moment(props.editableTo).add(-7, 'days').isBefore(now)
  // The expected editableTo is in the past
  const disableShiftForward = props.shiftedBy > 0

  const handleShiftBack = () => props.onShift(-7)

  const handleShiftForward = () => props.onShift(7)

  return (
    <LightBox isOpen={props.isOpen} closeHandler={props.closeHandler} width={'614px'} height={830}>
      <Wrapper>
        <Icon />
        <Question>When would you like to receive this delivery</Question>
        <Options>
          <Option>
            <Date>{shiftedBackDate}</Date>
            <Button primary disabled={disableShiftBack} onClick={handleShiftBack}>
              Shift Backward one week
            </Button>
          </Option>
          <Option>
            <Date>{shiftedForwardDate}</Date>
            <Button primary disabled={disableShiftForward} onClick={handleShiftForward}>
              Shift Forward one week
            </Button>
          </Option>
        </Options>
      </Wrapper>
    </LightBox>
  )
}
ShiftingModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  deliveryDate: PropTypes.object.isRequired,
  // Already shifted if relevant
  editableTo: PropTypes.object.isRequired,

  isOpen: PropTypes.bool,

  shiftedBy: PropTypes.number.isRequired,
  // moment
  onShift: PropTypes.func.isRequired // eslint-disable-line
}
