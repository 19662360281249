import { createSelector } from 'reselect'
export const selectWarehousesState = (state) => state.warehouses
export const selectWarehousesLoading = createSelector(
  selectWarehousesState,
  (d) => d.warehousesLoading,
)
export const selectWarehousesLoaded = createSelector(
  selectWarehousesState,
  (d) => d.warehousesLoaded,
)
export const selectWarehouses = createSelector(selectWarehousesState, (d) => d.data)
