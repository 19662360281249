import LightBox from '~/app/components/LightBox'
import {
  Wrapper,
  Header,
  Heading,
  Content,
  StyledTable,
  TableCell,
  TableHeader,
  TableRow,
  LoaderWrapper,
} from './styled'
import moment from 'moment'
import { Order } from '../types'
import user$ from '~/app/store/user'
import { useEffect } from 'react'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { ApiOrderRefund } from '~/app/common/api/types'
import RefundOrderForm from './Form'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { OrderRefundsStatus, RootState } from '~/app/store/user/orderRefunds/types'
import { option, switchOn } from '~/app/utils/switchOn'
import Loader from '~/app/components/Loader'

interface Props {
  onClose: () => void
  order: Order
}

export default function RefundOrderDialog(props: Props) {
  const self = useController(props)

  return (
    <LightBox isOpen closeHandler={self.handleClose}>
      <Wrapper>
        <Content>
          {switchOn(
            option(
              self.isLoading && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ),
            ),
            option(
              self.orderRefunds.length > 0 && (
                <>
                  <Header>
                    <Heading noTopMargin>Previous refunds for this charge</Heading>
                    <span>
                      Note: It shows all refunds associated with this charge, not this order, so you
                      might see refunds from different order here.
                    </span>
                  </Header>
                  <StyledTable>
                    <thead>
                      <TableRow>
                        <TableHeader>Order ID</TableHeader>
                        <TableHeader>Amount</TableHeader>
                        <TableHeader>Date</TableHeader>
                        <TableHeader>Products</TableHeader>
                        <TableHeader>Author</TableHeader>
                        <TableHeader>Custom Reason</TableHeader>
                      </TableRow>
                    </thead>
                    <tbody>
                      {self.orderRefunds.map((refund: ApiOrderRefund) => (
                        <TableRow key={String(refund.createdAt)}>
                          <TableCell>{refund.metadata?.orderId}</TableCell>
                          <TableCell>${convertCentsToDollars(refund.amount)}</TableCell>
                          <TableCell>{moment(refund.createdAt).format('MM/DD/YYYY')}</TableCell>
                          <TableCell>
                            {refund.products?.map((product: { id: number; count: number }) => (
                              <div key={product.id}>
                                {self.productsByIdOrSku[product.id]?.name ?? product.id} x{' '}
                                {product.count}
                              </div>
                            ))}
                          </TableCell>
                          <TableCell>{refund.user?.email ?? 'N/A'}</TableCell>
                          <TableCell>{refund.customRefundReason}</TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </StyledTable>
                </>
              ),
            ),
          )}
          {self.showRefundForm && !self.isLoading && (
            <>
              <Header>
                <Heading noTopMargin>Refund Order</Heading>
              </Header>
              <RefundOrderForm
                order={props.order}
                onSubmit={self.handleCreateOrderRefund}
                isLoading={self.isLoading}
              />
            </>
          )}
        </Content>
      </Wrapper>
    </LightBox>
  )
}

function useController(props: Props) {
  const { order } = props
  const orderRefundsState = useMappedState(
    (state: object & { user: RootState }) => state.user.orderRefunds as RootState['orderRefunds'],
  )
  const isLoading = orderRefundsState.status !== OrderRefundsStatus.loaded
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const showRefundForm = moment(props.order.editableTo).isAfter(moment().subtract(3, 'months'))

  useEffect(() => {
    user$.call.fetchOrderRefunds(order.id)
  }, [order.id])

  const handleClose = () => {
    user$.call.setOrderRefundsInitialState()
    props.onClose()
  }

  const handleCreateOrderRefund = (payload: {
    products: { id: number; count: number }[]
    customRefundReason?: string
    customRefundAmount?: number
  }) => {
    user$.call.createOrderRefund({
      products: payload.products,
      orderId: order.id,
      chargeId: order.charge,
      customRefundReason: payload.customRefundReason,
      customRefundAmount: payload.customRefundAmount,
    })
  }

  return {
    orderRefunds: orderRefundsState.data,
    handleClose,
    handleCreateOrderRefund,
    productsByIdOrSku,
    isLoading,
    showRefundForm,
  }
}
