import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { SUSPENSION_ACTION } from './constants'
import { CancellationReasonData } from './types'

export function fetchUserSubscriptions(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    loader$.call.show()
    try {
      const apiVersion = 'v3'
      const subscriptions = await Api.fetchUserSubscriptions(userId, apiVersion)
      await store.dispatch(self$.setUserSubscriptions(subscriptions))
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function cancelUserSubscription(store: any, self$: any) {
  return async (payload: {
    userId: number
    data: { templateId: number; cancel: boolean }
    cancellationReasonData: CancellationReasonData
    isPaused: boolean
  }): Promise<void> => {
    loader$.call.show()
    try {
      if (payload.isPaused) {
        await Api.pauseUserSubscriptions(payload.userId, {
          active: true,
          templateId: payload.data.templateId,
        })
      }
      if (payload.data.cancel) {
        await Api.suspendSubscription({
          action: SUSPENSION_ACTION.CANCEL,
          templateId: payload.data.templateId,
          ...payload.cancellationReasonData,
        })
      } else {
        await Api.reactivateUserSubscriptions(payload.userId, payload.data.templateId)
      }
      await self$.call.fetchUserSubscriptions(payload.userId)
      alert$.call.setNotification(
        `Subscription has been ${payload.data.cancel ? 'cancelled' : 'reactivated'}`,
      )
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function changePlan(store: any, self$: any) {
  return async (payload: {
    userId: number
    kidName: string
    subscriptionId: number
    planId: number
    planType: 'blends' | 'plates'
  }): Promise<void> => {
    loader$.call.show()
    try {
      // @ts-ignore
      await Api.changeSubscriptionPlan(payload.userId, payload.subscriptionId, {
        planId: payload.planId,
        type: payload.planType,
      })
      alert$.call.setNotification('Plan has been successfully changed')
      const [orders] = await Promise.all([
        Api.fetchUpcomingOrders(payload.userId),
        self$.call.fetchUserSubscriptions(payload.userId),
      ])
      self$.call.setUpcomingOrders(orders)
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function pauseUserSubscription(store: any, self$: any) {
  return async (payload: {
    userId: number
    data: { templateId: number; pause: boolean }
    cancellationReasonData: CancellationReasonData
  }): Promise<void> => {
    loader$.call.show()
    try {
      if (payload.data.pause) {
        await Api.suspendSubscription({
          action: SUSPENSION_ACTION.PAUSE,
          templateId: payload.data.templateId,
          ...payload.cancellationReasonData,
        })
      } else {
        await Api.unpauseUserSubscriptions(payload.userId, payload.data.templateId)
      }
      await self$.call.fetchUserSubscriptions(payload.userId)
      alert$.call.setNotification(
        `Subscription has been ${payload.data.pause ? 'paused' : 'unpaused'}`,
      )
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function postAddOnSubscription(store: any, self$: any) {
  return async (payload: {
    userId: number
    templateId: number
    data: { templateId: number; pause: boolean }
  }): Promise<void> => {
    loader$.call.show()
    try {
      await Api.postAddOnSubscription(payload.userId, payload.templateId, payload.data)
      await self$.call.fetchUserSubscriptions(payload.userId)
      alert$.call.setNotification('Coupon has been successfully added!')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Postpone all orders for a template
 *
 * @param store - Root store
 * @param self$ - Subscriptions store
 */
export function postponeFirstOrder(store: any, self$: any) {
  return async (payload: {
    userId: number
    templateId: number
    data: { editableTo: string }
  }): Promise<void> => {
    loader$.call.show()
    try {
      await Api.postponeFirstOrder(payload.templateId, payload.data)
      await self$.call.fetchUserSubscriptions(payload.userId)
      alert$.call.setNotification('Subscription has been postponed')
    } catch (error) {
      alert$.call.setNotification(error)
    }
    loader$.call.hide()
  }
}
