import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'
import Button from '~/app/components/Button/Button'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Content = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.midGray};
  padding: 0 15px 61px;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.darkGray};
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 29px;
  }
  ${media.lg} {
    padding: 0 75px 61px;
  }
`

export const Header = styled.header`
  background: ${(props) => props.theme.color.tint};
  padding: 4.9rem 0 2rem;
  position: relative;
  margin-bottom: 0.5rem;
`

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 18px;
`

export const ContentWrapper = styled.p`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const OrderSummary = styled.div`
  background: ${(props) => props.theme.color.white};
  padding: 1rem 4rem;
  margin-top: 2rem;
`

export const ProductCount = styled.div`
  font-weight: 500;
  text-align: right;
  margin-bottom: 1rem;
  font-size: 14px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const SubmitButton = styled(Button)`
  margin-top: 20px;
`

export const SelectContainer = styled.div`
  > select {
    max-width: 50rem;
    background: ${(props) => props.theme.color.white};
  }
`
