import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import loader$ from '~/app/store/loader'
import withLoader from '~/app/hocs/withLoader'
import LoginPage from '~/app/pages/Login/Loadable'
import Sidebar from '~/app/containers/Sidebar'
import Notifications from '~/app/containers/Notifications'
import Loader from '~/app/components/Loader'
import Logo from '~/app/components/Logo'
import Header from '~/app/components/Header'
import { initialize } from '~/app/store/app/actions'
import { selectAppInitialized, selectLoggedIn } from '~/app/store/app/selectors'
import Routes from './Routes'
import { Wrap, Container } from './styled'

const App = ({ loggedIn, location, showLoader }) => (
  <Wrap>
    <Logo textColor={loggedIn ? 'light' : 'dark'} />
    {!loggedIn && <LoginPage />}
    {loggedIn && <Sidebar pathname={location.pathname} />}
    {loggedIn && (
      <Container>
        {showLoader && <Loader isFixed />}
        <Header />
        <Routes />
      </Container>
    )}
    <Notifications />
  </Wrap>
)

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  initialized: selectAppInitialized(state),
  loggedIn: selectLoggedIn(state),
  showLoader: loader$.get(state),
})

const mapDispatchToProps = {
  initialize,
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.initialize()
    },
  }),
  withLoader((props) => !props.initialized),
)(App)
