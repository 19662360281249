import { Position } from '..'

const styles = {
  bottom: bottomStyle,
  top: topStyle,
}

export default function placeTooltipArrow(position: Position = 'bottom') {
  return styles[position]()
}

function bottomStyle() {
  return `
    top: -5px;
    left: 50%;
  `
}

function topStyle() {
  return `
    bottom: -5px;
    left: 50%;
  `
}
