import styled, { keyframes } from 'styled-components'
const appear = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  100% {
    opacity: .99;
    transform: translate(-50%, 0);
  }
`
const disappear = keyframes`
  0% {
    opacity: .99;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
`
export const Notification = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.isError ? props.theme.color.paleRed : props.theme.color.green};
  border-radius: 6px;
  bottom: 9%;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  left: 50%;
  line-height: 1.5;
  max-width: 450px;
  opacity: 0.99;
  padding: 20px 100px;
  position: fixed;
  text-align: center;
  transform: translate(-50%, 0);
  z-index: 999;

  animation: ${(props) => (props.closing ? disappear : appear)} 0.25s ease;
`
