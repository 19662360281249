/**
 * Babyblends eating stages. Maps DB products.stage column values to FE eating
 * stages.
 */
const stages = [
  {
    id: 0,
    name: 'Single Ingredient Blends (Stage 1)',
  },
  {
    id: 1,
    name: 'Two Ingredient Blends (Stage 2)',
  },
  {
    id: 2,
    name: 'Three Ingredient Blends (Stage 3)',
  },
  {
    id: 3,
    name: 'Four Ingredient Blends (Stage 4)',
  },
  {
    id: 4,
    name: 'Five or More Ingredient Blends (Stage 5)',
  },
  {
    id: 5,
    name: 'Transition Blends (Stage 6)',
  },
]

export const validStageIds = stages.map(({ id }) => id)

export default stages
