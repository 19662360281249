export default function UsersIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <title>Users</title>
      <g fill="#838A9D" fillRule="evenodd">
        <path d="M5.98 3.65c-1.83 0-3.075 1.37-3.075 3.063 0 1.69 1.245 3.064 3.075 3.064s3.075-1.373 3.075-3.064C9.055 5.02 7.81 3.65 5.98 3.65zM.46 15.948c.042.808.34 1.713.826 2.23.465.524 8.945.485 9.122 0 .486-.517.782-1.422.825-2.23 0-2.746-2.396-4.974-5.36-4.998v-.003l-.026.002-.024-.003v.003C2.856 10.974.46 13.2.46 15.948zM12.09 0c-1.83 0-3.074 1.372-3.074 3.064s1.244 3.064 3.075 3.064c1.83 0 3.075-1.372 3.075-3.064S13.922 0 12.09 0z" />
        <path d="M11.846 7.3v-.002h-.049V7.3c-.79.007-1.542.172-2.217.46-.176 1.16-.835 2.12-1.786 2.686 2.083.697 3.66 2.396 4.05 4.46 2.23 0 4.45-.13 4.54-.377.485-.52.782-1.423.824-2.23 0-2.747-2.396-4.975-5.362-5z" />
      </g>
    </svg>
  )
}
