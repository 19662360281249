import { maxLength, minLength, required } from '~/app/common/validators'
import InputTextArea from '~/app/components/InputTypes/Textarea'
import Select from '~/app/components/InputTypes/Select'
import DateInput from '~/app/components/InputTypes/Date'

const formDefinition = [
  {
    hasEmptyOption: true,
    label: 'Account',
    name: 'account',
    type: Select,
  },

  {
    hasEmptyOption: true,
    label: 'Food',
    name: 'food',
    type: Select,
  },

  {
    hasEmptyOption: true,
    label: 'Delivery',
    name: 'delivery',
    type: Select,
  },

  {
    hasEmptyOption: true,
    label: 'General',
    name: 'general',
    type: Select,
  },

  {
    hasEmptyOption: true,
    label: 'Admin',
    name: 'admin',
    type: Select,
  },

  {
    label: 'Notes (required)',
    name: 'notes',
    type: InputTextArea,
    validators: [required, minLength(3), maxLength(5000)],
  },

  {
    name: 'postponeDate',
    postpone: true,
    type: DateInput,
  },
] as const

export default formDefinition
