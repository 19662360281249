import styled from 'styled-components'

const CloseBtn = styled.div`
  position: absolute;
  top: ${(props: any) => (props.top ? props.top : '0')}px;
  right: ${(props: any) => (props.right ? props.right : '0')}px;
  transform: translate(40%, -40%) rotate(0deg);
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: #cbd1df;
  cursor: pointer;
  transition: transform 0.3s;
  z-index: 1;
  &:hover {
    transform: translate(40%, -40%) rotate(-90deg);
  }
  &:before,
  &:after {
    content: '';
    display: block;
    width: 2px;
    height: 24px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  backface-visibility: hidden;
`

export default CloseBtn
