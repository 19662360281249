import { merge } from 'ramda'
import { getDate } from '~/app/utils/dateHelper'
import { FETCH_TOTAL_SALES, FETCH_TOTAL_SALES_SUCCESS } from './constants'

function getReturnSalesData(data) {
  const viewType = data.viewType
  const gross = []
  const discounts = []
  const credits = []
  const net = []
  const columnDates = []
  data.detailResults.forEach((detailData) => {
    gross.push(Math.round(detailData.grossSales))
    discounts.push(Math.round(detailData.couponsDiscount))
    credits.push(Math.round(detailData.credits))
    net.push(Math.round(detailData.netSales))
    const dataDate = getDate({
      day: detailData.day,
      month: detailData.month,
      type: viewType,
      week: detailData.week,
      year: detailData.year,
    })
    columnDates.push(dataDate)
  })
  const details = {
    columnDates,
    credits,
    discounts,
    gross,
    net,
  }
  const aggData = data.aggResults
  const agg = {
    credits: Math.round(aggData.credits),
    discounts: Math.round(aggData.couponsDiscount),
    gross: Math.round(aggData.grossSales),
    net: Math.round(aggData.netSales),
  }
  const prevData = data.previousResults
  const previous = {
    credits: Math.round(prevData.credits),
    discounts: Math.round(prevData.couponsDiscount),
    gross: Math.round(prevData.grossSales),
    net: Math.round(prevData.netSales),
  }
  return {
    agg,
    details,
    loaded: true,
    loading: false,
    previous,
  }
}

const initialState = {
  totalSales: {
    loaded: false,
    loading: false,
    salesTotalsAgg: {
      credits: 0,
      discounts: 0,
      gross: 0,
      net: 0,
    },
    salesTotalsDetails: {
      columnDates: [],
      credits: [],
      discounts: [],
      gross: [],
      net: [], // Used for checking consistency against header
    },
    salesTotalsPrevious: {
      credits: 0,
      discounts: 0,
      gross: 0,
      net: 0,
    },
  },
}

function salesTotalsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOTAL_SALES: {
      return { ...state, totalSales: { ...state.totalSales, loaded: false, loading: true } }
    }

    case FETCH_TOTAL_SALES_SUCCESS: {
      const result = getReturnSalesData(action.payload.data)
      return merge(state, {
        totalSales: {
          loaded: true,
          loading: false,
          salesTotalsAgg: result.agg,
          salesTotalsDetails: result.details,
          salesTotalsPrevious: result.previous,
        },
      })
    }

    default:
      return state
  }
}

export default salesTotalsReducer
