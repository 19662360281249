import { createSelector } from 'reselect'

const selectDeliveryInfo = (state) => state.deliveryInfo

export const selectCarriersLoading = createSelector(selectDeliveryInfo, (d) => d.carriersLoading)
export const selectCarriersLoaded = createSelector(selectDeliveryInfo, (d) => d.carriersLoaded)
export const selectCarriers = createSelector(selectDeliveryInfo, (d) => d.carriers)
export const selectCarrierServicesLoading = createSelector(
  selectDeliveryInfo,
  (d) => d.carrierServicesLoading,
)
export const selectCarrierServicesLoaded = createSelector(
  selectDeliveryInfo,
  (d) => d.carrierServicesLoaded,
)
export const selectCarrierServices = createSelector(selectDeliveryInfo, (d) => d.carrierServices)
