import { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '~/app/components/Modal'
import Switch from '~/app/components/InputTypes/Switch'
import { ModalContent, Note, SwitchWrapper, Title } from './styled'
export default function ModifyAllProductsModal(props) {
  const [updateAllFutureOrders, toggleUpdateAlFutureOrders] = useState(false)
  return (
    <Modal
      isOpen={props.isOpen}
      mainBtn="Update"
      onMainBtnClick={() => props.updateOrder(updateAllFutureOrders)}
      title="Would you like to modify this and future orders?"
    >
      <ModalContent>
        <Note>Note: This excludes future orders that were previously modified.</Note>
        <SwitchWrapper>
          <Title>Update future orders</Title>
          {props.showSwitch && (
            <>
              <Switch
                value={updateAllFutureOrders}
                onChange={() => toggleUpdateAlFutureOrders(!updateAllFutureOrders)}
              />
            </>
          )}
        </SwitchWrapper>
      </ModalContent>
    </Modal>
  )
}
ModifyAllProductsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showSwitch: PropTypes.bool.isRequired,
  updateOrder: PropTypes.func.isRequired,
}
