import moment from 'moment'
import { useState, useEffect } from 'react'
import { groupBy } from 'lodash'
import Api from '~/app/common/api'
import LightBox from '~/app/components/LightBox'
import Tabs from '~/app/components/Tabs'
import Select from '~/app/components/InputTypes/Select'
import formatProductType from '~/app/utils/formatProductType'
import { ProductType } from '~/app/common/types'
import alert$ from '~/app/store/alert'
import user$ from '~/app/store/user'
import initialState from '~/app/store/user/initialState'
import { selectProducts } from '~/app/store/products/selectors'
import { useMappedState } from '~/app/hooks/useReduxStore'
import SelectableProductsList from '../../DeliverySchedule/SelectableProductsList'
import {
  Wrapper,
  Header,
  Title,
  OrderSummary,
  ProductCount,
  Content,
  ContentWrapper,
  SubmitButton,
  SelectContainer,
} from './styled'

type Product = {
  id: number
  count: number
  type: string
  packetCount: number
  inStock: boolean
  shadow: string
  name: string
  top: string
}

interface Props {
  isOpen: boolean
  closeHandler: () => void
  handleTabChange: () => void
  activeTab: ProductType
}

export default function ManualOrderLightBox(props: Props) {
  const self = useController(props)

  return (
    <LightBox isOpen={props.isOpen} closeHandler={props.closeHandler} width="1200px" height={830}>
      <Wrapper>
        <Header>
          <Title>Choose Products for Manual Order</Title>
          {Boolean(self.orderIdsWithDeliveryIssues?.length) && (
            <SelectContainer>
              <Select
                disabled={!self.orderIdsWithDeliveryIssues?.length}
                defaultValue=""
                emptyLabel="Optional - Copy products from an order with delivery issues"
                value={self.selectValue}
                options={self.orderIdsWithDeliveryIssues}
                onChange={self.setSelectValue}
                hasEmptyOption
                small
              />
            </SelectContainer>
          )}
          <OrderSummary>
            {Object.entries(self.selectedProductsCount).map(([productType, productCount]) => {
              return (
                <ProductCount key={productType}>
                  {formatProductType(productType)}: {productCount}
                </ProductCount>
              )
            })}
          </OrderSummary>
          <SubmitButton primary onClick={self.handleSubmit} large loading={self.isLoading}>
            Create Manual Order
          </SubmitButton>
        </Header>
        <Content>
          {Boolean(Object.keys(self?.products).length) && (
            <Tabs
              defaultTab={ProductType.blend}
              activeTab={props.activeTab}
              handleTabChange={props.handleTabChange}
              tabs={Object.entries(self.products).map(([type, productsByType]) => {
                return {
                  content: (
                    <ContentWrapper>
                      <SelectableProductsList
                        products={productsByType}
                        mealsLeftToPick={1}
                        isFirst={false}
                        type={type}
                        // @ts-expect-error: TODO: Fix type error
                        countMultiplier={1}
                        onlyQuantityEditor
                        disableClose={false}
                        isManualOrder
                      />
                    </ContentWrapper>
                  ),
                  name: type,
                  title: formatProductType(type),
                }
              })}
            />
          )}
        </Content>
      </Wrapper>
    </LightBox>
  )
}

function getProductTotal(products: Product[]) {
  return products.reduce((sum: number, product: Product) => (sum += product.count), 0)
}

function useController(props: any) {
  const {
    products: { data },
    editedOrder,
    user,
  } = useMappedState((state: Record<string, any>) => ({
    products: selectProducts(state),
    editedOrder: user$.getEditedOrder(state) as any,
    user: user$.getDetails(state),
  }))
  const [selectValue, setSelectValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const userWarehouseId = user.shippingInfo.warehouseId

  const ordersWithDeliveryIssues = props.pastOrders.filter((pastOrder) =>
    Boolean(pastOrder.deliveryIssues?.length),
  )
  const orderIdsWithDeliveryIssues = ordersWithDeliveryIssues.map((orderWithDeliveryIssues) => {
    return { label: `Order ID: ${orderWithDeliveryIssues.id}`, value: orderWithDeliveryIssues.id }
  })

  const orderWithDeliveryIssues = ordersWithDeliveryIssues.find(
    (orderDeliveryIssues) => String(orderDeliveryIssues.id) === selectValue,
  )

  const now = moment.utc()
  const nextTuesday = moment.utc().set({
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    ms: 0,
  })
  const previousEditableTo = moment
    .utc()
    .set({
      day: 6,
      hour: 22,
      minute: 0,
      second: 0,
      ms: 0,
    })
    .subtract(1, 'week')
  const nextEditableTo = moment.utc().set({
    day: 6,
    hour: 22,
    minute: 0,
    second: 0,
    ms: 0,
  })

  const editableTo = now.isBefore(nextTuesday) ? previousEditableTo : nextEditableTo

  const productsWithCountsAndStock = data
    .map((product) => {
      return {
        ...product,
        inStock: isInStock(product, userWarehouseId, editableTo),
        count:
          editedOrder.products.find((addedProduct) => addedProduct.id === product.id)?.count || 0,
      }
    })
    .filter((product) => {
      if (product.type === ProductType.misc && product.freeGift === false) {
        return false
      }
      if (product.type === ProductType.booster || product.published === false) {
        return false
      }
      return true
    })
    .sort((a, b) => b.count - a.count)

  const handleSubmit = async () => {
    const productsToSubmit = editedOrder.products.map(({ id, count }) => {
      return { id, count }
    })
    setIsLoading(true)
    try {
      await Api.createManualOrder({ products: productsToSubmit }, user.id)
      props.closeHandler()
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (orderWithDeliveryIssues) {
      const orderAddOns = orderWithDeliveryIssues.addOns.flatMap(({ products }) => {
        return products.map((product) => {
          return {
            count: product.count,
            id: product.id,
            inStock: productsWithCountsAndStock.find(
              (productWithStock) => productWithStock.id === product.id,
            ).inStock,
          }
        })
      })

      const orderProducts = orderWithDeliveryIssues.products.map((product) => {
        return {
          count: product.count,
          id: product.id,
          inStock: productsWithCountsAndStock.find(
            (productWithStock) => productWithStock.id === product.id,
          ).inStock,
        }
      })
      user$.call.setEditedOrder(
        {
          ...initialState.deliverySchedule.editedOrder,
          type: orderWithDeliveryIssues.type,
          editableTo: moment.utc(orderWithDeliveryIssues.editableTo),
          products: [...orderProducts, ...orderAddOns],
        },
        null,
        null,
      )
      props.handleTabChange(orderWithDeliveryIssues.type)
    } else {
      user$.call.setEditedOrder(
        {
          ...initialState.deliverySchedule.editedOrder,
          type: ProductType.blend,
          editableTo: moment.utc(),
          products: [],
        },
        null,
        null,
      )
    }
  }, [selectValue])

  const products = groupBy(productsWithCountsAndStock, 'type')
  const selectedProductsCount = Object.keys(products).reduce((acc, productType) => {
    const productCountTotal = getProductTotal(products[productType])
    if (acc[productType]) {
      acc[productType] += productCountTotal
    } else {
      acc[productType] = productCountTotal
    }
    return acc
  }, {})

  return {
    products,
    selectedProductsCount,
    handleSubmit,
    selectValue,
    setSelectValue,
    orderIdsWithDeliveryIssues,
    isLoading,
  }
}

/**
 * Checks if product is in stock.
 *
 * @param product - Product.
 * @param userWarehouseId - User warehouse id.
 * @param editableTo - Editable to.
 */
function isInStock(product: any, userWarehouseId: number, editableTo: moment.Moment): boolean {
  const replacement = product.productReplacements.find(
    ({ warehouseId }: { warehouseId: number }) => Number(warehouseId) === userWarehouseId,
  )
  if (replacement && moment(replacement.availability).isAfter(editableTo)) {
    return false
  }
  return true
}
