export default function PromoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#838A9D"
      viewBox="0 0 128 128"
    >
      <title>Promo</title>
      <circle cx="67.999" cy="73.001" r="3" />
      <circle cx="51.998" cy="51" r="3" />
      <path
        fill="#838A9D"
        d="M109.323 32.743a3.963 3.963 0 00-2.311-.745 4.068 4.068 0 00-.668.056 3.977 3.977 0 00-2.598 1.621v.001a4 4 0 105.577-.933z"
      />
      <path
        fill="#838A9D"
        d="M121.293 21.152l-30.94-8.607a21.062 21.062 0 00-14.41 1.856L4.499 56.16A9.037 9.037 0 001.2 68.46l24.997 43.086a9.029 9.029 0 0012.312 3.238l72.595-41.886a21.275 21.275 0 008.807-11.435l7.79-29.271a9.112 9.112 0 00-6.409-11.039zM19.401 67.765l-1.085-1.68a2 2 0 113.362-2.169l1.084 1.681a2 2 0 11-3.361 2.168zm3.794 5.882l-1.084-1.68a2 2 0 013.362-2.169l1.084 1.681a2 2 0 11-3.362 2.168zm3.795 5.882l-1.084-1.68a2 2 0 013.362-2.169l1.084 1.681a2 2 0 11-3.362 2.168zm3.796 5.883l-1.084-1.68a2 2 0 013.361-2.169l1.084 1.681a2 2 0 11-3.361 2.168zm3.795 5.882l-1.084-1.68a2 2 0 113.361-2.169l1.084 1.681a2 2 0 11-3.362 2.168zm6.5 6.388a2 2 0 01-2.764-.597l-1.025-1.59a2 2 0 113.361-2.168l1.026 1.59a2 2 0 01-.597 2.765zM44.999 51a7 7 0 117 7 7.008 7.008 0 01-7-7zm4.999 27a2 2 0 01-1.626-3.161l20-28.001a2 2 0 113.256 2.324l-20.001 28a1.997 1.997 0 01-1.63.839zM68 80a7 7 0 117-7 7.008 7.008 0 01-7 7zm13.404-49.236l-1.084-1.68a2 2 0 113.361-2.169l1.085 1.681a2 2 0 11-3.362 2.168zm3.795 5.882l-1.084-1.68a2 2 0 013.361-2.169l1.084 1.681a2 2 0 11-3.361 2.168zm3.795 5.882l-1.084-1.68a2 2 0 013.361-2.169l1.084 1.681a2 2 0 11-3.361 2.168zm3.795 5.883l-1.084-1.68a2 2 0 113.362-2.169l1.084 1.681a2 2 0 11-3.362 2.168zm3.795 5.882l-1.084-1.68a2 2 0 113.362-2.169l1.084 1.68a2 2 0 11-3.362 2.169zm6.501 6.388a2 2 0 01-2.764-.597l-1.026-1.59a2 2 0 013.362-2.168l1.025 1.59a2 2 0 01-.597 2.765zm10.426-20.028a7.946 7.946 0 01-5.197 3.238 8.146 8.146 0 01-1.328.11 8 8 0 116.525-3.348z"
      />
    </svg>
  )
}
