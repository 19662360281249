const nameSpace = 'app/Warehouses'
export const FETCH_WAREHOUSES = `${nameSpace}/FETCH_WAREHOUSES`
export const FETCH_WAREHOUSES_SUCCESS = `${nameSpace}/FETCH_WAREHOUSES_SUCCESS`
export const FETCH_WAREHOUSES_FAIL = `${nameSpace}/FETCH_WAREHOUSES_FAIL`
export const FETCH_CARRIERS = `${nameSpace}/FETCH_CARRIERS`
export const FETCH_CARRIERS_SUCCESS = `${nameSpace}/FETCH_CARRIERS_SUCCESS`
export const FETCH_CARRIERS_FAIL = `${nameSpace}/FETCH_CARRIERS_FAIL`
export const FETCH_CARRIER_SERVICES = `${nameSpace}/FETCH_CARRIER_SERVICES`
export const FETCH_CARRIER_SERVICES_SUCCESS = `${nameSpace}/FETCH_CARRIER_SERVICES_SUCCESS`
export const FETCH_CARRIER_SERVICES_FAIL = `${nameSpace}/FETCH_CARRIER_SERVICES_FAIL`
