import { SHOW_NOTIFICATION, CLOSE_NOTIFICATION } from './constants'
let id = 0
const initialState = []

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return state.concat({
        id: id++,
        text: action.payload.text,
      })

    case CLOSE_NOTIFICATION:
      return state.slice(1)

    default:
      return state
  }
}

export default notificationsReducer
