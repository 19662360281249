const reducers = (state: any) => ({
  setFaultyOrderReasons(reasons: any) {
    // @ts-expect-error TODO: Fix error
    const formattedReasons = reasons.map((reason) => ({
      isDeprecated: Boolean(reason.deprecatedAt),
      label: reason.description,
      value: reason.id,
    }))
    return { ...state, reasons: formattedReasons }
  },
})
export default reducers
