import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function fetchLabels(store: any, self$: any) {
  return async (): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const response = await Api.fetchLabels()
      self$.call.setLabels(response)
      self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

export function editLabel(store: any, self$: any) {
  return async (payload: { id: number; data: { name: string; color: string } }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.editLabel(payload.id, payload.data)
      const response = await Api.fetchLabels()
      self$.call.setLabels(response)
      self$.call.setStatus('loaded')
      alert$.call.setNotification('Successfully edited label')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

export function createLabel(store: any, self$: any) {
  return async (payload: {
    data: { name: string; color: string }
    onSuccess: (id: number) => void
  }): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      const { id } = await Api.createLabel(payload.data)
      const response = await Api.fetchLabels()
      self$.call.setLabels(response)
      self$.call.setStatus('loaded')
      payload.onSuccess(id)
      alert$.call.setNotification('Successfully created new label')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

export function deleteLabel(store: any, self$: any) {
  return async (id: number): Promise<void> => {
    loader$.call.show()
    self$.call.setStatus('loading')
    try {
      await Api.deleteLabel(id)
      const response = await Api.fetchLabels()
      self$.call.setLabels(response)
      self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}
