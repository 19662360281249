import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function fetchAllPlans(store: any, self$: any) {
  return async (): Promise<void> => {
    loader$.call.show()
    self$.call.setLoading()
    try {
      const response = await Api.fetchAllPlans()
      self$.call.setLoaded(response)
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
      self$.call.setError()
    }
    loader$.call.hide()
  }
}
