import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import { fetchSymbolsSuccess, fetchSymbolsFail } from './actions'
import { FETCH_SYMBOLS } from './constants'

const fetchSymbolsEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_SYMBOLS).switchMap(() =>
    Observable.fromPromise(Api.fetchSymbols())
      .map((result) => fetchSymbolsSuccess(result))
      .catch((e) => Observable.of(fetchSymbolsFail(), showNotification(e))),
  )

export default [fetchSymbolsEpic]
