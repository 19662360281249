interface Props {
  width: number
  height: number
}

export default function ModifyIcon({ width = 10, height = 17 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg">
      <title>Modify</title>
      <g fill="#585D61" fillRule="evenodd">
        <path d="M5 0C4.54 0 .5.048.5 1.687v9.97c0 .008.003.014.003.023 0 .027.005.053.008.078l.01.04.03.078.018.032c.016.03.034.057.055.084l.01.015v.003l3.974 4.8c.1.12.246.19.4.19.154 0 .3-.07.4-.19l3.973-4.8c.024-.03.045-.064.063-.098.004-.01.006-.02.01-.028l.03-.084.008-.04c.006-.026.01-.053.01-.08l.004-.024V1.688C9.5.048 5.46 0 5 0zm-.707 14.763L1.98 11.97c.757-.175 1.952-.397 3.17-.397.332 0 .654.017.96.045.065.03.137.048.214.048.04 0 .077-.005.113-.014.637.075 1.19.192 1.6.296l-2.33 2.814c-.154-.233-.413-.386-.707-.386-.295 0-.554.153-.707.387zM5.796 3.375v7.127c-.21-.01-.425-.018-.645-.018-.32 0-.63.014-.94.038V3.376H5.8zm2.647 0v7.553c-.4-.107-.948-.233-1.592-.323l.01-7.23h1.59zM3.147 10.64c-.636.092-1.19.212-1.592.31V3.375H3.15v7.264z" />
        <path d="M7.445 13.722C6.74 13.265 5.9 13 5 13c-.912 0-1.76.27-2.47.738-.612.403.93 1.616 2.47 1.61 1.54-.005 3.062-1.226 2.445-1.626z" />
      </g>
    </svg>
  )
}
