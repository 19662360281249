import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchDietaryTags, editDietaryTag, createDietaryTag, deleteDietaryTag } from './effects'
import { setDietaryTags, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const dietaryTags$ = makeModel({
  effects: makeEffects({
    createDietaryTag,
    deleteDietaryTag,
    editDietaryTag,
    fetchDietaryTags,
  }),
  prefix: 'dietaryTags',
  reducers: makeReducers({
    setDietaryTags,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default dietaryTags$
