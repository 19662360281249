import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'
import { ValidProductType } from '~/app/common/types'

export function fetchCancellationReasons(store: any, self$: any) {
  return async (payload: { planType: ValidProductType }): Promise<void> => {
    await self$.call.setStatus({ status: 'loading', type: payload.planType })
    try {
      const response = await Api.fetchCancellationReasons(payload.planType)
      await store.dispatch(
        self$.setCancellationReasons({ [payload.planType]: formatReasons(response) }),
      )
      await self$.call.setStatus({ status: 'loaded', type: payload.planType })
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      alert$.call.setNotification(err.message)
    }
    loader$.call.hide()
  }
}

const formatReasons = (reasons: any) =>
  reasons.reduce(
    // @ts-expect-error TODO: Fix error
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.name]: currentValue.reasons,
    }),
    {},
  )
