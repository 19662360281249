import BabyBottle from './BabyBottle'
import Puree from './Puree'
import FingerFood from './FingerFood'
import FingerFoodAndPuree from './FingerFoodAndPuree'

type Props = {
  className?: string
  value: string
}

export default function EatingPhase(props: Props) {
  switch (props.value) {
    case 'notSolids':
      return <BabyBottle {...props} />
    case 'pureed':
      return <Puree />
    case 'finger':
      return <FingerFood {...props} />
    case 'both':
      return <FingerFoodAndPuree {...props} />
    default:
      return null
  }
}
