import queryString from 'query-string'
import { merge } from 'ramda'
import {
  FETCH_BLUEPRINTS,
  FETCH_BLUEPRINTS_SUCCESS,
  FETCH_BLUEPRINTS_FAIL,
  FETCH_BLUEPRINT_DETAIL,
  FETCH_BLUEPRINT_DETAIL_SUCCESS,
  CREATE_BLUEPRINT_SUCCESS,
  UPDATE_BLUEPRINT_SUCCESS,
  UPDATE_BLUEPRINT_FAIL,
  UPDATE_BLUEPRINTS_PAGE,
} from './constants'

const getCurrentPage = () => Number(queryString.parse(location.search)['usecase-page']) || 1

const initialState = {
  activePage: getCurrentPage(),
  data: [],
  dataIsUpdated: true,
  dataLoaded: false,
  dataLoading: false,
  detail: null,
  detailLoaded: false,
  detailLoading: false,
}

function blueprintsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BLUEPRINTS:
      return merge(state, {
        dataLoaded: false,
        dataLoading: true,
      })

    case FETCH_BLUEPRINTS_SUCCESS:
      return merge(state, {
        data: action.payload.data,
        dataIsUpdated: true,
        dataLoaded: true,
        dataLoading: false,
      })

    case FETCH_BLUEPRINTS_FAIL:
      return merge(state, {
        data: [],
        dataLoaded: false,
        dataLoading: false,
      })

    case CREATE_BLUEPRINT_SUCCESS:
    case UPDATE_BLUEPRINT_SUCCESS:
      return merge(state, {
        dataIsUpdated: false,
        detail: action.payload.data,
      })

    case UPDATE_BLUEPRINT_FAIL: {
      // TODO: Deal with failure in a better way
      // NOTE: Right now we are only creating a new detail object
      // to trigger a check failure on the save button when checking
      // the blueprint detail model
      return merge(state, {
        dataIsUpdated: false,
        detail: { ...state.detail },
      })
    }

    case FETCH_BLUEPRINT_DETAIL:
      return merge(state, {
        detailLoaded: false,
        detailLoading: true,
      })

    case FETCH_BLUEPRINT_DETAIL_SUCCESS:
      return merge(state, {
        detail: action.payload.data,
        detailLoaded: true,
        detailLoading: false,
      })

    case UPDATE_BLUEPRINTS_PAGE:
      return merge(state, {
        activePage: getCurrentPage(),
      })

    default:
      return state
  }
}

export default blueprintsReducer
