import { compose, withHandlers, withState } from 'recompose'
import user$ from '~/app/store/user'
export default compose(
  withState('editDeliveryInfo', 'setEditDeliveryInfo', false),
  withState('editShippingAddress', 'setEditShippingAddress', false),
  withState('editBillingAddress', 'setEditBillingAddress', false),
  withHandlers({
    handleCarrierChange:
      ({ fetchCarrierServices }) =>
      (value) => {
        fetchCarrierServices(value)
      },
    handleConfirm:
      ({ user }) =>
      (data) => {
        user$.call.editUserDetails({
          data,
          id: user.id,
        })
      },
    toggleEdit:
      ({ setEditShippingAddress, setEditDeliveryInfo, setEditBillingAddress }) =>
      () => {
        setEditShippingAddress(() => false)
        setEditBillingAddress(() => false)
        setEditDeliveryInfo(() => false)
      },
    toggleEditBillingAddress:
      ({ editBillingAddress, setEditBillingAddress }) =>
      () => {
        setEditBillingAddress(() => !editBillingAddress)
      },
    toggleEditShippingAddress:
      ({ editShippingAddress, setEditShippingAddress }) =>
      () => {
        setEditShippingAddress(() => !editShippingAddress)
      },
  }),
)
