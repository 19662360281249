import type * as reducers from './reducers'

export interface UserLogsState {
  data: UserAuditLogAttributes[]
  limit: number
  page: number
  status: UserLogsStatus
}

export interface UserAuditLogAttributes {
  id: number
  userId: number
  timestamp: Date
  eventType: string
  description: string | null
  metadata: object | null
}

export type RootState = object & { logs: UserLogsState }

export enum UserLogsStatus {
  pending = 'pending',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export type UserLogsReducers = {
  [key in keyof typeof reducers]: ReturnType<(typeof reducers)[key]>
}
