import Button from '~/app/components/Button/Button'
import LightBox from '~/app/components/LightBox'
import { FieldSet } from '~/app/components/FieldSet'
import { CenteredRow, Full } from '~/app/components/FormGrid'
import { Block } from '~/app/components/Block'
import useForm from '~/app/hooks/useForm'
import { Error } from '~/app/components/Input/styled'
import Loader from '~/app/components/Loader'
import { ValidProductType, StatusType, ReasonTypes } from '~/app/common/types'
import { SUSPENSION_ACTION } from '~/app/store/user/subscriptions/constants'

import formDefinition from './formDefinition'
import { sortReasons } from './utils'

interface Props {
  isOpen: boolean
  handleCancel: (reasonsSelected: number[], value: string) => void
  type: ValidProductType
  reasons: {
    account: ReasonTypes
    delivery: ReasonTypes
    general: ReasonTypes
    food: ReasonTypes
    admin: ReasonTypes
  }[]
  closeHandler: () => void
  reasonsStatus: StatusType
  suspensionAction: (typeof SUSPENSION_ACTION)[keyof typeof SUSPENSION_ACTION]
}

export default function CancellationForm(props: Props) {
  const { buttonText, form, isValid, reasonsSelected, title } = useController(props)

  if (props.reasonsStatus !== 'loaded') {
    return <Loader />
  }

  return (
    // @ts-ignore
    <LightBox
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      height={830}
      maxWidth="1200px"
      width="90%"
    >
      {/* @ts-ignore */}
      <Block paddingBottom={0}>
        <FieldSet legend={title}>
          {!isValid && (
            <CenteredRow>
              <Error>Please select 1-3 reasons with notes</Error>
            </CenteredRow>
          )}

          <CenteredRow>
            <Full>
              {form.renderField('account', {
                // @ts-ignore
                options: sortReasons(props.reasons.account).map((reason) => ({
                  label: reason.value,
                  value: reason.id,
                })),
                value: form.formState.account.value,
              })}
            </Full>
          </CenteredRow>

          <CenteredRow>
            <Full>
              {form.renderField('food', {
                // @ts-ignore
                options: sortReasons(props.reasons.food).map((reason) => ({
                  label: reason.value,
                  value: reason.id,
                })),
                value: form.formState.food.value,
              })}
            </Full>
          </CenteredRow>

          <CenteredRow>
            <Full>
              {form.renderField('delivery', {
                // @ts-ignore
                options: sortReasons(props.reasons.delivery).map((reason) => ({
                  label: reason.value,
                  value: reason.id,
                })),
                value: form.formState.delivery.value,
              })}
            </Full>
          </CenteredRow>

          <CenteredRow>
            <Full>
              {form.renderField('general', {
                // @ts-ignore
                options: sortReasons(props.reasons.general).map((reason) => ({
                  label: reason.value,
                  value: reason.id,
                })),
                value: form.formState.general.value,
              })}
            </Full>
          </CenteredRow>

          <CenteredRow>
            <Full>
              {form.renderField('admin', {
                // @ts-ignore
                options: sortReasons(props.reasons.admin).map((reason) => ({
                  label: reason.value,
                  value: reason.id,
                })),
                value: form.formState.admin.value,
              })}
            </Full>
          </CenteredRow>

          <CenteredRow>
            <Full>{form.renderField('notes', {})}</Full>
          </CenteredRow>
        </FieldSet>
      </Block>

      <CenteredRow>
        <Button
          danger
          // @ts-ignore
          large
          onClick={() => props.handleCancel(reasonsSelected, form.formState.notes.value)}
          disabled={!isValid}
        >
          {buttonText}
        </Button>
      </CenteredRow>
    </LightBox>
  )
}

function useController(props: Props) {
  const form = useForm({
    // @ts-ignore
    fieldDefinitions: formDefinition,
    formData: {},
  })

  const buttonText = `${props.suspensionAction} subscription`

  const reasonsSelected = Object.keys(form.formState).reduce(
    (accumulator, currentValue) =>
      currentValue !== 'notes' && Number(form.formState[currentValue].value)
        ? // @ts-ignore
          accumulator.concat(Number(form.formState[currentValue].value))
        : accumulator,
    [],
  )

  const isValid = Boolean(form.isValid && reasonsSelected.length)

  return {
    buttonText,
    form,
    isValid,
    reasonsSelected,
    title: buttonText,
  }
}
