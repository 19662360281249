export default function getSubscriptionStatus(props: any) {
  const { deactivatedAt, deletedAt } = props
  if (!deletedAt && !deactivatedAt) {
    return 'active'
  } else if (deletedAt) {
    return 'cancelled'
  }
  if (deactivatedAt && !deletedAt) {
    return 'paused'
  }
  return 'incomplete'
}
