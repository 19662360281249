export const FETCH_PRODUCTS = 'app/Products/FETCH_PRODUCTS'
export const FETCH_PRODUCTS_SUCCESS = 'app/Products/FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAIL = 'app/Products/FETCH_PRODUCTS_FAIL'

export const FETCH_PRODUCT_DETAIL = 'app/Products/FETCH_PRODUCT_DETAIL'
export const FETCH_PRODUCT_DETAIL_SUCCESS = 'app/Products/FETCH_PRODUCT_DETAIL_SUCCESS'

export const CREATE_PRODUCT = 'app/Products/CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'app/Products/CREATE_PRODUCT_SUCCESS'

export const EDIT_PRODUCT = 'app/Products/EDIT_PRODUCT'
export const EDIT_PRODUCT_SUCCESS = 'app/Products/EDIT_PRODUCT_SUCCESS'

export const DELETE_PRODUCT = 'app/Products/DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'app/Products/DELETE_PRODUCT_SUCCESS'

export const UPDATE_PRODUCTS_PAGE = 'app/Products/UPDATE_PRODUCTS_PAGE'

export const FETCH_PRODUCTS_REPLACEMENTS = 'app/Products/FETCH_PRODUCTS_REPLACEMENTS'
export const FETCH_PRODUCTS_REPLACEMENTS_SUCCESS =
  'app/Products/FETCH_PRODUCTS_REPLACEMENTS_SUCCESS'
export const SET_PRODUCT_REPLACEMENTS = 'app/Products/SET_PRODUCT_REPLACEMENTS'
export const CREATE_PRODUCT_REPLACEMENT_SUCCESS = 'app/Products/CREATE_PRODUCT_REPLACEMENT_SUCCESS'
export const UPDATE_PRODUCT_REPLACEMENT_SUCCESS = 'app/Products/UPDATE_PRODUCT_REPLACEMENT_SUCCESS'
export const DELETE_PRODUCT_REPLACEMENT_SUCCESS = 'app/Products/DELETE_PRODUCT_REPLACEMENT_SUCCESS'

export const FETCH_GIFT_PRODUCTS = 'app/Products/FETCH_GIFT_PRODUCTS'
export const FETCH_GIFT_PRODUCTS_SUCCESS = 'app/Products/FETCH_GIFT_PRODUCTS_SUCCESS'

export const SET_PRODUCT_SEQUENCE = 'app/Products/SET_PRODUCT_SEQUENCE'
export const SET_PRODUCT_SEQUENCE_SUCCESS = 'app/Products/SET_PRODUCT_SEQUENCE_SUCCESS'

export const FETCH_ALL_PRODUCTS = 'app/Products/FETCH_ALL_PRODUCTS'
export const FETCH_ALL_PRODUCTS_SUCCESS = 'app/Products/FETCH_ALL_PRODUCTS_SUCCESS'
