import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import {
  fetchCarriersSuccess,
  fetchCarriersFail,
  fetchCarrierServicesSuccess,
  fetchCarrierServicesFail,
} from './actions'
import { FETCH_CARRIERS, FETCH_CARRIER_SERVICES } from './constants'

const fetchCarriersEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_CARRIERS).switchMap(() =>
    Observable.fromPromise(Api.fetchCarriers())
      .map((result) => fetchCarriersSuccess(result))
      .catch((e) => Observable.of(fetchCarriersFail(), showNotification(e))),
  )

const fetchCarrierServicesEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_CARRIER_SERVICES).switchMap((action) =>
    Observable.fromPromise(Api.fetchCarrierServices(action.payload.carrier))
      .map((result) => fetchCarrierServicesSuccess(result))
      .catch((e) => Observable.of(fetchCarrierServicesFail(), showNotification(e))),
  )

export default [fetchCarriersEpic, fetchCarrierServicesEpic]
