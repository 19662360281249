import { createAction } from '~/app/common/actions'
import { FETCH_ORDERS, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAIL } from './constants'

// @TODO: [LS-3138] remove FF check once `enable_removed_linked_templates` is fully rolled out
export const fetchOrders = (userId: number, usePastOrdersV3: boolean) =>
  createAction(FETCH_ORDERS, {
    usePastOrdersV3,
    userId,
  })
export const fetchOrdersSuccess = (data) =>
  createAction(FETCH_ORDERS_SUCCESS, {
    data,
  })
export const fetchOrdersFail = () => createAction(FETCH_ORDERS_FAIL)
