import queryString from 'query-string'
import { merge } from 'ramda'
import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  UPDATE_USERS_PAGE,
  SEARCH_USER,
} from './constants'

const initialState = {
  activePage: getCurrentPage(),
  data: [],
  dataIsUpdated: false,
  dataLoaded: false,
  dataLoading: false,
  searchTerm: getCurrentSearchTerm(),
}

export default function usersListReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case FETCH_USERS:
      return merge(state, {
        data: [],
        dataLoaded: false,
        dataLoading: true,
      })

    case FETCH_USERS_SUCCESS:
      return merge(state, {
        data: action.payload.data,
        dataIsUpdated: true,
        dataLoaded: true,
        dataLoading: false,
      })

    case FETCH_USERS_FAIL:
      return merge(state, {
        data: [],
        dataLoaded: false,
        dataLoading: false,
      })

    case UPDATE_USERS_PAGE:
      return merge(state, {
        activePage: getCurrentPage(),
      })

    case SEARCH_USER:
      return merge(state, {
        searchTerm: getCurrentSearchTerm(),
      })

    default:
      return state
  }
}

function getCurrentPage() {
  return Number(queryString.parse(location.search)['user-page']) || 1
}

function getCurrentSearchTerm() {
  return queryString.parse(location.search)['user-search'] || ''
}
