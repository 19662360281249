import Checkbox from '../Checkbox'
import { Wrap } from './styled'

interface Option {
  [key: string]: number | string
  name: string
}

type $Props = {
  value: Array<Option>
  options: Array<Option>
  onChange?: () => void
  idType: string
  disabled?: boolean
}

const MultipleCheckboxes = ({ value = [], options, onChange, idType = 'id', disabled }: $Props) => {
  // @ts-expect-error TODO: Fix error
  function handleChange(option) {
    const isAlreadySelected = value.some((x) => x[idType] === option.id)
    if (isAlreadySelected) {
      // @ts-expect-error TODO: Fix error
      onChange(value.filter((x) => x[idType] !== option.id))
      return
    }
    // @ts-expect-error TODO: Fix error
    onChange(value.concat({ [idType]: option.id, name: option.name }))
  }

  return (
    <Wrap>
      {options.map((t, index) => (
        <Checkbox
          key={`${index}-${t[idType]}-${t.name}`}
          // @ts-expect-error TODO: Fix error
          value={value.some((x) => x[idType] === t.id)}
          text={t.name}
          onChange={() => handleChange(t)}
          disabled={disabled}
        />
      ))}
    </Wrap>
  )
}

export default MultipleCheckboxes
