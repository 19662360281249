interface Props {
  onClick?: () => void
  className?: string
  fillColor: string
}

export default function BoosterIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Booster Icon</title>
      <desc>Little Spoon booster packet icon</desc>
      <path
        fill={props.fillColor}
        fillRule="evenodd"
        d="M11.033.278l2.689 2.69a.95.95 0 0 1 0 1.343l-.336.336L9.353.614l.336-.336a.95.95 0 0 1 1.344 0zM9.016.951l4.033 4.033a34.674 34.674 0 0 0-4.37 3.698 34.618 34.618 0 0 0-3.695 4.367L.95 9.016a34.6 34.6 0 0 0 4.367-3.695A34.656 34.656 0 0 0 9.016.95zM.614 9.353l4.033 4.033-.336.336a.95.95 0 0 1-1.344 0l-2.689-2.69a.95.95 0 0 1 0-1.343l.336-.336z"
      />
    </svg>
  )
}
