import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import { fetchOrdersSuccess, fetchOrdersFail } from './actions'
import { FETCH_ORDERS } from './constants'

const fetchOrdersEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_ORDERS).switchMap((action) =>
    Observable.fromPromise(Api.fetchOrders(action.payload.userId))
      .map((result) => fetchOrdersSuccess(result))
      .catch((e) => Observable.of(fetchOrdersFail(), showNotification(e))),
  )

export default [fetchOrdersEpic]
