// @ts-expect-error TODO: Fix error
import queryString from 'query-string'
import { merge } from 'ramda'
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
  CREATE_PRODUCT_SUCCESS,
  FETCH_PRODUCT_DETAIL,
  FETCH_PRODUCT_DETAIL_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCTS_PAGE,
  FETCH_PRODUCTS_REPLACEMENTS,
  FETCH_PRODUCTS_REPLACEMENTS_SUCCESS,
  SET_PRODUCT_REPLACEMENTS,
  CREATE_PRODUCT_REPLACEMENT_SUCCESS,
  UPDATE_PRODUCT_REPLACEMENT_SUCCESS,
  DELETE_PRODUCT_REPLACEMENT_SUCCESS,
  FETCH_GIFT_PRODUCTS,
  FETCH_GIFT_PRODUCTS_SUCCESS,
  SET_PRODUCT_SEQUENCE_SUCCESS,
  FETCH_ALL_PRODUCTS,
  FETCH_ALL_PRODUCTS_SUCCESS,
} from './constants'
import { remapPicturesToKeys } from './mappers'

const getCurrentPage = () => Number(queryString.parse(location.search)['product-page']) || 1

const initialState = {
  activePage: getCurrentPage(),
  allProducts: [],
  allProductsLoaded: false,
  allProductsLoading: false,
  data: [],
  dataByIdOrSku: null,
  dataIsUpdated: true,
  dataLoaded: false,
  dataLoading: false,
  detail: null,
  detailLoaded: false,
  detailLoading: false,
  giftProducts: [],
  giftProductsLoaded: false,
  giftProductsLoading: false,
  replacements: [],
  replacementsLoaded: false,
  replacementsLoading: false,
  sequenceUpdated: false,
  source: null,
}

function productsReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return merge(state, {
        allProductsLoaded: false,
        dataLoaded: false,
        dataLoading: true,
        sequenceUpdated: false,
        source: action.payload.source,
      })
    case FETCH_PRODUCTS_SUCCESS: {
      const data = action.payload.data.map(remapPicturesToKeys)
      const dataByIdOrSku = Object.values(data).reduce((accum, product) => {
        // @ts-ignore
        accum[String(product.id)] = product
        // @ts-ignore
        accum[String(product.sku)] = product

        return accum
      }, {})

      return merge(state, {
        data,
        dataByIdOrSku,
        dataIsUpdated: true,
        dataLoaded: true,
        dataLoading: false,
      })
    }
    case FETCH_PRODUCTS_FAIL:
      return merge(state, {
        data: [],
        dataByIdOrSku: null,
        dataLoaded: false,
        dataLoading: false,
      })
    case CREATE_PRODUCT_SUCCESS:
    case EDIT_PRODUCT_SUCCESS:
      return merge(state, {
        dataIsUpdated: false,
        detail: [action.payload.data].map(remapPicturesToKeys)[0],
      })
    case DELETE_PRODUCT_SUCCESS:
      return merge(state, {
        dataIsUpdated: false,
      })
    case FETCH_PRODUCT_DETAIL:
      return merge(state, {
        detailLoaded: false,
        detailLoading: true,
      })
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return merge(state, {
        detail: [action.payload.data].map(remapPicturesToKeys)[0],
        detailLoaded: true,
        detailLoading: false,
      })
    case UPDATE_PRODUCTS_PAGE:
      return merge(state, {
        activePage: getCurrentPage(),
      })
    case FETCH_PRODUCTS_REPLACEMENTS:
      return merge(state, {
        replacementsLoaded: false,
        replacementsLoading: true,
      })
    case FETCH_PRODUCTS_REPLACEMENTS_SUCCESS:
      return merge(state, {
        replacements: action.payload.data,
        replacementsLoaded: true,
        replacementsLoading: false,
      })
    case SET_PRODUCT_REPLACEMENTS:
      return merge(state, {
        replacementsLoaded: false,
        replacementsLoading: true,
      })
    case CREATE_PRODUCT_REPLACEMENT_SUCCESS:
      return merge(state, {
        // @ts-expect-error TODO: Fix error
        replacements: state.replacements.concat([action.payload.data]),
        replacementsLoaded: true,
        replacementsLoading: false,
      })
    case UPDATE_PRODUCT_REPLACEMENT_SUCCESS: {
      const updatedReplacement = action.payload.data
      return merge(state, {
        replacements: state.replacements.map((replacement) =>
          // @ts-expect-error TODO: Fix error
          replacement.id === updatedReplacement.id ? updatedReplacement : replacement,
        ),
        replacementsLoaded: true,
        replacementsLoading: false,
      })
    }
    case DELETE_PRODUCT_REPLACEMENT_SUCCESS:
      return merge(state, {
        replacements: state.replacements.filter(({ id }) => id !== action.payload.id),
        replacementsLoaded: true,
        replacementsLoading: false,
      })
    case FETCH_GIFT_PRODUCTS:
      return merge(state, {
        giftProductsLoaded: false,
        giftProductsLoading: true,
      })
    case FETCH_GIFT_PRODUCTS_SUCCESS:
      return merge(state, {
        // @ts-expect-error TODO: Fix error
        giftProducts: action.payload.data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),

        giftProductsLoaded: true,

        giftProductsLoading: false,
      })
    case SET_PRODUCT_SEQUENCE_SUCCESS:
      return merge(state, {
        dataIsUpdated: true,
        sequenceUpdated: true,
      })
    case FETCH_ALL_PRODUCTS:
      return merge(state, {
        allProductsLoaded: false,
        allProductsLoading: true,
        sequenceUpdated: false,
      })
    case FETCH_ALL_PRODUCTS_SUCCESS:
      return merge(state, {
        allProducts: action.payload.data.map(remapPicturesToKeys),
        allProductsLoaded: true,
        allProductsLoading: false,
      })
    default:
      return state
  }
}

export default productsReducer
