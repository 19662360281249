import { add, adjust, append, curry, evolve, findIndex, merge, pick, remove, whereEq } from 'ramda'
import { ApiUpcomingOrder } from './types'

/**
 * Sets upcoming orders
 */
export const setUpcomingOrders = (state: any) => (upcomingOrders: ApiUpcomingOrder[]) => {
  return {
    ...state,
    deliverySchedule: {
      ...state.deliverySchedule,
      upcomingOrders: mapToUpcomingOrdersShape(upcomingOrders),
    },
  }
}

/**
 * Sets order price summary
 */
export const setOrderSummary = (state: any) => (orderSummary: any) => {
  return {
    ...state,
    deliverySchedule: {
      ...state.deliverySchedule,
      orderSummary,
    },
  }
}

/**
 * Sets upcoming week orders summary
 */
export const setUpcomingWeekOrdersSummary = (state: any) => (upcomingWeekOrdersSummary: any) => {
  return {
    ...state,
    deliverySchedule: {
      ...state.deliverySchedule,
      upcomingWeekOrdersSummary,
    },
  }
}

/**
 * Sets second upcoming week orders summary
 */
export const setSecondUpcomingWeekOrdersSummary =
  (state: any) => (secondUpcomingWeekOrdersSummary: any) => {
    return {
      ...state,
      deliverySchedule: {
        ...state.deliverySchedule,
        secondUpcomingWeekOrdersSummary,
      },
    }
  }

/**
 * Sets edited order
 */
export const setEditedOrder = (state: any) => (order: any, index: number, kidId: number) => ({
  ...state,
  deliverySchedule: {
    ...state.deliverySchedule,
    editedOrder: merge(
      pick(
        [
          'id',
          'plan',
          'changeable',
          'isFirst',
          'chargedAt',
          'type',
          'addOns',
          'blueprintId',
          'deliveryDate',
          'summary',
        ],
        order,
      ),
      {
        addOnProducts:
          order.addOns &&
          order.addOns.flatMap(({ products, type }: any) =>
            products.map(({ id, count, inStock }: any) => ({
              count,
              id,
              inStock,
              type,
            })),
          ),
        editableTo: order.editableTo.toISOString(),
        index,
        kidId,
        modifyAddOnTemplate: order.modifyAddOnTemplate || false,
        products: order.products.map(({ id, count, inStock }: any) => ({ count, id, inStock })),
      },
    ),
  },
})

/**
 * Removes products from order
 */
export const removeFromOrder = (state: any) => (payload: any) => {
  const editedOrder = state.deliverySchedule.editedOrder
  const { type, productId } = payload
  const isAddOn = editedOrder.type !== type
  const products = isAddOn ? editedOrder.addOnProducts : editedOrder.products
  const productIndex = findIndex(whereEq({ id: productId }))(products)
  const decreaseAmount = 1

  if (productIndex > -1) {
    const productQty = products[productIndex].count
    if (isAddOn) {
      if (productQty > 1) {
        return {
          ...state,
          deliverySchedule: {
            ...state.deliverySchedule,
            editedOrder: {
              ...editedOrder,
              // @ts-ignore
              addOnProducts: adjust(curry(evolve({ count: add(-1) })), productIndex, products),
            },
          },
        }
      } else if (productQty === 1) {
        return {
          ...state,
          deliverySchedule: {
            ...state.deliverySchedule,
            editedOrder: {
              ...editedOrder,
              addOnProducts: remove(productIndex, 1, products),
            },
          },
        }
      }
    } else if (productQty > decreaseAmount) {
      return {
        ...state,
        deliverySchedule: {
          ...state.deliverySchedule,
          editedOrder: {
            ...editedOrder,
            products: adjust(
              // @ts-ignore
              curry(evolve({ count: add(-decreaseAmount) })),
              productIndex,
              products,
            ),
          },
        },
      }
    } else if (productQty === decreaseAmount) {
      return {
        ...state,
        deliverySchedule: {
          ...state.deliverySchedule,
          editedOrder: {
            ...editedOrder,
            products: remove(productIndex, 1, products),
          },
        },
      }
    }
    return state
  }
  return state
}

/**
 * Adds products to order
 */
export const addToOrder = (state: any) => (payload: any) => {
  const editedOrder = state.deliverySchedule.editedOrder
  const { type, inStock, productId } = payload
  const isAddOn = editedOrder.type !== type
  const products = isAddOn ? editedOrder.addOnProducts : editedOrder.products
  const productIndex = findIndex(whereEq({ id: productId }))(products)
  const increaseAmount = 1

  // If the product is already on the list we update its quantity if necessary
  if (isAddOn) {
    if (productIndex > -1) {
      return {
        ...state,
        deliverySchedule: {
          ...state.deliverySchedule,
          editedOrder: {
            ...editedOrder,
            // @ts-ignore
            addOnProducts: adjust(curry(evolve({ count: add(1) })), productIndex, products),
          },
        },
      }
    }
    // Else we add it for the first time
    return {
      ...state,
      deliverySchedule: {
        ...state.deliverySchedule,
        editedOrder: {
          ...editedOrder,
          addOnProducts: append({ count: 1, id: productId, inStock, type }, products),
        },
      },
    }
  }
  if (payload.mealsLeftToPick > 0) {
    if (productIndex > -1) {
      return {
        ...state,
        deliverySchedule: {
          ...state.deliverySchedule,
          editedOrder: {
            ...editedOrder,
            // @ts-ignore
            products: adjust(curry(evolve({ count: add(increaseAmount) })), productIndex, products),
          },
        },
      }
    }
    // Else we add it for the first time
    return {
      ...state,
      deliverySchedule: {
        ...state.deliverySchedule,
        editedOrder: {
          ...editedOrder,
          products: append({ count: increaseAmount, id: productId, inStock }, products),
        },
      },
    }
  }

  return state
}

/**
 * Adds products to manual order
 */
export const addToManualOrder = (state: any) => (payload: any) => {
  const editedOrder = state.deliverySchedule.editedOrder
  const { inStock, productId } = payload
  const products = editedOrder.products
  const productIndex = findIndex(whereEq({ id: productId }))(products)
  const increaseAmount = 1

  if (productIndex > -1) {
    return {
      ...state,
      deliverySchedule: {
        ...state.deliverySchedule,
        editedOrder: {
          ...editedOrder,
          // @ts-ignore
          products: adjust(curry(evolve({ count: add(increaseAmount) })), productIndex, products),
        },
      },
    }
  }
  // Else we add it for the first time
  return {
    ...state,
    deliverySchedule: {
      ...state.deliverySchedule,
      editedOrder: {
        ...editedOrder,
        products: append({ count: increaseAmount, id: productId, inStock }, products),
      },
    },
  }
}

/**
 * Removes products from manual order
 */
export const removeFromManualOrder = (state: any) => (payload: any) => {
  const editedOrder = state.deliverySchedule.editedOrder
  const { productId } = payload
  const products = editedOrder.products
  const productIndex = findIndex(whereEq({ id: productId }))(products)
  const decreaseAmount = 1

  if (productIndex > -1) {
    const productQty = products[productIndex].count
    if (productQty > decreaseAmount) {
      return {
        ...state,
        deliverySchedule: {
          ...state.deliverySchedule,
          editedOrder: {
            ...editedOrder,
            products: adjust(
              // @ts-ignore
              curry(evolve({ count: add(-decreaseAmount) })),
              productIndex,
              products,
            ),
          },
        },
      }
    } else if (productQty === decreaseAmount) {
      return {
        ...state,
        deliverySchedule: {
          ...state.deliverySchedule,
          editedOrder: {
            ...editedOrder,
            products: remove(productIndex, 1, products),
          },
        },
      }
    }
    return state
  }
  return state
}

/**
 * Maps upcoming orders to the expected order shape
 */
export function mapToUpcomingOrdersShape(upcomingOrders: ApiUpcomingOrder[]) {
  return upcomingOrders.flatMap((template) => {
    const orders = template.orders.map((order) => ({
      ...order,
      active: template.active,
      blueprintId: template.blueprintId,
      deliveryIssues: null,
      id: template.templateId,
      type: template.type,
    }))
    return {
      ...template,
      orders,
    }
  })
}
