import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export default function patchKid(store: any, self$: any) {
  return async (payload: { userId: number; kidId: number; data: any }): Promise<void> => {
    const { userId, kidId, data } = payload
    loader$.call.show()
    try {
      const response = await await Api.patchKid(userId, kidId, data)
      await store.dispatch(self$.setKidDetails(response))
      alert$.call.setNotification('Kid data has been updated')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}
