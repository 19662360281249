export { default as effectsMiddleWare } from './effectsMiddleWare'

let store = {
  dispatch: () => {
    throw new Error('dispatch stub called')
  },
  getState: () => {
    throw new Error('getState stub called')
  },
  subscribe() {
    throw new Error('subscribe stub called')
  },
}

export const registerStore = (reduxStore: typeof store) => {
  store = reduxStore
}

export const getStore = () => store
