import styled from 'styled-components'
export const HeaderSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -50px;
  width: 100%;
`
export const ProfileImage = styled.img`
  border-radius: 100%;
  height: 73px;
  margin: 0 auto;
  width: 73px;
`
export const Title = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 24px;
  line-height: 22px;
  margin: 16px 0;
`
export const UserInfo = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 8px 0;
  padding: 0;

  li {
    color: ${(props) => props.theme.color.darkGray};
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: 0 15px;
    text-transform: uppercase;
  }
`
export const TagsLine = styled.li`
  span:not(:first-child) {
    margin-left: 12px;
  }
`
export const AddTagButton = styled.button`
  border: 1px solid ${(props) => props.theme.color.gray};
  border-radius: 100px;
  box-sizing: border-box;
  color: ${(props) => props.theme.color.gray};
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-left: 12px;
  padding: 6px 12px;
  text-transform: uppercase;

  svg {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.gray};
    border: 1px solid ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.white};

    svg {
      fill: white !important;
    }
  }
`
export const ImageWrapper = styled.span`
  padding-right: 5px;
`
export const MagicWandSection = styled.div`
  margin-bottom: 10px;
  svg {
    margin-right: 8px;
    margin-left: 8px;
  }
`
export const MagicLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  max-width: 40%;
`
export const MagicLinkNotification = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  color: ${(props) => (props.danger ? props.theme.color.red : props.theme.color.green)};
`
export const LinkButton = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.color.green};
`
