import { compose } from 'recompose'
import { Header, InfoRow, LargeCell } from '~/app/components/Table'
import EditIconFull from '~/app/assets/svg/EditIconFull'
import type { Address } from '~/app/common/types'
import { SpacerRow, SpacerCol } from '../styled'
import { OptionsHeaderRow, EditBtn } from './styled'
import DeliveryShippingAddressForm from '../DeliveryShippingAddressForm'
import DeliveryBillingAddressForm from '../DeliveryBillingAddressForm'
import withDataLoader from './withDataLoader'
import withEditInfoController from './withEditInfoController'

interface Props {
  user: {
    transitDays: number
    carrier: string
    service: string
    warehouseId: number
    addresses: Address[]
    onboardingZip: string
  }
  shippingAddress: Address
  billingAddress: Address
  carriers: {
    code: string
    name: string
  }[]
  carrierServices: {
    code: string
    name: string
  }[]
  warehouses: {
    id: number
    name: string
  }[]
  editDeliveryInfo: boolean
  handleConfirm: () => void
  toggleEditShippingAddress: () => void
  editShippingAddress: boolean
  toggleEditBillingAddress: () => void
  editBillingAddress: boolean
  toggleEdit: () => void
}

function DeliveryInfo({
  user,
  shippingAddress,
  billingAddress,
  carriers,
  carrierServices,
  warehouses,
  editDeliveryInfo,
  handleConfirm,
  toggleEditShippingAddress,
  editShippingAddress,
  toggleEditBillingAddress,
  editBillingAddress,
  toggleEdit,
}: Props) {
  const optionsHeaderRow = (
    <OptionsHeaderRow>
      <td colSpan={8}>
        {(editShippingAddress || editDeliveryInfo || editBillingAddress) && (
          <div role="presentation" onClick={toggleEdit}>
            <EditIconFull />
          </div>
        )}
      </td>
    </OptionsHeaderRow>
  )

  const { addresses, service, transitDays, warehouseId } = user || {}
  const shipping = addresses.find((address) => address.type === 'shipping')
  const userCarrierService = carrierServices.find((carrierService) => {
    const shippingCode = service || shipping?.shippingInfo.service
    return carrierService.code === shippingCode
  })

  const displayShippingAddress = shippingAddress ? getDisplayAddress(shippingAddress) : ''
  const displayBillingAddress = billingAddress
    ? getDisplayAddress(billingAddress)
    : displayShippingAddress

  const billingFormAddress = billingAddress || shippingAddress
  const region = shipping
    ? shipping.shippingInfo.region.charAt(0).toUpperCase() + shipping.shippingInfo.region.slice(1)
    : null

  const packDay = shipping
    ? shipping.shippingInfo.packDay.charAt(0).toUpperCase() + shipping.shippingInfo.packDay.slice(1)
    : null

  if (editShippingAddress) {
    return (
      <tbody>
        {optionsHeaderRow}
        <tr>
          <td colSpan={6}>
            <DeliveryShippingAddressForm
              // @ts-ignore
              formData={{
                shippingAddress: shippingAddress.address,
                shippingApt: shippingAddress.apt,
                shippingCity: shippingAddress.city,
                shippingFirstName: shippingAddress.firstName,
                shippingLastName: shippingAddress.lastName,
                shippingState: shippingAddress.state,
                shippingZip: shippingAddress.zip,
              }}
              onConfirm={handleConfirm}
            />
          </td>
        </tr>
      </tbody>
    )
  }

  if (editBillingAddress) {
    return (
      <tbody>
        {optionsHeaderRow}
        <tr>
          <td colSpan={6}>
            <DeliveryBillingAddressForm
              // @ts-ignore
              formData={{
                billingAddress: billingFormAddress.address,
                billingApt: billingFormAddress.apt,
                billingCity: billingFormAddress.city,
                billingFirstName: billingFormAddress.firstName,
                billingLastName: billingFormAddress.lastName,
                billingState: billingFormAddress.state,
                billingZip: billingFormAddress.zip,
              }}
              onConfirm={handleConfirm}
            />
          </td>
        </tr>
      </tbody>
    )
  }

  if (shipping) {
    return (
      <tbody>
        {optionsHeaderRow}
        <SpacerRow />
        {shippingAddress &&
          shippingAddress.address && [
            <tr key="address-header">
              <SpacerCol />
              <Header colSpan={2}>Delivery Address</Header>
              <Header colSpan={2}>Billing Address</Header>
            </tr>,
            <InfoRow key="address-info">
              <SpacerCol />
              <LargeCell colSpan="2">
                {displayShippingAddress}
                <EditBtn onClick={toggleEditShippingAddress} />
              </LargeCell>
              <LargeCell colSpan="2">
                {billingAddress ? displayBillingAddress : displayShippingAddress}
                <EditBtn onClick={toggleEditBillingAddress} />
              </LargeCell>
            </InfoRow>,
          ]}
        <tr>
          <SpacerCol />
          <Header>Carrier</Header>
          <Header>Region</Header>
          <Header>Service</Header>
          <Header>Pack Day</Header>
          <Header>Transit Days</Header>
          <Header>Warehouse</Header>
          <Header />
          <SpacerCol />
        </tr>
        {region && (
          <InfoRow>
            <SpacerCol />
            <LargeCell>
              {carriers.length > 0
                ? carriers.find((carrier) => {
                    const carrierCode = user.carrier || shipping.shippingInfo.carrier
                    return carrier.code === carrierCode
                  })?.name
                : 'N/A'}
            </LargeCell>
            <LargeCell>{region}</LargeCell>
            <LargeCell>{userCarrierService?.name || 'N/A'}</LargeCell>
            <LargeCell>{packDay || 'N/A'}</LargeCell>
            <LargeCell>{transitDays || shipping.shippingInfo.transitDays || 'N/A'}</LargeCell>
            <LargeCell>
              {warehouses instanceof Array &&
                warehouses.length &&
                warehouses.find(
                  (warehouse) =>
                    Number(warehouse.id) ===
                    Number(warehouseId || shipping.shippingInfo.warehouseId),
                )?.name}
            </LargeCell>
            <LargeCell />
          </InfoRow>
        )}
      </tbody>
    )
  }

  return null
}

export default compose(withDataLoader, withEditInfoController)(DeliveryInfo as any)

/**
 * Gets display address.
 *
 * @param address - Address.
 */
function getDisplayAddress(address: Address): string {
  return `${address.firstName} ${address.lastName}
   ${address.address} ${address.apt || ' '}
   ${address.city}, ${address.state}, ${address.zip}`
}
