import { all, compose, propEq, values } from 'ramda'
import { FieldName } from '~/app/pages/Product/DetailForm/types'

export const isFormValid = compose(all(propEq('valid', true)), values)

export const getFieldValidity = (
  value: any,
  validators: Record<string, any>[] = [],
  formState: any,
) => {
  for (let i = 0; i < validators.length; i++) {
    if (!validators[i].fn(value, formState)) {
      return {
        errorMsg: validators[i].msg,
        valid: false,
      }
    }
  }
  return {
    errorMsg: '',
    valid: true,
  }
}

const getInitialFieldValue = (
  field: Record<string, any>,
  formData: Record<string, any> = {},
  onChange: (name: FieldName, value?: string) => void,
) => {
  const defaultVal = field.defaultValue != null ? field.defaultValue : undefined
  // @ts-ignore
  const definedVal = formData[field.name] != null ? formData[field.name] : field.value
  // Default to defaultVal if definedVal is a falsy value !== 0
  const value = definedVal != null ? definedVal : defaultVal
  const validators = field.validators || []
  const validity = getFieldValidity(value, validators, formData)

  return {
    ...field,
    ...validity,
    dirty: false,
    onChange: onChange(field.name),
    pristine: true,
    value,
  }
}

// initializes the form state based on the fieldsDefinition received
export const getInitialFormState = (fieldsDefinition: any, formData: any, onChange: any) =>
  fieldsDefinition.reduce(
    (acc: any, field: any) => ({
      ...acc,
      [field.name]: getInitialFieldValue(field, formData, onChange),
    }),
    {},
  )

// initializes the form state based on the fieldsDefinition received
// TODO: (bnikom) because there is no binding in functional components (aka using hooks), you have to use Arrow function when calling onChange
export const getInitialFormStateHook = (
  fieldsDefinition: Record<string, any>[],
  formData: Record<string, any>,
  onChange: (name: FieldName, value: string) => void,
): Record<string, any> =>
  fieldsDefinition.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: getInitialFieldValue(field, formData, () => onChange),
    }),
    {},
  )

export const getNewFieldValue = (prevState: any, value: any, formState: any) => {
  const validity = getFieldValidity(value, prevState.validators, formState)
  return {
    ...prevState,
    ...validity,
    dirty: true,
    pristine: false,
    value,
  }
}

export function mergeArrayKeys(data: Record<string, any>) {
  return Object.keys(data).reduce<Record<string, any>>((obj, key) => {
    const [bracketedIndex] = key.match(/\[(\d)\]/) || []
    if (bracketedIndex) {
      const arrayKey = key.replace(bracketedIndex, '')
      const array = obj[arrayKey] || []
      array.push(data[key])
      obj[arrayKey] = array
    } else {
      obj[key] = data[key]
    }
    return obj
  }, {})
}
