interface Props {
  onClick?: () => void
  className?: string
  fillColor?: string
}

export default function BiteableIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="10"
      viewBox="0 0 31.25 15.55"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Biteable Icon</title>
      <desc>Little Spoon biteable container icon</desc>
      <path
        fill={props.fillColor}
        d="M31.05,9.45l-2.74-8.15v-.03s-.03-.03-.03-.03c-.36-.81-1.47-1.23-2.37-1.23h-.03S6.01.15,6.01.15c-.88,0-1.99.43-2.39,1.21v.03s-.03.03-.03.03L.27,9.48c-.36.72-.36,1.45,0,2.03.36.58,1.03.92,1.86.92h.57s.19,1.56.19,1.56c0,.86.69,1.55,1.55,1.55h22.33c.78,0,1.42-.64,1.42-1.42l.16-1.73h.66c.86,0,1.57-.35,1.96-.95.37-.57.4-1.29.09-2ZM17.72,1.88c.03-.08.22-.19.51-.2l6.66-.04h.02c.76,0,1.41.37,1.54.67l1.39,6.13c.07.19.1.37.09.54,0,.06-.02.22-.27.31-.34.12-.93.01-1.34-.4L17.76,1.97c-.05-.05-.04-.08-.04-.09ZM7.53,9.79L15.74,3.55s.09-.04.15-.04.13.01.17.04l8.07,6.19s.01.03.02.04H7.53ZM3.4,9.35c-.08-.24-.06-.51.09-.84l1.85-6c.18-.4.92-.74,1.61-.75l6.75-.05c.26.02.42.1.44.15,0,.02-.03.06-.03.06l-9.3,7.22c-.37.35-.86.46-1.16.39-.14-.03-.22-.1-.26-.2Z"
      />
    </svg>
  )
}
