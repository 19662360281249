import { merge } from 'ramda'
import { FETCH_PROMOCODES, FETCH_PROMOCODES_SUCCESS, FETCH_PROMOCODES_FAIL } from './constants'
const initialState = {
  data: [],
  loaded: false,
  loading: false,
}

function promocodesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROMOCODES:
      return merge(state, {
        loaded: false,
        loading: true,
      })

    case FETCH_PROMOCODES_SUCCESS: {
      return merge(state, {
        data: action.payload.data,
        loaded: true,
        loading: false,
      })
    }

    case FETCH_PROMOCODES_FAIL: {
      return merge(state, {
        loaded: true,
        loading: false,
      })
    }

    default:
      return state
  }
}

export default promocodesReducer
