export default function withModal(condition: any, Renderer: any) {
  return (Component: any) => (props: any) => {
    if (condition(props)) {
      return (
        <div>
          <Component {...props} />
          <Renderer {...props} />
        </div>
      )
    }
    return <Component {...props} />
  }
}
