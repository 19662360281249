import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import {
  fetchBlueprintsSuccess,
  fetchBlueprintsFail,
  fetchBlueprintDetailSuccess,
  createBlueprintSuccess,
  updateBlueprintSuccess,
  updateBlueprintFail,
} from './actions'
import {
  FETCH_BLUEPRINTS,
  FETCH_BLUEPRINT_DETAIL,
  CREATE_BLUEPRINT,
  UPDATE_BLUEPRINT,
} from './constants'

const fetchBlueprintsEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_BLUEPRINTS).switchMap((action) =>
    Observable.fromPromise(Api.fetchBlueprints(action.payload.limit, action.payload.offset))
      .map((result) => fetchBlueprintsSuccess(result))
      .catch((e) => Observable.of(fetchBlueprintsFail(), showNotification(e))),
  )

const fetchBlueprintDetailEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_BLUEPRINT_DETAIL).switchMap((action) =>
    Observable.fromPromise(Api.fetchBlueprintDetail(action.payload.id))
      .map((result) => fetchBlueprintDetailSuccess(result))
      .catch((e) => Observable.of(showNotification(e.message))),
  )

const createBlueprintEpic = (action$, store, { Api }) =>
  action$.ofType(CREATE_BLUEPRINT).switchMap((action) =>
    Observable.fromPromise(Api.createBlueprint(action.payload.data))
      .switchMap((result) => Observable.of(createBlueprintSuccess(result)))
      .catch((e) => Observable.of(showNotification(e))),
  )

const updateBlueprintEpic = (action$, store, { Api }) =>
  action$.ofType(UPDATE_BLUEPRINT).switchMap(({ payload }) =>
    Observable.fromPromise(Api.updateBlueprint(payload.id, payload.data))
      .switchMap((result) => Observable.of(updateBlueprintSuccess(result)))
      .catch((e) => Observable.of(updateBlueprintFail(), showNotification(e))),
  )

export default [
  fetchBlueprintsEpic,
  fetchBlueprintDetailEpic,
  createBlueprintEpic,
  updateBlueprintEpic,
]
