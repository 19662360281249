import styled from 'styled-components'
export const Wrapper = styled.ul`
  list-style-type: none;
`
export const Detail = styled.li`
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
`
export const DetailData = styled.div`
  margin-left: 1.7rem;
`
export const Label = styled.span`
  font-size: 1.4rem;
  display: block;
  margin-bottom: 0.1rem;
`
export const Value = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`
export const SectionName = styled.h3`
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 3rem;
`
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
