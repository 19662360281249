import { isNumber, required } from '~/app/common/validators'
import InputTextArea from '~/app/components/InputTypes/Textarea'
import SmallText from '~/app/components/InputTypes/SmallText'
import Switch from '~/app/components/InputTypes/Switch'

const formDefinition = [
  {
    label: 'Is custom refund?',
    name: 'isCustomRefund',
    type: Switch,
  },
  {
    label: 'Custom refund amount *',
    labelMargins: '0 0 0.5rem 0',
    name: 'customRefundAmount',
    type: SmallText,
    validators: [isNumber],
  },
  {
    label: 'Custom refund reason *',
    labelMargins: '0 0 0.5rem 0',
    name: 'customRefundReason',
    type: InputTextArea,
    validators: [required],
  },
]

export default formDefinition
