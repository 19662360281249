import { validAnchorProductTypesMap, validAddOnProductTypesMap } from '~/app/common/types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { Wrapper } from '../../styled'
import { getUpcomingOrders } from '~/app/store/user/selectors'
import { UpcomingOrder } from '~/app/store/user/deliverySchedule/types'
import moment from 'moment'
import {
  useFeatureFlagEnableCadenceSync,
  useFeatureFlagEnableWeeklyOrders,
} from '~/app/hooks/useFeatureFlag'
import Skip from './SkipBtn'
import Modify from './ModifyBtn'
import Shift from './ShiftBtn'
import RemoveAddonsButton from './RemoveAddonsButton'

interface Props {
  active: boolean
  addOns: {
    active: boolean
    id: number
    products: {
      count: number
      id: number
      inStock: boolean
      name: string
      packetCount: number
    }[]
    skipped: boolean
    type: validAddOnProductTypesMap
    skippable: boolean
  }[]
  chargeId?: number
  chargedAt?: string
  data: any
  editable?: boolean
  editableTo: string | moment.Moment
  groupIndex: number
  hasBeenSentToShipStation?: boolean
  id: number
  index: number
  isFirst?: boolean
  kidId: number
  orderId: number
  parentId?: number
  skippable?: boolean
  skipped?: boolean
  type: validAnchorProductTypesMap
  userId: number
}

/**
 * Renders order option buttons
 */
export default function Options(props: Props) {
  const self = useController(props)

  if (!props.skipped) {
    return (
      <Wrapper>
        {props.editable && (
          <Modify
            data={props.data}
            index={props.index}
            kidId={props.kidId}
            groupIndex={props.groupIndex}
            id={props.id}
            userId={props.userId}
            type={props.type}
          />
        )}
        {props.skippable && (
          <Skip
            data={props.data}
            index={props.index}
            kidId={props.kidId}
            userId={props.userId}
            groupIndex={props.groupIndex}
            skippable={props.skippable}
            buttonText="Skip Order"
          />
        )}
        {self.shouldShowShiftOption && (
          <Shift
            data={props.data}
            index={props.index}
            userId={props.userId}
            groupIndex={props.groupIndex}
          />
        )}
        {self.parentOrder &&
          !self.parentOrder?.chargeId &&
          props.addOns.map((addOn, index) => {
            // @ts-ignore
            if (addOn.type !== props.type && !self.hasBeenCharged) {
              return (
                <RemoveAddonsButton
                  key={addOn.id || index}
                  parentOrder={self.parentOrder!}
                  type={addOn.type}
                  userId={props.userId}
                />
              )
            }
          })}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {props.skippable && (
        <Skip
          data={props.data}
          index={props.index}
          kidId={props.kidId}
          userId={props.userId}
          groupIndex={props.groupIndex}
          skippable={props.skippable}
          buttonText="Unskip Order"
        />
      )}
    </Wrapper>
  )
}

function useController(props: Props) {
  const isAddOn = props.parentId != null
  const { upcomingOrders } = useMappedState((state) => ({
    upcomingOrders: getUpcomingOrders(state as never),
  }))
  const isCadenceSyncEnabled = useFeatureFlagEnableCadenceSync()
  const isWeeklyOrdersEnabled = useFeatureFlagEnableWeeklyOrders()

  const hasBeenCharged = Boolean(props.chargeId && props.chargedAt)

  const shouldShowShiftOption = props.editable && !isCadenceSyncEnabled && !isWeeklyOrdersEnabled

  const parentOrder = (upcomingOrders as UpcomingOrder[])
    .flatMap((template) => template.orders)
    .find((order) => {
      if (props.editableTo) {
        const editableTo =
          typeof props.editableTo === 'string' ? props.editableTo : props.editableTo.toISOString()
        return order.addOns.length && order.id === props.id && order.editableTo === editableTo
      }
      return order.addOns.length && order.id === props.id
    })

  return {
    hasBeenCharged,
    isAddOn,
    parentOrder,
    shouldShowShiftOption,
  }
}
