import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchLabels, editLabel, createLabel, deleteLabel } from './effects'
import { setLabels, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const labels$ = makeModel({
  effects: makeEffects({
    createLabel,
    deleteLabel,
    editLabel,
    fetchLabels,
  }),
  prefix: 'labels',
  reducers: makeReducers({
    setLabels,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default labels$
