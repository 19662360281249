import { UserLogsStatus, type UserLogsState } from './types'
import { limitPerPage } from './constants'

const initialState: UserLogsState = Object.freeze({
  data: [],
  limit: limitPerPage,
  page: 0,
  status: UserLogsStatus.pending,
})

export default initialState
