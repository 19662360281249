import moment from 'moment-timezone'

const printTime = (value, unit) => {
  if (value === 0) return ''
  return `${value} ${unit}${value > 1 ? 's' : ''} `
}

export const getCurrentAge = (birthdate) => {
  const years = Math.floor(moment().diff(birthdate, 'years', true))
  const months = Math.floor(moment().diff(moment(birthdate).add(years, 'years'), 'months', true))
  const days = Math.floor(
    moment().diff(moment(birthdate).add(months, 'months').add(years, 'years'), 'days', true),
  )
  return `${printTime(years, 'year')}${printTime(months, 'month')}${printTime(days, 'day')}`
}
export default getCurrentAge
