import { Observable } from 'rxjs/Observable'
import { showNotification } from '~/app/store/notifications/actions'
import { fetchWarehousesSuccess, fetchWarehousesFail } from './actions'
import { FETCH_WAREHOUSES } from './constants'

const fetchWarehousesEpic = (action$, store, { Api }) =>
  action$.ofType(FETCH_WAREHOUSES).switchMap(() =>
    Observable.fromPromise(Api.fetchWarehouses())
      .map((result) => fetchWarehousesSuccess(result))
      .catch((e) => Observable.of(fetchWarehousesFail(), showNotification(e))),
  )

export default [fetchWarehousesEpic]
