import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchPromoBanners, editPromoBanner, createPromoBanner } from './effects'
import { setPromoBanners, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const promoBanners$ = makeModel({
  effects: makeEffects({
    createPromoBanner,
    editPromoBanner,
    fetchPromoBanners,
  }),
  prefix: 'formFactors',
  reducers: makeReducers({
    setPromoBanners,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default promoBanners$
