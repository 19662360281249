import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Content = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.midGray};
  padding: 0 15px 61px;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.darkGray};
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 29px;
  }
  ${media.lg} {
    padding: 0 75px 61px;
  }
`

export const Header = styled.header`
  background: ${(props) => props.theme.color.tint};
  padding: 4.9rem 0 2rem;
  position: relative;
  margin-bottom: 0.5rem;
`

export const Title = styled.h1`
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 18px;
`

export const OrdersDate = styled.h2`
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const Info = styled.p`
  font-size: 13px;
`

export const QuantityNotice = styled.p`
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.color.paleRed};
  color: white;
  font-size: 15px;
  border-radius: 30px;
  padding: 13px 25px;
  svg {
    margin-right: 5px;
  }
  path {
    fill: currentColor;
  }
  width: 50%;
  ${media.md} {
    width: auto;
  }
`

export const ContentWrapper = styled.p`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const OrderSummary = styled.div`
  background: ${(props) => props.theme.color.white};
  padding: 1rem 4rem;
  margin-top: 2rem;
`

export const ProductCount = styled.div`
  font-weight: 700;
  text-align: right;
  margin-bottom: 1rem;
`

export const ProductTotal = styled.div`
  font-weight: 700;
  text-align: right;
  margin-bottom: 0.2rem;
`
