import { isArray } from 'lodash'
import { register, INIT_ACTION } from './shared'

const effectsMiddleWare = (store: any) => (next: () => void) => (action: any) => {
  // @ts-ignore
  const effect = register.effects[action.type]
  if (effect) {
    // @ts-ignore
    effect(action.payload, store.getState())
  }
  // @ts-ignore
  const match = register.listeners[action.type]
  if (match) {
    if (action.type === INIT_ACTION) {
      callListeners(match, action.payload)
    } else {
      // We want this to run after any reducer is done
      setTimeout(() => {
        if (match.type === 'action') {
          store.dispatch(match.fn(action.payload))
        } else {
          callListeners(match, action.payload)
        }
      }, 0)
    }
  }
  // @ts-ignore
  return next(action)
}

export default effectsMiddleWare

function callListeners(match: any, payload: any) {
  if (isArray(match)) {
    match.forEach((x) => {
      x.fn(payload)
    })
  } else {
    match.fn(payload)
  }
}
