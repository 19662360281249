import { createAction } from '~/app/common/actions'
import {
  FETCH_BLUEPRINTS,
  FETCH_BLUEPRINTS_SUCCESS,
  FETCH_BLUEPRINTS_FAIL,
  FETCH_BLUEPRINT_DETAIL,
  FETCH_BLUEPRINT_DETAIL_SUCCESS,
  CREATE_BLUEPRINT,
  CREATE_BLUEPRINT_SUCCESS,
  UPDATE_BLUEPRINT,
  UPDATE_BLUEPRINT_SUCCESS,
  UPDATE_BLUEPRINT_FAIL,
  UPDATE_BLUEPRINTS_PAGE,
} from './constants'
export const fetchBlueprints = (limit, offset) =>
  createAction(FETCH_BLUEPRINTS, {
    limit,
    offset,
  })
export const fetchBlueprintsSuccess = (data) => createAction(FETCH_BLUEPRINTS_SUCCESS, data)
export const fetchBlueprintsFail = () => createAction(FETCH_BLUEPRINTS_FAIL)
export const fetchBlueprintDetail = (id) =>
  createAction(FETCH_BLUEPRINT_DETAIL, {
    id,
  })
export const fetchBlueprintDetailSuccess = (data) =>
  createAction(FETCH_BLUEPRINT_DETAIL_SUCCESS, {
    data,
  })
export const createBlueprint = (data) =>
  createAction(CREATE_BLUEPRINT, {
    data,
  })
export const createBlueprintSuccess = (data) =>
  createAction(CREATE_BLUEPRINT_SUCCESS, {
    data,
  })
export const updateBlueprint = (id, data) =>
  createAction(UPDATE_BLUEPRINT, {
    data,
    id,
  })
export const updateBlueprintSuccess = (data) =>
  createAction(UPDATE_BLUEPRINT_SUCCESS, {
    data,
  })
export const updateBlueprintFail = () => createAction(UPDATE_BLUEPRINT_FAIL)
export const updatePage = () => createAction(UPDATE_BLUEPRINTS_PAGE)
