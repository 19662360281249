import type React from 'react'
import OverlayWindow from '../OverlayWindow'
import { ScrollableContent, CloseBtn } from './styled'

interface Props {
  isOpen?: boolean
  hideCloseBtn?: boolean
  closeHandler?: () => void
  children?: React.ReactNode
  height?: number
  width?: string
  noRoundBorders?: boolean
  disableClosing?: boolean
  padding?: string
  maxWidth?: string
  'aria-label'?: string
  title?: string
}

const NOOP = () => {}

export default function LightBox(props: Props) {
  return (
    // @ts-ignore
    <OverlayWindow
      isOpen={props.isOpen}
      closeHandler={!props.disableClosing ? props.closeHandler : NOOP}
      width={props.width || 'auto'}
      noRoundBorders={props.noRoundBorders || false}
      padding={props.padding}
      maxWidth={props.maxWidth}
      aria-label={props['aria-label']}
    >
      {(!props.hideCloseBtn || false) && (
        <CloseBtn
          role="button"
          aria-label="Close"
          onClick={!props.disableClosing ? props.closeHandler : NOOP}
        />
      )}
      <ScrollableContent
        // @ts-ignore
        autoHeight
        autoHeightMin={100}
        autoHeightMax={Math.min(props.height || 830, window.innerHeight * 0.9)}
      >
        {props.children}
      </ScrollableContent>
    </OverlayWindow>
  )
}
