import { useEffect, useState } from 'react'
import user$ from '~/app/store/user'
import Select from '~/app/components/InputTypes/Select'
import Button from '~/app/components/Button/Button'
import { Form, FormChild } from './styled'
import { Order } from '~/app/store/user/deliverySchedule/types'
import { Moment } from 'moment'
import useIsEnvironment from '~/app/hooks/useIsEnvironment'
import { NodeEnv } from '~/app/common/constants'
import { useFeatureFlagEnableCadenceSync } from '~/app/hooks/useFeatureFlag'
import useShiftController from '~/app/pages/User/DeliverySchedule/Order/Options/ShiftBtn/useShiftController'
import { get } from 'lodash'
import ShiftingModal from '~/app/pages/User/DeliverySchedule/ShiftingModal'

interface Props {
  userId: number
  deliveryEstimate: string
  groupedOrders: Array<any>
  groupIndex: number
  // TODO: fix type to match order type from getOrdersByDate selector
  orders: Order[]
}

enum OptionValue {
  default = 'default',
  forceCharge = 'forceCharge',
  forceCartonizeShip = 'forceCartonizeShip',
  createLabelsForShipments = 'createLabelsForShipments',
  sendTrackingNotifications = 'sendTrackingNotifications',
  shift = 'shift',
}

export default function ActionsForm(props: Props) {
  const self = useController(props)

  return (
    <>
      <Form onSubmit={self.handleSubmit}>
        <FormChild>
          <Select
            disabled={false}
            defaultValue={OptionValue.default}
            value={self.selectValue}
            options={self.options}
            onChange={self.setSelectValue as React.Dispatch<React.SetStateAction<string>>}
          />
        </FormChild>
        <Button primary type="submit" disabled={!self.isSubmitEnabled}>
          Submit
        </Button>
      </Form>
      {self.shift.orderToShift !== null && (
        <ShiftingModal
          isOpen={self.shift.orderToShift !== null}
          closeHandler={self.shift.toggleShiftingModalVisibility}
          shiftedBy={get(self.shift, 'orderToShift.shiftedBy')}
          deliveryDate={get(self.shift, 'orderToShift.deliveryDate')}
          editableTo={get(self.shift, 'orderToShift.editableTo')}
          onShift={(shitftBy: number) => self.shift.handleOrderShift(shitftBy, true)}
        />
      )}
    </>
  )
}

function useController(props: Props) {
  const [firstOrder] = props.orders
  const isProduction = useIsEnvironment(NodeEnv.production)
  const isCadenceSyncEnabled = useFeatureFlagEnableCadenceSync()
  const editableTo = (firstOrder.editableTo as unknown as Moment).toISOString()
  const options = [
    {
      label: 'Select an action',
      value: OptionValue.default,
    },
  ]
  if (!isProduction)
    options.push({
      label: 'Force Charge',
      value: OptionValue.forceCharge,
    })
  if (!isProduction) {
    options.push(
      {
        label: 'Force Cartonize + Ship',
        value: OptionValue.forceCartonizeShip,
      },
      {
        label: 'Create Labels For Shipments',
        value: OptionValue.createLabelsForShipments,
      },
      {
        label: 'Send Tracking Notifications',
        value: OptionValue.sendTrackingNotifications,
      },
    )
  }
  if (isCadenceSyncEnabled && firstOrder?.editable)
    options.push({
      label: 'Shift',
      value: OptionValue.shift,
    })
  const [selectValue, setSelectValue] = useState(OptionValue.default)
  const [isSubmitEnabled, setSubmitEnabled] = useState(false)
  const shift = useShiftController({
    groupedOrders: props.groupedOrders,
    userId: props.userId,
  })
  const { userId, deliveryEstimate } = props
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (selectValue === OptionValue.forceCharge) {
      user$.call.forceChargeOrder({ userId, editableTo, deliveryEstimate })
      setSelectValue(OptionValue.default)
    } else if (selectValue === OptionValue.forceCartonizeShip) {
      user$.call.forceCartonizeShip({ userId, editableTo, deliveryEstimate })
    } else if (selectValue === OptionValue.createLabelsForShipments) {
      user$.call.createLabelsForShipments({ userId, editableTo, deliveryEstimate })
    } else if (selectValue === OptionValue.sendTrackingNotifications) {
      user$.call.sendTrackingNotifications({ userId, editableTo, deliveryEstimate })
    } else if (selectValue === OptionValue.shift) {
      shift.toggleShiftingModalVisibility(0, props.groupIndex)
      setSelectValue(OptionValue.default)
    }
  }

  useEffect(() => {
    const isEveryOrderCharged = props.orders.every((order) => order.chargeId && order.chargedAt)
    const hasAnyOrderBeenCharged = props.orders.some((order) => order.chargeId && order.chargedAt)
    const hasAnyOrderBeenSentToShipStation = props.orders.some(
      (order) => order.hasBeenSentToShipStation,
    )
    const doesAnyOrderHaveATrackingUrl = props.orders.some((order) => Boolean(order.trackingUrl))

    // represents the state of each option value, the state being whether the option is actionable
    const optionValueEnabledMap = new Map<OptionValue, boolean>([
      [OptionValue.default, false],
      [OptionValue.forceCharge, !isEveryOrderCharged],
      [OptionValue.forceCartonizeShip, hasAnyOrderBeenCharged && !hasAnyOrderBeenSentToShipStation],
      [
        OptionValue.createLabelsForShipments,
        hasAnyOrderBeenSentToShipStation && !doesAnyOrderHaveATrackingUrl,
      ],
      [OptionValue.sendTrackingNotifications, doesAnyOrderHaveATrackingUrl],
    ])
    const shouldEnableButton = optionValueEnabledMap.get(selectValue)!

    setSubmitEnabled(shouldEnableButton)
  }, [selectValue, props.orders])

  return {
    options,
    handleSubmit,
    selectValue,
    setSelectValue,
    isSubmitEnabled,
    shift,
  }
}
