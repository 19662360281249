import { Wrapper } from './styled'
import iconsByType from './iconsByType'
import type { AddOnType, ProductType } from '~/app/common/types'

interface Props {
  size: 'small' | 'medium' | 'big'
  type: Exclude<ProductType, AddOnType> | ProductType.smoothie
  custom: boolean
}

export default function PlanIcon({ type, size, custom }: Props) {
  const icons = type ? iconsByType[type] : null
  const icon = icons ? icons[size] : 'x_X'
  // @ts-ignore
  return <Wrapper big={custom}>{icon}</Wrapper>
}
