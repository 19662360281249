export const createAction = (type: any, data?: any) => {
  if (data) {
    return {
      payload: data,
      type,
    }
  }

  return { type }
}

export const noop = () => createAction('noop')
