import type React from 'react'
import { Wrapper, Overlay, Window } from './styled'

interface Props {
  isOpen?: boolean
  closeHandler?: () => void
  children?: React.ReactNode
  width?: string
  noRoundBorders?: boolean
  autoHeight?: boolean
  padding?: string
  maxWidth?: string
  'aria-label'?: string
}

export default function OverlayWindow(props: Props) {
  return (
    // @ts-ignore
    <Wrapper aria-label={props['aria-label']} hidden={!props.isOpen} isVisible={props.isOpen}>
      <Overlay onClick={props.closeHandler} />
      <Window
        width={props.width}
        // @ts-ignore
        noRoundBorders={props.noRoundBorders}
        autoHeight={props.autoHeight}
        padding={props.padding}
        maxWidth={props.maxWidth}
      >
        {props.children}
      </Window>
    </Wrapper>
  )
}
