import user$ from '~/app/store/user'
import Tooltip from '~/app/components/Tooltip'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { Wrapper, TooltipWrapper, TooltipHeader, FlagListItem } from './styled'
import FeatureFlagIcon from '~/app/components/Icons/FeatureFlag'
import { theme } from '~/app/settings/colors'

export default function FeatureFlagTooltip() {
  const self = useController()

  if (!self.enabledUserFeatureFlags.length) {
    return null
  }

  return (
    <Wrapper>
      <Tooltip
        text={
          <TooltipWrapper>
            <TooltipHeader>Enabled feature flags:</TooltipHeader>
            {self.enabledUserFeatureFlags.map((flag) => (
              <FlagListItem key={flag}>{flag}</FlagListItem>
            ))}
          </TooltipWrapper>
        }
      >
        <FeatureFlagIcon fillColor={theme.color.green} />
      </Tooltip>
    </Wrapper>
  )
}

function useController() {
  const { userFeatureFlags } = useMappedState((state: Record<string, any>) => ({
    userFeatureFlags: user$.getUserFeatureFlags(state),
  }))
  const userFeatureFlagsData = userFeatureFlags?.data || {}
  const enabledUserFeatureFlags = Object.keys(userFeatureFlagsData).filter(
    (flag) => userFeatureFlagsData[flag],
  )

  return {
    enabledUserFeatureFlags,
  }
}
