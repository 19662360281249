export default function LabelsIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="30"
      viewBox="0 0 1280.000000 640.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Labels</title>
      <g
        transform="translate(0.000000,640.000000) scale(0.100000,-0.180000)"
        fill="#939AA6"
        stroke="none"
      >
        <path
          d="M11660 4909 c-318 -174 -836 -434 -1060 -533 -1123 -497 -2031 -682
    -2800 -570 -106 15 -291 56 -430 94 -338 93 -872 217 -1235 285 -761 143
    -1388 205 -2091 205 -1007 0 -1950 -135 -3279 -470 -314 -80 -706 -182 -745
    -196 -17 -6 -15 -7 175 -114 231 -129 524 -330 715 -489 85 -71 310 -291 310
    -304 0 -4 -46 -61 -101 -125 -269 -311 -421 -549 -544 -851 -53 -130 -177
    -492 -171 -498 3 -3 94 29 203 70 992 376 1824 570 2783 649 243 20 1155 17
    1445 -5 556 -42 1098 -110 1735 -217 270 -46 393 -68 950 -174 400 -75 715
    -101 1130 -93 548 11 1018 86 1560 247 798 238 1603 653 2234 1153 129 101
    356 311 346 319 -3 3 -97 35 -210 73 -113 37 -333 115 -490 173 -157 57 -341
    124 -410 147 -69 23 -125 47 -125 51 0 5 27 61 59 124 129 253 197 476 286
    940 22 118 43 225 46 238 2 12 1 22 -3 22 -5 -1 -132 -69 -283 -151z"
        />
      </g>
    </svg>
  )
}
