import { StyledTextarea } from './styled'

interface Props {
  value?: string
  onChange: (event: any) => void
  disabled?: boolean
}

export default function Textarea({ value = '', onChange, disabled }: Props) {
  return (
    // @ts-ignore
    <StyledTextarea value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} />
  )
}
