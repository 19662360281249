import { createSelector } from 'reselect'

const selectFormFactors = (state: any) => state.formFactors

export const getFormFactors = createSelector(
  selectFormFactors,
  (formFactorState) => formFactorState.data,
)
export const selectStatus = createSelector(
  selectFormFactors,
  (formFactorState) => formFactorState.status,
)
