type ConditionTuple = [void | boolean, string | any]

const matchOne = (...conditions: ConditionTuple[]) => {
  const [, val] = conditions.filter(([cond]) => cond)[0] || []
  return val
}

export default matchOne

export const defaultVal = (val: string | any) => [true, val]
