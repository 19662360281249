import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchPaccurateConfig } from './effects'
import { setLoading, setLoaded, setError } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const paccurateConfig$ = makeModel({
  effects: makeEffects({ fetchPaccurateConfig }),
  prefix: 'paccurateConfig',
  reducers: makeReducers({
    setError,
    setLoaded,
    setLoading,
  }),
  selectors,
  state: initialState,
})

export default paccurateConfig$
