import { minLength, required } from '~/app/common/validators'
import InputSelect from '~/app/components/InputTypes/Select'

const formDefinition = [
  {
    label: 'Code',
    name: 'code',
    validators: [minLength(3), required],
  },
  {
    name: 'plan',
    emptyLabel: 'No plan: user level',
    hasEmptyOption: true,
    label: 'Optional: add to a plan',
    options: [],
    type: InputSelect,
  },
]
export default formDefinition
