/* eslint-disable */
import * as React from 'react'
import { getStore } from './getStore'

const useMappedDispatch = <A>(actions: A): A => {
  const { dispatch } = getStore()
  return React.useMemo(
    () =>
      Object.keys(actions).reduce((obj, key) => {
        // @ts-expect-error TODO: Fix error
        obj[key] = (...args) => {
          // @ts-expect-error TODO: Fix error
          dispatch(actions[key](...args))
        }
        return obj
      }, {}),
    [],
  ) as any
}

export default useMappedDispatch
