import InputSmallText from '~/app/components/InputTypes/SmallText'
import Select from '~/app/components/InputTypes/Select'
import countryStates from '~/app/common/dataLists/countryStates'
const formDefinition = [
  {
    label: 'First Name',
    name: 'billingFirstName',
    type: InputSmallText,
  },
  {
    label: 'Last Name',
    name: 'billingLastName',
    type: InputSmallText,
  },
  {
    label: 'Address',
    name: 'billingAddress',
    type: InputSmallText,
  },
  {
    label: 'Apt',
    name: 'billingApt',
    type: InputSmallText,
  },
  {
    label: 'City',
    name: 'billingCity',
    type: InputSmallText,
  },
  {
    hasEmptyOption: true,
    label: 'State',
    name: 'billingState',
    options: countryStates,
    type: Select,
  },
  {
    label: 'Zip Code',
    name: 'billingZip',
    type: InputSmallText,
  },
]
export default formDefinition
