import styled, { css } from 'styled-components'

export const Wrapper = styled.div``

export const Notice = styled.p`
  color: ${(props) => props.theme.color.darkGray};
  font-family: 'Lato', sans-serif;
  padding: 20px 0 40px;
  text-align: center;
  width: 100%;
`

export const OrderGroups = styled.ul`
  list-style: none;
`

export const OrderGroup = styled.li``

export const TableBody = styled.tbody`
  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`

export const OrderGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  border-bottom: 0.1rem solid ${(props) => props.theme.color.lightGray};
  padding-bottom: 1.5rem;
  margin-top: 2rem;
`

export const OrderGroupDate = styled.h2`
  margin-bottom: 0.2rem;
`

export const OrderGroupActions = styled.div``

export const OrderGroupTextColumn = styled.div`
  display: flex;
  flex-direction: column;
`
