interface Props {
  className?: string
}

export default function BigPlanIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="92" viewBox="0 0 88 92" {...props}>
      <title>Big plan</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.821 53.9L1 65.461l9.923.289z"
        />
        <path
          fill="#8165C2"
          d="M33.113 4.774l-1.985 10.323a2.2 2.2 0 0 0 1.439 2.493l13.627 4.737a2.2 2.2 0 0 0 2.595-.923l5.505-8.921-21.181-7.71z"
        />
        <path
          fill="#8165C2"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M33.113 4.774l-1.985 10.323a2.2 2.2 0 0 0 1.439 2.493l13.627 4.737a2.2 2.2 0 0 0 2.595-.923l5.505-8.921-21.181-7.71z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeWidth="1.5"
          d="M33.682 1.222a2.061 2.061 0 0 0-2.611 1.163l-.587 1.447-.005.013a.35.35 0 0 0 .21.448l25.104 9.072a.35.35 0 0 0 .443-.197l.619-1.526.026-.067a1.852 1.852 0 0 0-1.113-2.371L33.682 1.222z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M51.15 38.453l-4.255 9.612a2.2 2.2 0 0 0 .84 2.753L59.95 58.5a2.2 2.2 0 0 0 2.735-.316l7.37-7.454-18.903-12.277z"
        />
        <path
          fill="#E66889"
          d="M55.108 33.394L74.833 46.14a2.602 2.602 0 0 1 .717 3.681l-.947 1.347a1.1 1.1 0 0 1-1.497.292l-22.42-14.487a1.1 1.1 0 0 1-.302-1.556l.897-1.278a2.811 2.811 0 0 1 3.827-.745z"
        />
        <path
          stroke="#302D1F"
          strokeWidth="1.5"
          d="M53.22 34.95a2.061 2.061 0 0 0-2.805.547l-.897 1.278-.008.01a.35.35 0 0 0 .104.485l22.42 14.487a.35.35 0 0 0 .476-.093l.946-1.348.04-.059a1.852 1.852 0 0 0-.55-2.56L53.22 34.95z"
        />
        <g stroke="#302D1F" strokeWidth="1.5">
          <path
            fill="#F7D148"
            strokeLinejoin="round"
            d="M13.576 46.642l7.948 6.88a2.2 2.2 0 0 0 2.878.001L35.32 44.09a2.2 2.2 0 0 0 .498-2.709l-4.974-9.228-17.267 14.49z"
          />
          <path
            fill="#FFF"
            d="M10.785 44.374a2.061 2.061 0 0 0-.298 2.842l.96 1.232.008.01a.35.35 0 0 0 .493.043l20.409-17.204a.35.35 0 0 0 .05-.483l-1.011-1.299-.046-.056a1.852 1.852 0 0 0-2.61-.222L10.786 44.374z"
          />
        </g>
        <path
          fill="#E1EFD5"
          fillRule="nonzero"
          stroke="#495464"
          strokeWidth="1.32"
          d="M41.636 46.487a1.296 1.296 0 0 0-1.402 1.005l-1.227 5.678-2.956-3.72a1.962 1.962 0 0 0-1.347-.671 1.935 1.935 0 0 0-1.417.485 1.982 1.982 0 0 0-.403 2.756l2.19 2.753-4.747.274a1.301 1.301 0 0 0-1.21 1.26c.004.29.126.567.339.763.212.197.494.298.78.278l6.603-.359 2.705 3.793a2.032 2.032 0 0 0 2.801.244 2.071 2.071 0 0 0 .377-2.809l-1.856-2.72 1.671-7.725c.066-.292.006-.6-.167-.845a1.05 1.05 0 0 0-.734-.44z"
        />
        <path
          fill="#B6D042"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M44.759 40.895c.103-1.592-1.588-3.802-3.721-1.82-1.318-1.066-4.364-.658-3.953 1.974-.34.058-.66.207-.925.43a2.229 2.229 0 0 0-2.613-1.078c-1.77-2.234-4.143-3.006-5.305.726 0 0-4.006-1.364-2.694 3.212-1.94.51-3.765 3.51-.496 5.203a4.5 4.5 0 0 0 .73 2.039 2.616 2.616 0 0 0-.837 1.336c-1.29-.398-3.442.54-2.254 2.602-.653 1.192-.535 3.114 1.66 2.484.046.744.552 1.38 1.262 1.588.71.207 1.47-.06 1.895-.666 1.175.695 3.572.008 3.008-1.959 1.32-.378 2.139-2.815.322-3.381a3.906 3.906 0 0 0-.295-1.158c1.418.598 3.237.26 3.492-2.317a3.505 3.505 0 0 0 2.81-2.021c.594.56 1.55.615 2.953-.226 0 0 1.782 3.062 3.474-.493 1.483.762 4.258-.088 3.134-2.936.986-1.416 1.15-3.93-1.647-3.539z"
        />
        <path fill="#49C5B1" d="M45.897 43.793V91l33.077-10.352V53.661z" />
        <path
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M42.897 43.793V91l33.077-10.352V53.661z"
        />
        <path
          fill="#7CDBBF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.821 53.835v27.097L42.897 91V43.793z"
        />
        <path
          fill="#FFF"
          stroke="#302D1F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M42.897 43.793l11.566 17.889L87 65.75 76.128 53.118z"
        />
      </g>
    </svg>
  )
}
