import { useState } from 'react'
import user$ from '~/app/store/user'

type $Props = {
  groupedOrders: Array<any>
  userId: number
}

export default function useShiftController(props: $Props) {
  const { groupedOrders, userId } = props
  const [orderToShift, setOrderToShift] = useState(null)

  const toggleShiftingModalVisibility = (index: number, groupIndex: number): void => {
    if (!orderToShift) {
      const orderToShift = groupedOrders[groupIndex].orders[index]
      setOrderToShift(orderToShift)
    } else {
      setOrderToShift(null)
    }
  }

  const handleOrderShift = (shiftBy: number, applyToAllOrdersInWeek = false): void => {
    // @ts-expect-error TODO: Fix error
    const { id, editableTo } = orderToShift
    if (applyToAllOrdersInWeek) {
      user$.call.shiftAllOrders({
        editableTo: editableTo.toISOString(),
        shiftBy,
        userId,
      })
    } else {
      user$.call.shiftOrder({
        editableTo: editableTo.toISOString(),
        orderId: id,
        shiftBy,
        userId,
      })
    }
    setOrderToShift(null)
  }

  return {
    handleOrderShift,
    orderToShift,
    toggleShiftingModalVisibility,
  }
}
