import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import { showNotification } from '~/app/store/notifications/actions'

const effects = (store: any, self$: any) => ({
  async fetchCreditReasons() {
    loader$.call.show()
    await self$.call.setStatus('loading')
    try {
      const response = await Api.fetchCreditsReasons()
      await self$.call.setCreditReasons(response.data)
      await self$.call.setStatus('loaded')
    } catch (err) {
      // @ts-expect-error TODO: Fix error
      showNotification(err.message)
      await self$.call.setStatus('error')
    }
    loader$.call.hide()
  },
})

export default effects
