import { createAction } from '~/app/common/actions'
import {
  FETCH_INGREDIENTS,
  FETCH_INGREDIENTS_SUCCESS,
  FETCH_INGREDIENTS_FAIL,
  FETCH_INGREDIENT_DETAIL,
  FETCH_INGREDIENT_DETAIL_SUCCESS,
  CREATE_INGREDIENT,
  CREATE_INGREDIENT_SUCCESS,
  EDIT_INGREDIENT,
  EDIT_INGREDIENT_SUCCESS,
  DELETE_INGREDIENT,
  DELETE_INGREDIENT_SUCCESS,
  UPDATE_INGREDIENTS_PAGE,
} from './constants'
export const fetchIngredients = (source, limit, offset, orderBy, direction) =>
  createAction(FETCH_INGREDIENTS, {
    direction,
    limit,
    offset,
    orderBy,
    source,
  })
export const fetchIngredientsSuccess = (data) => createAction(FETCH_INGREDIENTS_SUCCESS, data)
export const fetchIngredientsFail = () => createAction(FETCH_INGREDIENTS_FAIL)
export const fetchIngredientDetail = (id) =>
  createAction(FETCH_INGREDIENT_DETAIL, {
    id,
  })
export const fetchIngredientDetailSuccess = (data) =>
  createAction(FETCH_INGREDIENT_DETAIL_SUCCESS, {
    data,
  })
export const createIngredient = (data) =>
  createAction(CREATE_INGREDIENT, {
    data,
  })
export const createIngredientSuccess = (data) =>
  createAction(CREATE_INGREDIENT_SUCCESS, {
    data,
  })
export const editIngredient = (id, data) =>
  createAction(EDIT_INGREDIENT, {
    data,
    id,
  })
export const editIngredientSuccess = (id, data) =>
  createAction(EDIT_INGREDIENT_SUCCESS, {
    data,
    id,
  })
export const deleteIngredient = (id) =>
  createAction(DELETE_INGREDIENT, {
    id,
  })
export const deleteIngredientSuccess = () => createAction(DELETE_INGREDIENT_SUCCESS)
export const updatePage = () => createAction(UPDATE_INGREDIENTS_PAGE)
