import { useEffect } from 'react'
import { useMappedState } from '~/app/hooks/useReduxStore'
import promoBanners$ from '~/app/store/promoBanners'
import PromoBannerModel from '~/app/store/promoBanners/model'
import { getPromoBanners } from '~/app/store/promoBanners/selectors'
import useHasUserRole from '~/app/hooks/useHasUserRole'
import { NEW, UserRole } from '~/app/common/constants'
import Page from '~/app/components/Page'
import { Title } from '~/app/components/Titles/PageTitle'
import type { PromoBanner } from '~/app/common/types'
import DetailsForm from './DetailForm'

type Props = {
  history: {
    push: (value: string) => void
  }
  match: {
    params: {
      bannerId: typeof NEW | number
    }
  }
}

export default function PromoBannerPage(props: Props) {
  const self = useController(props)

  if (!self.isSuperAdmin) {
    return null
  }

  return (
    <Page>
      <Title>{self.isNew ? 'Create' : 'Edit'} Promo Banner</Title>
      <DetailsForm isNew={self.isNew} formData={self.promoBanner} onConfirm={self.handleConfirm} />
    </Page>
  )
}

function useController(props: Props) {
  const isSuperAdmin = useHasUserRole([UserRole.superadmin])
  const isNew = props.match.params.bannerId === NEW
  const { promoBanners } = useMappedState((state) => {
    return {
      promoBanners: getPromoBanners(state).data,
    }
  })

  useEffect(() => {
    promoBanners$.call.fetchPromoBanners()
  }, [])

  const promoBanner = isNew
    ? new PromoBannerModel()
    : promoBanners?.find(
        (promoBanner: any) => String(promoBanner.id) === String(props.match.params.bannerId),
      )

  const handleConfirm = async (data: PromoBanner) => {
    if (isNew) {
      await promoBanners$.call.createPromoBanner({
        data,
        onSuccess: (id: number) => props.history.push(`${id}`),
      })
    } else {
      const id = Number(props.match.params.bannerId)
      await promoBanners$.call.editPromoBanner({ data, id })
    }
  }

  return {
    isSuperAdmin,
    isNew,
    promoBanners,
    promoBanner,
    handleConfirm,
  }
}
