interface Props {
  color: string
}

export default function PlusIcon({ color }: Props) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
      <title>Plus</title>
      <path
        d="M10.33 4.896H6.103V.67c0-.375-.295-.67-.67-.67-.377 0-.672.295-.672.67v4.226H.672c-.377 0-.672.295-.672.67 0 .377.295.672.67.672h4.092v4.09c0 .377.295.672.67.672.377 0 .672-.295.672-.67V6.237h4.225c.375 0 .67-.295.67-.67 0-.377-.295-.672-.67-.672z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}
