import { StatusType } from '~/app/common/types'

/**
 * sets fetched active boxes by state data into redux object
 *
 * @param data - fetches state and box data
 */
export const setActiveBoxesByState = (state: any) => (data: any) => ({
  ...state,
  data,
})

/**
 * sets loading status of fetched active boxes by state data into redux object
 *
 * @param status - status of fetch call
 */
export const setStatus = (state: any) => (status: StatusType) => ({
  ...state,
  status,
})
