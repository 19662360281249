import { AddOnType, PlanStatusType, ProductType } from '~/app/common/types'
import getFillColor from './getFillColor'
import BlendIcon from './BlendIcon'
import BoosterIcon from './BoosterIcon'
import PlateIcon from './PlateIcon'
import SauceIcon from './SauceIcon'
import SmoothieIcon from './SmoothieIcon'
import LuncherIcon from './LuncherIcon'
import SnackIcon from './SnackIcon'
import PuffIcon from './PuffIcon'
import CerealIcon from './CerealIcon'
import MerchIcon from './MerchIcon'
import BiteableIcon from './BiteableIcon'

interface Props {
  type: ProductType | AddOnType
  status?: PlanStatusType
  onClick?: () => void
  className?: string
}

export default function ProductTypeIcon({ type, status, ...rest }: Props) {
  const fillColor = getFillColor(status || '')

  switch (type) {
    case ProductType.smoothie:
      return <SmoothieIcon {...rest} fillColor={fillColor} />
    case ProductType.blend:
      return <BlendIcon {...rest} fillColor={fillColor} />
    case ProductType.plate:
      return <PlateIcon {...rest} fillColor={fillColor} />
    case ProductType.booster:
      return <BoosterIcon {...rest} fillColor={fillColor} />
    case ProductType.sauce:
      return <SauceIcon {...rest} fillColor={fillColor} />
    case ProductType.luncher:
      return <LuncherIcon {...rest} fillColor={fillColor} />
    case ProductType.biteable:
      return <BiteableIcon {...rest} fillColor={fillColor} />
    case ProductType.snack:
      return <SnackIcon {...rest} fillColor={fillColor} />
    case ProductType.puff:
      return <PuffIcon {...rest} fillColor={fillColor} />
    case ProductType.cereal:
      return <CerealIcon {...rest} fillColor={fillColor} />
    case ProductType.misc:
      return <MerchIcon {...rest} fillColor={fillColor} />
    default:
      return null
  }
}
