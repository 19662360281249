import React from 'react'
import moment from 'moment'
import { useMappedState, useMappedDispatch } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'
import { fetchProducts, fetchGiftProducts } from '~/app/store/products/actions'
import {
  selectGiftProducts,
  selectGiftProductsLoading,
  selectGiftProductsLoaded,
} from '~/app/store/products/selectors'

type $Props = {
  id: number
}

//  TODO: Update products model eventually
const useDataLoader = (user: $Props) => {
  const state = useMappedState((rootState: any) => ({
    addOnProducts: user$.getAddOnProductsWithCounts(rootState) as Array<any>,
    editedOrder: user$.getEditedOrder(rootState) as any,
    editedOrderAddOnsQ: user$.getAddOnsQInOrder(rootState) as number,
    orderSummary: user$.getOrderSummary(rootState) as any,
    upcomingWeekOrdersSummary: user$.getUpcomingWeekOrdersSummary(rootState) as any,
    secondUpcomingWeekOrdersSummary: user$.getSecondUpcomingWeekOrdersSummary(rootState) as any,
    giftProducts: selectGiftProducts(rootState) as Array<any>,
    giftProductsLoaded: selectGiftProductsLoaded(rootState) as boolean,
    giftProductsLoading: selectGiftProductsLoading(rootState) as boolean,
    groupedOrders: user$.getOrdersByDate(rootState) as Array<any>,
    products: user$.getProductsWithCounts(rootState) as Array<any>,
    subscriptions: user$.getUserSubscriptions(rootState) as Array<any>,
  }))

  const dispatcher = useMappedDispatch({ fetchGiftProducts, fetchProducts })

  React.useEffect(() => {
    const userId = user.id || window.location.pathname.split('/')[2]
    user$.call.fetchUpcomingOrders(userId)
    user$.call.fetchUserSubscriptions(userId)
    if (!state.products.length) {
      dispatcher.fetchProducts({
        source: 'deliverySchedule',
      })
    }
    if (!state.giftProducts.length) {
      dispatcher.fetchGiftProducts(100, 0)
    }
  }, [])

  React.useEffect(() => {
    const fetchOrderSummary = async () => {
      if (!state.editedOrder?.products?.length) {
        return
      }
      const userId = user.id || window.location.pathname.split('/')[2]
      state.editedOrder.editableTo = moment.utc(state.editedOrder.editableTo)
      await user$.call.fetchOrderSummary({ userId, order: state.editedOrder })
    }

    fetchOrderSummary()
  }, [state.editedOrder.products])

  React.useEffect(() => {
    user$.call.setUpcomingWeekOrdersSummary(null)
    user$.call.setSecondUpcomingWeekOrdersSummary(null)
    const fetchUpcomingWeekOrdersSummary = async () => {
      if (!state.groupedOrders?.length) {
        return
      }
      const userId = user.id || window.location.pathname.split('/')[2]
      const areAllOrdersFromFirstWeekCharged = state.groupedOrders[0].orders.every(
        (order: any) => order.chargedAt,
      )

      await Promise.all([
        user$.call.fetchOrdersSummaryByEditableTo({
          userId,
          editableTo: moment(state.groupedOrders[0].orders[0].editableTo)
            .tz('America/New_York')
            .format('YYYY-MM-DD'),
        }),
        areAllOrdersFromFirstWeekCharged &&
          user$.call.fetchOrdersSummaryByEditableTo({
            userId,
            editableTo: moment(state.groupedOrders[1].orders[0].editableTo)
              .tz('America/New_York')
              .format('YYYY-MM-DD'),
            isSecondUpcomingWeek: true,
          }),
      ])
    }

    fetchUpcomingWeekOrdersSummary()
  }, [state.groupedOrders])

  return {
    ...state,
  }
}

export default useDataLoader
