interface Props {
  color: string
}

export default function Checkmark({ color }: Props) {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg">
      <title>Checkmark</title>
      <path
        d="M4.091 12L0 8l1.363-1.333 2.728 2.667L13.637 0 15 1.334z"
        fillRule="nonzero"
        fill={color}
      />
    </svg>
  )
}
