import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchCancellationReasons } from './effects'
import { setCancellationReasons, setStatus } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const cancellationReasons$ = makeModel({
  effects: makeEffects({
    fetchCancellationReasons,
  }),
  prefix: 'cancellationReasons',
  reducers: makeReducers({
    setCancellationReasons,
    setStatus,
  }),
  selectors,
  state: initialState,
})

export default cancellationReasons$
