import { UPDATE_DASHBOARD, CLEAR_DASHBOARD_DATA } from './constants'
import { getFromDate, getToDate, getCurrentViewType, getDashboardHeader } from './helpers'
const initialState = {
  dashboardHeader: getDashboardHeader(),
  fromDate: getFromDate(),
  showDashboard: false,
  toDate: getToDate(),
  viewType: getCurrentViewType(),
}

function dashboardMenuReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DASHBOARD: {
      return {
        dashboardHeader: getDashboardHeader(),
        fromDate: action.payload.data.fromDate,
        showDashboard: true,
        toDate: action.payload.data.toDate,
        viewType: action.payload.data.viewType,
      }
    }

    case CLEAR_DASHBOARD_DATA: {
      return {
        dashboardHeader: getDashboardHeader(),
        fromDate: getFromDate(),
        showDashboard: false,
        toDate: getToDate(),
        viewType: getCurrentViewType(),
      }
    }

    default:
      return state
  }
}

export default dashboardMenuReducer
