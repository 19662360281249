type Props = {
  className: string
}

export default function Rattle(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="44"
      viewBox="0 0 44 44"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M39.349 21.382a3.153 3.153 0 0 1-3.153-3.153 3.153 3.153 0 0 0-3.154-3.153 3.153 3.153 0 0 1-3.153-3.153 3.153 3.153 0 0 0-3.153-3.153 3.153 3.153 0 0 1-3.153-3.153 3.153 3.153 0 0 0-3.153-3.153h-.074a14.256 14.256 0 0 0-5.862 5.814l-.024.205a3.153 3.153 0 0 1 3.153 3.153 3.153 3.153 0 0 0 3.153 3.153 3.153 3.153 0 0 1 3.153 3.153 3.153 3.153 0 0 0 3.153 3.153 3.153 3.153 0 0 1 3.153 3.154 3.153 3.153 0 0 0 3.153 3.153l.296.125a14.257 14.257 0 0 0 5.576-5.385l.089-.76zM13.605 21.606l-1.664 1.665a1.733 1.733 0 0 0 0 2.45l4.337 4.338a1.733 1.733 0 0 0 2.45 0l1.666-1.664"
        />
        <path
          fill="#77D3E1"
          d="M30.988 32.023C39.266 32.023 46 25.29 46 17.012 46 8.734 39.266 2 30.988 2c-8.277 0-15.011 6.734-15.011 15.012 0 2.09.43 4.082 1.205 5.893a.818.818 0 0 0-.157.121l-1.664 1.665a2.553 2.553 0 0 0-.684 2.368l-9.38 9.38A4.4 4.4 0 0 0 4 39.57a4.4 4.4 0 0 0 1.297 3.133A4.4 4.4 0 0 0 8.43 44a4.4 4.4 0 0 0 3.132-1.297l9.378-9.379a2.536 2.536 0 0 0 2.369-.685l1.665-1.664a.816.816 0 0 0 .121-.157 14.928 14.928 0 0 0 5.893 1.205z"
        />
        <path
          fill="#302D1F"
          fillRule="nonzero"
          d="M26.988 30.023C35.266 30.023 42 23.29 42 15.012 42 6.734 35.266 0 26.988 0c-8.277 0-15.011 6.734-15.011 15.012 0 2.09.43 4.082 1.205 5.893a.818.818 0 0 0-.157.121l-1.664 1.665a2.553 2.553 0 0 0-.684 2.368l-9.38 9.38A4.4 4.4 0 0 0 0 37.57a4.4 4.4 0 0 0 1.297 3.133A4.4 4.4 0 0 0 4.43 42a4.4 4.4 0 0 0 3.132-1.297l9.378-9.379a2.536 2.536 0 0 0 2.369-.685l1.665-1.664a.816.816 0 0 0 .121-.157 14.928 14.928 0 0 0 5.893 1.205zM40.36 15.012c0 1.975-.43 3.852-1.203 5.541a2.336 2.336 0 0 1-2.14-2.324 3.978 3.978 0 0 0-3.974-3.973 2.335 2.335 0 0 1-2.332-2.333 3.978 3.978 0 0 0-3.974-3.974 2.335 2.335 0 0 1-2.333-2.332c0-1.353-.68-2.549-1.715-3.267 1.35-.46 2.797-.71 4.3-.71 7.373 0 13.371 5.999 13.371 13.372zM20.564 3.288a2.336 2.336 0 0 1 2.199 2.329 3.978 3.978 0 0 0 3.973 3.973 2.335 2.335 0 0 1 2.333 2.333 3.978 3.978 0 0 0 3.973 3.973 2.335 2.335 0 0 1 2.333 2.333 3.98 3.98 0 0 0 2.963 3.843 13.472 13.472 0 0 1-4.713 4.544.82.82 0 0 0-.237-.035 2.335 2.335 0 0 1-2.333-2.333 3.978 3.978 0 0 0-3.973-3.973 2.335 2.335 0 0 1-2.333-2.333 3.978 3.978 0 0 0-3.973-3.973 2.335 2.335 0 0 1-2.333-2.333 3.98 3.98 0 0 0-2.746-3.779 13.472 13.472 0 0 1 4.867-4.57zM14.881 9.34a2.336 2.336 0 0 1 1.921 2.296 3.978 3.978 0 0 0 3.974 3.973 2.335 2.335 0 0 1 2.333 2.333 3.978 3.978 0 0 0 3.973 3.974 2.335 2.335 0 0 1 2.333 2.332c0 1.444.774 2.71 1.928 3.406a13.311 13.311 0 0 1-4.355.729c-7.373 0-13.37-5.998-13.37-13.371 0-2.027.453-3.949 1.263-5.672zm-8.48 30.202a2.77 2.77 0 0 1-1.971.817 2.77 2.77 0 0 1-1.973-.817 2.77 2.77 0 0 1-.816-1.972c0-.745.29-1.445.816-1.972l9.1-9.1 3.944 3.945-9.1 9.1zM18.15 29.48a.906.906 0 0 1-.952.214.814.814 0 0 0-.2-.1.925.925 0 0 1-.139-.114l-4.337-4.337a.908.908 0 0 1-.115-.14.812.812 0 0 0-.098-.198.913.913 0 0 1 .213-.953l1.42-1.42a15.123 15.123 0 0 0 5.629 5.627l-1.421 1.421z"
        />
        <path
          fill="#302D1F"
          fillRule="nonzero"
          stroke="#302D1F"
          strokeWidth="0.5"
          d="M9.694 31.146L4.67 36.17a.82.82 0 0 0 1.16 1.16l5.024-5.024a.82.82 0 0 0-1.16-1.16z"
        />
        <path
          fill="#302D1F"
          fillRule="nonzero"
          d="M35.495 30.184a17.397 17.397 0 0 1-8.507 2.218.82.82 0 0 0 0 1.64c3.263 0 6.482-.839 9.31-2.428a.82.82 0 0 0-.803-1.43zM8.777 15.832a.82.82 0 0 0 .82-.82c0-3.791 1.199-7.394 3.465-10.418a.82.82 0 0 0-1.312-.984 18.871 18.871 0 0 0-3.793 11.402c0 .453.367.82.82.82z"
        />
      </g>
    </svg>
  )
}
