import styled from 'styled-components'
import { Link } from 'react-router-dom'
import when, { defaultVal } from '~/app/utils/matchOne'
export const Wrapper = styled.div``
export const Header = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`
export const StyledTab = styled.li`
  color: ${(props) => props.theme.color.darkGray};
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2.5px;
  padding: 20px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  ${(props) =>
    props.active &&
    `
    background-color: ${props.theme.color.white};
    border-radius: 4px 4px 0 0;
    color: ${props.theme.color.green};
  `};
`
export const StyledContentTab = styled.li`
    flex-grow: 3;
    width: 50%;
    display: inline-block;
    padding: 0.3rem 0;
    white-space: nowrap;
   background: ${(props) =>
     when([props.isActive, props.theme.color.paleLightGreen], defaultVal('white'))};
    color: ${(props) => when([props.isActive, props.theme.color.green], defaultVal('black'))};
    text-align-center;
    border-radius: inherit;
    cursor: pointer;
`
export const Content = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border-radius: 4px;
  padding: 30px 33px;
  width: 100%;
`
export const TabList = styled.ul`
  display: flex;
  padding: 0.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${(props) => props.theme.color.green};
  width: 50%;
`
