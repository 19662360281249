import styled from 'styled-components'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { Row } from './styled'

export const Wrapper = styled.ul`
  text-align: left;
`
export const Product = styled.li`
  margin-bottom: 15px;
`
export const Quantity = styled.strong`
  margin-right: 14px;
  color: ${(props) => props.theme.color.dark};
`
export const Name = styled.span`
  color: ${(props) => props.theme.color.green};
`

interface Props {
  products: any[]
}

export default function ProductDetails(props: Props) {
  const self = useController()

  return (
    <Row>
      <Wrapper>
        {props.products.map(({ id, name, count }) => (
          <Product key={id}>
            <Quantity>{count}x</Quantity>
            {/* add-ons do not have names, so we rely on products data model to get the names */}
            <Name>{name || self.productsByIdOrSku[id]?.name}</Name>
          </Product>
        ))}
      </Wrapper>
    </Row>
  )
}

function useController() {
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))

  return {
    productsByIdOrSku,
  }
}
