import styled from 'styled-components'
import EditIcon from '~/app/assets/svg/EditIconFull'
export const OptionsHeaderRow = styled.tr`
  td {
    padding-right: 6px;
    padding-top: 6px;
    text-align: right;
  }

  svg {
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
`
export const EditBtn = styled(EditIcon)`
  cursor: pointer;
  margin-left: 8px;
  margin-right: 20px;
`
