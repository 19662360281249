import { useState } from 'react'
import useForm from '~/app/hooks/useForm'
import user$ from '~/app/store/user'
import formDefinition from './formDefinition'

interface Props {
  type: string
  userId: number
  formData: {
    [key: string]: string
  }
}

function useEditInfoController(props: Props) {
  const phone = 'phone'
  const { type, userId, formData } = props
  const [editField, setField] = useState(false)
  const form = useForm({
    // @ts-ignore
    fieldDefinitions: formDefinition(type),
    formData,
  })

  const { serialize, formState } = form

  const toggleEdit = () => {
    setField(!editField)
  }

  const handleSave = () => {
    serialize(() => handleConfirm())
  }

  const sanitizePhoneNumber = (phoneNumber: string) =>
    // remove non-numeric characters from phone number
    phoneNumber.replace(/\D/g, '')

  const handleConfirm = () => {
    if (type === phone) {
      formState[type].value = sanitizePhoneNumber(formState[type].value)
    }

    user$.call.editUserDetails({ data: { [type]: formState[type].value }, id: userId })
    setField(false)
  }

  const handleDisplay = (value: string, displayMethod?: (value: string) => string) => {
    if (typeof displayMethod === 'function') {
      return displayMethod(value)
    }
    return value
  }

  return {
    editField,
    ...form,
    handleConfirm,
    handleDisplay,
    handleSave,
    toggleEdit,
  }
}

export default useEditInfoController
