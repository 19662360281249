export default function SkipIcon() {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" xmlns="http://www.w3.org/2000/svg">
      <title>Skip</title>
      <g fill="#585D61" fillRule="evenodd">
        <path d="M13.09 11.867c.21-.265.186-.648-.053-.888-.28-.28-.745-.264-.988.046-1.198 1.525-3.11 2.463-5.23 2.284-2.827-.236-5.146-2.49-5.45-5.31C.96 4.237 4.055 1.066 7.8 1.352c3.018.23 5.476 3.013 5.527 6.035l-1.19-1.19c-.26-.26-.683-.26-.943 0-.26.26-.26.682 0 .942l2.333 2.332c.132.128.302.194.473.194.17 0 .34-.066.47-.196l2.335-2.332c.26-.26.26-.682 0-.943-.26-.26-.683-.26-.943 0L14.667 7.39v-.057c0-4.32-3.754-7.78-8.172-7.286C3.13.422.422 3.132.047 6.495c-.494 4.418 2.966 8.172 7.286 8.172 2.334 0 4.415-1.095 5.758-2.8z" />
        <circle cx="7.5" cy="7.5" r="2.5" />
      </g>
    </svg>
  )
}
