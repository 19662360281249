import styled, { css } from 'styled-components'
import Button from '~/app/components/Button/Button'
import { switchOn, option, defaultOption } from '~/app/utils/switchOn'

export const ButtonContainer = styled(Button)(
  // @ts-ignore
  (props) => css`
    padding: 0 100px;
    display: block;
    margin: ${props.center ? '0 auto' : '0'};
    padding-left: 0;
    padding-right: 0;
    width: ${props.width || props.width === 0 ? `${props.width}px` : '260px'};
    margin-right: ${switchOn(
      option(props.width !== 0 && `calc(260px - ${props.width}px`),
      defaultOption('0'),
    )};
    &.saving {
      background-color: transparent;
      border: 1px solid ${props.theme.color.gray};
      box-shadow: none;
      color: ${props.theme.color.darkGray};
      pointer-events: none;

      div {
        margin-left: -6px;
      }
    }

    &.saved {
      background-color: transparent;
      border: 1px solid ${props.theme.color.green};
      box-shadow: none;
      color: ${props.theme.color.green};
      pointer-events: none;

      div {
        margin-left: -6px;
        top: -2px;
      }
    }
  `,
)

export const IconContainer = styled.div`
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: -1px;
`
