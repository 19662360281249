import { createAction } from '~/app/common/actions'
import { FETCH_TOTAL_SALES, FETCH_TOTAL_SALES_SUCCESS } from './constants'
export const fetchSalesTotals = ({ viewType, fromDate, toDate, salesOrderType }) =>
  createAction(FETCH_TOTAL_SALES, {
    fromDate,
    salesOrderType,
    toDate,
    viewType,
  })
export const fetchSalesTotalsSuccess = ({ data }) => createAction(FETCH_TOTAL_SALES_SUCCESS, data)
