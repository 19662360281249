import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { tint } from 'polished'
export const Item = styled.div`
  border-radius: 4px;
  color: ${(props) => props.theme.color.gray};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-transform: uppercase;

  ${(props) =>
    props.active &&
    `
    color: ${props.theme.color.white}
  `};
`
export const Icon = styled.div`
  height: 20px;
  margin: 0 20px 4.5px 30px;
  width: 20px;

  ${(props) =>
    props.active &&
    `
    svg path, svg g {
      fill: ${props.theme.color.white}
    }
  `};
`
export const StyledLink = styled(Link)`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'Lato', sans-serif;
  height: 45px;
  outline: none;
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:hover {
    background: ${(props) => tint(0.85, props.theme.color.dark)};
  }
`
export const StyledLinkButton = StyledLink.withComponent('button').extend`
  margin: 0;
  padding: 0;
`
export const SubLink = styled(Link)`
  color: ${(props) => (props.active ? props.theme.color.green : props.theme.color.gray)};
  cursor: pointer;
  display: block;
  font-size: 12px;
  padding: 8px 0 8px 68px;
  text-decoration: none;

  &:hover {
    background: ${(props) => tint(0.85, props.theme.color.dark)};
  }
`
export const CaretContainer = styled.div`
  position: absolute;
  left: 194px;

  svg {
    height: 10px;
    position: relative;
    top: -2px;
    width: 10px;
  }
`
