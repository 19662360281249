interface ReasonType {
  id: number
  value: string
}

/**
 * Sorts reasons alphabetically.
 */
export function sortReasons(reasons: ReasonType[]) {
  return reasons.slice().sort((reason1, reason2) => {
    if (reason1.value < reason2.value) {
      return -1
    }
    if (reason1.value > reason2.value) {
      return 1
    }
    return 0
  })
}
