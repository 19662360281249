import { makeModel, makeReducers, makeEffects } from '~/app/utils/storeManager'
import { fetchProductDefaults, editProductDefaults } from './effects'
import { setLoading, setLoaded, setError } from './reducers'
import * as selectors from './selectors'
import initialState from './initialState'

const productDefaults$ = makeModel({
  effects: makeEffects({ fetchProductDefaults, editProductDefaults }),
  prefix: 'productDefaults',
  reducers: makeReducers({
    setError,
    setLoaded,
    setLoading,
  }),
  selectors,
  state: initialState,
})

export default productDefaults$
