type Props = {
  className: string
}

export default function Book(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="43"
      viewBox="0 0 46 43"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FEF7EB" d="M1.742 37.344h43.02v2.967H1.742z" />
        <path fill="#FFF0D9" d="M6.192 6.192h38.57v31.152H6.192z" />
        <path fill="#FFF" d="M1.742 6.192h4.45v31.152h-4.45z" />
        <path
          fill="#F7BFC6"
          fillRule="nonzero"
          d="M10.642 26.219h1.484v-4.45h4.45v4.45h1.484v-9.643h-7.418v9.643zm5.934-8.16v2.226h-4.45V18.06h4.45z"
        />
        <path
          fill="#FFF"
          d="M6.192 1.742h5.934v4.45H6.192zM12.126 41.795L9.159 40.31l-2.967 1.484v-4.45h5.934z"
        />
        <path
          fill="#FDB43F"
          fillRule="nonzero"
          stroke="#FDB43F"
          strokeWidth="0.5"
          d="M12.126 21.768h4.45v4.45h1.484v-9.642h-7.418v9.643h1.484v-4.45zm4.45-3.708v2.225h-4.45V18.06h4.45zM29.444 16.576h-7.418v9.643h7.418v-9.643zM23.51 18.06h4.45v2.966h-4.45V18.06zm4.45 6.675h-4.45V22.51h4.45v2.225zM40.086 24.735h-5.192V18.06h5.192v-1.484h-6.675v9.643h6.675z"
        />
        <path
          fill="#FDB43F"
          fillRule="nonzero"
          stroke="#FDB43F"
          strokeWidth="0.5"
          d="M11.868 1H6.45a1 1 0 0 0-1 1v3.45H2a1 1 0 0 0-1 1v33.603a1 1 0 0 0 1 1h3.45v.324a1 1 0 0 0 1.448.894l2.26-1.13 2.262 1.13a1 1 0 0 0 1.448-.894v-.324h31.635a1 1 0 0 0 1-1V6.45a1 1 0 0 0-1-1H12.868V2a1 1 0 0 0-1-1zM6.934 2.483h4.45V5.45h-4.45V2.483zm-4.45 4.45H5.45v29.67H2.483V6.933zm0 32.637v-1.484H5.45v1.484H2.483zm8.9 1.024L9.16 39.482l-2.225 1.112v-2.508h4.45v2.508zM44.02 39.57H12.868v-1.484H44.02v1.484zm0-32.636v29.669H6.934V6.933H44.02z"
        />
      </g>
    </svg>
  )
}
