import 'rxjs/add/observable/of'
import 'rxjs/add/observable/fromPromise'
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/finally'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/switchMap'

// Import all the third party stuff
import { StrictMode } from 'react'
// TODO: Remove this ESLint comment in WEB-109
// eslint-disable-next-line react/no-deprecated
import { render, unmountComponentAtNode } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { ThemeProvider } from 'styled-components'
import { createBrowserHistory } from 'history'

import 'sanitize.css/sanitize.css'

// Import root app
import App from './containers/App'

// Import needed components
import ConfirmationModal from './components/Modal/ConfirmationModal'
import { setStoreOnce } from './hooks/useReduxStore'
import { theme } from './settings/colors'
import { BLOCK_ROUTE_CHANGE_FOR_UNSAVED_WORK } from './common/constants'

import configureStore from './store'

// Import CSS reset and Global Styles
import './global-styles'

// Create redux store with history
const initialState = {}

const history = createBrowserHistory({
  // @ts-expect-error TODO: Fix error
  getUserConfirmation(message, callback) {
    render(
      <ThemeProvider theme={theme}>
        <ConfirmationModal
          isOpen
          handleCancel={() => {
            callback(false)
            unmountComponentAtNode(
              // @ts-expect-error TODO: Fix error
              document.querySelector(`.${BLOCK_ROUTE_CHANGE_FOR_UNSAVED_WORK}`),
            )
          }}
          handleConfirm={() => callback(true)}
          title="Heads Up!"
          text="Looks like you have some stuff unsaved. Want to leave anyway?"
          confirmText="Yes, leave this page"
          cancelText="No, go back"
        />
      </ThemeProvider>,
      document.querySelector(`.${BLOCK_ROUTE_CHANGE_FOR_UNSAVED_WORK}`),
    )
  },
})

const store = configureStore(initialState, history)
setStoreOnce(store)

render(
  <StrictMode>
    <Provider
      // @ts-expect-error TODO: Fix error
      store={store}
    >
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('app'),
)
