import styled from 'styled-components'

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 2.9rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`
