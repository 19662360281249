export const FETCH_INGREDIENTS = 'app/Ingredients/FETCH_INGREDIENTS'
export const FETCH_INGREDIENTS_SUCCESS = 'app/Ingredients/FETCH_INGREDIENTS_SUCCESS'
export const FETCH_INGREDIENTS_FAIL = 'app/Ingredients/FETCH_INGREDIENTS_FAIL'
export const FETCH_INGREDIENT_DETAIL = 'app/Ingredients/FETCH_INGREDIENT_DETAIL'
export const FETCH_INGREDIENT_DETAIL_SUCCESS = 'app/Ingredients/FETCH_INGREDIENT_DETAIL_SUCCESS'
export const CREATE_INGREDIENT = 'app/Ingredients/CREATE_INGREDIENT'
export const CREATE_INGREDIENT_SUCCESS = 'app/Ingredients/CREATE_INGREDIENT_SUCCESS'
export const EDIT_INGREDIENT = 'app/Ingredients/EDIT_INGREDIENT'
export const EDIT_INGREDIENT_SUCCESS = 'app/Ingredients/EDIT_INGREDIENT_SUCCESS'
export const DELETE_INGREDIENT = 'app/Ingredients/DELETE_INGREDIENT'
export const DELETE_INGREDIENT_SUCCESS = 'app/Ingredients/DELETE_INGREDIENT_SUCCESS'
export const UPDATE_INGREDIENTS_PAGE = 'app/Ingredients/UPDATE_INGREDIENTS_PAGE'
export const INGREDIENTS_PER_PAGE = 10
