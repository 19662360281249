import { useState } from 'react'
import { find } from 'lodash'
import type moment from 'moment'
import user$ from '~/app/store/user'
import alert$ from '~/app/store/alert'
import pricingGroup$ from '~/app/store/pricingGroup'
import { PricingGroup } from '~/app/store/pricingGroup/types'
import { ProductType } from '~/app/common/types'
import { useMappedState } from '~/app/hooks/useReduxStore'

interface Plan {
  amountPerMeal: number
  coupon: any
  groupName: string
  id: number
  mealsPerDay: number
  mealsPerDelivery: number
  planGroupId: number
  price: {
    per2Weeks: number
    perWeek: number
    shipping: number
  }
  shippingCost: number
}

interface Product {
  count: number
  id: number
  inStock: true
}

interface EditedOrder {
  addOnProducts: any[]
  addOns: {
    active: boolean
    products: {
      count: number
      id: number
      images: any
      inStock: true
      name: string
      packetCount: number
      shadow: string
      top: string
    }[]
  }[]
  blueprintId: number
  changeable: {
    skip: boolean
    plan: boolean
  }
  chargedAt: string | null
  deliveryDate: moment.Moment
  editableTo: string
  id: number
  index?: number
  isFirst: boolean
  kidId?: number
  modifyAddOnTemplate: boolean
  plan: Plan[]
  products: Product[]
  type: ProductType
}

interface Props {
  editedOrder: EditedOrder
  groupedOrders: {
    active: boolean
    kidId: number
    kidsName: string
    orders: any[]
    templateId: number
  }[]
  userId: number
}

export default function useEditOrderController(props: Props) {
  const { editedOrder, userId, groupedOrders } = props
  const [activeTab, setActiveTab] = useState(null)
  const [openEditedOrder, setOpenEditedOrder] = useState<any>(null)
  const [openModifyAllProductsModal, setOpenModifyAllProductsModal] = useState(false)
  // TODO: LS-3442 revert after pricing migration
  const [initialProductCount, setInitialProductCount] = useState(0)
  // TODO: LS-3448 remove FF when RLT is released
  const { pricingGroup } = useMappedState((state: Record<string, any>) => ({
    pricingGroup: pricingGroup$.getPricingGroup(state) as PricingGroup,
  }))

  const openLightBox = (groupIndex: number, index: number, kidId: number): void => {
    const order = groupedOrders[groupIndex].orders[index]
    user$.call.setEditedOrder(order, index, kidId)

    setOpenEditedOrder({
      ...order,
      addOnProducts: order.addOns.flatMap(
        ({ products, type }: { products: Product[]; type: string }) =>
          products.map(
            ({ id, count, inStock }: { id: number; count: number; inStock: boolean }) => ({
              count,
              id,
              inStock,
              type,
            }),
          ),
      ),
      products: order.products.map(
        ({ id, count, inStock }: { id: number; count: number; inStock: boolean }) => ({
          count,
          id,
          inStock,
        }),
      ),
    })

    // TODO: LS-3442 revert after pricing migration
    const productCount = order.products.reduce(
      (total: number, { count }: { count: number }) => total + count,
      0,
    )
    setInitialProductCount(productCount)
  }

  const updateOrder = (shouldUpdateAllFutureOrders: boolean): void => {
    user$.call.saveEditedOrder({
      editedOrder,
      orderId: openEditedOrder?.orderId,
      shouldUpdateAllFutureOrders,
      userId,
    })
    setOpenModifyAllProductsModal(false)
    setOpenEditedOrder(null)
  }

  const closeLightBox = (): void => {
    const mainOrderChanged = hasMainOrderChanged(openEditedOrder, editedOrder)
    const addonsChanged = hasAddonsChanged(openEditedOrder, editedOrder)
    const hasAnyOOS =
      editedOrder.chargedAt &&
      editedOrder.products.some((product) => product.count && !product.inStock)
    if (hasAnyOOS) {
      alert$.call.setNotification('Remove out of stock products from the order!')
    }

    const isMainProductCountValid = isProductCountValid(editedOrder, pricingGroup)

    const isAddOnProductCountValid = isAddOnCountValid(editedOrder, openEditedOrder, pricingGroup)
    if (!isMainProductCountValid || !isAddOnProductCountValid) {
      alert$.call.setNotification('There is an invalid number of products in your order!')
    } else if (mainOrderChanged || addonsChanged) {
      setOpenModifyAllProductsModal(true)
      setActiveTab(null)
    } else {
      setOpenEditedOrder(null)
    }
  }

  const handleTabChange = (tab: string): void => {
    setActiveTab(tab as any)
  }

  return {
    activeTab,
    closeLightBox,
    handleTabChange,
    initialProductCount,
    openEditedOrder,
    openLightBox,
    openModifyAllProductsModal,
    setActiveTab,
    setOpenEditedOrder,
    setOpenModifyAllProductsModal,
    updateOrder,
  }
}

function hasAddonsChanged(openEditedOrder: any, editedOrder: EditedOrder): boolean {
  const initialOrderProducts = openEditedOrder.addOnProducts
  const editedOrderProducts = editedOrder.addOnProducts

  return (
    initialOrderProducts.some(
      ({ id, count }: { id: number; count: number }) => !find(editedOrderProducts, { count, id }),
    ) || initialOrderProducts.length !== editedOrderProducts.length
  )
}

function hasMainOrderChanged(openEditedOrder: any, editedOrder: EditedOrder): boolean {
  const initialOrderProducts = openEditedOrder.products

  // somehow editedOrder.products contains addon's products, filter them
  const editedOrderProducts = editedOrder.products.filter((product) => {
    if (editedOrder.addOnProducts.some((addonProduct) => product.id === addonProduct.id)) {
      return null
    }

    return product
  })

  const lengthChange = initialOrderProducts.length !== editedOrderProducts.length

  return (
    lengthChange ||
    initialOrderProducts.some(
      ({ id, count }: { id: number; count: number }) => !find(editedOrderProducts, { count, id }),
    )
  )
}

// TODO: LS-3442 revert after pricing migration
function isProductCountValid({ products, type }: EditedOrder, pricingGroup: PricingGroup) {
  const totalProductCount = products.reduce(
    (total: number, { count }: { count: number }) => total + count,
    0,
  )

  const maxProductCount = pricingGroup?.incrementalityConstraints[type].max
  const minProductCount = pricingGroup?.incrementalityConstraints[type].min
  if (totalProductCount > maxProductCount || totalProductCount < minProductCount) {
    return false
  }
  return true
}

/**
 * Checks if addon product count is valid.
 *
 * @param editedOrder - Edited order.
 * @param openEditedOrder - Open edited order.
 * @returns - Whether addon product count is valid.
 */
function isAddOnCountValid(
  editedOrder: EditedOrder,
  openEditedOrder: any,
  pricingGroup: PricingGroup,
): boolean {
  const totalAddOnsCount = editedOrder.addOnProducts.reduce(
    (total: number, { count }: { count: number }) => total + count,
    0,
  )

  const maxAddOnsCount = editedOrder.addOnProducts.reduce(
    (total: number, { type }: { type: ProductType }) => {
      const addOnType = type === ProductType.smoothie ? 'smoothieAddon' : type
      return total + pricingGroup?.incrementalityConstraints[addOnType].max
    },
    0,
  )
  if (totalAddOnsCount > maxAddOnsCount) {
    return false
  }

  // addons can be 0 if it's not first order and removed linked templates is enabled for user
  if (!editedOrder.isFirst) {
    return true
  }

  const initialAddOnsCount = openEditedOrder.addOnProducts.reduce(
    (total: number, { count }: { count: number }) => total + count,
    0,
  )

  const hasZeroCountForType = !openEditedOrder.addOnProducts.every(({ type }: { type: string }) =>
    editedOrder.addOnProducts.find((product: { type: string }) => product.type === type),
  )

  return !(
    hasZeroCountForType ||
    (editedOrder.chargedAt && totalAddOnsCount !== initialAddOnsCount)
  )
}
