export default function CreditCardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 64 64" viewBox="0 0 64 64">
      <title>Credit card</title>
      <path
        fill="#585D61"
        d="M56,13H8c-1.7,0-3,1.3-3,3v7v4v21c0,1.7,1.3,3,3,3h48c1.7,0,3-1.3,3-3V27v-4v-7C59,14.3,57.7,13,56,13z M8,15 h48c0.6,0,1,0.4,1,1v4.2c-0.3-0.1-0.6-0.2-1-0.2H8c-0.4,0-0.7,0.1-1,0.2V16C7,15.4,7.4,15,8,15z M57,27c0,0.6-0.4,1-1,1H8 c-0.6,0-1-0.4-1-1v-4c0-0.6,0.4-1,1-1h48c0.6,0,1,0.4,1,1V27z M56,49H8c-0.6,0-1-0.4-1-1V29.8C7.3,29.9,7.6,30,8,30h48 c0.4,0,0.7-0.1,1-0.2V48C57,48.6,56.6,49,56,49z"
      />
      <path
        fill="#585D61"
        d="M19.6,35h-3.3c-1.9,0-3.4,1.5-3.4,3.4v3.3c0,1.9,1.5,3.4,3.4,3.4h3.3c1.9,0,3.4-1.5,3.4-3.4v-3.3 C23,36.5,21.5,35,19.6,35z M21,41.6c0,0.8-0.6,1.4-1.4,1.4h-3.3c-0.8,0-1.4-0.6-1.4-1.4v-3.3c0-0.8,0.6-1.4,1.4-1.4h3.3 c0.8,0,1.4,0.6,1.4,1.4V41.6z"
      />
    </svg>
  )
}
