import ProductTypeIcon from '~/app/components/Icons/ProductTypeIcon'
import { ProductType } from '~/app/common/types'
import getSubscriptionStatus from '~/app/utils/getSubscriptionStatus'

interface Props {
  subscription: {
    active: boolean
    id: number
    blueprintId?: number
    kidId?: number
    planId?: number
    firstPlanType?: string
    deactivatedAt?: string
    type: ProductType
    deletedAt?: string
    plan: {
      mealsPerDay?: number
    }
  }
}

export default function Subscription(props: Props) {
  return (
    <ProductTypeIcon
      type={props.subscription.type}
      status={getSubscriptionStatus(props.subscription)}
    />
  )
}
