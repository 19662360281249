import formatDate from '~/app/utils/formatDate'
import getAgeAtSignup from '~/app/utils/getAgeAtSignup'
import getCurrentAge from '~/app/utils/getCurrentAge'
import { MISSING_INFO_TEXT } from '~/app/utils/formatStringsforFE'
import { hisOrHer, heOrShe } from '~/app/common/dataMaps/genderPronouns'
import { Wrapper, Header, Name, BirthDate, MainInfo, Content, ContentBlock } from './styled'
import KidDetails from './KidDetails'
import Plans from './Plans'

interface Props {
  kid?: any
  subscriptions: any
  plans: any
  handleEdit: () => void
  user: any
}

export default function SubscriptionCard({ kid, subscriptions, plans, handleEdit, user }: Props) {
  const kidData = (() => {
    const filteredSubscriptionsByKid = subscriptions.filter((subscription) => {
      return subscription.kidId === (kid?.id || null)
    })
    return { ...kid, subscriptions: filteredSubscriptionsByKid }
  })()
  const ageAtSignup = kid?.birthdate ? (
    <BirthDate>
      <strong>{heOrShe[kidData?.gender]}</strong> was{' '}
      {kidData.subscriptions.length > 0 && (
        <strong>{getAgeAtSignup(kidData?.birthdate, kidData.subscriptions[0].createdAt)}</strong>
      )}
      at signup
    </BirthDate>
  ) : (
    <BirthDate>Age at signup is unknown</BirthDate>
  )
  const kidAge = kid?.birthdate ? (
    <BirthDate>
      <strong>{heOrShe[kidData?.gender]}</strong> currently is{' '}
      {kidData.subscriptions.length > 0 && <strong>{getCurrentAge(kidData?.birthdate)}</strong>} old
    </BirthDate>
  ) : (
    <BirthDate>Age is unknown</BirthDate>
  )
  return (
    <Wrapper>
      <Header>
        <MainInfo>
          <Name>{kidData?.name || MISSING_INFO_TEXT}</Name>
          {kidData.subscriptions ? (
            <BirthDate>
              <strong>{hisOrHer[kidData?.gender]}</strong> Birthdate is{' '}
              <strong>
                {kidData?.birthdate
                  ? formatDate(kidData.birthdate, {
                      timestamp: false,
                    })
                  : MISSING_INFO_TEXT}
              </strong>
            </BirthDate>
          ) : (
            <span>Complete the information to pick up the plan.</span>
          )}
          {ageAtSignup}
          {kidAge}
        </MainInfo>
      </Header>
      {kidData.subscriptions && (
        <Content>
          <ContentBlock>
            <KidDetails kid={kidData} handleEdit={handleEdit} />
          </ContentBlock>
          <ContentBlock>
            <Plans kid={kidData} plans={plans} user={user} />
          </ContentBlock>
        </Content>
      )}
    </Wrapper>
  )
}
