type Props = {
  onClick?: any
  className?: string
  fillColor: string
}

export default function FeatureFlagIcon(props: Props) {
  return (
    <svg
      height="18"
      viewBox="0 0 24 24"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <title>Feature Flag Icon</title>
      <desc>Little Spoon feature flag icon</desc>
      <path
        d="m2 24v-22c8-3.5238864 11 4.64375708 20 0v12c-8 4.895222-13-4.10348903-20 0"
        fill={props.fillColor}
        stroke={props.fillColor}
        strokeWidth="2"
      />
    </svg>
  )
}
