import { Observable } from 'rxjs/Observable'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/takeUntil'
import queryString from 'query-string'
import { showNotification } from '~/app/store/notifications/actions'
import {
  fetchPromocodes,
  fetchPromocodesSuccess,
  fetchPromocodesFail,
  createPromocodeSuccess,
  deletePromocodeSuccess,
  updatePromocodeSuccess,
} from './actions'
import { DELETE_PROMOCODE, CREATE_PROMOCODE, FETCH_PROMOCODES, UPDATE_PROMOCODE } from './constants'

const createPromocodeEpic = (action$, store, { Api }) =>
  action$.ofType(CREATE_PROMOCODE).switchMap((action) =>
    Observable.fromPromise(Api.createPromocode(action.payload.data))
      .switchMap((result) =>
        Observable.of(
          createPromocodeSuccess(result),
          showNotification('Promocode created'),
          fetchPromocodes(50, 0, false, getCurrentSearchTerm()),
        ),
      )
      .catch((e) => Observable.of(showNotification(e))),
  )

const deletePromocodeEpic = (action$, store, { Api }) =>
  action$.ofType(DELETE_PROMOCODE).switchMap((action) =>
    Observable.fromPromise(Api.deletePromocode(action.payload.id))
      .switchMap((result) =>
        Observable.of(
          deletePromocodeSuccess(result),
          showNotification('Promocode deleted'),
          fetchPromocodes(50, 0, false, getCurrentSearchTerm()),
        ),
      )
      .catch((e) => Observable.of(showNotification(e))),
  )

const updatePromocodeEpic = (action$, store, { Api }) =>
  action$.ofType(UPDATE_PROMOCODE).switchMap((action) =>
    Observable.fromPromise(Api.updatePromocode(action.payload.id, action.payload.data))
      .switchMap((result) =>
        Observable.of(
          updatePromocodeSuccess(result),
          showNotification('Promocode updated'),
          fetchPromocodes(50, 0, false, getCurrentSearchTerm()),
        ),
      )
      .catch((e) => Observable.of(showNotification(e))),
  )

const fetchPromocodesEpic = (action$, store, { Api }) =>
  action$
    .ofType(FETCH_PROMOCODES)
    .debounceTime(200)
    .switchMap((action) =>
      action.payload.codeString
        ? Observable.fromPromise(
            Api.fetchPromocodes(
              action.payload.limit,
              action.payload.offset,
              action.payload.referral,
              action.payload.codeString,
            ),
          )
            .takeUntil(action$.ofType(FETCH_PROMOCODES))
            .map((result) => fetchPromocodesSuccess(result))
            .catch((e) => Observable.of(fetchPromocodesFail(), showNotification(e.message)))
        : Observable.fromPromise(
            Api.fetchPromocodes(
              action.payload.limit,
              action.payload.offset,
              action.payload.referral,
            ),
          )
            .takeUntil(action$.ofType(FETCH_PROMOCODES))
            .map((result) => fetchPromocodesSuccess(result))
            .catch((e) => Observable.of(fetchPromocodesFail(), showNotification(e.message))),
    )

export default [createPromocodeEpic, deletePromocodeEpic, fetchPromocodesEpic, updatePromocodeEpic]

function getCurrentSearchTerm() {
  return queryString.parse(location.search)['code-search'] || ''
}
