const genderPronouns = {
  heOrShe: {
    female: 'she',
    male: 'he',
  },
  hisOrHer: {
    female: 'her',
    male: 'his',
  },
}

export default genderPronouns

export const { heOrShe } = genderPronouns

export const { hisOrHer } = genderPronouns
