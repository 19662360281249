import { createSelector } from 'reselect'

const selectCancellationReasonsType = (state: any, props: any) =>
  state.cancellationReasons[props.type]
const selectCancellationReasons = (state: any, props: any) =>
  state.cancellationReasons[`${props.type}Status`]

export const getCancellationReasons = createSelector(
  selectCancellationReasonsType,
  (reasons) => reasons,
)
export const selectCancellationStatus = createSelector(
  selectCancellationReasons,
  (status) => status,
)
