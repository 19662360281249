const initialState = {
  // TODO: Clean up the below in the reducer to use less data
  editedOrder: {
    addOnProducts: [],
    addOns: [],
    blueprintId: null,
    changeable: {},
    chargedAt: null,
    editableTo: null,
    id: null,
    index: null,
    intiallySelectedAddOns: [],
    isAddOnActive: false,
    isAddOnSkipped: false,
    isFirst: null,
    kidId: null,
    modifyAddOnTemplate: null,
    plan: {},
    planId: null,
    products: [],
    type: null,
  },

  upcomingOrders: [],

  orderSummary: {},

  upcomingWeekOrdersSummary: {},
  secondUpcomingWeekOrdersSummary: {},
}

export default initialState
