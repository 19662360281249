import { createAction } from '~/app/common/actions'
import {
  DELETE_PROMOCODE,
  DELETE_PROMOCODE_SUCCESS,
  CREATE_PROMOCODE,
  CREATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE,
  UPDATE_PROMOCODE_SUCCESS,
  FETCH_PROMOCODES,
  FETCH_PROMOCODES_SUCCESS,
  FETCH_PROMOCODES_FAIL,
} from './constants'
export const createPromocode = (data) =>
  createAction(CREATE_PROMOCODE, {
    data,
  })
export const createPromocodeSuccess = (data) => createAction(CREATE_PROMOCODE_SUCCESS, data)
export const deletePromocode = (id) =>
  createAction(DELETE_PROMOCODE, {
    id,
  })
export const deletePromocodeSuccess = (data) => createAction(DELETE_PROMOCODE_SUCCESS, data)
export const updatePromocode = (id, data) =>
  createAction(UPDATE_PROMOCODE, {
    data,
    id,
  })
export const updatePromocodeSuccess = () => createAction(UPDATE_PROMOCODE_SUCCESS)
export const fetchPromocodes = (limit, offset, referral, codeString) =>
  createAction(FETCH_PROMOCODES, {
    codeString,
    limit,
    offset,
    referral,
  })
export const fetchPromocodesSuccess = (data) => createAction(FETCH_PROMOCODES_SUCCESS, data)
export const fetchPromocodesFail = () => createAction(FETCH_PROMOCODES_FAIL)
