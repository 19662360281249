import React from 'react'
import { Wrap, TooltipBox } from './styled'

export type Position = 'top' | 'bottom'

/**
 * Options for the tooltip
 *
 * @typeParam active - whether or not to show the tooltip (default: true)
 * @typeParam position - the position of the tooltip relative to the element (default: bottom)
 * @typeParam noWrap - whether or not to wrap the tooltip text (default: false)
 * @typeParam width - the width of the tooltip (default: auto)
 */
interface TooltipOptions {
  active?: boolean
  position?: Position
  noWrap?: boolean
  width?: string
}

const defaultOptions: TooltipOptions = {
  active: true,
  noWrap: false,
  position: 'bottom',
  width: 'auto',
}

export interface TooltipProps {
  children: React.ReactNode
  text: React.ReactNode
  options?: TooltipOptions
}

/**
 * Tooltip
 *
 * @param children - the element to wrap with the tooltip
 * @param text - content to display in the tooltip
 * @param options - options to pass to the tooltip, including position, active, and noWrap
 * @returns rendered tooltip
 */
export default function Tooltip({ children, text, options }: TooltipProps) {
  const optionsToApply = React.useMemo(() => ({ ...defaultOptions, ...options }), [options])

  return (
    // @ts-ignore
    <Wrap to="/">
      {children}
      <TooltipBox {...optionsToApply}>{text}</TooltipBox>
    </Wrap>
  )
}
