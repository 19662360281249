import { useState } from 'react'
import { validAddOnProductTypesMap } from '~/app/common/types'
import LightBox from '~/app/components/LightBox'
import Button from '~/app/components/Button/Button'
import Icon from './Icon'
import { Option, Text } from '../../styled'
import { Wrapper, Question, Options, ButtonWrapper } from './styled'
import user$ from '~/app/store/user'
import { AddOn, Order } from '~/app/store/user/deliverySchedule/types'

interface Props {
  type: validAddOnProductTypesMap
  parentOrder: Order
  userId: number
}

/**
 * Remove addons button
 */
export default function RemoveAddonsButton(props: Props) {
  const self = useController(props)
  return (
    <>
      <Option
        onClick={() => {
          self.setModalState(true)
        }}
        noBorders={false}
      >
        <Text>{`Remove ${props.type}s`}</Text>
      </Option>
      {self.isOpen && (
        <LightBox
          isOpen={self.isOpen}
          closeHandler={() => self.setModalState(false)}
          width="614px"
          height={830}
        >
          <Wrapper>
            <Icon />
            <Question>{`Would you like to remove ${props.type}s?`}</Question>
            <Options>
              <ButtonWrapper>
                <Button primary onClick={() => self.handleRemove(false)}>
                  Remove from order
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button primary onClick={() => self.handleRemove(true)}>
                  Remove from all future orders
                </Button>
              </ButtonWrapper>
            </Options>
          </Wrapper>
        </LightBox>
      )}
    </>
  )
}

function useController(props: Props) {
  const [isOpen, setModalState] = useState(false)
  const addOnProducts = props.parentOrder.addOns
    .filter((order: AddOn) => order.type !== props.type)
    .flatMap((order: AddOn) => order.products)
  const editedOrder = { addOnProducts, products: props.parentOrder.products }

  const handleRemove = (shouldUpdateAllFutureOrders: boolean) => {
    user$.call.saveEditedOrder({
      editedOrder,
      orderId: props.parentOrder.orderId,
      shouldUpdateAllFutureOrders,
      userId: props.userId,
    })

    setModalState(false)
  }

  return {
    handleRemove,
    isOpen,
    setModalState,
  }
}
