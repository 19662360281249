import type { PlansState } from './types'
import { createSelector } from 'reselect'

const selectPlans = (state: any): PlansState => state.plans

export const getPlans = createSelector(selectPlans, (plansState: PlansState) => plansState.data)
export const selectStatus = createSelector(
  selectPlans,
  (plansState: PlansState) => plansState.status,
)
