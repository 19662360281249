import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`

export const IconWrapper = styled.div`
  width: 12rem;
  display: flex;
  justify-content: center;
`

export const PlanInfo = styled.div`
  margin-left: 2.2rem;
  line-height: 25px;
`

export const Name = styled.h4`
  margin-bottom: 1rem;
`

export const Detail = styled.span`
  display: block;
  font-size: 1.4rem;
  word-break: none;
  margin-bottom: 0.8rem;
`
export const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
`
