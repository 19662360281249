type Props = {
  className?: string
}

export default function FingerFood(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="46"
      viewBox="0 0 47 47"
      className={props.className}
    >
      <title>Finger Food</title>
      <defs>
        <circle id="FingerFood__circle" cx="28" cy="28" r="28" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-5 -5)">
        <mask id="FingerFood__mask" fill="#fff">
          <use xlinkHref="#FingerFood__circle" />
        </mask>
        <circle cx="30" cy="28" r="22" fill="#49C5B1" mask="url(#FingerFood__mask)" />
        <circle
          cx="28"
          cy="28"
          r="22"
          stroke="#302D1F"
          strokeWidth="1.5"
          mask="url(#FingerFood__mask)"
        />
        <circle cx="28" cy="28" r="18" fill="#DBF4F0" fillRule="nonzero" />
        <circle cx="20" cy="22" r="1" fill="#FFF" />
        <circle cx="29" cy="14" r="1" fill="#FFF" />
        <circle cx="37" cy="42" r="1" fill="#FFF" />
        <circle cx="20" cy="17" r="1" fill="#FFF" />
        <circle cx="13" cy="25" r="1" fill="#FFF" />
        <circle cx="17" cy="20" r="1" fill="#FFF" />
        <path
          fill="#E66889"
          d="M26.505 30.175a4.009 4.009 0 0 0-3.22.394 1.688 1.688 0 0 1-1.609 0 3.974 3.974 0 0 0-3.203-.399c-2.184.725-3.083 3.433-2.038 6.097a6.67 6.67 0 0 0 2.195 3.096c1.52 1.047 2.754 0 4.321.456 2.88.797 4.703-1.161 5.618-3.518 1.045-2.66.125-5.396-2.064-6.126z"
        />
        <path
          stroke="#302D1F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M24.505 30.175a4.009 4.009 0 0 0-3.22.394 1.688 1.688 0 0 1-1.609 0 3.974 3.974 0 0 0-3.203-.399c-2.184.725-3.083 3.433-2.038 6.097a6.67 6.67 0 0 0 2.195 3.096c1.52 1.047 2.754 0 4.321.456 2.88.797 4.703-1.161 5.618-3.518 1.045-2.66.125-5.396-2.064-6.126zM19 27c1.27.715 2.01 1.825 2 3"
        />
        <path
          fill="#E66889"
          d="M26.218 33.117a1.806 1.806 0 0 0-.536.382c-.82.848-1.371 1.944-2.057 2.886-.776 1.067-1.505 2.178-2.351 3.2-.25.342-.333.766-.232 1.169.102.402.38.748.768.953.403.2.855.3 1.312.293A7.85 7.85 0 0 0 27 40.75c1.966-1.166 3.75-3.606 2.678-5.813a3.476 3.476 0 0 0-1.607-1.537 2.352 2.352 0 0 0-1.607-.382 1.358 1.358 0 0 0-.246.1z"
        />
        <path
          fill="#F6FAFD"
          fillRule="nonzero"
          d="M28.22 32.397a3.455 3.455 0 0 0-.955-.397c.446.32.802.751 1.034 1.25.989 2.206-.663 4.646-2.472 5.81a6.87 6.87 0 0 1-3.55 1.24H22c.124.17.285.308.47.407.372.2.789.3 1.211.293a6.87 6.87 0 0 0 3.55-1.27c1.814-1.165 3.46-3.605 2.472-5.811a3.337 3.337 0 0 0-1.483-1.522z"
        />
        <path
          stroke="#302D1F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M26.218 33.117a1.806 1.806 0 0 0-.536.382c-.82.848-1.371 1.944-2.057 2.886-.776 1.067-1.505 2.178-2.351 3.2-.25.342-.333.766-.232 1.169.102.402.38.748.768.953.403.2.855.3 1.312.293A7.85 7.85 0 0 0 27 40.75c1.966-1.166 3.75-3.606 2.678-5.813a3.476 3.476 0 0 0-1.607-1.537 2.352 2.352 0 0 0-1.607-.382 1.358 1.358 0 0 0-.246.1z"
        />
        <g fillRule="nonzero" stroke="#302D1F" strokeWidth="1.5">
          <path
            fill="#F7D148"
            d="M34.762 25.408c2.304 3.337 3.545 6.969 3.653 10.044a.324.324 0 0 0 .218.298.313.313 0 0 0 .347-.113 11.123 11.123 0 0 0 1.964-4.718c.903-5.697-2.592-11.073-7.962-12.246a9.747 9.747 0 0 0-3.956-.013.543.543 0 0 0-.385.442.543.543 0 0 0 .23.539 19.976 19.976 0 0 1 5.891 5.767z"
          />
          <path
            fill="#D8C6B0"
            strokeLinejoin="round"
            d="M39.233 35.329l-.996 2.218c-.451.17-.99.067-1.34-.258a148.93 148.93 0 0 0 1.095-2.649l1.241.689z"
          />
        </g>
      </g>
    </svg>
  )
}
