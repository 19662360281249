import { StyledLoader, Circular, Path } from './styled'

// SOURCE: https://codepen.io/jczimm/pen/vEBpoL
export default function Loader() {
  return (
    <StyledLoader>
      <Circular viewBox="25 25 50 50">
        <Path cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </Circular>
    </StyledLoader>
  )
}
