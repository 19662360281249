import { OrderRefundsState, OrderRefundsStatus, RootState } from './types'
import initialState from './initialState'
import { ApiOrderRefund, ApiOrderRefundSummary } from '~/app/common/api/types'

export function setOrderRefundsLoading(state: RootState) {
  return () =>
    ({
      ...state,
      orderRefunds: {
        ...state.orderRefunds,
        status: OrderRefundsStatus.loading,
      },
    }) as RootState
}

export function setOrderRefundsLoaded(state: RootState) {
  return ({ data }: { data: ApiOrderRefund[] }) =>
    ({
      ...state,
      orderRefunds: {
        ...state.orderRefunds,
        data: structuredClone(data),
        status: OrderRefundsStatus.loaded,
      } as OrderRefundsState,
    }) as RootState
}

export function setOrderRefundsInitialState(state: RootState) {
  return () =>
    ({
      ...state,
      orderRefunds: initialState,
    }) as RootState
}

export function setOrderRefundsSummaryLoaded(state: RootState) {
  return ({ data }: { data: ApiOrderRefundSummary }) =>
    ({
      ...state,
      orderRefunds: {
        ...state.orderRefunds,
        summary: data,
      },
    }) as RootState
}

export function setOrderRefundsError(state: RootState) {
  return () =>
    ({
      ...state,
      orderRefunds: {
        ...state.orderRefunds,
        data: [],
        status: OrderRefundsStatus.error,
      },
    }) as RootState
}
