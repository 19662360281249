import type { AddOnType, ProductType } from '~/app/common/types'

export enum OrderStatus {
  skipped = 'skipped',
  delivered = 'delivered',
  inTransit = 'inTransit',
  preparing = 'preparing',
  pending = 'pending',
}

export enum DiscountType {
  coupon = 'coupon',
  giftcard = 'giftcard',
  product = 'product',
  referral = 'referral',
  productTier = 'productTier',
}

export interface QuoteProduct {
  id: number
  quantity: number
  totalPriceCents: number
  unitPriceCents: number
  type: ProductType
}

export interface QuoteOrder {
  id: number
  itemizedList: QuoteProduct[]
  orderValueCents: number
  shippingCents?: number
}

export interface QuoteDiscount {
  amountCents: number
  percentage: number | null
  description: string
  discountCode: string | null
  couponId: number | null
  templateId?: number
  type: DiscountType
  metadata?: { productType: ProductType; orderId?: number }
}
export interface Quote {
  amountToChargeCents: number
  discountCents: number
  discounts: QuoteDiscount[]
  orders: QuoteOrder[]
  shippingCents: number
  totalOrderValueCents: number
}

export interface OrderDeliveryIssues {
  issueIds: number[]
  resent: boolean
  createdAt?: Date
  note: string
  intercomLink: string
  photosProvided: boolean
  photos?: string[]
  products: { sku: string; count: number }[]
  extraProductIds?: number[]
  reporter: { email: string } | null
}

export interface OrderCharge {
  amount: number
  amountRaw: number
  receiptJson: Quote | null
  shippingCost: number
  taxRate: number
  refunds: { amount: number }[]
  coupon: {
    discount: number
    percentage: boolean
    code: string
    maxDiscountCents?: number
  } | null
  userCredit: { amount: number }[]
  credits: number
}

export interface OrderProduct {
  id: number
  count: number
  type: ProductType | AddOnType
}

export interface OrderAddOn {
  type: AddOnType
  products: OrderProduct[]
  charge: { amount: number | null; credits: number }
  skipped?: boolean
}

export interface Order {
  addOns: OrderAddOn[]
  charge: OrderCharge
  deliveryIssues?: OrderDeliveryIssues[]
  id: number
  planId: number
  processedAt: Date
  canceledAt: Date | null
  shipment?: Shipment | null
  products: OrderProduct[]
  status: OrderStatus
  subscriptionTotal: number
  templateId: number
  trackingUrl: string
  type: ProductType | 'manual'
  editableTo: Date
}

export interface Shipment {
  id: number
  boxId: number
  cartonizerSolutionId: number
  createdAt: Date
  shipmentProducts: ShipmentProduct[]
  updatedAt: Date
  metadata: PaccurateBoxResponse
  shippedAt: Date | null
  shipstationId: number | null
  shipstationKey: number | null
  status: string | null
  trackingUrl: string | null
}

export type PaccurateBoxResponse = {
  box: {
    name: string
    id: number
    dimensions: {
      x: number
      y: number
      z: number
    }
    weightTare: number
  }
  centerOfMass: {
    x: number
    y: number
    z: number
  }
  price: number
  volumeUtilization: number
  weightUsed: number
  items: {
    name: string
    refId: number
    sequence: string
  }[]
}

export interface ShipmentProduct {
  count: number
  createdAt: Date
  productId: number
  shipmentId: number
  updatedAt: Date
}

export interface OrderProductGroup {
  type: ProductType
  products: OrderProduct[]
  totalPriceCents: number
}
