export default function DeveloperToolsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 495.984 495.984" width="18">
      <title>Developer Tools</title>
      <g fill="#939AA6" fillRule="evenodd">
        <path d="m490.797 85.029-80.343-80.342c-6.25-6.25-16.38-6.248-22.627 0l-26.112 26.112c-24.501 24.501-27.837 62.256-10.045 90.389l-156.4 156.4-60.382-60.382c-6.248-6.249-16.378-6.249-22.627 0l-59.735 59.735c-40.352 40.349-58.929 98.057-49.695 154.368l6.924 42.224c1.111 6.777 6.423 12.089 13.2 13.2 45.124 7.399 52.76 9.251 70.822 9.251 46.734 0 92.245-18.495 125.77-52.021l59.735-59.735c6.249-6.249 6.249-16.379 0-22.627l-61.387-61.387 156.388-156.388c27.631 17.547 65.43 14.914 90.402-10.058l26.112-26.111c6.244-6.244 6.248-16.379 0-22.628zm-245.456 287.886-48.422 48.422c-33.081 33.082-80.394 48.314-126.563 40.742l-30.884-5.064-5.064-30.883c-7.57-46.168 7.661-93.481 40.742-126.563l48.422-48.422 49.069 49.069-34.884 34.884c-6.249 6.248-6.249 16.379 0 22.627 6.247 6.248 16.379 6.249 22.627 0l34.884-34.884zm196.717-261.774c-7.708 7.708-17.957 11.953-28.857 11.953-36.085 0-54.584-43.943-28.858-69.669l14.798-14.798 57.716 57.716z" />
      </g>
    </svg>
  )
}
