import { createAction } from '~/app/common/actions'
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCT_DETAIL,
  FETCH_PRODUCT_DETAIL_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCTS_PAGE,
  FETCH_PRODUCTS_REPLACEMENTS,
  FETCH_PRODUCTS_REPLACEMENTS_SUCCESS,
  SET_PRODUCT_REPLACEMENTS,
  CREATE_PRODUCT_REPLACEMENT_SUCCESS,
  UPDATE_PRODUCT_REPLACEMENT_SUCCESS,
  DELETE_PRODUCT_REPLACEMENT_SUCCESS,
  FETCH_GIFT_PRODUCTS,
  FETCH_GIFT_PRODUCTS_SUCCESS,
  SET_PRODUCT_SEQUENCE,
  SET_PRODUCT_SEQUENCE_SUCCESS,
  FETCH_ALL_PRODUCTS,
  FETCH_ALL_PRODUCTS_SUCCESS,
} from './constants'

export const fetchProducts = ({ source, type }: any) =>
  createAction(FETCH_PRODUCTS, {
    source,
    type,
  })
export const fetchProductsSuccess = (data: any) => createAction(FETCH_PRODUCTS_SUCCESS, data)
export const fetchProductsFail = () => createAction(FETCH_PRODUCTS_FAIL)

export const fetchProductDetail = (id: number) => createAction(FETCH_PRODUCT_DETAIL, { id })
export const fetchProductDetailSuccess = (data: any) =>
  createAction(FETCH_PRODUCT_DETAIL_SUCCESS, { data })

export const createProduct = (data: any) => createAction(CREATE_PRODUCT, { data })
export const createProductSuccess = (data: any) => createAction(CREATE_PRODUCT_SUCCESS, { data })

export const editProduct = (id: number, data: any) => createAction(EDIT_PRODUCT, { data, id })
export const editProductSuccess = (id: number, data: any) =>
  createAction(EDIT_PRODUCT_SUCCESS, { data, id })

export const deleteProduct = (id: number) => createAction(DELETE_PRODUCT, { id })
export const deleteProductSuccess = () => createAction(DELETE_PRODUCT_SUCCESS)

export const updatePage = () => createAction(UPDATE_PRODUCTS_PAGE)

// Product replacements (Out of stock per region)
export const fetchProductsReplacements = () => createAction(FETCH_PRODUCTS_REPLACEMENTS)

export const fetchProductsReplacementsSuccess = (data: any) =>
  createAction(FETCH_PRODUCTS_REPLACEMENTS_SUCCESS, { data })

export const setProductReplacements = (data: any) =>
  createAction(SET_PRODUCT_REPLACEMENTS, { data })

export const createProductReplacementSuccess = (data: any) =>
  createAction(CREATE_PRODUCT_REPLACEMENT_SUCCESS, { data })

export const updateProductReplacementSuccess = (data: any) =>
  createAction(UPDATE_PRODUCT_REPLACEMENT_SUCCESS, { data })

export const deleteProductReplacementSuccess = (id: number) =>
  createAction(DELETE_PRODUCT_REPLACEMENT_SUCCESS, { id })

export const fetchGiftProducts = (limit: number, offset: number) =>
  createAction(FETCH_GIFT_PRODUCTS, {
    limit,
    offset,
  })
export const fetchGiftProductsSuccess = (data: any) =>
  createAction(FETCH_GIFT_PRODUCTS_SUCCESS, data)

export const setProductSequence = (data: any) => createAction(SET_PRODUCT_SEQUENCE, { data })

export const setProductSequenceSuccess = () => createAction(SET_PRODUCT_SEQUENCE_SUCCESS)

export const fetchAllProducts = (type: string) =>
  createAction(FETCH_ALL_PRODUCTS, {
    source: 'list',
    type,
  })
export const fetchAllProductsSuccess = (data: any) => createAction(FETCH_ALL_PRODUCTS_SUCCESS, data)
