import { StatusType, ValidProductType } from '~/app/common/types'

export const setCancellationReasons = (state: any) => (data: any) => ({
  ...state,
  ...data,
})

export const setStatus =
  (state: any) => (data: { status: StatusType; type: ValidProductType }) => ({
    ...state,
    [`${data.type}Status`]: data.status,
  })
