import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'
import { colors } from '~/app/settings/colors'
const contentBreakPoint = 660
export const Wrapper = styled.div`
  border-radius: 0.4rem;
  border: 0.1rem solid ${colors.lightGray};
`
export const Wrap = styled.div`
  display: flex;
  margin-bottom: 20px;
`
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${media.sm} {
    flex-direction: row;
    padding: 2.5rem 2.1rem;
  }
  align-items: center;
  border-bottom: 0.1rem solid ${colors.lightGray};
`
export const MainInfo = styled.div`
  width: 100%;
  ${media.sm} {
    width: auto;
  }
  padding: 2.5rem 2.1rem;
  ${media.sm} {
    padding: 0;
  }
`
export const Name = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
`
export const BirthDate = styled.h4`
  font-size: 1.5rem;
  font-weight: normal;
  strong {
    text-transform: capitalize;
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 1.5rem;
  border-bottom: 0.1rem solid ${colors.lightGray};
  padding: 1.9rem 0;
  align-items: center;
`
export const Label = styled.label`
  font-weight: bold;
  width: 40%;
`
export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  ${media.minW(contentBreakPoint)} {
    flex-direction: row;
  }
`
export const ContentBlock = styled.li`
  ${media.minW(contentBreakPoint)} {
    width: 50%;
  }
  padding: 3rem 2.6rem;
  &:first-child {
    border-bottom: 0.1rem dashed ${colors.lightGray};
    ${media.minW(contentBreakPoint)} {
      width: calc(50% - 0.1rem);
      border-bottom: none;
      border-right: 0.1rem dashed ${colors.lightGray};
    }
  }
`
export const SectionName = styled.h3`
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 3rem;
`
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
export const StyledInput = styled.div`
  background: none;
  border: none;
  box-shadow: none;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  width: 100%;
`
export const Footer = styled.footer`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
`
