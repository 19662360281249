import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withForm from '~/app/hocs/withForm'
import SaveButton from '~/app/components/Button/SaveButton'
import formatDate from '~/app/utils/formatDate'
import formDefinition from './formDefinition'
import { Row, Label, StyledInput, SectionName, Section, Footer } from './styled'
import Options from '../SubscriptionCard/Options'
import withEditKidController from '../withEditKidController'

const EditKidForm = ({
  renderField,
  isNew,
  hasChanges,
  isValid,
  formData,
  kid,
  handleEdit,
  handleSave,
}) => (
  <>
    <Section>
      <SectionName>Your Kid Info</SectionName>
      <Options kid={kid} handleEdit={handleEdit} />
    </Section>
    <Row>
      {renderField('name', {
        isNewUI: true,
      })}
    </Row>
    <Row>
      <Label>Birthday</Label>
      <StyledInput>
        {formatDate(kid.birthdate, {
          timestamp: false,
        })}
      </StyledInput>
    </Row>
    <Row>
      {renderField('gender', {
        isNewUI: true,
        label: `${kid.name} is`,
      })}
    </Row>
    <Footer>
      <SaveButton
        isNew={isNew}
        handleConfirm={handleSave}
        model={formData}
        hasChanges={hasChanges}
        isValid={isValid}
      />
    </Footer>
  </>
)

EditKidForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  hasChanges: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  kid: PropTypes.any,
  renderField: PropTypes.func.isRequired,
}
export default compose(withForm(formDefinition), withEditKidController)(EditKidForm)
