import moment from 'moment'

export function formatDate(
  // @ts-ignore
  date: string | { month: number; day: number; year: number },
  options: { timeStamp: boolean; format?: string },
) {
  const { timeStamp, format } = options
  const dateFormat = format || 'MMM DD, YYYY'
  if (timeStamp) {
    // @ts-ignore
    return date.format(dateFormat)
  }
  return moment.utc(date).format(dateFormat)
}

export default formatDate
