import { useState } from 'react'
import { find } from 'lodash'
import user$ from '~/app/store/user'

type $Props = {
  groupedOrders: Array<any>
  userId: number
}

export default function useSkipController(props: $Props) {
  const { groupedOrders, userId } = props
  const [orderToUnskip, setOrderToUnskip] = useState(null)

  const unsetOrderToUnskip = (): void => setOrderToUnskip(null)

  const handleSkipToggle = (index: number, kidId: number, groupIndex: number): void => {
    const order = groupedOrders[groupIndex].orders[index]
    const { id, editableTo, skipped, skippable } = order
    if (skippable) {
      user$.call.skipOrder({
        editableTo: editableTo.toISOString(),
        id,
        skip: !skipped,
        userId,
      })
    } else {
      // @ts-expect-error: TODO: Fix type error
      setOrderToUnskip({ editableTo, id })
    }
  }

  const handleSkipAddOnToggle = (index: number, templateId: number, addOnType: string): void => {
    const parentOrders = find(groupedOrders, { templateId })
    const order = parentOrders.orders[index]
    const { addOns, editableTo } = order
    const {
      skippable,
      skipped,
      templateId: addOnTemplateId,
      // @ts-expect-error: TODO: Fix type error
    } = addOns.find(({ type }) => addOnType === type)
    if (skippable) {
      user$.call.skipLinkedOrder({
        editableTo: editableTo.toISOString(),
        skip: !skipped,
        templateId: addOnTemplateId,
        userId,
      })
    }
  }

  const forceUnskip = (): void => {
    // @ts-expect-error: TODO: Fix type error
    const { id, editableTo } = orderToUnskip
    user$.call.skipOrder({
      editableTo,
      force: true,
      id,
      userId,
    })
  }

  return {
    forceUnskip,
    handleSkipAddOnToggle,
    handleSkipToggle,
    orderToUnskip,
    unsetOrderToUnskip,
  }
}
