import { get } from 'lodash'
import { pure } from 'recompose'
import LightBox from '~/app/components/LightBox'
import { Wrapper, Header, Heading, Info, Content } from './styled'
import NewFaultyOrderForm from './Form'
import moment from 'moment'
import { type OrderDeliveryIssues } from '~/app/pages/User/UserOrders/types'
import { Order } from '~/app/common/api/types'
import { useMemo } from 'react'

interface FaultyOrderReason {
  label: string
  value: number
  isDeprecated: boolean
}

interface Props {
  faultyOrderReasons: FaultyOrderReason[]
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  giftProducts: any[]
  faultyOrder: Order
}

function PreviouslyReportedDeliveryIssues({
  data,
  faultyOrderReasons,
  giftProducts,
}: {
  data: OrderDeliveryIssues[]
  faultyOrderReasons: { name: string; options: { label: string; value: number }[] }[]
  giftProducts: any[]
}) {
  return (
    <>
      {data.map((issue: OrderDeliveryIssues, index: number) => (
        <div key={`${issue.createdAt}`}>
          <Heading>Issue #{index + 1}</Heading>
          <Info>Reported on: {moment(issue.createdAt).format('DD MMM YYYY, HH:mm')}</Info>
          {issue.reporter && <Info>Reported by: {issue.reporter.email}</Info>}
          <NewFaultyOrderForm
            onSubmit={() => {}}
            isFaulty
            issueIds={issue.issueIds}
            resend={issue.resent}
            note={issue.note}
            photosProvided={issue.photosProvided}
            faultyOrderReasons={faultyOrderReasons}
            giftProducts={giftProducts}
            extraProduct={get(issue, 'extraProductIds[0]', false)}
            products={issue.products}
            intercomLink={issue.intercomLink}
            photos={issue.photos}
          />
        </div>
      ))}
    </>
  )
}

function FaultyOrderLightBox(props: Props) {
  const faultyOrderReasons = useMemo(
    () => getFaultyOrderReasons(props.faultyOrderReasons),
    [props.faultyOrderReasons],
  )

  return (
    <LightBox isOpen={props.isOpen} closeHandler={props.onCancel}>
      <Wrapper>
        <Content>
          {(props.faultyOrder.deliveryIssues?.length ?? 0) > 0 && (
            <PreviouslyReportedDeliveryIssues
              data={props.faultyOrder.deliveryIssues!}
              giftProducts={props.giftProducts}
              faultyOrderReasons={faultyOrderReasons}
            />
          )}
          <Header>
            <Heading noTopMargin>Report issue</Heading>
            <Info>
              If there was an issue with the order, set the details and resend if necessary
            </Info>
          </Header>
          <NewFaultyOrderForm
            onSubmit={props.onConfirm}
            isFaulty={false}
            issueIds={[]}
            resend={false}
            note=""
            photosProvided={false}
            faultyOrderReasons={faultyOrderReasons}
            giftProducts={props.giftProducts}
            extraProduct={false}
            order={props.faultyOrder}
            products={[]}
          />
        </Content>
      </Wrapper>
    </LightBox>
  )
}

function getFaultyOrderReasons(faultyOrderReasons: FaultyOrderReason[]) {
  return faultyOrderReasons
    .filter(({ isDeprecated }) => !isDeprecated)
    .sort((reasonA, reasonB) =>
      reasonA.label.localeCompare(reasonB.label, 'en', {
        ignorePunctuation: true,
      }),
    )
    .reduce(
      (acc, reason) => {
        const splitLabel = reason.label.split(' - ')
        const existingGroup = acc.find((group) => group.name === splitLabel[0])
        if (existingGroup) {
          existingGroup.options.push({ label: splitLabel[1], value: reason.value })
          return acc
        }
        acc.push({
          name: splitLabel[0],
          options: [{ label: splitLabel[1] ?? reason.label, value: reason.value }],
        })
        return acc
      },
      [] as {
        name: string
        options: { label: string; value: number }[]
      }[],
    )
}

export default pure(FaultyOrderLightBox)
