import { merge } from 'ramda'
import Filter from '~/app/store/filters/model'
import { SET_FILTER } from './constants'
const initialState = {
  currentFilter: new Filter(),
}

function filterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTER:
      return merge(state, {
        currentFilter: action.payload.data,
      })

    default:
      return state
  }
}

export default filterReducer
