import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

export function postUserCoupon(store: any, self$: any) {
  return async (payload: { userId: number; code: string }): Promise<void> => {
    loader$.call.show()
    try {
      await Api.postUserCoupon(payload)
      const creditsResponse = await Api.fetchUserCredits(payload.userId)
      const couponsResponse = await Api.fetchUserCoupons(payload.userId)
      await store.dispatch(
        self$.setUserCredits({
          balance: creditsResponse.balance,
          history: creditsResponse.history,
        }),
      )
      await store.dispatch(self$.setUserCoupons(couponsResponse))
      alert$.call.setNotification('Coupon has been successfully added!')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

export function fetchUserCoupons(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    loader$.call.show()
    try {
      const couponsResponse = await Api.fetchUserCoupons(userId)
      await store.dispatch(self$.setUserCoupons(couponsResponse))
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}
