import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
  padding: 40px 44px;
`

export const Question = styled.h2`
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 25px auto 35px;
`

export const Options = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
`

export const ButtonWrapper = styled.li`
  max-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
