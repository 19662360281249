import styled, { css } from 'styled-components'
export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`
export const Option = styled.button`
  align-items: center;
  border: 0;
  display: flex;
  margin-right: 20px;
  outline: 0;
  padding-left: 0;
`
export const RadioButton = styled.div`
  border: 1px solid ${(props) => props.theme.color.lightGray};
  border-radius: 4px;
  display: inline-block;
  height: 24px;
  vertical-align: top;
  width: 24px;

  ${(props) =>
    props.checked &&
    css`
      background: ${(props) => props.theme.color.green};
      border-color: ${(props) => props.theme.color.green};
    `}
`
export const Label = styled.span`
  color: #162234 !important;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: auto;
  margin-left: 12px;
  text-transform: none;
  white-space: nowrap;
`
