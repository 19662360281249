import { ListRow, Cell } from '~/app/components/Table'

interface Props {
  timestamp: string
  eventType: string
  description: string | null
  metadata: string | null
}

export default function Log(props: Props) {
  return (
    <ListRow>
      <Cell>{props.timestamp}</Cell>
      <Cell>{props.eventType}</Cell>
      <Cell>{props.description}</Cell>
      <Cell>
        <code>{props.metadata}</code>
      </Cell>
    </ListRow>
  )
}
