import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'
import { theme } from '../../settings/colors'

export const Link = styled(ReactRouterLink)`
  left: 33px;
  position: absolute;
  text-decoration: none;
  top: 30px;

  img {
    height: 18px;
  }
`

export const Heading = styled.h1`
  color: ${theme.color.white};
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 9px;
  text-transform: uppercase;

  img {
    height: 30px;
  }
`
