interface Props {
  className?: string
}

export default function SmallPlanIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="64" viewBox="0 0 100 64" {...props}>
      <title>Small plan</title>
      <g fill="none" fillRule="evenodd" transform="translate(-11 -30)">
        <circle cx="62" cy="62" r="62" opacity="0.1" />
        <g transform="translate(12 32)">
          <ellipse cx="34.195" cy="30" fill="#FACE4E" rx="29.805" ry="30" />
          <ellipse
            cx="29.805"
            cy="30"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="29.805"
            ry="30"
          />
          <path
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M.977 21.64c12.621 4.59 22.149 6.885 28.584 6.885 6.435 0 15.962-2.295 28.583-6.886"
          />
          <path
            fill="#FFF"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M29.56 28.525v30.983"
          />
          <path
            fill="#F4AAAE"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M22.734 14.262c-.636 2.788-2.362 3.717-5.176 2.788-4.221-1.394-4.466.198-4.343 2.61.122 2.412 3.076 6.201 6.298 4.307 3.22-1.895 4.886.513 7.329-4.596 1.628-3.406.26-5.109-4.108-5.109z"
          />
          <path
            fill="#F4AAAE"
            d="M48.328 14.63c-3.272 2.346-5.473 2.104-6.6-.725-1.693-4.244-3.474-2.824-5.813-.34-2.338 2.485-3.986 8.256.283 8.549 4.269.293 3.066 3.816 10 .382 4.622-2.29 5.332-4.911 2.13-7.865z"
          />
          <ellipse cx="11.482" cy="31.967" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse cx="18.811" cy="32.951" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse cx="14.903" cy="35.902" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse cx="17.346" cy="42.295" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse cx="20.766" cy="39.344" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse cx="21.743" cy="46.23" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse cx="11.482" cy="41.311" fill="#9DEFE6" rx="1.71" ry="1.967" />
          <ellipse
            cx="13.429"
            cy="31.967"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
            ry="1.967"
          />
          <ellipse
            cx="20.758"
            cy="32.951"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
            ry="1.967"
          />
          <ellipse
            cx="16.849"
            cy="35.902"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
            ry="1.967"
          />
          <ellipse
            cx="19.292"
            cy="42.295"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
            ry="1.967"
          />
          <ellipse
            cx="22.713"
            cy="39.344"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
            ry="1.967"
          />
          <ellipse cx="23.69" cy="46.23" stroke="#2A313F" strokeWidth="1.3" rx="1.71" ry="1.967" />
          <ellipse
            cx="13.429"
            cy="41.311"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
            ry="1.967"
          />
          <path
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M46.373 14.63c-3.272 2.346-5.472 2.104-6.6-.725-1.693-4.244-3.474-2.824-5.812-.34-2.339 2.485-3.986 8.256.283 8.549 4.268.293 3.066 3.816 10 .382 4.621-2.29 5.331-4.911 2.13-7.865z"
          />
          <path
            fill="#F4AAAE"
            d="M28.968 3.576c.753 3.978-.44 5.777-3.576 5.399-4.705-.568-4.162 1.666-2.869 4.897 1.293 3.231 5.957 7.441 8.105 3.865 2.148-3.575 4.91-.834 4.748-8.523-.108-5.125-2.244-7.005-6.408-5.638z"
          />
          <path
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M28.006 4.047c.752 3.978-.44 5.777-3.576 5.399-4.705-.568-4.162 1.666-2.87 4.897 1.293 3.231 5.958 7.441 8.106 3.865 2.148-3.575 4.909-.834 4.748-8.523-.108-5.125-2.244-7.005-6.408-5.638z"
          />
          <rect width="3.42" height="16.721" x="33.714" y="33.443" fill="#DCE8F4" rx="1.71" />
          <path
            fill="#29C5BF"
            d="M46.737 30.303a1.5 1.5 0 01.118 2.166l-9.263 9.867c-.601.64-1.6.695-2.266.124a1.5 1.5 0 01-.118-2.166l9.263-9.868c.6-.64 1.6-.694 2.266-.123z"
          />
          <path
            fill="#FF979D"
            d="M38.377 33.583a1.648 1.648 0 012.23.479l7.583 11.204a1.515 1.515 0 01-.458 2.14 1.648 1.648 0 01-2.23-.48l-7.584-11.204a1.515 1.515 0 01.459-2.139z"
          />
          <rect
            width="3.42"
            height="16.721"
            x="34.691"
            y="32.459"
            stroke="#2A313F"
            strokeWidth="1.3"
            rx="1.71"
          />
          <path
            stroke="#2A313F"
            strokeWidth="1.3"
            d="M48.203 29.32a1.5 1.5 0 01.118 2.165l-9.263 9.868c-.601.64-1.6.694-2.267.123a1.5 1.5 0 01-.117-2.165l9.262-9.868c.602-.64 1.6-.695 2.267-.124z"
          />
          <path
            stroke="#302D1F"
            strokeWidth="1.7"
            d="M39.354 32.6a1.648 1.648 0 012.23.478l7.584 11.205a1.515 1.515 0 01-.46 2.138 1.648 1.648 0 01-2.23-.478l-7.583-11.205a1.515 1.515 0 01.46-2.138z"
          />
        </g>
        <g transform="translate(48 31)">
          <ellipse cx="32" cy="30.703" fill="#C6B6E3" rx="31" ry="30.703" />
          <ellipse
            cx="31"
            cy="30.703"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.5"
            rx="31"
            ry="30.703"
          />
        </g>
        <g transform="translate(67.406 42.844)">
          <path
            fill="#F4AAAE"
            stroke="#2A313F"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M0 24.377c2.88-1.614 4.321-2.687 4.321-3.218 0-.797.327-14.018 2.425-13.115 2.098.903 3.716 1.309 4.503 2.172.788.863-.563 6.656 2.106 7.849 2.669 1.193 10.506 4.302 11.921 6.959 1.415 2.656 1.42 2.337 2.697 2.058 1.277-.278 2.072-.94 2.072 0s-1.805 4.01-5.918 2.304c-.782 1.87-1.607 2.854-2.475 2.952-.868.098-1.821-.038-2.861-.409v-1.421c2.038-2.098 2.28-3.24.724-3.426-1.555-.186-2.513-.309-2.875-.37l2.15 1.233v1.727L16.814 31c-2.812-1.358-4.442-2.35-4.89-2.977-2.887.116-5.116-.944-6.686-3.18-1.08 1.466-2.365 2.083-3.853 1.853L.17 24.77 0 24.377z"
          />
          <circle cx="8.391" cy="3.391" r="3.391" fill="#49C5B1" />
          <circle cx="6.391" cy="3.391" r="3.391" stroke="#2A313F" strokeWidth="1.3" />
        </g>
      </g>
    </svg>
  )
}
