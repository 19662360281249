import PropTypes from 'prop-types'
import CaretIcon from '~/app/assets/svg/Caret'
import { StyledLink, StyledLinkButton, Item, Icon, SubLink, CaretContainer } from './styled'
export const SidebarLink = ({ to, pathname, subLinks, children, icon, 'data-cy': dataCy }) => {
  const active = pathname.startsWith(to)
  return (
    <div>
      <StyledLink to={to} data-cy={dataCy}>
        <Icon active={active}>{icon}</Icon>
        <Item active={active}>{children}</Item>
        {active && subLinks.length > 0 && (
          <CaretContainer>
            <CaretIcon color="white" />
          </CaretContainer>
        )}
      </StyledLink>
      {active &&
        subLinks.map((subLink) => (
          <SubLink
            key={subLink.name}
            to={subLink.path}
            active={pathname.startsWith(subLink.path) ? 1 : 0}
          >
            {subLink.name}
          </SubLink>
        ))}
    </div>
  )
}
SidebarLink.defaultProps = {
  subLinks: [],
}
SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element.isRequired,
  pathname: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  to: PropTypes.string.isRequired,
  'data-cy': PropTypes.string,
}
export const SidebarLinkButton = ({ children, icon, ...props }) => (
  <StyledLinkButton {...props}>
    <Icon>{icon}</Icon>
    <Item>{children}</Item>
  </StyledLinkButton>
)
SidebarLinkButton.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}
