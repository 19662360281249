import { snakeCase } from 'lodash'
import isServer from '~/app/utils/isServer'
import { register, INIT_ACTION } from './shared'
import { getStore } from './setUp'

export default function makeModel(def: any) {
  const pureMethodsNames = def.reducers ? Object.keys(def.reducers(def.state, def.state)) : []
  // @ts-ignore
  const effectsMethodsNames = def.effects ? Object.keys(def.effects(getStore(), {})) : []
  const methodsNames = [...pureMethodsNames, ...effectsMethodsNames]

  const { actions, actionsMap, methodsMap } = methodsNames.reduce(
    (obj, key) => {
      const reduxActionType = snakeCase(key).toUpperCase()
      const type = [def.prefix, reduxActionType].filter((v) => v).join('/')
      // @ts-ignore
      obj.actions[key] = (payload) => ({ payload, type })
      // @ts-ignore
      obj.actions[key].type = type
      if (effectsMethodsNames.includes(key)) {
        // @ts-ignore
        obj.actions[key].completedType = `@@${type}_COMPLETED`
      }
      // @ts-ignore
      obj.actionsMap[type] = key
      // @ts-ignore
      obj.methodsMap[key] = type
      return obj
    },
    { actions: {}, actionsMap: {}, methodsMap: {} },
  )

  const initialState = def.state

  const reducer = (state = initialState, action: any) => {
    // @ts-ignore
    const methodName = actionsMap[action.type]
    if (methodName) {
      const pureMethods = def.reducers ? def.reducers(state, initialState) : {}
      const matchedMethod = pureMethods[methodName]
      if (!matchedMethod) {
        return state
      }
      return matchedMethod(action.payload)
    }
    return state
  }

  const publicModel = {
    ...actions,
    ...def.selectors,
    test: {
      effects: def.effects,
      reducers: def.reducers,
    },
  }

  const delayedDispatch = (action: any) =>
    new Promise((resolve) => {
      // @ts-ignore
      getStore().dispatch(action)
      setTimeout(() => {
        resolve(undefined)
      }, 0)
    })

  const storeProxy = {
    dispatch: delayedDispatch,
    getState: () => getStore().getState(),
    // @ts-ignore
    subscribe: (listener: any) => getStore().subscribe(listener),
  }

  function dispatchAndWait(actionType: any, payload: any) {
    return new Promise((resolve) => {
      const listenerObj = {
        fn: resolve,
        type: 'callback',
      }
      // @ts-ignore
      register.listeners[`@@${actionType}_COMPLETED`] = listenerObj
      storeProxy.dispatch({
        payload,
        type: actionType,
      })
    })
  }

  const model$ = {
    ...publicModel,
    call: {
      // NOTE: Experimental feature, do not use yet
      ...Object.keys(methodsMap).reduce((obj, key) => {
        // @ts-ignore
        const actionType = methodsMap[key]

        if (effectsMethodsNames.includes(key)) {
          // @ts-ignore
          obj[key] = async (payload?: any) => dispatchAndWait(actionType, payload)
        } else {
          // Pure events
          // @ts-ignore
          obj[key] = async (payload?: any) =>
            storeProxy.dispatch({
              payload,
              type: actionType,
            })
        }

        return obj
      }, {}),
      // @ts-ignore
      fetch: async (payload?: any) => dispatchAndWait(methodsMap.fetch, payload),
    },
  }

  if (def.effects != null) {
    const effectsDef = def.effects
    const effects = effectsDef(storeProxy, model$)
    effectsMethodsNames.forEach((methodName) => {
      // @ts-ignore
      const actionType = methodsMap[methodName]
      const effect = effects[methodName]
      const proxiedEffect = async (...args: any) => {
        try {
          await effect(...args)
          storeProxy.dispatch({ type: `@@${actionType}_COMPLETED` })
        } catch (err) {
          storeProxy.dispatch({
            payload: { err },
            type: `@@${actionType}_COMPLETED`,
          })
          throw err
        }
      }
      // @ts-ignore
      register.effects[actionType] = proxiedEffect
    })
  }

  if (def.listenTo) {
    def.listenTo.forEach((set: any) => {
      const [action, actionName] = set
      const actionType = action.completedType || action.type || action
      // @ts-ignore
      const matchedAction = actions[actionName]
      // @ts-ignore
      register.listeners[actionType] = {
        fn: matchedAction,
        type: 'action',
      }
    })
  }

  if (def.onMount && !isServer()) {
    const { onMount } = def
    const arr = register.listeners[INIT_ACTION]
    arr.push({
      // @ts-ignore
      fn: () => {
        onMount(storeProxy, model$)
      },
      // @ts-ignore
      type: 'callback',
    })
  }

  return {
    reducer: def.reducers ? reducer : null,
    ...model$,
  }
}
