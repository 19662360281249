export default function TrackingIcon() {
  return (
    <svg width="30" height="14" viewBox="0 0 30 14" xmlns="http://www.w3.org/2000/svg">
      <title>Track the order</title>
      <g fill="#41BAAC" fillRule="evenodd">
        <path d="M7.783 1v1.435h-2.19c-1.22 0-1.705 1.338-1.888 1.826l-.608 1.83h-.183c-1.035 0-1.826.793-1.826 1.828v1.218h-.61v1.433c0 .552.448 1 1 1h1.435c0 1.336 1.097 2.43 2.434 2.43 1.34 0 2.435-1.096 2.435-2.435h6.087c0 1.34 1.092 2.435 2.43 2.435 1.34 0 2.435-1.096 2.435-2.435h1.434c.553 0 1-.447 1-1L21.176 1c0-.552-.448-1-1-1H8.783c-.552 0-1 .448-1 1zm-2.87 3.733c.162-.488.433-1.084.92-1.084h1.95v2.435H4.478l.434-1.355z" />
        <path
          d="M21.5 3.75h6.083m-5.083 6h6.083m-7.083-3h4"
          stroke="#44BAAC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray="2.5"
        />
      </g>
    </svg>
  )
}
