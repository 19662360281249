interface Props {
  className?: string
}

export default function FaultyOrderIcon({ className }: Props) {
  return (
    <svg viewBox="0 0 60 60" className={className}>
      <title>Faulty order</title>
      <g data-name="Layer 1">
        <path d="M22 38a1 1 0 0 1-1.857.515l-2.461-4.101-2.127 1.418a1 1 0 0 1-1.45-.385l-1.493-2.987-2.057 1.372A1 1 0 0 1 9 33V21.018l-4.992-2.421V45.74a1 1 0 0 0 .567.902L27.008 57.41V29.629L22 27.226zM32.617 9.075a12.95 12.95 0 0 1 1.89-3.707l-2.068-1.034a1 1 0 0 0-.881-.006L5.304 17.002l5.331 2.585z" />
        <path d="M11 21.63v9.501l1.445-.963a1 1 0 0 1 1.45.385l1.493 2.987 2.057-1.372a1 1 0 0 1 1.412.317L20 34.39V26a1 1 0 0 1 .583-.909l12.97-5.953a12.82 12.82 0 0 1-1.458-7.596z" />
        <path d="M34.656 20.832l-10.916 5.01 4.267 2.048 9.26-4.47a13.089 13.089 0 0 1-2.61-2.588zM46.874 2.155a11.005 11.005 0 1 0 8.98 8.98 11.018 11.018 0 0 0-8.98-8.98zm3.833 15.138a1 1 0 1 1-1.414 1.414L45 14.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L43.586 13l-4.293-4.293a1 1 0 0 1 1.414-1.414L45 11.586l4.293-4.293a1 1 0 0 1 1.414 1.414L46.414 13z" />
        <path d="M39.297 24.66l-10.29 4.968V57.41l22.434-10.768a1 1 0 0 0 .567-.902V23.937a12.879 12.879 0 0 1-12.711.723zm1.158 11.235l-8 4a1 1 0 1 1-.894-1.79l8-4a1 1 0 0 1 .894 1.79zm0-6l-8 4a1 1 0 1 1-.894-1.79l8-4a1 1 0 0 1 .894 1.79z" />
      </g>
    </svg>
  )
}
