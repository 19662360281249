import DashboardIcon from '~/app/assets/svg/DashboardIcon'
import UsersIcon from '~/app/assets/svg/UsersIcon'
import ProductsIcon from '~/app/assets/svg/ProductsIcon'
import GrayBoxIcon from '~/app/assets/svg/GrayBoxIcon'
import LogoutIcon from '~/app/assets/svg/LogoutIcon'
import PromoIcon from '~/app/assets/svg/PromoIcon'
import OperationsIcon from '~/app/assets/svg/OperationsIcon'
import DeveloperToolsIcon from '~/app/assets/svg/DeveloperToolsIcon'
import LabelsIcon from '~/app/assets/svg/LabelsIcon'
import { logout } from '~/app/store/app/actions'
import { selectUserRoles } from '~/app/store/app/selectors'
import useIsEnvironment from '~/app/hooks/useIsEnvironment'
import { useMappedState, useMappedDispatch } from '~/app/hooks/useReduxStore'
import { NodeEnv, UserRole } from '~/app/common/constants'
import { SidebarLink, SidebarLinkButton } from './SidebarLink'
import { Wrap, Bottom } from './styled'
import { productTypeNamePlural } from '~/app/common/dataMaps/productTypeName'
import productTypeSlug from '~/app/common/dataMaps/productTypeSlug'
import { type Link, ProductType } from '~/app/common/types'

export default function Sidebar({ pathname }: { pathname: string }) {
  const self = useController()
  return (
    <Wrap>
      {self.hasAccessTo.dashboard && (
        <SidebarLink pathname={pathname} to="/dashboardOld" icon={<DashboardIcon />}>
          Dashboard
        </SidebarLink>
      )}
      {self.hasAccessTo.users && (
        <SidebarLink pathname={pathname} to="/users/list" icon={<UsersIcon />}>
          Users
        </SidebarLink>
      )}
      {self.hasAccessTo.products && (
        <SidebarLink
          pathname={pathname}
          to="/products"
          icon={<ProductsIcon />}
          subLinks={self.productsSubLinks}
        >
          Products
        </SidebarLink>
      )}
      {self.hasAccessTo.merchandising && (
        <SidebarLink
          pathname={pathname}
          to="/merchandising"
          icon={<LabelsIcon />}
          subLinks={[
            {
              name: 'Labels',
              path: '/merchandising/labels',
            },
            {
              name: 'Ingredients',
              path: '/merchandising/ingredients',
            },
            {
              name: 'Dietary Tags',
              path: '/merchandising/dietary-tags',
            },
            {
              name: 'Form Factors',
              path: '/merchandising/form-factors',
            },
          ]}
        >
          Merchandising
        </SidebarLink>
      )}
      {self.hasAccessTo.promoBanners && (
        <SidebarLink pathname={pathname} to="/promo-banners" icon={<DashboardIcon />}>
          Promo banners
        </SidebarLink>
      )}
      {self.hasAccessTo.blueprints && (
        <SidebarLink
          pathname={pathname}
          to="/blueprint"
          icon={<GrayBoxIcon title="Blueprints" />}
          subLinks={[
            /* {
            path: '/blueprint/master',
            name: 'Master Use Cases',
          }, */
            {
              name: 'Use Cases',
              path: '/blueprint/usecases',
            },
          ]}
          data-cy="@link:blueprint"
        >
          Blueprint
        </SidebarLink>
      )}
      {self.hasAccessTo.productDefaults && (
        <SidebarLink
          pathname={pathname}
          to="/product-defaults"
          icon={<ProductsIcon />}
          subLinks={self.productDefaultsSubLinks}
        >
          Prod Defaults
        </SidebarLink>
      )}
      {self.hasAccessTo.promoCodes && (
        <SidebarLink pathname={pathname} to="/promocodes/promocodes" icon={<PromoIcon />}>
          Promo Codes
        </SidebarLink>
      )}
      {self.hasAccessTo.operations && (
        <SidebarLink
          pathname={pathname}
          to="/operations"
          icon={<OperationsIcon />}
          subLinks={self.operationsSubLinks}
        >
          Operations
        </SidebarLink>
      )}
      {self.hasAccessTo.developerTools && (
        <SidebarLink
          pathname={pathname}
          to="/developertools"
          icon={<DeveloperToolsIcon />}
          subLinks={[
            {
              name: 'Invalidate Cache',
              path: '/developertools/invalidatecache',
            },
          ]}
        >
          Developer Tools
        </SidebarLink>
      )}
      <Bottom>
        <SidebarLinkButton onClick={self.dispatcher.logout} icon={<LogoutIcon />}>
          Log Out
        </SidebarLinkButton>
      </Bottom>
    </Wrap>
  )
}

function useController() {
  const ifIRoleIs = (allowedRoles: any) => roles.some((r: any) => allowedRoles.includes(r))
  const isProduction = useIsEnvironment(NodeEnv.production)

  const { roles } = useMappedState((state: any) => ({
    // @ts-ignore
    roles: selectUserRoles(state).map((r: any) => r.name),
  }))

  const dispatcher = useMappedDispatch({
    logout,
  })

  const productsSubLinks: Link[] = [
    ProductType.blend,
    ProductType.plate,
    ProductType.biteable,
    ProductType.luncher,
    ProductType.smoothie,
    ProductType.snack,
    ProductType.puff,
    ProductType.cereal,
    ProductType.misc,
  ].map((productType) => ({
    name: productTypeNamePlural[productType],
    // @ts-ignore
    path: `/products/${productTypeSlug[productType]}`,
  }))

  const productDefaultsSubLinks: Link[] = [
    ProductType.plate,
    ProductType.biteable,
    ProductType.luncher,
    ProductType.smoothie,
    ProductType.snack,
    ProductType.puff,
  ].map((productType) => ({
    name: productTypeNamePlural[productType],
    path: `/product-defaults/${productType}`,
  }))

  const operationsSubLinks: Link[] = [
    {
      name: 'Shipping Boxes',
      path: '/operations/shipping-boxes',
    },
    {
      name: 'Boxes By State',
      path: '/operations/boxes-by-state',
    },
    {
      name: 'Paccurate Config',
      path: '/operations/paccurate-config',
    },
    ...(!isProduction
      ? [
          {
            name: 'Cartonize Orders',
            path: '/operations/cartonize-orders',
          },
        ]
      : []),
  ]

  return {
    hasAccessTo: {
      blueprints: ifIRoleIs([UserRole.superadmin]),
      productDefaults: ifIRoleIs([UserRole.superadmin]),
      dashboard: ifIRoleIs([UserRole.superadmin]),
      developerTools: ifIRoleIs([UserRole.superadmin]),
      merchandising: ifIRoleIs([UserRole.superadmin]),
      operations: ifIRoleIs([UserRole.superadmin]),
      products: ifIRoleIs([UserRole.superadmin, UserRole.admin]),
      promoCodes: ifIRoleIs([UserRole.superadmin, UserRole.admin]),
      users: ifIRoleIs([UserRole.superadmin, UserRole.admin, UserRole['user-manager']]),
      promoBanners: ifIRoleIs([UserRole.superadmin]),
    },
    dispatcher,
    operationsSubLinks,
    productDefaultsSubLinks,
    productsSubLinks,
  }
}
