/* eslint-disable sort-keys */

const breakpoints = {
  xs: 340,
  sm: 550,
  md: 768,
  lg: 1000,
  xl: 1200,
  xxl: 1600,
} as const

const toEm = (pxls: number) => `${pxls / 16}em`

const minWidthQuery = (size: number) => `@media (min-width: ${toEm(size)})`
const minHeightQuery = (size: number) => `@media (min-height: ${toEm(size)})`

interface MediaQ {
  minW: typeof minWidthQuery
  minH: typeof minHeightQuery
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
}

// Includes @media keyword
// @ts-ignore
const mediaQ: MediaQ = Object.keys(breakpoints).reduce(
  (obj, size) => {
    // @ts-ignore
    obj[size] = `@media (min-width: ${toEm(breakpoints[size])})`
    return obj
  },
  { ...{ minW: minWidthQuery, minH: minHeightQuery } },
)

export const queries = Object.keys(breakpoints).reduce(
  (obj, size) => {
    // @ts-ignore
    obj.min[size] = `(min-width: ${toEm(breakpoints[size])})`
    // @ts-ignore
    obj.max[size] = `(max-width: ${toEm(breakpoints[size] - 1)})`
    return obj
  },
  { min: {}, max: {} },
)

export default mediaQ
