/**
 * Converts environment variable value to boolean.
 */
export function envToBoolean(value?: string): boolean {
  switch (value) {
    case 'true':
    case '1':
      return true
    default:
      return false
  }
}
