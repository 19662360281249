import moment from 'moment-timezone'
import SaveButton from '~/app/components/Button/SaveButton'
import { Block } from '~/app/components/Block'
import useForm from '~/app/hooks/useForm'
import { FieldSet } from '~/app/components/FieldSet'
import { Half, Row } from '~/app/components/FormGrid'
import formDefinition from './formDefinition'
import { FieldNames } from '~/app/pages/Product/DetailForm/types'
import useIsEnvironment from '~/app/hooks/useIsEnvironment'
import { NodeEnv } from '~/app/common/constants'
import type { PromoBanner } from '~/app/common/types'

interface Props {
  isNew: boolean
  formData: PromoBanner
  onConfirm: (data: PromoBanner) => Promise<void>
}

export default function PromoBannerDetailsForm(props: Props) {
  const { isNew } = props
  const {
    form,
    form: { formState },
    ...self
  } = useController(props)

  return (
    <Block>
      <FieldSet legend="Fill banner details">
        {form.renderField(FieldNames.title)}
        {form.renderField(FieldNames.description)}
        <Row>
          <Half>
            {form.renderField(FieldNames.startDate, {
              selected: moment(formState.startDate.value).seconds(0).tz('America/New_York'),
              showTimeSelect: true,
              dateFormat: 'M/D/yyyy h:mm A',
              minDate: moment()
                .seconds(0)
                .tz('America/New_York')
                .add(1, 'hour')
                .tz('America/New_York'),
              disabled: self.isStaging
                ? false
                : props.formData?.startDate &&
                  !isNew &&
                  moment()
                    .seconds(0)
                    .tz('America/New_York')
                    .isAfter(moment(props.formData.startDate).tz('America/New_York')),
            })}
          </Half>
          <Half>
            {form.renderField(FieldNames.endDate, {
              selected: moment(formState.endDate.value).seconds(0).tz('America/New_York'),
              showTimeSelect: true,
              dateFormat: 'M/D/yyyy h:mm A',
              value: moment(formState.endDate.value).seconds(0).tz('America/New_York'),
              minDate: moment(formState.startDate.value).seconds(0).tz('America/New_York'),
              injectTimes: [moment().hours(23).minutes(59).seconds(0)],
              disabled: self.isStaging
                ? false
                : props.formData?.endDate &&
                  !isNew &&
                  moment()
                    .tz('America/New_York')
                    .isAfter(moment(props.formData.endDate).seconds(0).tz('America/New_York')),
            })}
          </Half>
        </Row>
        {form.renderField(FieldNames.textColor)}
        {form.renderField(FieldNames.backgroundColor)}
        {form.renderField(FieldNames.footerTermsText)}
        {form.renderField(FieldNames.couponCode)}
        {form.renderField(FieldNames.autoApplyCouponOnClick)}
        {form.renderField(FieldNames.redirectURL)}
        {form.renderField(FieldNames.isForNonAuthUsersOnly)}
        {form.renderField(FieldNames.targetingTypes)}
      </FieldSet>
      <SaveButton
        isNew={props.isNew}
        handleConfirm={self.handleConfirm}
        model={props.formData}
        hasChanges={form.hasChanges}
        isValid={Boolean(form.isValid)}
      />
    </Block>
  )
}

function useController(props: Props) {
  const isStaging = useIsEnvironment(NodeEnv.staging)

  const form = useForm({
    fieldDefinitions: formDefinition,
    formData: {
      ...props.formData,
      couponCode: props.formData?.coupon?.code,
      targetingTypes: props.formData?.targetingTypes?.map((name) => ({ id: name, name })),
    },
  })

  const handleConfirm = () => {
    if (!isStaging) {
      const start = moment(form.formState.startDate.value).seconds(0).tz('America/New_York')
      const end = moment(form.formState.endDate.value).seconds(0).tz('America/New_York')
      const now = moment().seconds(0).tz('America/New_York')

      if (start.isAfter(end)) {
        return alert('Start date should be after the end date!')
      }

      if (end.isBefore(now)) {
        return alert('End date should be in the future!')
      }
    }

    form.serialize((data: any) => {
      const targetingTypes = data.targetingTypes?.map(({ name }: { name: string }) => name)
      const formData = { ...data, targetingTypes } as PromoBanner

      return props.onConfirm({
        ...formData,
        isForNonAuthUsersOnly: Boolean(formData.isForNonAuthUsersOnly),
        autoApplyCouponOnClick: Boolean(formData.autoApplyCouponOnClick),
      })
    })
  }

  return { form, handleConfirm, isStaging }
}
