import type { ApiFeatureFlags, ApiFeatureFlag } from '~/app/common/api/types'
import { DataStatus } from '~/app/common/types'

/**
 * Sets the user feature flags from the api response
 *
 * @param state - Root store
 *
 * @returns Mutated state with user feature flags
 */
export function setUserFeatureFlagsLoaded(state: Record<string, any>) {
  return (data: ApiFeatureFlags) => ({
    ...state,
    userFeatureFlags: {
      data: data.reduce<Record<string, ApiFeatureFlag['value']>>((flags, flag) => {
        flags[flag.name] = flag.value
        return flags
      }, {}),
      status: DataStatus.loaded,
    },
  })
}

/**
 * Sets the user feature flags status to loading
 *
 * @param state - Root store
 *
 * @returns Mutated state with loading status
 */
export const setUserFeatureFlagsLoading = (state: Record<string, any>) => () => ({
  ...state,
  userFeatureFlags: {
    data: null,
    status: DataStatus.loading,
  },
})

/**
 * Sets the user feature flags status to error
 *
 * @param state - Root store
 *
 * @returns Mutated state with error status
 */
export const setUserFeatureFlagsError = (state: Record<string, any>) => () => ({
  ...state,
  userFeatureFlags: {
    data: null,
    status: DataStatus.error,
  },
})
