import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'
import Button from '~/app/components/Button/Button'

export const ListWrapper = styled.ul`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`

export const SectionName = styled.h3`
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 3rem;
`

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Header = styled.header`
  background: ${(props) => props.theme.color.tint};
  padding: 49px;
  position: relative;
  margin-bottom: 21px;
`

export const Title = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 18px;
`

export const Info = styled.p`
  font-size: 1rem;
`

export const Content = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.midGray};
  padding: 0 15px 61px;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.darkGray};
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 29px;
  }
  ${media.lg} {
    padding: 0 75px 61px;
  }
`

export const TotalWrapper = styled.div`
  position: relative;
  min-height: 5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-content: center;
  border-top: 0.1rem solid ${(props) => props.theme.darkGray};
  font-size: 1rem;
`

export const Summary = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  margin-bottom: 2rem;
  ${media.lg} {
    margin-bottom: 0;
  }
`

export const SummaryLabel = styled.span`
  margin-right: 2.1rem;
`

export const ProductsSummary = styled.ul`
  margin-right: 4rem;
  text-align: left;
  margin-block-start: 0rem;
`

export const Product = styled.li`
  margin-bottom: 0.5rem;
  list-style: none;
`

export const Total = styled.span`
  font-weight: bold;
`
export const SubmitButton = styled(Button)`
  margin: 30px;
`
