import { range } from 'ramda'
import queryString from 'query-string'
import moment from 'moment-timezone'
import {
  getStartOfWeek,
  getEndOfWeek,
  getStartOfFourWeek,
  getStartOfMonth,
  getStartOfQuarter,
  getEndOfFourWeek,
  getEndOfMonth,
  getEndOfQuarter,
  DAYS,
  MONTHS,
  API_DATE_FORMAT,
  FE_DATE_FORMAT,
  FE_DATE_FORMAT_MONTH,
} from '~/app/utils/dateHelper'
const columnMappings = {
  '4week': {
    agg: '4WTD',
    prev: '4W/4W',
  },
  month: {
    agg: 'MTD',
    prev: 'M/M',
  },
  quarter: {
    agg: 'QTD',
    prev: 'Q/Q',
  },
  week: {
    agg: 'WTD',
    prev: 'W/W',
  },
}
export const getCurrentViewType = () => queryString.parse(location.search)['view-type'] || 'week'
export const getFromDate = () => {
  const viewType = getCurrentViewType()

  if (viewType === '4week') {
    return queryString.parse(location.search)['from-date'] || getStartOfFourWeek()
  } else if (viewType === 'month') {
    return queryString.parse(location.search)['from-date'] || getStartOfMonth()
  } else if (viewType === 'quarter') {
    return queryString.parse(location.search)['from-date'] || getStartOfQuarter()
  }

  return queryString.parse(location.search)['from-date'] || getStartOfWeek()
}
export const getToDate = () => {
  const viewType = getCurrentViewType()

  if (viewType === '4week') {
    return queryString.parse(location.search)['to-date'] || getEndOfFourWeek()
  } else if (viewType === 'month') {
    return queryString.parse(location.search)['to-date'] || getEndOfMonth()
  } else if (viewType === 'quarter') {
    return queryString.parse(location.search)['to-date'] || getEndOfQuarter()
  }

  return queryString.parse(location.search)['to-date'] || getEndOfWeek()
}
export const getDashboardHeader = () => {
  const viewType = getCurrentViewType()
  const fromDate = moment(getFromDate(), API_DATE_FORMAT)
  const toDate = moment(getToDate(), API_DATE_FORMAT)
  let detailColumns = []

  if (viewType === '4week') {
    detailColumns = range(0, 4).map((index) => {
      const newDate = fromDate.clone().add(index, 'weeks')
      return {
        rowOne: `Week ${newDate.isoWeek()}`,
        rowTwo: newDate.format(FE_DATE_FORMAT),
      }
    })
  } else if (viewType === 'month') {
    detailColumns = [
      {
        rowOne: MONTHS[toDate.month()],
        rowTwo: toDate.format(FE_DATE_FORMAT_MONTH),
      },
    ]
  } else if (viewType === 'quarter') {
    detailColumns = range(0, 3).map((index) => {
      const newDate = fromDate.clone().add(index, 'months')
      return {
        rowOne: MONTHS[newDate.month()],
        rowTwo: newDate.format(FE_DATE_FORMAT_MONTH),
      }
    })
  } else {
    detailColumns = range(0, 7).map((index) => ({
      rowOne: DAYS[index],
      rowTwo: fromDate.clone().add(index, 'days').format(FE_DATE_FORMAT),
    }))
  }

  const aggColumn = columnMappings[viewType].agg
  const prevColumn = columnMappings[viewType].prev
  return {
    aggColumn,
    detailColumns,
    prevColumn,
  }
}
