import type React from 'react'
import styled from 'styled-components'

const StyledFieldSet = styled.fieldset`
  border: none;
  & + & {
    margin-top: 90px;
  }
`

const Legend = styled.legend`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;
  color: ${(props) => props.theme.color.green};
`

interface Props {
  children?: React.ReactNode
  legend: string
}

export function FieldSet({ legend, children }: Props) {
  return (
    <StyledFieldSet>
      <Legend>{legend}</Legend>
      {children}
    </StyledFieldSet>
  )
}
