import { makeModel } from '~/app/utils/storeManager'
import * as selectors from './selectors'

// TODO: Change all code to use alert and change to array
const alert$ = makeModel({
  prefix: 'alert',
  reducers: () => ({
    hideNotification() {
      return { notification: null }
    },
    setNotification(text: string) {
      return { notification: { text } }
    },
  }),
  selectors,
  state: {
    notification: null,
  },
})

export default alert$
