import { pick } from 'ramda'

const setDetails = (state: any) => (details: any) => ({
  ...state,
  details: pick(
    [
      'addresses',
      'carrier',
      'createdAt',
      'deletedAt',
      'email',
      'firstName',
      'id',
      'kids',
      'lastName',
      'onboardingZip',
      'service',
      'shippingInfo',
      'transitDays',
      'type',
      'updatedAt',
      'warehouseId',
      'smsNotification',
      'ltv',
    ],
    details,
  ),
})

export default setDetails
