import * as React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { find, propEq } from 'ramda'
import formatDate from '~/app/utils/formatDate'
import getAgeAtSignup from '~/app/utils/getAgeAtSignup'
import { hisOrHer, heOrShe } from '~/app/common/dataMaps/genderPronouns'
import { Wrapper } from './styled'
import {
  BirthDate,
  Content,
  ContentBlock,
  Header,
  MainInfo,
  Name,
} from '../SubscriptionCard/styled'
import EditKidForm from './EditKidForm'
import Plans from '../SubscriptionCard/Plans'

const EditKid = ({ kid, handleEdit, subscriptions, plans, handleConfirm, handleSave }) => {
  const findByKidId = (kidId) => find(propEq('kidId', kidId))

  const findKidSubscription = (kidId) => findByKidId(kidId)(subscriptions.data)

  const kidSubscriptionData = findKidSubscription(kid.id) || {
    subscriptions: [],
  }
  const sortedSubscriptions =
    kidSubscriptionData.subscriptions.length > 1
      ? sort(kidSubscriptionData.subscriptions)
      : kidSubscriptionData.subscriptions
  const kidData = { ...kid, subscriptions: sortedSubscriptions }
  return (
    <Wrapper>
      <Header>
        <MainInfo>
          <Name>{kid.name}</Name>
          {kidData.subscriptions ? (
            <BirthDate>
              <strong>{hisOrHer[kidData.gender]}</strong> birthdate is{' '}
              <strong>
                {formatDate(kidData.birthdate, {
                  timestamp: false,
                })}
              </strong>
            </BirthDate>
          ) : (
            <span>Complete the information to pick up the plan.</span>
          )}
          <BirthDate>
            <strong>{heOrShe[kidData.gender]}</strong> was
            <strong>{getAgeAtSignup(kidData.birthdate, kidData.subscriptions[0].createdAt)}</strong>
            at signup
          </BirthDate>
        </MainInfo>
      </Header>
      <Content>
        <ContentBlock>
          <EditKidForm
            formData={kidData}
            kid={kidData}
            onConfirm={handleConfirm}
            handleEdit={handleEdit}
            handleSave={handleSave}
          />
        </ContentBlock>
        <ContentBlock>
          <Plans kid={kidData} plans={plans} />
        </ContentBlock>
      </Content>
    </Wrapper>
  )
}

EditKid.propTypes = {
  handleConfirm: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  kid: PropTypes.any,
  plans: PropTypes.any,
  subscriptions: PropTypes.any,
}
export default pure(EditKid)

function sort(data) {
  return data.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1
    }

    if (b.createdAt < a.createdAt) {
      return -1
    }

    return 0
  })
}
