import InputSmallText from '~/app/components/InputTypes/SmallText'
import Select from '~/app/components/InputTypes/Select'
import countryStates from '~/app/common/dataLists/countryStates'
import { required, notPoBox } from '~/app/common/validators'
const formDefinition = [
  {
    label: 'First Name',
    name: 'shippingFirstName',
    type: InputSmallText,
    validators: [required],
  },
  {
    label: 'Last Name',
    name: 'shippingLastName',
    type: InputSmallText,
    validators: [required],
  },
  {
    label: 'Address',
    name: 'shippingAddress',
    type: InputSmallText,
    validators: [required, notPoBox],
  },
  {
    label: 'Apt',
    name: 'shippingApt',
    type: InputSmallText,
  },
  {
    label: 'City',
    name: 'shippingCity',
    type: InputSmallText,
    validators: [required],
  },
  {
    hasEmptyOption: true,
    label: 'State',
    name: 'shippingState',
    options: countryStates,
    type: Select,
    validators: [required],
  },
  {
    label: 'Zip Code',
    name: 'shippingZip',
    type: InputSmallText,
    validators: [required],
  },
]
export default formDefinition
